import React from 'react'
import { 
  Button, ButtonGroup, Grid, CircularProgress, Typography, Box, makeStyles
} from '@material-ui/core'

import AlertIcon from '@material-ui/icons/WarningOutlined';
import PropTypes from 'prop-types';

const useCustomStyles = makeStyles((theme) => ({
  root: { position: 'relative' },
  bottom: { color: '#478E22' },
  top: { color: '#D21F3E', position: 'absolute', left: 0 },
  circle: { strokeLinecap: 'round' },
}));

function CircularProgressWithLabel(props) {
  const classes = useCustomStyles();
  const percentage = Math.round(props.value)
  return (
    <Box position="relative" display="inline-flex">
      <div className={classes.root}>
        <CircularProgress variant="determinate" className={classes.bottom} size={55} thickness={5} {...props} value={100}/>
        <CircularProgress variant="determinate" className={`${classes.top}`} classes={{circle: classes.circle}} size={55} thickness={5} value={percentage}/>
      </div>
      <Box top={0} left={0} bottom={0} right={0} position="absolute" display="flex" alignItems="center" justifyContent="center">
        <Typography variant="caption" component="div" color="textSecondary">{`${percentage}%`}</Typography>
      </Box>
    </Box>
  );
}

CircularProgressWithLabel.propTypes = {
  value: PropTypes.number.isRequired,
};

export default function AlertIndicatorsComponent(props) {
  const [ totals, managed, pendings, categories ] = props.values
  const percentage = pendings/totals*100
  return (
    <>
      <Grid container spacing={0} direction="row" className="ruc-indicators-grid">
        <Grid item xs={12} sm={8} md={4}>
          <ButtonGroup disableElevation variant="contained" className="ruc-indicators-button-group">
            <Button className="ruc-indicators-button-totals" onClick={() => props.filterAlerts('totals')}>
              <label>Totales</label>
              <span>{totals}</span>
            </Button>
            <Button className="ruc-indicators-button-managed" onClick={() => props.filterAlerts('managed')}>
              <label>Gestionadas</label>
              <span>{managed}</span>
            </Button>
            <Button className="ruc-indicators-button-pending" onClick={() => props.filterAlerts('pending')}>
              <label>Pendientes</label>
              <span>{pendings}</span>
            </Button>
          </ButtonGroup>
        </Grid>
        <Grid item xs={12} sm={4} md={1} className="circular-progress-grid">
          { isNaN(percentage) ? null :
            <>
              <CircularProgressWithLabel value={percentage} />
              <span style={{display: 'block', fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif"}}>Pendientes</span>
            </>
          }
        </Grid>
        <Grid item xs={12} md={7}>
          <Grid container justify="center" spacing={2} className="ruc-indicators-button-group">
            {Object.keys(categories||{}).map((key)=>(
              <Grid item key={key}>
                <Button className={`ruc-indicators-button-category-${categories[key].alerts}`} onClick={() => props.filterAlerts(categories[key].id,'category')}>
                  <div className="category-filter-detail">
                    <label>{key}</label>
                    <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                      <Box p={1}>
                        <AlertIcon style={{color: categories[key].color }}/>
                      </Box>
                      <Box p={1}>
                        <span>{categories[key].alerts}</span>
                      </Box>
                    </Box>
                  </div>
                </Button>
              </Grid>
            ))}
          </Grid>
        </Grid>
      </Grid>
      {/* <Table size="small" className="ruc-indicators-table">
        <TableBody>
          <TableRow>
            <TableCell key="buttons">
              <ButtonGroup disableElevation variant="contained" className="ruc-indicators-button-group">
                <Button className="ruc-indicators-button-totals" onClick={() => props.filterAlerts('totals')}>
                  <label>Totales</label>
                  <span>{totals}</span>
                </Button>
                <Button className="ruc-indicators-button-managed" onClick={() => props.filterAlerts('managed')}>
                  <label>Gestionadas</label>
                  <span>{managed}</span>
                </Button>
                <Button className="ruc-indicators-button-pending" onClick={() => props.filterAlerts('pending')}>
                  <label>Pendientes</label>
                  <span>{pendings}</span>
                </Button>
              </ButtonGroup>
            </TableCell>
            <TableCell key="progress" align="center">
              <Grid container spacing={0} direction="row">
                <Grid item xs={6} className="ruc-indicators-pending-progress">
                  { isNaN(percentage) ? null :
                    <>
                      <CircularProgressWithLabel value={percentage} />
                      <span style={{display: 'block'}}>Pendientes</span>
                    </>
                  }
                </Grid>
                <Grid item xs={6} className="ruc-indicators-by-category">
                  <Grid container spacing={0} direction="row">
                    {Object.keys(categories||{}).map((key)=>(
                      <Grid item xs={6} key={key}>
                        <ListItem button className={`ruc-alert-priority-${categories[key].alerts}`}  onClick={() => props.filterAlerts(categories[key].id,'category')}>
                          <ListItemIcon>
                            <AlertIcon style={{color: categories[key].color }}/>
                          </ListItemIcon>
                          <ListItemText primary={`${categories[key].alerts} ${key}`} />
                        </ListItem>
                      </Grid>
                    ))}
                  </Grid>
                </Grid>
              </Grid>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table> */}
    </>
  )
}