import React from 'react'
import { useHistory } from 'react-router-dom'
import classnames from 'classnames'
import { pathOr } from 'ramda'
import { TableCell, Tooltip, makeStyles, IconButton } from '@material-ui/core'
import { mdiAlertCircle, mdiMapClock, mdiEyeSettingsOutline, mdiCamera, mdiDotsHorizontal } from '@mdi/js'
import { ProgressComponent, SvgIconComponent } from '../..'
import { parseDate, parseTime, i18n } from '../../../services/util'


const getPath = pathOr('--')

const styles = makeStyles(theme => ({
    progress: {
        minWidth: 150,
    },
    details: {
        '& svg': {
            padding: '5px 5px 0'
        }
    },
    timeNestedCell: {
        '& p': {
            padding: 0,
            margin: 0,
        },
        '& p:last-child': {
            color: '#f1ea00',
            fontWeight: 600,
        }
    },
    highlight: {
        color: '#f1ea00',
        fontWeight: 600,
    },
    center: {
        textAlign: 'center',
    },
    tooltipArrow: {
        fontSize: '.8rem'
    }
}))

function TableCellComponent(props) {
    const { data, type, path, image, divider, tooltip = [], colors, classStyles, align,
        showDetails, mapLink, showItems, onFlter, showComments } = props

    const { push } = useHistory()
    const classes = styles()
    const totalEventsProgress = parseFloat(getPath(['meta', 'table', 'events', 'total'], data)).toFixed(2)

    function setFilter({path, filter, redirect}, data) {
        return function () {
            onFlter({ [filter]: getPath(path, data)}, redirect)
        }
    }

    switch (type) {
        case 'string-nested':
            return (
                <TableCell>
                    <AddTooltips align={align} title={pathOr(false, tooltip, data)}>
                        {path.map((item, key) => {
                            return `${getPath(item, data)}${key + 1 < path.length ? divider : ''}`
                        })}
                    </AddTooltips>
                </TableCell>
            )
        case 'date-nested':
            return (
                <TableCell>
                    <AddTooltips align={align} title={pathOr(false, tooltip, data)}>
                        {path.map((item, key) => {
                            return `${parseDate(getPath(item, data))}${key + 1 < path.length ? divider : ''}`
                        })}
                    </AddTooltips>
                </TableCell>
            )
        case 'time-nested':
            return (
                <TableCell className={classnames(classes.timeNestedCell, classes[align])}>
                    <AddTooltips align={align} title={parseTime(pathOr(false, tooltip, data))}>
                        {path.map((item, key) => {
                            return <p key={key}>{parseTime(getPath(item, data))}{key + 1 < path.length ? divider : ''}</p>
                        })}
                    </AddTooltips>
                </TableCell>
            )
        case 'progress-table':
            return (
                <TableCell className={classes.progress}>
                    <ProgressComponent sections={[
                        { color: colors.pending, percentage: parseFloat(getPath(['meta', 'table', 'events', 'pending'], data) * 100 / totalEventsProgress).toFixed(2) },
                        { color: colors.failure, percentage: parseFloat(getPath(['meta', 'table', 'events', 'failure'], data) * 100 / totalEventsProgress).toFixed(2) },
                        { color: colors.success, percentage: parseFloat(getPath(['meta', 'table', 'events', 'success'], data) * 100 / totalEventsProgress).toFixed(2) },
                        { color: colors.partial, percentage: parseFloat(getPath(['meta', 'table', 'events', 'partial'], data) * 100 / totalEventsProgress).toFixed(2) },
                    ]} />
                </TableCell>
            )
        case 'date':
            return (
                <AddTooltips align={align} title={pathOr(false, tooltip, data)}>
                    <TableCell>
                        {parseDate(getPath(path, data))}
                    </TableCell>
                </AddTooltips>
            )
        case 'time-string':
            return (
                <TableCell className={classnames(classes[classStyles], classes[align])}>
                    <AddTooltips align={align} title={parseTime(pathOr(false, tooltip, data))}>
                        {parseTime(getPath(path, data))}
                    </AddTooltips>
                </TableCell>
            )
        case 'string':
            const text = getPath(path, data)
            const res = i18n(['routes', 'table_content', `${text}`.toLowerCase()], text)
            return (
                <TableCell className={classnames(classes[classStyles], classes[align])}>
                    <AddTooltips align={align} title={pathOr(false, tooltip, data)}>
                        {res}
                    </AddTooltips>
                </TableCell>
            )
        case 'comments':
            const comments = pathOr(null, path, data)
            const has_images = data.attributes.has_images
            return (
                <TableCell className={classes.center}>
                    <IconButton onClick={showComments(data.id, comments)} disabled={!comments}>
                        <SvgIconComponent  path={mdiDotsHorizontal} color={!comments ? 'disabled' : 'primary'}/>
                    </IconButton>
                    <a href={getPath(image, data)} target="_blank" rel="noopener noreferrer">
                        <IconButton>
                            <SvgIconComponent  path={mdiCamera} color={has_images ? 'primary' : 'disabled'}/>
                        </IconButton>
                    </a>
                </TableCell>
            )
        case 'observations':
            return (
                <TableCell className={classes.center}>
                    <IconButton onClick={showDetails(data)}>
                        <SvgIconComponent  path={mdiEyeSettingsOutline}/>
                    </IconButton>
                </TableCell>
            )
        case 'details':
            let id
            if (data.type === 'item') {
                id = data.relationships.route.data.id
            } else {
                id = data.id
            }
            return (
                <TableCell>
                    {showItems && (
                        <IconButton onClick={setFilter(showItems, data)}>
                            <SvgIconComponent path={mdiAlertCircle}/>
                        </IconButton>
                    )}
                    {mapLink && (
                        <IconButton onClick={() => { push('/routes/map/' + id)}}>
                            <SvgIconComponent path={mdiMapClock}/>
                        </IconButton>
                    )}
                </TableCell>
            )
        default:
            return <TableCell>Pendiente por definir</TableCell>
    }
}

function AddTooltips({title, align, children}) {
    const classes = styles()
    let placement

    switch (align) {
        case 'center':
            placement = 'top'
            break
        case 'rigth':
            placement = 'top-end'
            break
        default:
            placement = 'top-start'
            break
    }

    if (typeof title === 'string') {
        return <Tooltip title={title} placement={placement} arrow classes={{ tooltipArrow: classes.tooltipArrow }}><div>{children}</div></Tooltip>
    }
    return <React.Fragment>{children}</React.Fragment>
}

export default TableCellComponent
