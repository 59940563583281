/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { path, isEmpty, pathOr, isNil } from 'ramda'
import styles from './styles'
import * as FileSaver from "file-saver";
import * as XLSX from "xlsx";
import { autocomplete, buttonContainer } from '../../RoutesGeneral/RoutesFilters/styles'
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import { SvgIconComponent } from '../../../components'
import { makeStyles } from '@material-ui/core/styles';

import {
  Table, TableRow, TableCell, TableBody, Collapse, Box, Typography, IconButton, Tooltip, SvgIcon, Button, Grid, TextField, Divider, Paper, Chip, ListItem, Menu
} from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'

import { mdiInformation, mdiHistory, mdiInformationOutline, mdiCheckCircle, mdiEmailOpen, mdiCloseCircle, mdiBusAlert, mdiFileExcel, mdiFilterMenu } from '@mdi/js'
import AlertIcon from '@material-ui/icons/WarningOutlined';
import AlertsTotals from '@material-ui/icons/NotificationsOutlined';
import AlertsManaged from '@material-ui/icons/NotificationsOffOutlined';
import AlertsPendings from '@material-ui/icons/NotificationsActiveOutlined';
import SearchIcon from '@material-ui/icons/Search';
import { PaginatorComponent } from '../..'

import { i18n as i18nService, stringToSnakeCase, filterDataParser, getCurrentDate } from '../../../services/util'

import {
  fetchAlertsCsv as _fetchAlertsCsv,
  fetchAlerts as _fetchAlerts,
  markAsReadAlert as _markAsReadAlert,
  markAsManagedAlert as _markAsManagedAlert
} from '../../../redux/amm/alerts/actions'

import { ModalBuilder as RucModalBuilder, TableSortHeadBuilder as RucTableSortHeadBuilder } from '../../../components/Ruc/'
import { AlertIndicatorCardComponent as IndicatorCard, AlertPopupComponent as AlertPopup, AlertHistoryComponent as AlertHistory } from '../../../components'

const mapStateToProps = (state,ownProps) => ({
  props: ownProps,
  token: path(['user', 'auth_token'], state),
  indicatorsValues: path(['alerts', 'indicators'], state),
  header: path(['alerts', 'header'], state),
  pagination: path(['alerts', 'response', 'pagination'], state),
  alertsFullData: path(['alerts', 'response'], state),
  alertsCsvFullData: path(['alertsCsv', 'response'], state),
  rightPanelShow: path(['uiStates', 'rightPanel', 'show'], state),
  clusters: pathOr([], ['user', 'info', 'data', 'meta', 'clusters'], state),
})

const mapDispatchToProps = dispatch => ({
  fetchAlertsCsv: (token,qs) => dispatch(_fetchAlertsCsv(token,qs)),
  fetchAlerts: (token,alertAction,qs) => dispatch(_fetchAlerts(token,alertAction,qs)),
  markAsReadAlert: (token,alertId,action) => dispatch(_markAsReadAlert(token,alertId,action)),
  markAsManagedAlert: (token,alertId,body,action) => dispatch(_markAsManagedAlert(token,alertId,body,action)),
})

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row, header, token, afterOpenModalCallback, afterCloseModalCallback, afterSendForm, currentAction } = props;
  const [open, setOpen] = useState(false);
  const classes = useRowStyles();
  const initialValue = { comments: { value: '', error: null } }
  const [formData, setFormData] = useState(initialValue)
  const [modalOpen, setModalOpen] = useState(false);

  function handleChange(input) {
    return function(event) {
      setFormData({
        ...formData,
        [input]: {
          value: event.target.value,
          error: null,
        }
      })
    }
  }

  function handleSubmit() {
    const keys = Object.keys(formData)
    let hasError = false
    let payload = {}

    keys.forEach(key => {
      if (isEmpty(formData[key].value)) {
        hasError = true
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            error: 'El campo no puede estar vacio'
          }
        })
      } else {
        payload = {
          ...payload,
          [key]: formData[key].value
        }
      }
    })

    if ( ! hasError ) {
      afterSendForm(token, row.id, payload, currentAction)
      setModalOpen(false)
    }
  }

  return (
    <>
      <TableRow key={`rowIndex-${row.id}`} className={`${classes.root} ${row.managed_or_closed  ? `ruc-${row.closed ? 'un' : ''}managed-alert` : ''}`}>
        {header.map((h) => (
          <TableCell key={`cellIndex-${row.id}-${h.id}`} className={`ruc-${stringToSnakeCase(h.id)}`}>
            { h.id === 'priority' ? <AlertIcon style={{color: row.category_color}}/> : null}
            { h.id === 'id' ?
              <Chip 
                size="small" 
                label={row.id} 
                style={{backgroundColor: row.managed_or_closed  ? "#478E22" : "#D21F3E", color: 'white', float: 'left'}} 
              /> 
              :
              row[h.id]
            }
            {/* { h.id === 'priority' ?
              <div className="ruc-priority-circle-block" style={{backgroundColor: row.category_color }}>
                {row[h.id]}
              </div> :
              row[h.id]} */}
          </TableCell>
        ))}
        <TableCell style={{ whiteSpace: 'nowrap' }} className={`actions two-icons`}>
          <RucModalBuilder
            buttonClass={`ruc-alert-manage${row.managed_or_closed  ? 'd' : ''}-popup`}
            buttonSize="small"
            buttonTooltip={i18nService(['amm','tooltips',row.managed_or_closed  ? 'review' : 'manage'])}
            buttonIcon={row.managed_or_closed  ? mdiInformation : mdiInformation}
            cbFnAfterOpen={row.managed_or_closed  ? null : afterOpenModalCallback}
            cbArgAfterOpen={[row]}
            cbFnAfterClose={row.managed_or_closed  ? null : afterCloseModalCallback}
            cbArgAfterClose={[token]}
            customClassName="ruc-alert-popup-modal"
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
          >
            <AlertPopup
              managed={row.managed_or_closed }
              alert={row}
              formData={formData}
              i18nService={i18nService}
              handleChange={handleChange}
              handleSubmit={handleSubmit}
              managedIcon={mdiCheckCircle}
              readIcon={mdiEmailOpen}
              closedIcon={mdiCloseCircle}
              unreadIcon={mdiInformationOutline}
              eventUpdateIcon={mdiBusAlert}
            />
          </RucModalBuilder>
          <Tooltip title={i18nService(['amm','tooltips','history'])}>
            <IconButton aria-label="expand row" size="small" onClick={() => setOpen(!open)}>
              <SvgIcon className={open ? 'ruc-info-icon-open' : 'ruc-info-icon-close'}>
                <path d={mdiHistory}></path>
              </SvgIcon>
            </IconButton>
          </Tooltip>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={11} className="ruc-collapse-table-cell">
          <Collapse in={open} timeout="auto" unmountOnExit>
            <AlertHistory
              alert={row}
              i18nService={i18nService}
              managedIcon={mdiCheckCircle}
              readIcon={mdiEmailOpen}
              unreadIcon={mdiInformationOutline}
              closedIcon={mdiCloseCircle}
              commentText={true}
            />
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

function AlertTableComponent(props) {
  const { 
    token, 
    indicatorsValues, 
    header, 
    fetchAlerts, 
    fetchAlertsCsv, 
    markAsReadAlert, 
    markAsManagedAlert, 
    pagination,
    alertsFullData,
    alertsCsvFullData,
    alertAction,
    clusters
  } = props
  const alertsCsv = alertsCsvFullData
  const alerts = path(['set'], alertsFullData)
  const i18n = prop => i18nService(['amm',"alert",prop])
  const [ totals, managed, pendings, categories ] = indicatorsValues

  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();

  const [searchXData, setSearchXData] = useState("");
  const classes = styles()
  const autocompleteClasses = autocomplete()
  const buttonContainerClasses = buttonContainer()
  const i18nFilters = prop => i18nService(['routes', 'filters', prop])
  const dateFormat = 'yyyy/MM/dd'
  const concerns = [
    {value: 'read', name: 'Leída'},
    {value: 'managed', name: 'Gestionada'},
    {value: 'unmanaged_close', name: 'Cerrada'},
    {value: 'event_update', name: 'Actualización evento'}
  ]
  const [getAlertsCsv, setGetAlertsCsv] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);

  // Excel
  useEffect(() => {
    // con los filtros aplicados
    fetchAlertsCsv(token,{...filterDataParser(filtersQs, filtersByView, 'alerts'), format_csv: true, alert_action: alertAction})
    // sin filtros y trae tanto gestioandas como no gestionadas
    // fetchAlertsCsv(token, {format_csv: true, alert_action: alertAction})
  }, [alertAction, getAlertsCsv])

  function getExcelData() {
    const fileName = `alertas_${getCurrentDate('-',true)}`; 
    const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
    const fileExtension = ".xlsx";

    const ws = XLSX.utils.json_to_sheet(alertsCsv);
    const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  }

  const filtersByView = {
    alerts: [
      'by_status', 
      'by_category', 
      'by_string', 
      'by_cluster_id', 
      'by_starts_at[from]', 
      'by_starts_at[to]',
      'by_depot',
      'by_concern'
    ]
  }
  const filtersDefault = {
    'by_status': 'unmanaged',
    'by_category': null,
    'by_string': '',
    'by_cluster_id': null,
    'by_starts_at[from]': null,
    'by_starts_at[to]': null,
    'by_depot': '',
    'by_concern': null,
  }
  const [filtersQs, setFiltersQs] = useState(filtersDefault)
  const [filterSubmit, setFilterSubmit] = useState(false)

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    const page = pathOr(1, ['alerts', 'pagination', 'page'], alerts)
    fetchAlerts(token,alertAction,{...filterDataParser(filtersQs, filtersByView, 'alerts'), page})
  },[fetchAlerts,token,alertAction])

  useEffect(() => {
    if(filterSubmit){
      const page = pathOr(1, ['alerts', 'pagination', 'page'], alerts)
      fetchAlerts(token,alertAction,{...filterDataParser(filtersQs, filtersByView, 'alerts'), page})
      fetchAlertsCsv(token,{...filterDataParser(filtersQs, filtersByView, 'alerts'), format_csv: true, alert_action: alertAction})
    }
  },[filtersQs])

  const markAsReadAfterOpenModal = alert => {
    if ( alert.concern.length === 0 ) {
      markAsReadAlert(token,alert.id,alertAction)
    }
  }

  const reloadAlerts = token => {
    const page = pathOr(1, ['alerts', 'pagination', 'page'], alerts)
    fetchAlerts(token,alertAction,{...filterDataParser(filtersQs, filtersByView, 'alerts'), page})
  }

  function handleSearch(event) {
    setSearchXData(event.target.value)
  }

  function submitSearchXData(){
    if(searchXData.length > 0){
      filtersHandleChange({name: 'by_string', value: searchXData})
    } else {
      filtersHandleChange({name: 'by_string', value: ''})
    }
  }

  let headerData = []
  for(let h of header) {
    headerData.push({ label: i18n(h.label), key: h.id })
  }

  function paginationChange(event, page) {
    fetchAlerts(token,alertAction,{...filterDataParser(filtersQs, filtersByView, 'alerts'), page, show_all: 'false'})
  }

  function filtersHandleChange(event) {
    let eventName = event.target instanceof HTMLElement ? event.target.name : event["name"]
    let eventValue = event.target instanceof HTMLElement ? event.target.value : event["value"]
    let filters = {...filtersQs, [eventName]: eventValue }
    if(eventName === "by_status" && eventValue === "all"){
      filters['by_category'] = null
      filters['by_string'] = ''
      setSearchXData('')
    }
    setFiltersQs(filters)
    setFilterSubmit(true)
  }

  function selectHandleChange(name) {
    return function (event, value, reason) {
      setFiltersQs({...filtersQs, [name]: value })
      setFilterSubmit(false)
    }
  }

  function datePickerHandleChange(name) {
    return function(value) {
      setFiltersQs({ ...filtersQs, [name]: value })
      setFilterSubmit(false)
    }
  }

  function textHandleChange(event) {
    setFiltersQs({...filtersQs, [event.target.name]: event.target.value })
    setFilterSubmit(false)
  }

  function clearHandler() {
    let resetedFilters = filterDataParser(filtersDefault, filtersByView, 'alerts')
    setFiltersQs(resetedFilters)
    setFilterSubmit(true)
    handleClose()
  }

  function submitHandler() {
    const page = pathOr(1, ['alerts', 'pagination', 'page'], alerts)
    fetchAlerts(token,alertAction,{...filterDataParser(filtersQs, filtersByView, 'alerts'), page})
    setGetAlertsCsv(true)
    handleClose()
  }

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };
  
  return (
    <>
      <Grid container direction="row" justify="space-between" alignItems="center" spacing={0} className="ruc-indicators-general">
        <Grid item className={classes.submenuContainer}>
          <Typography variant="h6" style={alertAction !== 'history' ? {height: "100%", padding: 0} : {}}>
            {/* <SvgIcon className="ruc-title-first-icon"><path d={mdiBell}/></SvgIcon> */}
            <SvgIcon><path d={props.titleIcon}/></SvgIcon>
            {`Alertas ${props.alertTitle}`}
          </Typography>
        </Grid>
        {alertAction === 'history' && 
          <Grid item>
            <Grid container>
              <Grid item>
                <Button aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick} >
                  {i18nService(['routes', 'filters', 'main_button'])} <SvgIconComponent path={mdiFilterMenu} />
                </Button>
                <Menu
                  id="simple-menu"
                  anchorEl={anchorEl}
                  keepMounted
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  <ListItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format={dateFormat}
                        id="by_starts_at[from]"
                        label={i18nFilters('by_starts_at_from')}
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        disableFuture
                        value={filtersQs['by_starts_at[from]']}
                        onChange={datePickerHandleChange('by_starts_at[from]')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </ListItem>
                  <ListItem>
                    <MuiPickersUtilsProvider utils={DateFnsUtils}>
                      <KeyboardDatePicker
                        variant="inline"
                        format={dateFormat}
                        id="by_starts_at_to"
                        label={i18nFilters('by_starts_at_to')}
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        disabled={isNil(filtersQs['by_starts_at[from]'])}
                        value={filtersQs['by_starts_at[to]']}
                        onChange={datePickerHandleChange('by_starts_at[to]')}
                        KeyboardButtonProps={{
                          'aria-label': 'change date',
                        }}
                        autoOk={true}
                      />
                    </MuiPickersUtilsProvider>
                  </ListItem>
                  <ListItem>
                    <Autocomplete
                      id="by_cluster_id"
                      options={clusters}
                      disabled={isEmpty(clusters)}
                      classes={autocompleteClasses}
                      getOptionLabel={options => options.name}
                      value={filtersQs.by_cluster_id || null}
                      onChange={selectHandleChange('by_cluster_id')}
                      renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_cluster_id')} variant="outlined" />}
                    />
                  </ListItem>
                  <ListItem>
                    <TextField
                      id="by_depot"
                      name="by_depot"
                      fullWidth
                      margin="dense"
                      label={i18nFilters('by_depot')}
                      variant="outlined"
                      value={filtersQs.by_depot || ''}
                      onChange={textHandleChange}
                    />
                  </ListItem>
                  <ListItem>
                    <Autocomplete
                      id="by_concern"
                      options={concerns}
                      disabled={isEmpty(concerns)}
                      classes={autocompleteClasses}
                      getOptionLabel={options => options.name}
                      value={filtersQs.by_concern || null}
                      onChange={selectHandleChange('by_concern')}
                      getOptionSelected={(option, value) => option.value === value.value}
                      renderInput={params => <TextField {...params} margin="dense" label={"Estado de alerta"} variant="outlined" />}
                    />
                  </ListItem>
                  <ListItem classes={buttonContainerClasses}>
                    <Button variant="outlined" onClick={clearHandler}>{i18nFilters('clean_button')}</Button>
                    <Button variant="outlined" color="primary" onClick={submitHandler}>{i18nFilters('apply_button')}</Button>
                  </ListItem>
                </Menu>
              </Grid>
            </Grid>
          </Grid>
        }
        { props.indicators ?
        <Grid item xs={10} md={9}  className="ruc-indicators-button-group">
          <Grid container spacing={0} direction="row">
            <Grid item xs={3}>
              <TextField
                label="Buscar"
                variant="outlined"
                autoComplete="off"
                value={searchXData}
                onChange={handleSearch}
                style={{ width: "100%", marginTop: "17px"}}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end" onClick={() => submitSearchXData()}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
            <Grid item xs={3}>
              <IndicatorCard item="pending" callback={filtersHandleChange} icon={<AlertsPendings />} title="Pendientes" labelValue={pendings} totals={totals} name="by_status" value={"unmanaged"}/>
            </Grid>
            <Grid item xs={3}>
              <IndicatorCard item="managed" callback={filtersHandleChange} icon={<AlertsManaged />} title="Gestionadas" labelValue={managed} totals={totals}  name="by_status" value={"managed"}/>
            </Grid>
            <Grid item xs={3}>
              <IndicatorCard item="totals" callback={filtersHandleChange} icon={<AlertsTotals />} title="Totales" labelValue={totals}  name="by_status" value="all"/>
            </Grid>
          </Grid>
        </Grid> : null }
      </Grid>
      { props.indicators && indicatorsValues ?
        <Grid container spacing={0} direction="row" className="ruc-indicators-grid">
          <Grid item xs={12}>
            <Grid container justify="center" spacing={2} className="ruc-indicators-button-group">
              {Object.keys(categories||{}).map((key)=>(
                <Grid item key={key}>
                  <Button className={`ruc-indicators-button-category-${categories[key].alerts}`} onClick={() => filtersHandleChange({name: 'by_category', value: categories[key].id})}>
                    <div className="category-filter-detail">
                      <label>{key}</label>
                      <Box display="flex" justifyContent="flex-start" m={1} p={1}>
                        <Box p={1}>
                          <AlertIcon style={{color: categories[key].color }}/>
                        </Box>
                        <Box p={1}>
                          <span>{categories[key].alerts}</span>
                        </Box>
                      </Box>
                    </div>
                  </Button>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </Grid>
        : null
      }
      <Paper classes={{ root: classes.paperRoot }}>
        <Table>
          <RucTableSortHeadBuilder
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            headCells={header}
            i18n={i18n}
          >
          <TableCell className="actions three-icons">
            {i18n('actions')}
            <SvgIcon onClick={(e) => getExcelData()} style={{ cursor: 'pointer' }}>
              <title id="title">Descargar alertas</title>
              <path d={mdiFileExcel}></path></SvgIcon>
          </TableCell>
          </RucTableSortHeadBuilder>
          <TableBody>
            {alerts && stableSort(alerts, getComparator(order, orderBy)).map((alert, index) => (
              <Row
                key={alert.id}
                row={alert}
                header={header}
                token={token}
                afterOpenModalCallback={markAsReadAfterOpenModal}
                afterCloseModalCallback={reloadAlerts}
                afterSendForm={markAsManagedAlert}
                currentAction={alertAction}
              />
            ))}
          </TableBody>
        </Table>
      </Paper>
      <Divider />
      <PaginatorComponent onChange={paginationChange} {...pagination}/>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertTableComponent)
