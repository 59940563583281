import React, { useState } from 'react'
import { path } from 'ramda'
import { connect } from 'react-redux'
import classnames from 'classnames'
import { Divider, ListItem, List, Container, ListItemIcon, ListItemText, Collapse, Paper } from '@material-ui/core'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
import { mdiAccountCog, mdiAccountGroup, mdiAccountKey, mdiFormatListBulleted, mdiAccountMultiplePlus, mdiPlusCircle, mdiCogBox, mdiTranslate, mdiPalette } from '@mdi/js'

import { ControlPanelComponent, SvgIconComponent, AddRolesComponent, ListRolesComponent, AddPermissionsComponent, ListUsersComponent,
    ListPermissionsActionsComponent, EditActionsRoleComponent, LoaderComponent, ListPermissionsComponent, EditRoleActionsComponent,
    AddUserComponent, TranslateComponent, CustomizeComponent } from '../../components'
import { /*InConstruccion, */NotFoundPage } from '..'
import { toggleLeftPanel as _toggleLeftPanel } from '../../redux/ui-states/actions'
import { Switch, Route } from 'react-router-dom'
import { i18n as i18nService } from '../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    loading: path(['request', 'loading'], state),
    leftPanelShow: path(['uiStates', 'leftPanel', 'show'], state),
})

const mapDispatchToProps = dispatch => ({
    toggleLeftPanel: () => dispatch(_toggleLeftPanel()),
})

function AdminPage(props) {
    const {
        // States
        leftPanelShow,
        loading,

        // Actions
        toggleLeftPanel,

        // Rest
        history
    } = props
    const classes = styles()
    const i18n = prop => i18nService(['admin', 'menu', prop])
    const routes = {
        index: '/admin',
        addRoles: '/admin/roles/new',
        editRoles: '/admin/roles/:roleId/edit',
        listRoles: '/admin/roles',
        editRolesActions: '/admin/roles/:roleId/actions',
        addPermissions: '/admin/permissions/new',
        editPermissions: '/admin/permissions/:permissionId/edit',
        listPermissions: '/admin/permissions',
        listPermissionActions: '/admin/permissions/:permissionId/actions',
        listPermissionActionsAdd: '/admin/permissions/:permissionId/actions/new',
        listPermissionActionsEdit: '/admin/permissions/:permissionId/actions/:actionId/edit',
        listUsers: '/admin/users',
        addUsers: '/admin/users/new',
        editUsers: '/admin/users/:userId/edit',
        configI18n: '/admin/configurations/i18n',
        configCustomization: '/admin/configurations/customization',
    }
    const [menuShow, setMenuShow] = useState({
        roles: true,
        permissions: true,
        users: true,
        menus: true,
        config: true,
    })

    function handleChange(param) {
        return function () {
            setMenuShow({
                ...menuShow,
                [param]: !menuShow[param],
            })
        }
    }

    function pushTo(link) {
        return function() {
            history.push(link)
        }
    }

    return (
        <React.Fragment>
            <LoaderComponent visible={loading} />
            <ControlPanelComponent open={leftPanelShow} change={toggleLeftPanel}>
                <List>
                    <Divider />
                    <ListItem button onClick={handleChange('permissions')}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <SvgIconComponent path={mdiAccountKey} />
                        </ListItemIcon>
                        <ListItemText primary={i18n('permissions')} />
                        {menuShow.permissions ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuShow['permissions']} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} onClick={pushTo(routes.addPermissions)}>
                                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                    <SvgIconComponent path={mdiPlusCircle} />
                                </ListItemIcon>
                                <ListItemText primary={i18n('permissions_add')} />
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={pushTo(routes.listPermissions)}>
                                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                    <SvgIconComponent path={mdiFormatListBulleted} />
                                </ListItemIcon>
                                <ListItemText primary={i18n('permissions_list')} />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button onClick={handleChange('roles')}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <SvgIconComponent path={mdiAccountGroup} />
                        </ListItemIcon>
                        <ListItemText primary={i18n('roles')} />
                        {menuShow.roles ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuShow['roles']} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            <ListItem button className={classes.nested} onClick={pushTo(routes.addRoles)}>
                                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                    <SvgIconComponent path={mdiAccountMultiplePlus} />
                                </ListItemIcon>
                                <ListItemText primary={i18n('roles_add')} />
                            </ListItem>
                            <ListItem button className={classes.nested} onClick={pushTo(routes.listRoles)}>
                                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                    <SvgIconComponent path={mdiFormatListBulleted} />
                                </ListItemIcon>
                                <ListItemText primary={i18n('roles_list')} />
                            </ListItem>
                        </List>
                    </Collapse>

                    <ListItem button onClick={handleChange('users')}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <SvgIconComponent path={mdiAccountCog} />
                        </ListItemIcon>
                        <ListItemText primary={i18n('users')} />
                        {menuShow.users ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuShow['users']} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={pushTo(routes.listUsers)}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <SvgIconComponent path={mdiFormatListBulleted} />
                            </ListItemIcon>
                            <ListItemText primary={i18n('users_list')} />
                        </ListItem>
                        </List>
                    </Collapse>

                    {/* <ListItem button onClick={handleChange('menus')}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <SvgIconComponent path={mdiPlaylistEdit} />
                        </ListItemIcon>
                        <ListItemText primary="Administrar Menus" />
                        {menuShow.menus ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={menuShow['menus']} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <SvgIconComponent path={mdiPlaylistPlus} />
                            </ListItemIcon>
                            <ListItemText primary="Agregar Menus" />
                        </ListItem>
                        <ListItem button className={classes.nested}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <SvgIconComponent path={mdiFormatListBulleted} />
                            </ListItemIcon>
                            <ListItemText primary="Listar Menus" />
                        </ListItem>
                        </List>
                    </Collapse> */}
                    <ListItem button onClick={handleChange('config')}>
                        <ListItemIcon classes={{ root: classes.listItemIcon }}>
                            <SvgIconComponent path={mdiCogBox} />
                        </ListItemIcon>
                        <ListItemText primary={i18n('config')} />
                    </ListItem>

                    <Collapse in={menuShow['config']} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                        <ListItem button className={classes.nested} onClick={pushTo(routes.configI18n)}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <SvgIconComponent path={mdiTranslate} />
                            </ListItemIcon>
                            <ListItemText primary={i18n('config_i18n')} />
                        </ListItem>
                        <ListItem button className={classes.nested} onClick={pushTo(routes.configCustomization)}>
                            <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                <SvgIconComponent path={mdiPalette} />
                            </ListItemIcon>
                            <ListItemText primary={i18n('config_customization')} />
                        </ListItem>
                        </List>
                    </Collapse>
                </List>
            </ControlPanelComponent>
            <div className={classnames(classes.root, { [classes.openLeftControl]: leftPanelShow })}>
                <Container maxWidth={false} className={classes.routesViewRoot}>
                    <Switch>
                        <Route exact path={routes.addRoles}>
                            <Paper className={classes.formPaper}>
                                <AddRolesComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.editRoles}>
                            <Paper className={classes.formPaper}>
                                <AddRolesComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.listRoles}>
                            <Paper className={classes.listPaper}>
                                <ListRolesComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.editRolesActions}>
                            <div className={classes.editRoleActionContainer}>
                                <EditRoleActionsComponent />
                            </div>
                        </Route>

                        <Route exact path={routes.addPermissions}>
                            <Paper className={classes.formPaper}>
                                <AddPermissionsComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.editPermissions}>
                            <Paper className={classes.formPaper}>
                                <AddPermissionsComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.listPermissions}>
                            <Paper className={classes.listPaper}>
                                <ListPermissionsComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.listPermissionActions}>
                            <Paper className={classes.listPaper}>
                                <ListPermissionsActionsComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.listPermissionActionsEdit}>
                            <Paper className={classes.formPaperWide}>
                                <EditActionsRoleComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.listPermissionActionsAdd}>
                            <Paper className={classes.formPaperWide}>
                                <EditActionsRoleComponent />
                            </Paper>
                        </Route>

                        <Route exact path={routes.listUsers}>
                            <Paper className={classes.listPaper}>
                                <ListUsersComponent />
                            </Paper>
                        </Route>
                        <Route exact path={routes.editUsers}>
                            <Paper className={classes.formPaperWide}>
                                <AddUserComponent />
                            </Paper>
                        </Route>

                        <Route exact path={routes.configI18n}>
                            <Paper className={classes.formPaperWide}>
                                <TranslateComponent />
                            </Paper>
                        </Route>

                        <Route exact path={routes.configCustomization}>
                            <Paper className={classes.formPaperWide}>
                                <CustomizeComponent />
                            </Paper>
                        </Route>

                        {/* Default Options */}
                        <Route exact path={routes.index}>
                            <Paper className={classes.listPaper}>
                                <ListRolesComponent />
                            </Paper>
                        </Route>

                        <Route path="*">
                            <NotFoundPage />
                        </Route>
                    </Switch>
                </Container>
            </div>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AdminPage)
