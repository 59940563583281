import React from 'react'
import { Drawer } from '@material-ui/core'

function DetailPanelComponent(props) {
    const {children, ...rest} = props
    return (
        <Drawer variant="persistent" anchor="right" {...rest} >
            {children}
        </Drawer>
    )
}

export default DetailPanelComponent
