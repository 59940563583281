import { mergeRight } from 'ramda'
import {
    FETCH_START,
    FETCH_SUCCESS,
    FETCH_ERROR,
    FETCH_BY_ID_SUCCESS,
    FETCH_ACTIONS_SUCCESS,
} from './actions'

const initialState = {}

export default function userReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)
    switch (type) {
        case FETCH_SUCCESS:
            return merge({ data: payload })
        case FETCH_BY_ID_SUCCESS:
            return merge({ user: payload })
        case FETCH_ACTIONS_SUCCESS:
            return merge({ userActions: payload.permissions || {} })
        case FETCH_ERROR:
            return merge({ error: payload })
        case FETCH_START:
        default:
            return state
    }
}
