import React from 'react'
import PropTypes from 'prop-types'
import { Paper } from '@material-ui/core'
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'

import styles from './styles'

function DatePickerComponent(props) {
    const { date, onChange } = props
    const classes = styles()
    const today = new Date()

    return (
        <div className={classes.container}>
            <Paper>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <DatePicker
                        autoOk
                        orientation="landscape"
                        variant="static"
                        openTo="date"
                        value={date}
                        onChange={onChange}
                        maxDate={today}
                    />
                </MuiPickersUtilsProvider>
            </Paper>
        </div>
    )
}

DatePickerComponent.propTypes = {
    date: PropTypes.object,
    onChange: PropTypes.func,
}

DatePickerComponent.defaultProps = {
    date: new Date(),
    onChange: (e) => console.log(e),
}

export default DatePickerComponent