import Pusher from 'pusher-js';

Pusher.logToConsole = process.env.NODE_ENV === 'development'

const PUSHER_ID = process.env.REACT_APP_PUSHER_ID || 'd02b4fd825ea43aa7a4d'
const PUSHER_CLUSTER = process.env.REACT_APP_PUSHER_CLUSTER
const PUSHER_APP = new Pusher(PUSHER_ID, { cluster: PUSHER_CLUSTER });

export function channelSubscription(channelString,organization_id) {
  return PUSHER_APP.subscribe(`ox-${organization_id}-cx-${channelString}`);
}