import { get } from '../../services/fetchService'
import { addAlertMessage } from '../ui-states/actions'

// Action Types

export const GET_ALL_START = 'INDICATORS_GET_ALL_START'
export const GET_ALL_SUCCESS = 'INDICATORS_GET_ALL_SUCCESS'
export const GET_ALL_ERROR = 'INDICATORS_GET_ALL_ERROR'

// Action Creators

export const fetchDataPromise = async (token, querystring) => get('stats/indicators', querystring || '', {Authorization: token})

export const fetchData = (token, querystring, cb) => async dispatch => {
    dispatch({ type: GET_ALL_START })

    try {
        const payload = await fetchDataPromise(token, querystring)

        dispatch({
            type: GET_ALL_SUCCESS,
            payload,
        })

        if (cb) cb(null, payload)

    } catch (error) {
        if (cb) cb(error, null)
        dispatch({ type: GET_ALL_ERROR })
        dispatch(addAlertMessage(error))
    }
}
