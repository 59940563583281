import React, { useState } from 'react'
import PropTypes from 'prop-types'
import {
    Container,
    TextField,
    FormControl,
    Input,
    Button,
    InputLabel,
    IconButton,
    InputAdornment,
    Typography
} from '@material-ui/core'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import { isEmpty } from 'lodash'
import styles from './styles'
import { EMAIL_REGEX_PATTER } from '../../services/util'

function UserFormComponent(props) {
    const formControlInitialState = {
        type: 'text',
        value: '',
        valid: true,
        pristine: true,
    }

    const errorInitialState = {
        error: null,
        message: '',
    }

    const [formControlEmail, setFormControlEmail] = useState({
        ...formControlInitialState,
        label: 'Email',
    })

    const [formControlPassword, setFormControlPassword] = useState({
        ...formControlInitialState,
        label: 'Password',
        type: 'password'
    })

    const [error, setError] = useState(errorInitialState)

    const classes = styles()

    function handleClickShowPassword(e) {
        e.preventDefault()
        const prevState = {...formControlPassword}
        if (formControlPassword.type === 'password') {
            setFormControlPassword({
                ...prevState,
                type: 'text'
            })
        } else {
            setFormControlPassword({
                ...prevState,
                type: 'password'
            })
        }
    }

    function handleChange(prevState, setter) {
        return function (e) {
            e.preventDefault()
            const { value } = e.target

            setter({
                ...prevState,
                value,
                pristine: false,
            })

            setError(errorInitialState)
        }
    }

    function showErrorEmail(form) {
        return !form.pristine && !checkEmail(form.value)
    }

    function checkEmail(value) {
        return EMAIL_REGEX_PATTER.test(value)
    }

    function handleSubmit(e) {
        e.preventDefault()

        // TODO: Form Validators
        const data = {
            username: formControlEmail.value,
            password: formControlPassword.value,
        }

        props.submit(data)
    }

    function checkEmpty(formInput) {
        return isEmpty(formInput.value) && !formInput.pristine
    }

    return(
        <Container maxWidth="xs">
            <form noValidate>
                <TextField
                    id="email"
                    className={classes.input}
                    error={showErrorEmail(formControlEmail)}
                    type='email'
                    label="Email"
                    autoComplete="off"
                    onChange={handleChange(formControlEmail, setFormControlEmail)}
                />

                <FormControl className={classes.input}>
                    <InputLabel htmlFor="password-input">Password</InputLabel>
                    <Input
                        id="password-input"
                        type={formControlPassword.type}
                        error={checkEmpty(formControlPassword)}
                        value={formControlPassword.value}
                        onChange={handleChange(formControlPassword, setFormControlPassword)}
                        endAdornment={
                            <InputAdornment>
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                >
                                    {formControlPassword.type === 'password' ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>

                <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>Ingresar</Button>
            </form>

            {error.error && <Typography className={classes.error} color="error">Error: {error}</Typography>}
            {/* {errorMessage && <Typography className={classes.error} color="error">Please check your user data</Typography>} */}

        </Container>
    )
}

UserFormComponent.propTypes = {
    submit: PropTypes.func.isRequired
}

export default UserFormComponent
