import { makeStyles } from '@material-ui/core'

export default makeStyles({
    input: {
        width: '100%',
        marginBottom: '10px',
    },

    button: {
        margin: '40px 0',
        width: '100%',
    },

    error: {
        margin: '20px 0',
    },

    matchErrorMessage: {
        margin: '-8px 0 10px',
        display: 'block',
    }
})
