import { i18n } from "./services/util"

export const ROUTES = Object.freeze({
    DEFAULT: {
        path: '/',
        exact: true,
        type: 'private',
        redirect: '/routes'
    },
    ADMIN: {
        label: () => i18n(['main', 'admin_menu_item']),
        component: 'AdminPage',
        path: '/admin',
        exact: false,
        type: 'private'
    },
    LOGIN: {
        label: 'Login',
        component: 'LoginPage',
        path: '/login',
        exact: true,
        type: 'public',
    },
    DEPOTS: {
        label: 'CEDIS',
        component: 'DepotsPage',
        path: '/depots',
        exact: true,
        type: 'private'
    },
    ROUTES: {
        label: () => i18n(['main', 'rutes_menu_item']),
        component: 'RoutesViewPage',
        path: ['/routes', '/routes/map', '/routes/table'],
        exact: true,
        type: 'private'
    },
    ROUTES_DETAIL: {
        label: () => i18n(['main', 'rutes_menu_item']),
        component: 'RoutesDetailPage',
        path: ['/routes/map/:id', '/routes/table/:id'],
        exact: true,
        type: 'private'
    },
    STATS: {
        label: () => i18n(['main', 'stats']),
        component: 'StatsPage',
        path: '/stats',
        exact: true,
        type: 'private',
    },
    AMM: {
        label: () => i18n(['main', 'amm']),
        component: 'AmmPage',
        path: '/amm',
        exact: false,
        type: 'private'
    },
})

export default ROUTES
