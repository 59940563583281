import { jsonToUriSearch } from "./util"
import { HtmlError } from '../models/htmlError'
import { HtmlMessage } from '../models/htmlMessage'

async function fetchBase(path, opt) {
    const url = new URL(process.env.REACT_APP_API_URL)
    const headers = new Headers(opt.headers);

    url.pathname = path
    headers.append('Content-Type', 'application/json')
    headers.append('Accept', 'application/vnd.cm.v1+json')
    headers.append('Access-Control-Allow-Origin', '*')

    const options = {
        method: opt.method,
        headers,
    }


    if (opt.method === 'get' && opt.search) {
        url.search = jsonToUriSearch(opt.search)
    } else if (opt.body) {
        options.body = JSON.stringify(opt.body)
    }

    try {
        const response = await fetch(url, options)

        if (response.ok) {
            const resp = await response.json()
            if (response.headers.get('x-page')) {
                resp.pagination = {
                    page: parseInt(response.headers.get('x-page')),
                    perPage: parseInt(response.headers.get('x-per-page')),
                    total: parseInt(response.headers.get('x-total')),
                }
            }

            resp.responseStatus = new HtmlMessage(response.status, response.statusText, 'success')
            return resp
        }
        // if error throw to catch
        const { message } = await response.json()
        throw new HtmlError(response.status, response.statusText, message || response.statusText)
    } catch (error) {
        // Catch the error, do something and dispatch next catch
        console.error(error)
        throw error
    }

}

export const get = (path = '', search, headers) => {
    const method = 'get'
    return fetchBase(path, { method, search, headers })
}

export const post = (path = '', body, headers) => {
    const method = 'post'
    return fetchBase(path, { method, body, headers })
}

export const put = (path = '', body, headers) => {
    const method = 'put'
    return fetchBase(path, { method, body, headers })
}

export const del = (path = '', headers) => {
    const method = 'delete'
    return fetchBase(path, { method, headers })
}
