import { get, post, put, del } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../models/htmlMessage'

// Actions types
export const GET_ROLES_START = 'GET_ROLES_START'
export const GET_ROLES_SUCCESS = 'GET_ROLES_SUCCESS'
export const GET_ROLE_SUCCESS = 'GET_ROLE_SUCCESS'
export const GET_ROLES_ERROR = 'GET_ROLES_ERROR'
export const ADD_ROLE_START = 'ADD_ROLE_START'
export const ADD_ROLE_SUCCESS = 'ADD_ROLE_SUCCESS'
export const ADD_ROLE_ERROR = 'ADD_ROLE_ERROR'
export const UPDATE_ROLE_START = 'UPDATE_ROLE_START'
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_ERROR = 'UPDATE_ROLE_ERROR'
export const DELETE_ROLE_START = 'DELETE_ROLE_START'
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS'
export const DELETE_ROLE_ERROR = 'DELETE_ROLE_ERROR'
export const CLEAR_ROLE = 'CLEAR_ROLE'

export const GET_ROLE_ACTIONS_START = 'GET_ROLE_ACTIONS_START'
export const GET_ROLE_ACTIONS_SUCCESS = 'GET_ROLE_ACTIONS_SUCCESS'
export const GET_ROLE_ACTIONS_ERROR = 'GET_ROLE_ACTIONS_ERROR'
export const UPDATE_ROLE_ACTIONS_START = 'UPDATE_ROLE_ACTIONS_START'
export const UPDATE_ROLE_ACTIONS_SUCCESS = 'UPDATE_ROLE_ACTIONS_SUCCESS'
export const UPDATE_ROLE_ACTIONS_ERROR = 'UPDATE_ROLE_ACTIONS_ERROR'

// Actions creators

export const clearRole = () => ({
    type: CLEAR_ROLE
})

// Promisses
export const fetchRolesPromise = async (token, roleId = '') => get(`admin/roles/${roleId}`, '', {Authorization: token})
export const addRolePromise = async (body, token) => post('admin/roles', body, {Authorization: token})
export const updateRolePromise = async (id, body, token) => put(`admin/roles/${id}`, body, {Authorization: token})
export const deleteRolePromise = async (id, token) => del(`admin/roles/${id}`, {Authorization: token})

export const fetchRoleActionsPromise = async (roleId, token) => get(`admin/roles/${roleId}/actions`, '', {Authorization: token})
export const updateRoleActionsPromise = async (roleId, body, token) => put(`admin/roles/${roleId}/update_actions`, body, {Authorization: token})

// Async actions creators
export const fetchRoles = (token, roleId) => async dispatch => {
    dispatch({ type: GET_ROLES_START })
    dispatch(requestPending())

    try {
        const response = await fetchRolesPromise(token, roleId)

        dispatch(requestSuccess())

        if (!!roleId) {
            dispatch({ type: GET_ROLE_SUCCESS, payload: response })
        } else {
            dispatch({ type: GET_ROLES_SUCCESS, payload: response })
        }
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_ROLES_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const addRole = (body, token, callback) => async dispatch => {
    dispatch({ type: ADD_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await addRolePromise({ role: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_ROLE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateRole = (id, body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await updateRolePromise(id, { role: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ROLE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deleteRole = (id, token, callback) => async dispatch => {
    dispatch({ type: DELETE_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await deleteRolePromise(id, token)

        dispatch(requestSuccess())
        dispatch({ type: DELETE_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: DELETE_ROLE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const fetchRoleActions = (roleId, token) => async dispatch => {
    dispatch({ type: GET_ROLE_ACTIONS_START })
    dispatch(requestPending())

    try {
        const payload = await fetchRoleActionsPromise(roleId, token)

        dispatch(requestSuccess())
        dispatch({
            type: GET_ROLE_ACTIONS_SUCCESS,
            payload: { roleId, ...payload }
        })
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_ROLE_ACTIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateRoleActions = (roleId, body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_ROLE_ACTIONS_START })
    dispatch(requestPending())

    try {
        const payload = await updateRoleActionsPromise(roleId, { action_role: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_ROLE_ACTIONS_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ROLE_ACTIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}