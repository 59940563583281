/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { path, pathOr, map, compose, isNil, filter, omit, propOr } from 'ramda'
import L, { DivIcon, latLngBounds } from 'leaflet'
import { Map, Marker, Popup, Polygon, GeoJSON } from 'react-leaflet'
// import MarkerClusterGroup from 'react-leaflet-markercluster'
import Polyline from 'react-leaflet-arrowheads'
import { GoogleLayer } from 'react-leaflet-google-v2'
import { TableRow, TableBody, Table, TableCell } from '@material-ui/core'
import * as svgIcon from '@mdi/js'

import { getBounds } from '../../../services/mapServices'
import { coloredSvgIcon } from '../../../services/iconService'
import styles from './styles'

const road = 'ROADMAP'

const defaultPoint = [-33.437967, -70.6504]
const iconDefauls = {
    iconAnchor: [22, 40],
    popupAnchor: [0, -35],
    iconSize: [43, 43],
}

function DetailMapComponent(props) {
    const {points, mapLayer, trackpoints, showTrackpoints, showTrackpath, config, resizeListener, controls, geofence, showTooltip, clusterConfig} = props
    const classes = styles()
    const [data, setData] = useState(points)
    const [bounds, setBounds] = useState(new latLngBounds([]))
    const mapEl = useRef(null)
    let mapParams = {}

    useEffect(() => {
        if (mapEl.current) {
            resizeListener(mapEl.current.leafletElement)
        }
    }, [mapEl])

    useEffect(() => {
        if (JSON.stringify(points) !== JSON.stringify(data)) {
            setBounds(getLayersBounds(points))
            setData(points)
        }
    }, [points])

    function getLayersBounds(layersArray) {
        const dataFilter = filter(item => {
            if (!item.item_latitude) {
                return false
            }
            if (!item.item_longitude) {
                return false
            }
            return true
        })

        const dataParse = map(item => {
                const resp = {
                    latitude: parseFloat(item.item_latitude),
                    longitude: parseFloat(item.item_longitude),
                }

                return resp
            })

        const bounds = compose(getBounds, dataParse, dataFilter)

        return bounds(layersArray)
    }

    if (bounds.isValid()) {
        mapParams = {
            bounds
        }
    } else {
        mapParams = {
            center: defaultPoint,
            zoom: 13
        }
    }

    return (
        <div className={classes.routesMapRoot}>
            <Map {...mapParams} ref={mapEl}>
                <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_API}  maptype={road} />
                <RenderPoints
                    points={data}
                    mapLayer={mapLayer}
                    config={config}
                    classes={classes}
                    geofence={geofence}
                    showTooltip={showTooltip}
                    trackpoints={trackpoints}
                    showTrackpoints={showTrackpoints}
                    showTrackpath={showTrackpath}
                    clusterConfig={clusterConfig}
                />
            </Map>
            { controls }
        </div>
    )
}

function RenderPoints(props) {
    const {points, trackpoints, showTrackpoints, showTrackpath, classes, config, geofence, mapLayer, showTooltip, clusterConfig} = props
    const [pointsByType, setPointsByType] = useState({})
    const getPoligon = pathOr([], ['item_geofence', 'coords'])

    useEffect(() => {
        setPointsByType(groupPoint(points))
    }, [points])

    function groupPoint(points) {
        const group = {}
        for (const point of points) {
            if (isNil(point.item_latitude) || isNil(point.item_longitude)) continue

            if (!group[point.item_type]) {
                group[point.item_type] = [point]
            } else {
                group[point.item_type].push(point)
            }
        }

        return omit(['RETURN_TO_DEPOT'], group)
    }

    function getLatLng(point) {
        return [parseFloat(point.item_latitude), parseFloat(point.item_longitude)]
    }

    function mapIcon(iconConfig, point) {
        const {status, item_type, route_gps_status} = point
        const {iconAnchor, popupAnchor, iconSize} = iconDefauls
        const configByCluster = path([point.cluster_name, 'mapLayers', item_type.toLowerCase(), 'icon'], clusterConfig)

        if(configByCluster) {
            iconConfig = configByCluster
        }

        const icon = svgIcon[iconConfig.name]
        let color = iconConfig.color ||
            (iconConfig.colors && iconConfig.colors[(typeof status === 'string' ?   status : '').toLowerCase()]) ||
            pathOr('grey', ['colors', 'unassigned'], iconConfig)

        if (/vehicle/ig.test(item_type) && (!route_gps_status || route_gps_status === 'inactive')) {
            color = pathOr('grey', ['inactive'], iconConfig)
        }

        const html = coloredSvgIcon(icon, color)

        return new DivIcon({
            html,
            iconAnchor,
            popupAnchor,
            iconSize,
            className: classes.mapIcon,
        })
    }

    function trackpointIcon() {
        const icon = svgIcon[config.trackpoint.icon.name]
        let color = config.trackpoint.icon.color

        const html = coloredSvgIcon(icon, color)

        return new DivIcon({
            html,
            iconAnchor: [9, 10],
            popupAnchor: [0, -35],
            iconSize: [21, 21],
            className: classes.mapIcon,
        })
    }

    function compare(a, b) {
        const tmp = {
            item_latitude: propOr(false, 'item_latitude'),
            item_longitude: propOr(false, 'item_longitude'),
        }

        const lat = tmp.item_latitude(a) && tmp.item_latitude(b) && tmp.item_latitude(a) === tmp.item_latitude(b)
        const lng = tmp.item_longitude(a) && tmp.item_longitude(b) && tmp.item_longitude(a) === tmp.item_longitude(b)

        return lat && lng
    }

    function getPolylineSegments(trackpoints) {
        const segments = trackpoints.map((point, key, arr) => {
            if (key === arr.length  - 1) return undefined
            const segment = [point.map(p => parseFloat(p)), arr[key + 1].map(p => parseFloat(p))]
            return segment
        })

        segments.pop()
        return segments
    }

    return (
        <React.Fragment>
            {Object.keys(mapLayer).map((item, key) => {
                return <GeoJSON
                        key={key}
                        data={mapLayer[item].feature_collection}
                        style={function (feature) {
                            return {
                                stroke: !!feature.properties.stroke,
                                color: feature.properties.stroke,
                                weight: feature.properties.stroke_width,
                                fill: !!feature.properties.fill,
                                fillColor: feature.properties.fill,
                            };
                        }}
                        pointToLayer={function(geoJsonPoint, latlng) {
                            const {iconAnchor, popupAnchor, iconSize} = iconDefauls
                            return L.marker(latlng, {
                                icon: L.divIcon({
                                    html: coloredSvgIcon(svgIcon[config.default.icon.name], geoJsonPoint.properties.fill),
                                    iconAnchor,
                                    popupAnchor,
                                    iconSize,
                                    className: classes.mapIcon,
                                })
                            });
                        }}
                        onEachFeature={function (feature, layer) {
                            const popupContent = `<h3>${feature.properties.name}</h3><p>${feature.properties.description}<p>`
                            layer.bindPopup(popupContent)
                        }}
                    />
            })}

            {trackpoints && showTrackpoints && trackpoints.map((point, key) => {
                return <Marker
                        key={key}
                        position={point.map(p => parseFloat(p))}
                        icon={trackpointIcon()}
                    />
            })}

            {trackpoints && showTrackpath && getPolylineSegments(trackpoints).map((point, key) => {
                return <Polyline key={key} positions={point} arrowheads={{
                    yawn: 40,
                    size:"12px",
                    frequency: '50px'
                }} />
             })}

            {Object.keys(pointsByType).map((layer, key) => {
                return pointsByType[layer].map((point, key) => {
                        const markRef = ref => {
                            if (ref && compare(point, showTooltip)) {
                                ref.leafletElement.openPopup()
                            }
                        }
                        return (
                            <React.Fragment key={key+JSON.stringify(point)}>
                                {geofence && <Polygon color="purple" positions={getPoligon(point)} />}
                                <Marker
                                    position={getLatLng(point)}
                                    ref={markRef}
                                    icon={mapIcon(pathOr(config.default.icon, [point.item_type.toLowerCase(), 'icon'], config), point)}
                                >
                                    <Popup>
                                        <Tooltip classes={classes} point={point} />
                                    </Popup>
                                </Marker>
                            </React.Fragment>
                        )
                    })
            })}
        </React.Fragment>
    )
}

function Tooltip(props) {
    const { classes, point } = props
    const [prevPoint, setPrevPoint] = useState(point)

    useEffect(() => {
        if (JSON.stringify(point) !== JSON.stringify(prevPoint)) {
            setPrevPoint(point)
        }
    })

    switch (prevPoint.item_type.toLowerCase()) {
        case 'vehicle':
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            {/* <TableCell>Id</TableCell> */}
                            <TableCell>
                                {prevPoint.item_nid}
                            </TableCell>
                        </TableRow>
                        {point.event_data_error_reason && (
                            <TableRow>
                                <TableCell>Motivo</TableCell>
                                <TableCell>
                                    <a href={point.itinerary_events_url}>
                                        {point.event_data_error_reason}
                                    </a>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )
        case 'mobile_device':
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>
                                {prevPoint.nid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Lat</TableCell>
                            <TableCell>{`${prevPoint.license_plate} - ${prevPoint.description}`}</TableCell>
                        </TableRow>
                        {point.event_data_error_reason && (
                            <TableRow>
                                <TableCell>Motivo</TableCell>
                                <TableCell>
                                    <a href={point.itinerary_events_url}>
                                        {point.event_data_error_reason}
                                    </a>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )
        case 'stop':
            let item_nid;
            if (!prevPoint.profile_url) {
                item_nid = prevPoint.item_nid;
            } else {
                item_nid = <a href={prevPoint.profile_url} target="_blank" rel="noopener noreferrer">
                    {prevPoint.item_nid}
                </a>;
            }
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>
                                {item_nid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Lat</TableCell>
                            <TableCell>{prevPoint.item_latitude}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Lng</TableCell>
                            <TableCell>{prevPoint.item_longitude}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Carga</TableCell>
                            <TableCell>{prevPoint.load}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Carga efectiva</TableCell>
                            <TableCell>{prevPoint.effective_load}</TableCell>
                        </TableRow>
                        {prevPoint.event_data_error_reason && (
                            <TableRow>
                                <TableCell>Motivo</TableCell>
                                <TableCell>
                                    <a href={prevPoint.itinerary_events_url} target="_blank" rel="noopener noreferrer">
                                        {prevPoint.event_data_error_reason}
                                    </a>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )
        default:
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>
                                {prevPoint.item_nid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Lat</TableCell>
                            <TableCell>{prevPoint.item_latitude}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Lng</TableCell>
                            <TableCell>{prevPoint.item_longitude}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )
    }
}

DetailMapComponent.propTypes = {
    config: PropTypes.object,
    layers: PropTypes.object,
}

export default DetailMapComponent
