import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export const legend = makeStyles(theme => ({
    legendWrapper: {
        fontFamily: theme.typography.fontFamily,
        position: 'relative',
        margin: 'auto',
        padding: 0,
        borderRadius: '50%',
        border: '1px solid ' + grey[400],
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        flexDirection: 'column',
        height: 152,
        width: 152,
        justifyContent: 'center',

        '& > div': {
            margin: '2px auto'
        }
    }
}))
