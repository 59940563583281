import React from 'react'
import { SvgIcon } from '@material-ui/core'

function SvgIconComponent({path, ...rest}) {
    return (
        <SvgIcon {...rest}>
            <path d={path}/>
        </SvgIcon>
    )
}

export default SvgIconComponent
