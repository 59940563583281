import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        flexGrow: 1,
    },

    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },

    logoContainer: {
        marginRight: '2rem',
    },

    imgLogo: {
        maxHeight: 45,
    },

    isRightPanelOpen: {
        paddingRight: theme.detailDrawer.width,
        transition: theme.transitions.create('padding-right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },

    menuButton: {
        marginRight: theme.spacing(2),
    },

    title: {
        flexGrow: 0,
    },

    titleFull: {
        flexGrow: 1,
    },

    navigator: {
        flexGrow: 1,
        background: 'none',
        justifyContent: 'flex-end',
        paddingRight: '40px'
    },

    navigatorChild: {
        color: '#fff',
        maxWidth: '135px',
    },

    navigatorChildSelected: {
        color: '#eee !important'
    },

    mutateButton: {
        textDecoration: 'none',
        color: '#fff',
    },

    logoutButton: {
        textTransform: 'capitalize',
    }
}));