import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    saveButton: {
        position: 'fixed',
        right: 30,
        top: 86,
        zIndex: 99,
    },
    icon: {
        marginRight: 5,
    }
}))
