import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    container: {
        width: '100%',
    },

    // itemsContainer: {
    //     height: 'calc(100vh - 310px)',
    //     overflow: 'auto',
    // },

    spaceBetween: {
        display: 'flex',
        justifyContent: 'space-between',
    },

    tr: {
        backgroundColor: theme.palette.background.default + '!important',

        '& svg': {
            marginRight: 4,
        },

        '& td': {
            padding: '6px 8px 0 !important',
            borderBottom: 'none !important',

            '& p': {
                display: 'inline',
                verticalAlign: 'super',
            },
        }
    },

    button: {
        display: 'block',
        margin: '0 auto 0 auto',
    },

    tableItems: {
        '& tr': {
            '& td': {
                padding: '6px 8px 0 !important',
                borderBottom: 'none !important',

                '& p': {
                    display: 'inline',
                    verticalAlign: 'super',
                },

                '& p ~ p': {
                    marginLeft: 5,
                },

                '& p ~ a': {
                    marginLeft: 5,
                    color: theme.palette.primary.main,

                    '&:hover': {
                        color: theme.palette.primary.dark,
                    },
                },
            }
        }
    },

    itemLeft: {
        width: '10%',

        '& p': {
            display: 'block !important',
            whiteSpace: 'break-spaces',
        }
    },

    numberButton: {
        margin: '10px 0 0 2px',
        minWidth: 'auto',
        width: 30,
        height: 30,
        borderRadius: '50%',
    },

    itemsTop: {
        padding: '8px 8px 8px 4px',
        display: 'flex',
        justifyContent: 'space-between',
    },

    itemBottom: {
        padding: '8px 8px 8px 0',
        display: 'flex',
        justifyContent: 'space-between',
    },

    nestedTable: {
        marginBottom: 5,
        '& th': {
            color: theme.palette.text.primary,
            padding: '6px 8px 0 !important',
            borderBottom: 'none !important',
        }
    },

    nestedHead: {
        backgroundColor: theme.palette.background.paper
    },

    odd: {
        backgroundColor: theme.palette.background.default + ' !important'
    },

    even: {
        backgroundColor: theme.palette.background.emphasis + ' !important'
    },

    detailSection: {
        backgroundColor: theme.palette.background.higtligth + ' !important'
    },

    linkDisabled: {
        color: theme.palette.text.disabled + ' !important',
    }

}))
