import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    routesViewRoot: {
        display: 'flex',
        flexDirection: 'column',
        "& .MuiPaper-root": {
            margin: 0,
            padding: 0,
            background: "none",
            boxShadow: "none",
        },
        "& .MuiTypography-root.MuiTypography-h6": {
            color: "#757687",
            display: "flex",
            padding: "20px 0",
            alignItems: "center"
        },
        "& .MuiCollapse-wrapperInner .MuiTypography-root.MuiTypography-h6": {
            margin: "0 0 5px",
            fontSize: "15px"
        },
        "& .MuiTypography-root.MuiTypography-h6 .MuiSvgIcon-root": {
            float: "left",
            fontSize: "1.9rem",
            marginRight: "5px"
        },
        "& .MuiTypography-root.MuiTypography-h6 .MuiSvgIcon-root.ruc-title-first-icon": {
            marginRight: 0,
        },
        "& .MuiTypography-root.MuiTypography-h6 .MuiButtonBase-root": {
            margin: 0,
            padding: 0,
            float: "right",
            "&.new-class": {
                color: "#43A43F",//success color
            }
        },
        "& .MuiTypography-root.MuiTypography-h6 .MuiButtonBase-root .MuiSvgIcon-root": {
            margin: 0,
        },
        // "& .MuiTableHead-root": {
        //     backgroundColor: "#a7b4cc",
        //     "& .MuiTableCell-head": {
        //         color: "#f5f5f5",
        //         fontWeight: 500
        //     }
        // },
        // "& .MuiTableHead-root .MuiTableRow-root": {
        //     borderRight: "1px solid #a7b4cc"
        // },
        // "& .ruc-id, .ruc-priority, .ruc-color, .ruc-users_list, .ruc-date, .ruc-time, .ruc-category, .ruc-itinerary, .ruc-route, .ruc-item, .ruc-document": {
        //     textAlign: "center"
        // },
        "& .ruc-priority": {
            textAlign: "center"
        },
        "& .ruc-priority svg": {
            float: "left"
        },
        // "& .MuiTableBody-root .MuiTableRow-root": {
        //     borderRight: "1px solid #D7D7D7",
        //     borderLeft: "1px solid #D7D7D7"
        // },
        // "& .MuiTableBody-root .MuiTableRow-root.ruc-table-row-disabled": {
        //     "& td": {
        //         color: "#DEDEDE"
        //     },
        //     "& actions": {
        //         color: "rgba(0, 0, 0, 0.87)"
        //     }
        // },
        // "& .MuiCollapse-wrapperInner": {
        //     "& .MuiTableHead-root": {
        //         backgroundColor: "#F1F1E6",
        //         "& .MuiTableRow-head": {
        //             borderTop: "1px solid #D7D7D7",
        //             "& .MuiTableCell-root": {
        //                 color: "#000",
        //             }
        //         }
        //     }
        // },
        "& .MuiGrid-root .ruc-form": {
            padding: "20px 20px 10px 20px",
            background: "white",
            border: "1px solid #D7D7D7",
            "& .MuiFormControl-root": {
                marginBottom: "15px"
            },
            "& .MuiTextField-root": {
                width: "100%",
            },
            "& .MuiFormControl-root.last-child": {
                marginBottom: 0
            },
            "& .ruc-form-button-wrapper": {
                display: "inline-block",
                width: "100%",
                marginTop: "10px",
                "& *": { float: "right" },
                "& .ruc-form-checkbox": {
                    float: "left",
                    "& label": {
                        marginLeft: 0
                    }
                }
            },
            "& a": {
                textDecoration: "none",
                "& button": {
                    margin: "0 10px 0 0"
                }
            },
            "& button": {
                //float: "right",
                //marginTop: "50px",
                
            },
            // "& .MuiFormControl-root.ruc-textarea": {
            //     height: "50px"
            // },
        },
        // "& .MuiTableCell-body": {
        //     color: "#9293A9"
        // },
        "& .ruc-indicators-general":{
            "& .ruc-indicators-button-group": {
                fontFamily: "Roboto, Helvetica, Arial, sans-serif",
                "& [class*='ruc-indicators-button']": {
                    height: "70px",
                    margin: "10px 0 10px 10px",
                    borderRadius: "4px",
                    cursor: "pointer",
                },
                "& [class*='MuiGrid-root']:first-child": {
                    marginLeft: 0,
                },
                "& .ruc-indicators-button-managed .MuiLinearProgress-colorPrimary": {
                    backgroundColor: '#3b7917',
                },
                "& .ruc-indicators-button-pending .MuiLinearProgress-colorPrimary": {
                    backgroundColor: '#790000',
                },
                "& .ruc-card-info": {
                    paddingTop: "5px",
                    fontWeight: "300",
                    "& label": {
                        fontSize: "12px",
                        fontWeight: "400",
                    },
                    "& span": {
                        padding: "3px 0 0 0",
                        fontSize: "18px",
                        fontWeight: "400",
                    }
                },
                "& .ruc-card-progress-component": {
                    height: "2px",
                    margin: "7px 12px 0 0",
                    "& .MuiLinearProgress-barColorPrimary": {
                        backgroundColor: "#FFF"
                    }
                },
                "& .ruc-card-progress-numeric": {
                    fontSize: "13px",
                }
            }
        },
        "& .ruc-indicators-table, .ruc-indicators-grid": {
            marginBottom: "10px",
            borderTop: "1px solid #D9D9D9",
            padding: "20px"
        },
        "& .ruc-indicators-button-group": {
            "& .MuiGrid-item": {
                padding: 0,
                "& button": {
                    padding: 0,
                    "& label+.MuiBox-root div+div": {
                        paddingTop: "3px"
                    }
                }
            },
            "& .MuiBox-root": {
                margin: "0 8px",
                paddingTop: 0,
                paddingBottom: 0
            },
            "& [class*='ruc-indicators-button']": {
                backgroundColor: "#3159BE",
                color: "white",
                "& label": {
                    fontSize: "9px",
                    letterSpacing: "0.06rem"
                },
                "& span": {
                    fontSize: "21px",
                    fontWeight: "500",
                    lineHeight: "21px"
                },
                "& span, label": {
                    display: "block",
                },
                "& .MuiGrid-root.MuiGrid-container": {
                    height: "100%"
                },
            },
            "& .ruc-indicators-button-totales": {
                borderRight: "#569C2D"
            },
            "& .ruc-indicators-button-managed": {
                backgroundColor: "#569C2D"
            },
            "& .ruc-indicators-button-pending": {
                backgroundColor: "#D21F3E",
            }
        },
        "& [class*='ruc-alert-priority']": {
            "& .MuiListItemIcon-root": {
                minWidth: 0,
                marginRight: "5px",
                "& svg": {
                    fontSize: "1.8rem"
                }
            }
        },
        "& .circular-progress-grid": {
            textAlign: "center",
            "& span": {
                marginTop: "5px",
                fontSize: "9px",
                letterSpacing: "0.06rem",
                textTransform: "uppercase"
            }
        },
        "& fieldset.ruc-callback-options": {
            width: "100%",
            paddingTop: "20px",
            paddingBottom: "10px",
            border: "1px solid #c4c4c4",
            borderRadius: "4px",
            "& legend": {
                margin: "0 10px",
                padding: "0 4px",
                fontSize: "13px",
                color: "rgba(0, 0, 0, 0.54)"
            }
        },
        "": {

        }
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    },

    openLeftControl: {
        marginLeft: 240
    },

    openRightControl: {
        marginRight: 425
    },

    nested: {
        paddingLeft: theme.spacing(4),
    },

    listItemIcon: {
        minWidth: 30,
    },

    formPaper: {
        margin: '6vh auto auto',
        width: 600,
        minHeight: 200,
        padding: '30px 50px 100px',
        position: 'relative',
    },

    formPaperWide: {
        margin: '6vh auto auto',
        minHeight: 200,
        padding: '30px 50px 100px',
        position: 'relative',
        minWidth: '80%',
    },

    listPaper: {
        margin: '6vh auto auto',
        padding: 50,
        minWidth: '80%',
        position: 'relative',
    }

}))
