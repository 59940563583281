import {
    GET_START,
    GET_SUCCESS,
    GET_ERROR,
    UPDATE_SUCCESS,
} from './actions'
import { lensPath, path, set, mergeDeepRight } from 'ramda'

const initialState = {
    data: {
        id: '1',
        type: 'translate',
        attributes: {
            organization_id: 3,
            cluster_id: null,
            locale: 'es',
            i18n: {
                main: {
                    title: 'Centro de Planificación y Control',
                    rutes_menu_item: 'Rutas',
                    admin_menu_item: 'Administración',
                    stats: 'Indicadores',
                    logout: 'Cerrar Sesión',
                    amm: 'Alertas'
                },

                amm: {
                    alert: {
                        id: 'ID', 
                        date: 'Fecha', 
                        time: 'Hora', 
                        alert: 'Alerta',
                        cluster: 'Zona',
                        depot: 'CD', 
                        route: 'Ruta', 
                        alerts: 'Alertas', 
                        actions: 'Acciones', 
                        history: 'históricas', 
                        category: 'Categoría', 
                        comments: 'Gestiones', 
                        document: 'Documento', 
                        priority: 'Prioridad', 
                        itinerary: 'Itinerario', 
                        'of-the-day': 'del día'
                    },
                    set_up: 'Gestionar', 
                    actions: {
                        add: 'Crear', 
                        back: 'Volver', 
                        edit: 'Editar', 
                        list: 'Listar', 
                        read: 'Leída', 
                        save: 'Guardar', 
                        clear: 'Limpiar', 
                        close: 'Salir', 
                        cancel: 'Cancelar', 
                        closed: 'Cerrada', 
                        delete: 'Eliminar', 
                        manage: 'Gestionar', 
                        update: 'Actualizar', 
                        confirm: 'Confirmar', 
                        history: 'Historial', 
                        managed: 'Gestionada', 
                        options: 'Opciones', 
                        reviewed: 'Revisada', 
                        parameters: 'Parámetros', 
                        event_update: 'Actualización de evento', 
                        configuration: 'Configuración', 
                        delete_confirm: 'Confirme eliminación de', 
                        mark_as_managed: 'Marcar como gestionada', 
                        unmanaged_close: 'Cerrada'
                    },
                    options: {
                        time_zone: 'Zona horaria', 
                        time_zone_helper_text: '',
                        hours_before_start: 'Horas antes del inicio', 
                        hours_before_start_helper_text: 'Cantidad de horas para visualizar alertas previo a las 00:00 horas.', 
                        hours_before_unmanaged: 'Horas antes del cierre', 
                        hours_before_unmanaged_helper_text: 'Cantidad de horas para cerrar alertas automáticamente previo a las 00:00 horas.', 
                        callback_execution_time: 'Buscar alertas cada (minutos)',
                        callback_execution_time_helper_text: ''
                    },
                    category: {
                        id: 'ID', 
                        new: 'Nueva categoría', 
                        edit: 'Editar categoría', 
                        name: 'Nombre', 
                        color: 'Color', 
                        title: {
                            one: 'Categoría', 
                            two: 'Categorías'
                        },
                        users: 'Usuarios', 
                        actions: 'Acciones', 
                        priority: 'Prioridad', 
                        description: 'Descripción'
                    },
                    settings: 'Configuraciones', 
                    tooltips: {
                        delete: 'Borrar elemento', 
                        manage: 'Gestionar', 
                        review: 'Más info', 
                        history: 'Historia', 
                        'used-item': 'Elemento en uso'
                    },
                    'alert-template': {
                        id: 'ID', 
                        name: 'Alerta', 
                        unit: 'Unidad', 
                        zone: 'Zona', 
                        hours: 'Horas', 
                        title: {
                                one: 'Plantilla', 
                                two: 'Plantillas'
                        },
                        meters: 'Metros', 
                        actions: 'Acciones', 
                        minutes: 'Minutos', 
                        running: 'Rutas en ejecución', 
                        seconds: 'Segundos', 
                        activity: 'Actividad', 
                        finished: 'Rutas finalizadas', 
                        unstarted: 'Rutas sin iniciar', 
                        kilometers: 'Kilómetros', 
                        callback_options: 'Callback(options)', 
                        callback_trigger: 'Callback(callback)', 
                        alert_description: 'Descripción', 
                        callback_description: 'Callback(desc)'
                    },
                    'alert-configuration': {
                        id: 'ID', 
                        new: 'Nueva configuración de alerta', 
                        date: 'Fecha', 
                        edit: 'Editar configuración de alerta', 
                        name: 'Alerta', 
                        stop: 'Parada(nid)', 
                        time: 'Hora', 
                        alert: 'Alerta', 
                        depot: 'CD', 
                        route: 'Ruta', 
                        title: {
                            one: 'Configuración de Alerta', 
                            two: 'Configuración de Alertas'
                        },
                        alerts: 'Alertas', 
                        actions: 'Acciones', 
                        category: 'Categoría', 
                        priority: 'Prioridad', 
                        'alert-alt': 'Configuración', 
                        itinerary: 'Itinerario', 
                        parameter: 'Parámetro', 
                        shortcuts: 'Accesos directos', 
                        category_id: 'Categoría', 
                        description: 'Descripción', 
                        parameter_unity: 'Parámetro (unidad)', 
                        parameter_value: 'Parámetro (valor)', 
                        alert_template_id: 'Plantilla de Alerta', 
                        parameter_description: 'Parámetro'
                    }
                },

                routes: {
                    title: {
                        map: 'Mapa general',
                        routes: 'Tabla de Rutas',
                        items: 'Tabla de Items',
                        vehicles: 'Tabla de Vehiculos',
                        mobiles: 'Tabla de Dispositivos Mobiles',
                        map_detail: 'Detalle de Ruta',
                    },
                    routes_table: {
                        depot_nid: 'Base',
                        uid: 'Ruta',
                        starts_at: 'Fecha Ruta',
                        vehicle_nid: 'Vehiculo',
                        vehicle_driver_name: 'Conductor',
                        delivered: 'Entregas',
                        starts_at_diff: 'Hora inicio',
                        last_point_punctuality: 'Puntualidad ultimo punto',
                        time_in_route: 'Tiempo en ruta',
                        kms_in_route: 'Kms Rec',
                        gps_status: 'Gps',
                        route_status: 'Estado',
                        progress: '% Avance',
                        observations: 'Observaciones',
                        details: 'Detalles',
                        cluster_name: 'Zona'
                    },
                    routes_detail: {
                        load: 'Carga',
                        depot_nid: 'Base',
                        driver_nid: 'Conductor',
                        driver_helper_nid: 'Ayudante',
                        vehicle_nid: 'Vehiculo',
                        doc_created_at: 'Fecha creación documento',
                        client: 'Cliente',
                        document: 'Documentos',
                        distanceUnit: 'Kilometros',
                        timeUnit: 'Horas',
                        route_status: 'Estado de la ruta',
                        alerts: 'Alertas',
                        itinerary_table_url: 'Acceso al dashboard',
                        scheduling_url: 'Acceso al módulo asignador de vehículos',
                        itinerary_edit_url: 'Acceso al módulo de planificación',
                        notifications: 'Notificaciones',
                        trackpoint: 'Trackpoint',
                        trackpoints: 'Trackpoints',
                        geofence: 'Geocerca',
                        trackpath: 'Recorrido',
                        depot_in: 'Entrada a Planta',
                        depot_out: 'Salida de Planta',
                        depot_group: 'Planta',
                        start: 'Ruta iniciada',
                        arrival: 'Llegada a Parada',
                        success: 'Entrega exitosa',
                        failure: 'Entrega fallida',
                        finish: 'Ruta finalizada',
                        geofence_in: 'Entrada a Parada',
                        geofence_out: 'Salida de Parada',
                        stop_group: 'Paradas',
                        layer_geofence_in: 'Entrada a Zona',
                        layer_geofence_out: 'Salida de Zona',
                        layer_group: 'Zonas',
                        route_without_new_trackpoints: 'Ruta sin nuevos trackpoints',
                        route_active_for_too_long: 'Ruta activa por mucho tiempo',
                        trackpoint_group: 'Trackpoints',
                        notifications_types: 'Filtro de notificaciones',
                        gps_status: 'Estado del GPS'
                    },
                    items_table: {
                        depot_nid: 'Base',
                        uid: 'Ruta',
                        profile_nid: 'Id Cliente',
                        profile_name: 'Nombre Cliente',
                        document_id: 'Nombre Pedido',
                        starts_at: 'Fecha Ruta',
                        vehicle_license_plate: 'Vehiculo',
                        vehicle_driver_name: 'Conductor',
                        item_order: 'Orden',
                        arrives_at: 'Hora Entrega',
                        arrived_at: 'Hora Real',
                        arrived_at_diff: 'Delta Hora',
                        last_event_distance_diff: 'Delta Metros',
                        last_status: 'Estado',
                        itinerary_events_url: 'Comentarios',
                        observations: 'Observaciones',
                        details: 'Detalles',
                        cluster_name: 'Zona'
                    },
                    items_detail: {
                        code: 'Código',
                        description: 'Descripción',
                        depot_nid: 'Centro de distribución',
                        load: 'Carga 1',
                        load1: 'Carga 2',
                        load2: 'Carga 3',
                    },
                    vehicles_table: {
                        license_plate: 'Id',
                        route_id: 'Ruta',
                        capacity0: 'Capacidad 1',
                        route_avg_load: 'Carga',
                        delivered_load: 'Carga Entregada',
                        route_status: 'Estado',
                        planned_start_date: 'Hora Inicio',
                        route_ends_at: 'Hora Fin',
                    },
                    mobiles_table: {
                        description: 'Descripción',
                        vehicle_license_plate: 'Vehiculo',
                        route_id: 'Ruta',
                        route_status: 'Estado',
                        planned_start_date: 'Hora Inicio',
                        route_ends_at: 'Hora fin'
                    },
                    menu: {
                        title: 'Rutas'
                    },
                    switch: {
                        table: 'Tabla',
                        map: 'Mapa',
                        cluster: 'por Negocio',
                        general: 'General',
                    },
                    filters: {
                        main_button: 'Filtros',
                        routes_button: 'Rutas',
                        routes_vehicles: 'Vehiculos',
                        routes_mobiles: 'Movil',
                        items_check: 'Ver Items',
                        active_route_check: 'Ver Solo Rutas Activas',
                        with_routes_check: 'Con Rutas',
                        by_itinerary_id: 'Itinerario',
                        by_nid: 'ID',
                        by_uid: 'ID',
                        by_vehicle_nid: 'Vehiculo',
                        by_license_plate: 'Placa del Vehiculo',
                        by_route_nid: 'ID Ruta',
                        by_status: 'Estado de Ruta',
                        by_starts_at_from: 'Desde',
                        by_starts_at_to: 'Hasta',
                        by_item_document: 'Nro. Pedido',
                        by_depot: 'Plantas/CD',
                        by_item_status: 'Item Status',
                        by_item_nid: 'Cliente-Perfil',
                        by_vehicle: 'Vehiculo',
                        by_mobile_device_uid: 'Dispositivo',
                        by_description: 'Descripción',
                        clean_button: 'Limpiar',
                        apply_button: 'Aplicar Filtros',
                        item_status_option_arrival: 'Pendiente',
                        item_status_option_failure: 'Fallida',
                        item_status_option_success: 'Exitosa',
                        item_status_option_partial: 'Parcial',
                        by_cluster_id: 'Zona'
                    },
                    stats: {
                        title: 'Entregas Planificadas',
                        state_routes_planing_title: 'Estado rutas planificadas',
                        pending: 'Pendiente',
                        failure: 'Fallida',
                        success: 'Exitosa',
                        partial: 'Parcial',
                        unassigned: 'Sin Asignar',
                        pie_legend_inner_text: 'Terminadas',
                        pie_legend_unassigned: 'No Asignadas',
                        pie_legend_not_started: 'No Iniciadas',
                        pie_legend_running: 'En Ejecucución',
                        unordered: 'Rutas en Desorden',
                        delayed: 'Rutas fuera de Horario',
                        vehicles_with_routes: 'Vehiculos con rutas'
                    },
                    table_content: {
                        partial: 'Parada parcial',
                        success: 'Parada exitosa',
                        failure: 'Parada Fallida',
                        arrival: 'Arribo',
                        info: 'Info',
                        revoked: 'Cancelado',
                        departure: 'Salida',
                        hold: 'Detención',
                        start: 'Inicio de ruta',
                        finish: 'Fin',
                        postponed: 'Vuelvo mas tarde',
                        in_route: 'En Ruta',
                        started: 'Inicio ruta',
                        returning: 'Retorno',
                        inactive: 'Inactivo',
                        active: 'Activo'
                    },
                    tooltip: {
                        name: 'Nombre',
                        rute: 'Ruta',
                        truck: 'Camión',
                        eta: 'ETA',
                        address: 'Dirección',
                        reason: 'Motivo',
                    }
                },

                admin: {
                    menu: {
                        permissions: 'Administrar Permisos',
                        permissions_add: 'Agregar Permisos',
                        permissions_list: 'Listar Permisos',
                        roles: 'Administrar Roles',
                        roles_add: 'Agregar Rol',
                        roles_list: 'Listar Rol',
                        users: 'Administrar Usuarios',
                        users_list: 'Listar Usuarios',
                        config: 'Configuraciones',
                        config_i18n: 'Ajustes de Idioma',
                        config_customization: 'Personalización',
                    },

                    permissions_form: {
                        title_new: 'Agregar Permiso',
                        title_edit: 'Editar Permiso',
                        input_placeholder_name: 'Nombre',
                        input_placeholder_controller_url: 'Url del Controlador',
                        input_placeholder_description: 'Descripción',
                        input_check_label_has_crud: 'Tiene Crud',
                        button_new: 'Crear Permiso',
                        button_edit: 'Guardar',
                        button_clear: 'Limpiar Formulario',
                    },

                    permissions_list: {
                        title: 'Listado de Permisos',
                        button_save: 'Guardar',
                        table_name: 'Nombre',
                        table_description: 'Descriptción',
                        table_actions: 'Acciones',
                        tooltip_add: 'Agregar Acciones',
                        tooltip_edit: 'Editar',
                        tooltip_delete: 'Eliminar',
                        modal_title: 'Confirma eliminar el Rol',
                        modal_button_cancel: 'Cancel',
                        modal_button_confirm: 'Confirmar',
                    },

                    permissions_actions_list: {
                        title: 'Acciones del Permiso',
                        button_add: 'Agregar Acción',
                        table_name: 'Nombre',
                        table_description: 'Descriptción',
                        table_related_actions: 'Acciones Relacionadas',
                        table_actions: 'Acciones',
                        tooltip_edit: 'Editar',
                        tooltip_delete: 'Eliminar',
                        modal_title: 'Confirma eliminar la Acción del permiso',
                        modal_button_cancel: 'Cancel',
                        modal_button_confirm: 'Confirmar',
                    },

                    role_actions: {
                        title_new: 'Agregar Acción',
                        title_edit: 'Actualizar Acción',
                        input_placeholder_name: 'Nombre',
                        input_placeholder_controller_action: 'Acción del Controlador',
                        input_placeholder_http_method: 'Metodo HTTP',
                        input_placeholder_controller_url: 'URL del Controlador',
                        input_placeholder_use_parameters: 'Utiliza Parametros',
                        button_add: 'Agregar',
                        button_new: 'Crear',
                        button_edit: 'Guardar',
                        button_clear: 'Limpiar Formulario',
                    },

                    roles_form: {
                        title_new: 'Agregar Rol',
                        title_edit: 'Editar Rol',
                        input_placeholder_name: 'Nombre',
                        input_placeholder_description: 'Descripción',
                        button_new: 'Crear Permiso',
                        button_edit: 'Guardar',
                        button_clear: 'Limpiar Formulario',
                    },

                    roles_list: {
                        title: 'Listado de Roles',
                        table_name: 'Nombre',
                        table_description: 'Descriptción',
                        table_actions: 'Acciones',
                        tooltip_add: 'Permissions',
                        tooltip_edit: 'Editar',
                        tooltip_delete: 'Eliminar',
                        modal_title: 'Confirma eliminar el Rol',
                        modal_button_cancel: 'Cancel',
                        modal_button_confirm: 'Confirmar',
                    },

                    edit_actions_roles: {
                        title: 'Editar acciones del rol',
                        button_save: 'Guardar',
                        button_select_all: 'Seleccionar Todo',
                        button_revert: 'Deshacer Cambios',
                    },

                    users_list: {
                        title: 'Listado de Usuarios',
                        table_name: 'Nombre',
                        table_user_name: 'Nombre de Usuario',
                        table_role: 'Rol',
                        table_email: 'Correo Electrónico',
                        table_actions: 'Acciones',
                        tooltip_edit: 'Editar',
                    },

                    edit_roles_permissions: {
                        title: 'Editar roles y permisos de',
                        empty_placeholder: 'Debes elegir un rol para seleccionar un permiso',
                        input_placeholder_role: 'Rol',
                        input_placeholder_email: 'Correo Electronico',
                        button_new: 'Crear Rol',
                        button_edit: 'Guardar',
                        button_reload: 'Reestablecer Formulario',
                    },

                    i18n_config: {
                        title: 'Configuración de Idioma',
                        locale: 'Idioma',
                        button_save: 'Guardar',
                    },

                    app_config: {
                        title: 'Configuración de Idioma',
                        button_save: 'Guardar',
                    },
                },

                indicators: {
                    title: 'TODO GASCO',
                    routes: {
                        chart_title: 'Rutas',
                        delayed_routes: 'Rutas atrasadas',
                        ordered_routes: 'Rutas en orden',
                        planned_routes: 'Rutas planificadas',
                        planned_travel_distance: 'Kilometros planificados',
                        started_routes: 'Rutas iniciadas',
                        traveled_distance: 'Kilometros reales',
                        traveled_distance_percentage: 'Kilometros reales',
                        unordered_routes: 'Rutas en desorden',
                        finished_routes: 'Rutas finalizadas'
                    },
                    items: {
                        chart_title: 'Entregas',
                        planned_items: 'Entregas planificadas',
                        pending_items: 'Entregas pendientes',
                        partial_items: 'Entregas parciales',
                        success_items: 'Entregas exitosas',
                        failure_items: 'Entregas fallidas',
                        items_punctuality: 'Puntualidad entregas',
                        items_quality: 'Calidad de las entregas',
                        items_ahead: 'Adelantos',
                        items_on_time: 'Entregas puntuales',
                        items_late: 'Atrasos',
                        items_assigned: 'Entregas asignadas',
                        items_reported: 'Entregas reportadas',
                        items_on_site: 'Entregas en el lugar',
                    },
                    loads: {
                        chart_title: 'Litros/Kilos',
                        failure_delivery: 'Carga no entregada',
                        pending_delivery: 'Carga pendiente',
                        planned_delivery: 'Carga planificada',
                        success_delivery: 'Carga entregada',
                        load_type: 'Litros'
                    },
                    vehicles: {
                        total: 'Camiones',
                        with_routes: 'Camiones con rutas activas',
                        available: 'Camiones disponibles',
                        success_delivery: 'Litros entregados',
                        pending_delivery: 'Litros pendientes',
                        available_delivery: 'Litros disponibles',
                    },
                    failure_deliveries: {
                        failure_items: 'Entregas fallidas',
                    },
                    pieChartTooltip: {
                        success: 'Exitosa',
                        pending: 'Pendiente',
                        failure: 'Fallida',
                        started: 'Iniciada',
                        partial: 'Parcial',
                        pending_delivery: 'Entrega pendiente',
                        delayed: 'Atrasada',
                        success_delivery: 'Carga entregada',
                        available_delivery: 'Carga disponible',
                        failure_delivery: 'Carga no entregada',
                    }
                },
            }
        }
    }
}

export default function mobilesReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_SUCCESS:
            return mergeDeepRight(state, payload)
        case UPDATE_SUCCESS: {
            const {responseStatus, ...rest} = payload
            const pathData = ['data', 'attributes']
            const lensData = lensPath(pathData)
            const getData = path(pathData)
            const mergeData = mergeDeepRight(getData(state))
            const resp = set(lensData, mergeData(rest), state)
            return {...resp, responseStatus}
        }
        case GET_START:
        case GET_ERROR:
        default:
            return state
    }
}
