const SESION_NAME = process.env.REACT_APP_SESION_NAMESPACE

export function getSesionData() {
    const sesionData = localStorage.getItem(SESION_NAME)
    if (sesionData) {
        return JSON.parse(sesionData)
    }
    return null
}

export function setSesionData(data) {
    localStorage.setItem(SESION_NAME, JSON.stringify(data))
}

export function clearSesion() {
    localStorage.removeItem(SESION_NAME)
}
