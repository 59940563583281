/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'
import { path, isEmpty } from 'ramda'
import { TextField, Button, Typography } from '@material-ui/core'

import { addRole as _addRole, updateRole as _updateRole, fetchRoles as _fetchRoles, clearRole as _clearRole  } from '../../../redux/roles/actions'
import { i18n } from '../../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    role: path(['roles', 'role'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchRole: (token, roleId) => dispatch(_fetchRoles(token, roleId)),
    addRole: (payload, token, callback) => dispatch(_addRole(payload, token, callback)),
    updateRole: (id, payload, token, callback) => dispatch(_updateRole(id, payload, token, callback)),
    clearRole: () => dispatch(_clearRole()),
})

function AddRolesComponent({
    token,
    role,

    fetchRole,
    addRole,
    updateRole,
    clearRole,
}) {
    const classes = styles()
    const { roleId } = useParams()
    const { url } = useRouteMatch()

    const initialValue = { name: { value: '', error: null }, description: { value: '', error: null } }

    const [formData, setFormData] = useState(initialValue)

    useEffect(() => {
        if (url !== '/admin/roles/new' && roleId) {
            fetchRole(token, roleId)
        }

        if (url === '/admin/roles/new') {
            clearRole()
        }
    }, [url])

    useEffect(() => {
        if (!isEmpty(role)) {
            setFormData({
                name: {
                    value: role.name || '',
                    error: null,
                },
                description: {
                    value: role.description || '',
                    error: null,
                },
            })
        } else {
            setFormData(initialValue)
        }
    }, [role])

    function isEditUrl() {
        return url !== '/admin/roles/new' && roleId
    }

    function handleChange(input) {
        return function(event) {
            setFormData({
                ...formData,
                [input]: {
                    value: event.target.value,
                    error: null,
                }
            })
        }
    }

    function handleSubmit() {
        const keys = Object.keys(formData)
        let hasError = false
        let payload = {}

        keys.forEach(key => {
            if (isEmpty(formData[key].value)) {
                hasError = true
                setFormData({
                    ...formData,
                    [key]: {
                        ...formData[key],
                        error: 'El campo no puede estar vacio'
                    }
                })
            } else {
                payload = {
                    ...payload,
                    [key]: formData[key].value
                }
            }
        })

        if (!hasError) {
            if (isEditUrl()) {
                updateRole(roleId, payload, token)
            } else {
                addRole(payload, token)
            }
        }
    }

    function clear() {
        setFormData(initialValue)
    }

    return (
        <React.Fragment>
            <form className={classes.formContainer}>
                <Typography variant="h5" align="center">{isEditUrl() ? i18n(['admin', 'roles_form', 'title_edit']) : i18n(['admin', 'roles_form', 'title_new'])}</Typography>
                <TextField
                    id="name"
                    fullWidth
                    error={!!formData.name.error}
                    label={i18n(['admin', 'roles_form', 'input_placeholder_name'])}
                    required
                    variant="outlined"
                    value={formData.name.value}
                    onChange={handleChange('name')}
                />
                <TextField
                    id="description"
                    fullWidth
                    error={!!formData.description.error}
                    label={i18n(['admin', 'roles_form', 'input_placeholder_description'])}
                    required
                    multiline
                    row={6}
                    variant="outlined"
                    value={formData.description.value}
                    onChange={handleChange('description')}
                />
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>{isEditUrl() ? i18n(['admin', 'roles_form', 'button_edit']) : i18n(['admin', 'roles_form', 'button_new'])}</Button>
                    <Button className={classes.button} variant="contained" onClick={clear}>{i18n(['admin', 'roles_form', 'button_clear'])}</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddRolesComponent)
