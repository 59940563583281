import { get, post, put } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'
import { HtmlMessage, ResponseMessageParse } from '../../models/htmlMessage'

// Action Types

export const GET_START = 'I18N_GET_START'
export const GET_SUCCESS = 'I18N_GET_SUCCESS'
export const GET_ERROR = 'I18N_GET_ERROR'
export const ADD_START = 'I18N_ADD_START'
export const ADD_SUCCESS = 'I18N_ADD_SUCCESS'
export const ADD_ERROR = 'I18N_ADD_ERROR'
export const UPDATE_START = 'I18N_UPDATE_START'
export const UPDATE_SUCCESS = 'I18N_UPDATE_SUCCESS'
export const UPDATE_ERROR = 'I18N_UPDATE_ERROR'

// Action Creators

const fetchTranslationsPromise = async (token, querystring) => get('translations/', querystring || '', {Authorization: token})
const addTranslationsPromise = async (body, token) => post('translations', body, {Authorization: token})
const updateTranslationsPromise = async (id, body, token) => put(`translations/${id}`, body, {Authorization: token})

export const fetchTranslations = (token, querystring) => async dispatch => {
    dispatch({ type: GET_START })
    dispatch(requestPending())

    try {
        const payload = await fetchTranslationsPromise(token, querystring)

        dispatch({
            type: GET_SUCCESS,
            payload,
        })

        dispatch(requestSuccess())

    } catch (error) {
        console.error(error)
        dispatch({ type: GET_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const addTranslations = (body, token) => async dispatch => {
    dispatch({ type: ADD_START })
    dispatch(requestPending())

    try {
        const payload = await addTranslationsPromise({ translation: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_SUCCESS })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateTranslations = (id, body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_START })
    dispatch(requestPending())

    try {
        const payload = await updateTranslationsPromise(id, { translation: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_SUCCESS, payload })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ERROR })
        if (error.htmlStatus === 422) {
            if (!!callback) {
                console.error(error)
                callback(error)
            }
            // dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}
