import React from 'react'
import { Pagination } from '@material-ui/lab'

function PaginatorComponent(props) {
    const { onChange, page, total, perPage, siblingCount, boundaryCount, size } = props

    return (
        <Pagination
            onChange={onChange}
            page={page || 1}
            count={Math.ceil(total/perPage) || 1}
            showFirstButton
            showLastButton
            siblingCount={siblingCount === undefined ? 3 : siblingCount}
            boundaryCount={boundaryCount === undefined ? 7 : boundaryCount}
            variant="outlined"
            shape="rounded"
            size={size || 'large'}
            color="primary"
        />
    )
}

export default PaginatorComponent
