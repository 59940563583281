import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    listItemIcon: {
        color: theme.palette.primary.main
    },
    titleRoot: {
        paddingTop: 26,
        paddingBottom: 0,
    },
    title: {
        textAlign: 'center',
    },
    contentRoot: {
        padding: '1px 0',
    },
    itemRoot: {
        paddingLeft: 26,
        paddingRight: 26,
    }
}))
