import {
    LOGIN_START,
    LOGIN_SUCCESS,
    LOGOUT,
    LOAD_SESION,
    LOGIN_ERROR,
    GET_USER_INFO_SUCCESS,
} from './actions'
import { setSesionData, clearSesion } from '../../services/sesionService'

const initialState = {
    username: null,
    auth_token: null,
    errorMessage: null,
    info: null,
}

export default function userReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case LOGIN_SUCCESS:
            setSesionData(payload)
            return Object.assign({}, state, {...payload })

        case LOGIN_ERROR:
            clearSesion()
            return Object.assign({}, initialState, {
                errorMessage: payload,
            })

        case LOAD_SESION:
            return Object.assign({}, state, {...payload })

        case LOGOUT:
            clearSesion()
            return Object.assign({}, state, initialState)

        case GET_USER_INFO_SUCCESS:
            return Object.assign({}, state, { info: payload })
        case LOGIN_START:
        default:
            return state
    }
}
