import { makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

export default makeStyles(theme => ({
    routesViewRoot: {
        display: 'flex',
        flexDirection: 'column'
    },

    item: {
        display: 'flex',
        alignItems: 'center',
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    },

    openLeftControl: {
        marginLeft: 500
    },

    openRightControl: {
        marginRight: 425
    },

    pieTitle: {
        width: '100%',
        marginBottom: -10,
    },

    pieContainer: {
        height: 220,
        width: '100%',
        marginTop: -20,
    },

    filterInputWidth: {
        width: '100%',
    },

    planingItem: {
        flexDirection: 'column',
    },

    planingTable: {
        width: 'auto',
        marginTop: -8,
        marginBottom: 8,

        '& td': {
            border: '1px solid #9e9e9e',
            padding: '2px 6px',
        }
    },

    planingRowCant: {
        textAlign: 'center',
        color: theme.palette.primary.main,
        fontWeight: 700,
        backgroundColor: grey[100],
    },

    planingRowClear: {
        border: 'none !important',
        padding: '2px !important'
    },

    orderDesorderWrap: {
        width: 74,
        height: 70,
        padding: '15px 10px',
        margin: 5,
        whiteSpace: 'break-spaces',
        textAlign: 'center',
        backgroundColor: grey[100],
        fontFamily: theme.typography.fontFamily,
        fontSize: 13,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',

        '& div:first-child': {
            fontSize: 25,
            color: theme.palette.primary.main,
        }
    },

    layerOptions: {
        zIndex: 99
    },

    submenuContainer: {
        display: 'flex',
    },

    linkContainer: {
        margin: '0 5px',
        '& a': {
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            display: 'inline-block',
            margin: '2px 2px 1px',
            padding: '2px 10px',
            borderRadius: 4,
            height: 25,

            '& svg': {
                marginBottom: '-3px',
            }
        },

        '& button': {
            backgroundColor: theme.palette.primary.main,
            color: '#ffffff',
            display: 'inline-block',
            margin: '3px 2px 1px',
            padding: '2px 10px',
            borderRadius: 4,
            border: 'none',
            height: 30,

            '& svg': {
                marginBottom: '-3px',
            }
        },

        '& span': {
            display: 'inline-block',
            marginTop: 4,
            marginRight: 2,
            color: theme.palette.primary.dark,
            fontWeight: 'bold',
            textDecoration: 'underline',
        }
    },

    paginatorContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        padding: '0 0 20px'
    }
}))
