import React from 'react'
import construccion from '../../assets/construccion.jpg'
import { makeStyles } from '@material-ui/core'

const useStyle = makeStyles({
    container: {
        display: 'flex',
        width: '100%',
    },
    image: {
        margin: 'auto',
    }
})

function InConstruccionPage() {
    const classes = useStyle()

    return (
        <div className={classes.container}>
            <img className={classes.image} src={construccion} alt="En Construcción"/>
        </div>
    )
}

export default InConstruccionPage
