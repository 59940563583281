import React from 'react'
import { path, pathOr } from 'ramda'
import { connect } from 'react-redux'
import { ListItem, List, Container, ListItemIcon, ListItemText, Typography } from '@material-ui/core'
import { Settings } from '@material-ui/icons'
import { mdiFolderOpen, mdiBellOutline, mdiBell, mdiHistory, mdiCalendarToday } from '@mdi/js'

import { 
  SvgIconComponent, LoaderComponent,
  AlertTableComponent,CategoryTableComponent,
  AlertTemplateTableComponent, AlertConfigurationTableComponent,
  OptionsFormComponent
} from '../../components'

import { NotFoundPage } from '..'
import { toggleLeftPanel as _toggleLeftPanel } from '../../redux/ui-states/actions'
import { Switch, Route } from 'react-router-dom'
import { i18n as i18nService } from '../../services/util'
import styles from './styles'


import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from '@material-ui/core/Drawer';
import Button from '@material-ui/core/Button';
import Divider from '@material-ui/core/Divider';
import ViewHeadlineIcon from '@material-ui/icons/ViewHeadline';

const useStyles = makeStyles({
  list: {
    width: 250,
  },
  fullList: {
    width: 'auto',
  },
  title: {
    padding: "16px 0",
    boxShadow: "0px 2px 4px -1px rgb(0 0 0 / 20%), 0px 4px 5px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)",
    color: "#FFF",
    backgroundColor: "#1565C0"
  },
  titleIcon: {
    float: "right",
    marginTop: "3px",
    color: "#FFF",
  },
  buttonControl: {
    position: 'absolute',
    padding: 0,
    width: '20px',
    top: '19px',
    left: '-10px',
    color: 'white',
    zIndex: '1100'
  }
});


function TemporaryDrawer(props) {
  const classes = useStyles();
  const [state, setState] = React.useState({ left: false });
  const { pushTo, i18n, links } = props

  const toggleDrawer = (anchor, open) => (event) => {
    if (event.type === 'keydown' && (event.key === 'Tab' || event.key === 'Shift')) {
      return;
    }
    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <div
      className={clsx(classes.list, { [classes.fullList]: anchor === 'top' || anchor === 'bottom' })}
      role="presentation"
      onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <div className={classes.drawerHeader}>
        <Typography variant="h6" align="center" className={classes.title}>
          <ListItemIcon classes={{ root: classes.listItemIcon }} className={classes.titleIcon}>
            <SvgIconComponent path={mdiBell} />
          </ListItemIcon>
          Gestión de Alertas
        </Typography>
      </div>

      <Divider />

      <List>
        <ListItem button onClick={pushTo(routes.listAHistoryAlerts)}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <SvgIconComponent path={mdiHistory} />
          </ListItemIcon>
          <ListItemText primary={i18n(['actions','history'])} />
        </ListItem>

        <Divider />

        <ListItem button className={classes.nested} onClick={pushTo(routes.listCategories)}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <SvgIconComponent path={mdiFolderOpen} />
          </ListItemIcon>
          <ListItemText primary={i18n(['category','title','two'])} />
        </ListItem>

        <Divider />

        <ListItem button className={classes.nested} onClick={pushTo(routes.listAlertConfigurations)}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <Settings />
          </ListItemIcon>
          <ListItemText primary={i18n(['alert-configuration','alert-alt'])} />
        </ListItem>

        <Divider />

        <ListItem button className={classes.nested} onClick={pushTo(routes.options)}>
          <ListItemIcon classes={{ root: classes.listItemIcon }}>
            <ViewHeadlineIcon />
          </ListItemIcon>
          <ListItemText primary={i18n(['actions','parameters'])} />
        </ListItem>

        {links.includes('only_admin_actions') && <>
          <Divider />
          <ListItem button className={classes.nested} onClick={pushTo(routes.listAlertTemplates)}>
            <ListItemIcon classes={{ root: classes.listItemIcon }}>
              <SvgIconComponent path={mdiBellOutline} />
            </ListItemIcon>
            <ListItemText primary={i18n(['alert-template','title','two'])} />
          </ListItem>
        </>}
      </List>
    </div>
  );

  return (
    <>
      <Button className={classes.buttonControl} onClick={toggleDrawer('left', true)}>
        <ViewHeadlineIcon/></Button>
      <Drawer anchor={'left'} open={state['left']} onClose={toggleDrawer('left', false)}>
        {list('left')}
      </Drawer>
    </>
  );
}

const mapStateToProps = state => ({
  links: pathOr([], ['user', 'info', 'data', 'meta', 'links'], state),
  loading: path(['request', 'loading'], state),
  leftPanelShow: path(['uiStates', 'leftPanel', 'show'], state),
})

const mapDispatchToProps = dispatch => ({
  toggleLeftPanel: () => dispatch(_toggleLeftPanel()),
})

const routes = {
  index: '/amm', //in the backend this points to amm/alerts/of-the-day
  listCurrentAlerts: 'amm/alerts/of-the-day',
  listAHistoryAlerts: '/amm/alerts/history',
  listAlertConfigurations: '/amm/alert-configurations',
  listCategories: '/amm/categories',
  options: '/amm/options',
}

function AmmPage(props) {
  const { links, loading, history } = props
  const classes = styles()
  const i18n = prop => i18nService(['amm'].concat(prop))

  if ( links.includes('only_admin_actions') ) {
    routes['listAlertTemplates'] = '/amm/alert-templates';
  }

  function pushTo(link) {
    return function() {
      history.push(link)
    }
  }

  return (
    <>
      <LoaderComponent visible={loading} />
      <Container maxWidth={false} className={classes.routesViewRoot}>
        <TemporaryDrawer pushTo={pushTo} i18n={i18n} links={links}/>
        <Switch>
          {links.includes('only_admin_actions') && <Route path={routes.listAlertTemplates}>
            <AlertTemplateTableComponent />
          </Route>}
          <Route path={routes.listCategories}>
            <CategoryTableComponent />
          </Route>
          <Route path={routes.listAlertConfigurations}>
            <AlertConfigurationTableComponent />
          </Route>
          <Route path={routes.listAHistoryAlerts}>
            <AlertTableComponent alertAction="history" titleIcon={mdiHistory} alertTitle={i18n(['alert','history'])}/>
          </Route>
          <Route path={routes.options}>
            <OptionsFormComponent icon={<Settings />} />
          </Route>
          <Route path={routes.index}>
            <AlertTableComponent alertAction="of-the-day" titleIcon={mdiCalendarToday} alertTitle={i18n(['alert','of-the-day'])} indicators={true} />
          </Route>
          <Route path="*">
            <NotFoundPage />
          </Route>
        </Switch>
      </Container>
    </>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AmmPage)
