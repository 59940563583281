import { mergeRight } from 'ramda'

import { GET_OPTIONS_SUCCESS } from './actions'

const initialState = { options: {}, fields: [] }

export default function ammOptionsReducer(state = initialState, action) {
  const { type, payload } = action
  const merge = mergeRight(state)

  switch (type) {
    case GET_OPTIONS_SUCCESS:
      return merge({ options: payload.options, fields: payload.fields })
    default:
      return state
  }
}