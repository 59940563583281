/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isEmpty, isNil, equals } from 'ramda'
import { List, Divider, ListItem, TextField, Button, ButtonGroup, FormControlLabel, Checkbox } from '@material-ui/core'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import { Autocomplete } from '@material-ui/lab'
import DateFnsUtils from '@date-io/date-fns'

import { i18n as i18nService, filterDataParser } from '../../../services/util'
import { autocomplete, buttonContainer, list, sourceGroup, sourceControlWrapper } from './styles'

const dateFormat = 'yyyy/MM/dd HH:mm'

function RoutesFiltersComponent({
    // Filters Slect Data
    routes, depots, vehicles, mobiles, stats, filtersByView, clusters,
    // Views
    view, activeSource,
    // Set Filters
    qs, setqs, initialState,
    // Events
    setActiveSource, submit, toggleFilter
}) {
    const autocompleteClasses = autocomplete()
    const buttonContainerClasses = buttonContainer()
    const listClasses = list()
    const sourceGroupClasses = sourceGroup()
    const sourceControlWrapperClasses = sourceControlWrapper()
    const i18nFilters = prop => i18nService(['routes', 'filters', prop])

    const itemStatus = [
        { name: i18nFilters('item_status_option_arrival'), value: 'arrival' },
        { name: i18nFilters('item_status_option_failure'), value: 'failure' },
        { name: i18nFilters('item_status_option_success'), value: 'success' },
        { name: i18nFilters('item_status_option_partial'), value: 'partial' },
    ]

    const [pristine, setPristine] = useState(true)
    const [prevSource, setPrevSource] = useState(null)

    useEffect(() => {
        setPrevSource(activeSource)
        let handler

        if (prevSource === 'routes' && activeSource === 'items') {
            handler = submitHandler
        } else if (!pristine) {
            handler = submitHandler
        } else {
            handler = clearHandler
        }

        handler()
    }, [activeSource])

    function textHandleChange(event) {
        setqs({...qs, [event.target.name]: event.target.value })
    }

    function checkHandleChange(event) {
        setqs({...qs, [event.target.name]: event.target.checked })
    }

    function selectHandleChange(name) {
        return function (event, value, reason) {
            setqs({...qs, [name]: value })
        }
    }

    function datePickerHandleChange(name) {
        return function(value) {
            setqs({ ...qs, [name]: value })
        }
    }

    function submitHandler() {
        submit(filterDataParser(qs, filtersByView, activeSource), qs)
        if (!equals(qs, initialState)) {
            setPristine(false)
        } else {
            setPristine(true)
        }
        toggleFilter()
    }

    function clearHandler() {
        setPristine(true)
        submit(filterDataParser(initialState, filtersByView, activeSource), initialState)
    }

    function itemsToggleHanldler() {
        if (activeSource === 'routes') {
            setActiveSource('items')
        } else {
            setActiveSource('routes')
        }
    }

    function changeSourceHandler(souce) {
        return function() {
            setActiveSource(souce)
        }
    }

    if (!qs) return ''

    return (
        <List dense classes={listClasses}>
            <Divider />
            <ListItem className={sourceControlWrapperClasses.root}>
                <ButtonGroup color="primary" variant="contained" classes={sourceGroupClasses} fullWidth>
                    <Button disabled={['routes', 'items'].includes(activeSource)} onClick={changeSourceHandler('routes')}>{i18nFilters('routes_button')}</Button>
                    <Button disabled={activeSource === 'vehicles'} onClick={changeSourceHandler('vehicles')}>{i18nFilters('routes_vehicles')}</Button>
                    {/*<Button disabled={activeSource === 'mobiles'} onClick={changeSourceHandler('mobiles')}>{i18nFilters('routes_mobiles')}</Button>*/}
                </ButtonGroup>
            </ListItem>


            {['routes', 'items'].includes(activeSource) && view === 'table' && <ListItem>
                <FormControlLabel
                    control={<Checkbox checked={activeSource === 'items'} onChange={itemsToggleHanldler} name="use-items-table" color="primary" />}
                    label={i18nFilters('items_check')}
                />
            </ListItem>}

            {['routes'].includes(activeSource) && <ListItem>
                <FormControlLabel
                    control={<Checkbox checked={!!qs.running} onChange={checkHandleChange} name="running" color="primary" />}
                    label={i18nFilters('active_route_check')}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('with_routes') && <ListItem>
                <FormControlLabel
                    control={<Checkbox checked={!!qs.with_routes} onChange={checkHandleChange} name="with_routes" color="primary" />}
                    label={i18nFilters('with_routes_check')}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_cluster_id') && <ListItem>
                <Autocomplete
                    id="by_cluster_id"
                    options={clusters}
                    disabled={isEmpty(clusters)}
                    classes={autocompleteClasses}
                    getOptionLabel={options => options.name}
                    value={qs.by_cluster_id}
                    onChange={selectHandleChange('by_cluster_id')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_cluster_id')} variant="outlined" />}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_itinerary_id') && <ListItem>
                <TextField
                    id="by_itinerary_id"
                    name="by_itinerary_id"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_itinerary_id')}
                    variant="outlined"
                    value={qs.by_itinerary_id}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_nid') && <ListItem>
                <TextField
                    id="by_nid"
                    name="by_nid"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_nid')}
                    variant="outlined"
                    value={qs.by_nid}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_uid') && <ListItem>
                <TextField
                    id="by_uid"
                    name="by_uid"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_uid')}
                    variant="outlined"
                    value={qs.by_uid}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_vehicle_nid') && <ListItem>
                <TextField
                    id="by_vehicle_nid"
                    name="by_vehicle_nid"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_vehicle_nid')}
                    variant="outlined"
                    value={qs.by_vehicle_nid}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_license_plate') && <ListItem>
                <TextField
                    id="by_license_plate"
                    name="by_license_plate"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_vehicle_nid')}
                    variant="outlined"
                    value={qs.by_license_plate}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_route_nid') && <ListItem>
                <TextField
                    id="by_route_nid"
                    name="by_route_nid"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_route_nid')}
                    variant="outlined"
                    value={qs.by_route_nid}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_status') && <ListItem>
                <Autocomplete
                    id="by_status"
                    options={stats}
                    disabled={isEmpty(stats)}
                    classes={autocompleteClasses}
                    getOptionLabel={options => options.name}
                    value={qs.by_status}
                    onChange={selectHandleChange('by_status')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_status')} variant="outlined" />}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_starts_at[from]') && <ListItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        format={dateFormat}
                        id="by_starts_at[from]"
                        label={i18nFilters('by_starts_at_from')}
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        disableFuture
                        value={qs['by_starts_at[from]']}
                        onChange={datePickerHandleChange('by_starts_at[from]')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        ampm={false}
                    />
                </MuiPickersUtilsProvider>
            </ListItem>}
            {filtersByView[activeSource].includes('by_starts_at[to]') && <ListItem>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    <KeyboardDateTimePicker
                        variant="inline"
                        format={dateFormat}
                        id="by_starts_at_to"
                        label={i18nFilters('by_starts_at_to')}
                        inputVariant="outlined"
                        margin="dense"
                        fullWidth
                        disabled={isNil(qs['by_starts_at[from]'])}
                        value={qs['by_starts_at[to]']}
                        onChange={datePickerHandleChange('by_starts_at[to]')}
                        KeyboardButtonProps={{
                            'aria-label': 'change date',
                        }}
                        ampm={false}
                    />
                </MuiPickersUtilsProvider>
            </ListItem>}

            {filtersByView[activeSource].includes('by_item_document') && <ListItem>
                <TextField
                    id="by_item_document"
                    name="by_item_document"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_item_document')}
                    variant="outlined"
                    value={qs.by_item_document}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_depot') && <ListItem>
                {/* <Autocomplete
                    id="by_depot"
                    options={depots}
                    disabled={isEmpty(depots)}
                    getOptionLabel={options => options.name}
                    classes={autocompleteClasses}
                    value={qs.by_depot}
                    onChange={selectHandleChange('by_depot')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_depot')} variant="outlined" />}
                    /> */}
                <TextField
                    id="by_depot"
                    name="by_depot"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_depot')}
                    variant="outlined"
                    value={qs.by_depot}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_item_status') && <ListItem>
                <Autocomplete
                    id="by_item_status"
                    options={itemStatus}
                    getOptionLabel={options => options.name}
                    classes={autocompleteClasses}
                    value={qs.by_item_status}
                    onChange={selectHandleChange('by_item_status')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_item_status')} variant="outlined" />}
                    />
            </ListItem>}

            {filtersByView[activeSource].includes('by_item_nid') && <ListItem>
                {/* <Autocomplete
                    id="by_item_nid"
                    options={routes}
                    disabled={isEmpty(routes)}
                    classes={autocompleteClasses}
                    getOptionLabel={options => options.name}
                    value={qs.by_item_nid}
                    onChange={selectHandleChange('by_item_nid')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_item_nid')} variant="outlined" />}
                    /> */}
                <TextField
                    id="by_item_nid"
                    name="by_item_nid"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_item_nid')}
                    variant="outlined"
                    value={qs.by_item_nid}
                    onChange={textHandleChange}
                />
            </ListItem>}

            {filtersByView[activeSource].includes('by_vehicle') && <ListItem>
                <Autocomplete
                    id="by_vehicle"
                    options={vehicles}
                    disabled={isEmpty(vehicles)}
                    classes={autocompleteClasses}
                    getOptionLabel={options => options.name}
                    value={qs.by_vehicle}
                    onChange={selectHandleChange('by_vehicle')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_vehicle')} variant="outlined" />}
                    />
            </ListItem>}

            {filtersByView[activeSource].includes('by_mobile_device_uid') && <ListItem>
                <Autocomplete
                    id="by_mobile_device_uid"
                    options={mobiles}
                    disabled={isEmpty(mobiles)}
                    classes={autocompleteClasses}
                    getOptionLabel={options => options.name}
                    value={qs.by_mobile_device_uid}
                    onChange={selectHandleChange('by_mobile_device_uid')}
                    renderInput={params => <TextField {...params} margin="dense" label={i18nFilters('by_mobile_device_uid')} variant="outlined" />}
                    />
            </ListItem>}

            {filtersByView[activeSource].includes('by_description') && <ListItem>
                <TextField
                    id="by_description"
                    name="by_description"
                    fullWidth
                    margin="dense"
                    label={i18nFilters('by_description')}
                    variant="outlined"
                    value={qs.by_description}
                    onChange={textHandleChange}
                />
            </ListItem>}

            <ListItem classes={buttonContainerClasses}>
                <Button variant="outlined" onClick={clearHandler}>{i18nFilters('clean_button')}</Button>
                <Button variant="outlined" color="primary" onClick={submitHandler}>{i18nFilters('apply_button')}</Button>
            </ListItem>
        </List>
    )
}

RoutesFiltersComponent.propTypes = {
    routes: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
        })
    ).isRequired,

    depots: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
        })
    ).isRequired,

    vehicles: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
        })
    ).isRequired,

    mobiles: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
        })
    ).isRequired,

    stats: PropTypes.arrayOf(
        PropTypes.shape({
            value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
            name: PropTypes.string,
        })
    ).isRequired,

    view: PropTypes.string.isRequired,

    submit: PropTypes.func,
}

RoutesFiltersComponent.defaultProps = {
    routes: [],
    depots: [],
    vehicles: [],
    mobiles: [],
    stats: [],
    view: 'map',
}

export default RoutesFiltersComponent
