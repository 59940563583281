import React from 'react'
import { Button, IconButton, Tooltip, SvgIcon } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(2, 4, 3),
  },
}));

function ModalBuilder(props) {
  const { modalOpen, setModalOpen } = props
  const classes = useStyles();

  const handleOpen = () => {
    setModalOpen(true)
    if (props.cbFnAfterOpen) {
      props.cbFnAfterOpen(...props.cbArgAfterOpen)
    }
  };

  const handleClose = () => {
    setModalOpen(false)
    if (props.cbFnAfterClose) {
      props.cbFnAfterClose(...props.cbArgAfterClose)
    }
  };

  return (
    <>
      { props.buttonVariant && props.buttonColor && props.buttonTitle ?
        <Button className={props.buttonClass} size={props.buttonSize} variant={props.buttonVariant} color={props.buttonColor} onClick={handleOpen}>{props.buttonTitle}</Button> :
        <Tooltip title={props.buttonTooltip}>
          <IconButton className={props.buttonClass} size={props.buttonSize} onClick={handleOpen}>
            <SvgIcon><path d={props.buttonIcon}></path></SvgIcon>
          </IconButton>
        </Tooltip>
      }
      <Dialog
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={modalOpen}
        onClose={handleClose}
        closeAfterTransition
        scroll='body'
        maxWidth='md'
        disableBackdropClick={true}
      >
        <Fade in={modalOpen}>
          <div className={classes.paper}>
            {React.cloneElement(props.children, { handleClose: handleClose })}
          </div>
        </Fade>
      </Dialog>
    </>
  )
}

export default ModalBuilder