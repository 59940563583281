import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    rootPaper: {
        position: 'relative',
        marginLeft: 20,
    },
    open: {
        height: 'auto',
        transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        transition: theme.transitions.create('height', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        height: 0,
    },
    wrapper: {
        zIndex: theme.zIndex.appBar - 10,
        position: 'absolute',
        overflowY: 'hidden',
        right: 0
    },
    filterPaper: {
        height: '100%',
    }
}))
