import { get, post, put, del } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'
import { HtmlMessage, ResponseMessageParse } from '../../models/htmlMessage'

// Actions types

export const FETCH_START = 'USER_FETCH_START'
export const FETCH_SUCCESS = 'USER_FETCH_SUCCESS'
export const FETCH_BY_ID_SUCCESS = 'USER_FETCH_BY_ID_SUCCESS'
export const FETCH_ERROR = 'USER_FETCH_ERROR'
export const ADD_START = 'USER_ADD_START'
export const ADD_SUCCESS = 'USER_ADD_SUCCESS'
export const ADD_ERROR = 'USER_ADD_ERROR'
export const UPDATE_START = 'USER_UPDATE_START'
export const UPDATE_SUCCESS = 'USER_UPDATE_SUCCESS'
export const UPDATE_ERROR = 'USER_UPDATE_ERROR'
export const DELETE_START = 'USER_DELETE_START'
export const DELETE_SUCCESS = 'USER_DELETE_SUCCESS'
export const DELETE_ERROR = 'USER_DELETE_ERROR'

export const FETCH_ACTIONS_START = 'USER_FETCH_ACTIONS_START'
export const FETCH_ACTIONS_SUCCESS = 'USER_FETCH_ACTIONS_SUCCESS'
export const FETCH_ACTIONS_ERROR = 'USER_FETCH_ACTIONS_ERROR'
export const UPDATE_ROLE_START = 'USER_UPDATE_ROLE_START'
export const UPDATE_ROLE_SUCCESS = 'USER_UPDATE_ROLE_SUCCESS'
export const UPDATE_ROLE_ERROR = 'USER_UPDATE_ROLE_ERROR'
export const UPDATE_ACTIONS_START = 'USER_UPDATE_ACTIONS_START'
export const UPDATE_ACTIONS_SUCCESS = 'USER_UPDATE_ACTIONS_SUCCESS'
export const UPDATE_ACTIONS_ERROR = 'USER_UPDATE_ACTIONS_ERROR'

export const fetchUsersPromise = async (token, userId = '') => get(`admin/users/${userId}`, '', {Authorization: token})
export const addUserPromise = async (body, token) => post('admin/users', body, {Authorization: token})
export const updateUserPromise = async (id, body, token) => put(`admin/users/${id}`, body, {Authorization: token})
export const deleteUserPromise = async (id, token) => del(`admin/users/${id}`, null, {Authorization: token})

export const fetchUserActionsPromise = async (token, userId = '') => get(`/admin/users/${userId}/actions`, '', {Authorization: token})
export const updateUserRolePromise = async (userId, body, token) => put(`/users/${userId}`, body, {Authorization: token})
export const updateUserActionsPromise = async (userId, body, token) => put(`/admin/users/${userId}/update_actions`, body, {Authorization: token})

// Async Actions creators

export const fetchUsers = (token, userId) => async dispatch => {
    dispatch({ type: FETCH_START })
    dispatch(requestPending())

    try {
        const result = await fetchUsersPromise(token, userId)

        if (!userId) {
            dispatch({
                type: FETCH_SUCCESS,
                payload: result,
            })
        } else {
            dispatch({
                type: FETCH_BY_ID_SUCCESS,
                payload: result,
            })
        }

        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: FETCH_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const addUser = (body, token) => async dispatch => {
    dispatch({ type: ADD_START })
    dispatch(requestPending())

    try {
        const response = await addUserPromise({ role: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_SUCCESS })

        const { status, statusText, message, severity } = ResponseMessageParse(response)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateUser = (id, body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_START })
    dispatch(requestPending())

    try {
        const response = await updateUserPromise(id, { role: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_SUCCESS })
        if (!!callback) {
            callback(response)
        }

        if (!!callback) {
            callback(response)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(response)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deleteUser = (token, userId) => async dispatch => {
    dispatch({ type: DELETE_START })
    dispatch(requestPending())

    try {
        await deleteUserPromise(token, userId)

        dispatch({ type: DELETE_SUCCESS })
        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: DELETE_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const fetchUserActions = (token, userId) => async dispatch => {
    dispatch({ type: FETCH_ACTIONS_START })
    dispatch(requestPending())
    try {
        const result = await fetchUserActionsPromise(token, userId)

        dispatch({ type: FETCH_ACTIONS_SUCCESS, payload: result })
        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: FETCH_ACTIONS_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const updateUserRole = (userId, payload, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_ROLE_START })
    dispatch(requestPending())

    try {
        const response = await updateUserRolePromise(userId, {
            user: {
                main_role_id: payload
            }
        }, token)

        if (!!callback) {
            callback(response)
        }

        dispatch({ type: UPDATE_ROLE_SUCCESS })
        dispatch(requestSuccess())

        const { status, statusText, message, severity } = ResponseMessageParse(response)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        //TODO catch errors
        dispatch({ type: UPDATE_ROLE_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const updateUserActions = (userId, payload, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_ACTIONS_START })
    dispatch(requestPending())

    try {
        const response = await updateUserActionsPromise(userId, { action_user: payload }, token)

        if (!!callback) {
            callback(response)
        }

        dispatch({ type: UPDATE_ACTIONS_SUCCESS })
        dispatch(requestSuccess())

        const { status, statusText, message, severity } = ResponseMessageParse(response)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        //TODO catch errors
        dispatch({ type: UPDATE_ACTIONS_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}
