import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip } from '@material-ui/core'
import { mdiPlusCircle, mdiPencilCircle, mdiAccountMultipleRemove } from '@mdi/js'

import { ModalComponent, SvgIconComponent } from '../..'
import { fetchRoles as _fetchRoles, deleteRole as _deleteRole } from '../../../redux/roles/actions'
import { i18n as i18nService } from '../../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    roles: path(['roles', 'roles'], state)
})

const mapDispatchToProps = dispatch => ({
    fetchRoles: token => dispatch(_fetchRoles(token)),
    deleteRole: (roleId, token, callback) => dispatch(_deleteRole(roleId, token, callback)),
})

function ListRolesComponent({
    token,
    roles,
    fetchRoles,
    deleteRole,
}) {
    const classes = styles()
    const history = useHistory()
    const i18n = path => i18nService(['admin', 'roles_list', path])
    const selectedKeys = [{ value: 'name', label: i18n('table_name') }, { value: 'description', label: i18n('table_description') }]
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const [deleteModalShow, setDeleteModalShow] = useState(false)

    useEffect(() => {
        fetchRoles(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function editRolesActionsHandler({ id }) {
        return function() {
            history.push(`/admin/roles/${id}/actions`)
        }
    }

    function editRoleHandler({ id }) {
        return function () {
            history.push(`/admin/roles/${id}/edit`)
        }
    }

    function removeHanlder(role) {
        return function() {
            setSelectedToDelete(role)
            setDeleteModalShow(true)
        }
    }

    function closeRemoveModal() {
        setDeleteModalShow(false)
        setSelectedToDelete(null)
    }

    function confirmRemove() {
        deleteRole(selectedToDelete.id, token, () => {
            setDeleteModalShow(false)
            fetchRoles(token)
        })
    }

    return (
        <React.Fragment>
            <Typography variant="h5" align="center">{i18n('title')}</Typography>
            <Table size="small" classes={{ root: classes.tableRoot }}>
                <TableHead>
                    <TableRow>
                        {selectedKeys.map((column, k) => (
                            <TableCell key={k}>{column.label}</TableCell>
                        ))}
                        <TableCell>{i18n('table_actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {roles.map((role, k) => (
                        <TableRow key={k}>
                            {selectedKeys.map((item, k) => (
                                <TableCell key={k}>{role[item.value]}</TableCell>
                            ))}
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                <Tooltip title={i18n('tooltip_add')}>
                                    <IconButton onClick={editRolesActionsHandler(role)}>
                                        <SvgIconComponent path={mdiPlusCircle} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n('tooltip_edit')}>
                                    <IconButton onClick={editRoleHandler(role)}>
                                        <SvgIconComponent path={mdiPencilCircle} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n('tooltip_delete')}>
                                    <IconButton onClick={removeHanlder(role)}>
                                        <SvgIconComponent path={mdiAccountMultipleRemove} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <ModalComponent open={deleteModalShow} onClose={closeRemoveModal}>
                <div className={classes.modalWrapper}>
                    <Typography variant="h5" align="center">{i18n('modal_title')}<br />{selectedToDelete && selectedToDelete.name}</Typography>
                    <div className={classes.modalButtonContainer}>
                        <Button color="default" variant="contained" onClick={() => closeRemoveModal()}>{i18n('modal_button_cancel')}</Button>
                        <Button color="secondary" variant="contained" onClick={() => confirmRemove()}>{i18n('modal_button_confirm')}</Button>
                    </div>
                </div>
            </ModalComponent>
        </React.Fragment>
    )
}

ListRolesComponent.propTypes = {
    roles: PropTypes.array.isRequired
}

ListRolesComponent.defaultProps = {
    roles: []
}

export default connect(mapStateToProps, mapDispatchToProps)(ListRolesComponent)
