import React from 'react'
import { makeStyles, Typography, Tooltip } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles(theme => ({
    progressRoot: {
        width: '100%',
        display: 'flex',
        background: grey[500],
        minHeight: 35,
        borderRadius: 4,
        overflow: 'hidden',
        textAlign: 'center',
        position: 'relative'
    },
    textContainer: {
        position: 'absolute',
        top: '20%',
        left: 10,
        color: '#fff',
        '& p': {
            fontWeight: 700,
        }
    }
}))

function StatsProgressComponent(props) {
    const classes = styles()
    const { color, value, value_label, sufix, max, text, tooltip } = props

    if (tooltip) return <Tooltip title={tooltip} placement="top" arrow>
        <div className={classes.progressRoot}>
            <div style={{ background: color, width: `${value / max * 100}%` }}></div>
            <div className={classes.textContainer}>
                <Typography variant="body2">{text} {value} {sufix || ''}</Typography>
            </div>
        </div>
    </Tooltip>

    return (
        <div className={classes.progressRoot}>
            <div style={{ background: color, width: `${value / max * 100}%` }}></div>
            <div className={classes.textContainer}>
                <Typography variant="body2">{text} {value_label || value} {sufix || ''}</Typography>
            </div>
        </div>
    )
}

export default StatsProgressComponent
