import React, { /*useState, */useEffect } from 'react'
import PropTypes from 'prop-types'
import { Paper, List, ListItem, ListItemIcon, Checkbox, ListItemText, Typography } from '@material-ui/core'
import styles from './styles'
import { i18n } from '../../../services/util'

function PopCheckMenuComponent(props) {
    const classes = styles()
    const { itemsGroup, itemsByColumn, onChange, setPageTitle, setSelected, selected, popCheckTitle } = props
    const itemsValues = itemsGroup.map(data => data.items.map(item => item.value)).reduce((prev, curr) => [...prev, ...curr], [])
    // const [selected, setSelected] = useState([])

    useEffect(() => {
        // onChange(selected)
        if (selected.length > 0) {
            onChange(selected)
            let selected_depots = [];
            let new_title;
            let truncate;
            selected.forEach(function (depot_nid, index) {
                selected_depots.push(itemsGroup[0].items.find(x => x.value === depot_nid).name)
            });
            new_title = selected_depots.join(', ')
            truncate = (new_title.length > 28) ? new_title.substr(0, 28-1) + ' ...' : new_title;
            if(setPageTitle) {
                setPageTitle(truncate)
            }
        } else {
            if(setPageTitle) {
                setPageTitle(i18n(['indicators', 'title']))
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selected])

    function changeHandler(value) {
        return function () {
            if (selected.indexOf(value) === -1) {
                setSelected([...selected, value])
            } else {
                const copy = [...selected]
                copy.splice(selected.indexOf(value), 1)
                setSelected(copy)
            }
        }
    }

    function selectAll() {
        if (itemsValues.length === selected.length) {
            setSelected([])
        } else {
            setSelected(itemsValues)
        }
    }

    return (
        <div className={classes.container}>
            <Paper classes={{ root: classes.paperRoot }}>
                <Typography className={classes.title} color="textSecondary" gutterBottom>
                    {popCheckTitle}
                </Typography>
                <div className={classes.selectAll}>
                    <Checkbox
                        edge="start"
                        checked={itemsValues.length === selected.length}
                        tabIndex={-1}
                        disableRipple
                        inputProps={{ 'aria-labelledby': 'select-all' }}
                        onChange={selectAll}
                    />
                    <Typography id="select-all" variant="h6">Seleccionar Todos</Typography>
                </div>

                {itemsGroup.map((data, key) => {
                    const originalArray = [...data.items]
                    const newArray = []

                    if (itemsByColumn) {
                        while (originalArray.length) {
                            newArray.push(originalArray.splice(0, itemsByColumn))
                        }
                    } else {
                        newArray.push(originalArray)
                    }

                    return <div key={key + '-items-group'} className={classes.col}>
                        {newArray.map((col, key) => {
                            return <List key={key + '-col'}>
                                {col.map((item, key) => {
                                    return <ListItem key={key + '-item'}>
                                        <ListItemIcon>
                                            <Checkbox
                                                edge="start"
                                                checked={selected.indexOf(item.value) !== -1}
                                                tabIndex={-1}
                                                disableRipple
                                                inputProps={{ 'aria-labelledby': key + '-item' }}
                                                onChange={changeHandler(item.value)}
                                            />
                                        </ListItemIcon>
                                        <ListItemText id={key + '-item'} classes={{ root: classes.label }} primary={item.name} />
                                    </ListItem>
                                })}
                            </List>
                        })}
                    </div>
                })}
            </Paper>
        </div>
    )
}

PopCheckMenuComponent.propTypes = {
    itemsGroup: PropTypes.arrayOf(PropTypes.shape({
        title: PropTypes.string,
        items: PropTypes.array.isRequired,
    })).isRequired,
    itemsSelected: PropTypes.array,
    itemsByColumn: PropTypes.number,
    onChange: PropTypes.func,
    onToggleAll: PropTypes.func,
}

PopCheckMenuComponent.defaultProps = {
    itemsGroup: [{ items: [] }],
    itemsSelected: [],
    onChange: (item, value) => { console.log(item, value) },
    onToggleAll: (item, value) => { console.log(item, value) },
}

export default PopCheckMenuComponent
