import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Container, List, ListItem, Typography, Divider, IconButton, Fab, FormControl,
    InputLabel, Select, MenuItem, Input, InputAdornment, Button } from '@material-ui/core'
import * as mdi from '@mdi/js'
import { mdiOfficeBuilding, mdiClose, mdiCogOutline } from '@mdi/js'
import * as R from 'ramda'

import { DepotsMapComponent, DetailPanelComponent, MapControlComponent, SvgIconComponent, ModalComponent } from '../../components'
import { fetchData as _getData, getDetail as _getDetail, removeDetail as _removeDetail } from '../../redux/depots/actions'
import { openDetailConfig as _openDetailConfig, closeDetailConfig as _closeDetailConfig } from '../../redux/ui-states/actions'
import { updateDeptConfig as _updateDeptConfig, defaultDeptConfig as _defaultDeptConfig } from '../../redux/ui-config/actions'
import { mapPointerIconList } from '../../services/iconService'
import styles from './styles'

const mapStateToProps = state => ({
    token: R.path(['user', 'auth_token'], state),
    points: R.path(['depotsLayer', 'response', 'data'], state),
    pointDetail: R.path(['depotsLayer', 'detail'], state),
    pointsReqStatus: R.path(['depotsLayer', 'requestStatus'], state),
    mapIconConfig: R.path(['uiConfig', 'depotsPage', 'icon'], state),
    configOpen: R.path(['uiStates', 'detailConfig', 'show'], state)
})

const mapDispatchToProps = dispatch => ({
    getData: token => dispatch(_getData(token)),
    getDetail: id => dispatch(_getDetail(id)),
    removeDetail: () => dispatch(_removeDetail()),
    openDetailConfig: () => dispatch(_openDetailConfig()),
    closeDetailConfig: () => dispatch(_closeDetailConfig()),
    updateDeptConfig: payload => dispatch(_updateDeptConfig(payload)),
    defaultDeptConfig: () => dispatch(_defaultDeptConfig()),
})

function DepotsPage({
    // States
    token,
    points,
    pointsReqStatus,
    pointDetail,
    mapIconConfig,
    configOpen,
    // Actions
    getData,
    getDetail,
    removeDetail,
    openDetailConfig,
    closeDetailConfig,
    updateDeptConfig,
    defaultDeptConfig,
}) {

    const classes = styles()

    const [mapIcon, setMapIcon] = useState(mapIconConfig.name)
    const [mapIconColor, setMapIconColor] = useState(mapIconConfig.color)
    const [mapIconSize, setMapIconSize] = useState(mapIconConfig.size)

    useEffect(() => {
        if (pointsReqStatus === 'none') {
            getData(token)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pointsReqStatus])

    useEffect(() => {
        setMapIcon(mapIconConfig.name)
        setMapIconColor(mapIconConfig.color)
        setMapIconSize(mapIconConfig.size)
    }, [mapIconConfig])

    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(() => removeDetail, [])

    const detailProp = R.prop(R.__, pointDetail)

    function detailOpen() {
        return !R.isNil(pointDetail)
    }

    function handleSetMapIcon(e) {
        setMapIcon(e.target.value)
    }

    function handlerSetMapIconColor(e) {
        setMapIconColor(e.target.value)
    }

    function handleSetMapIconSize(e) {
        setMapIconSize(e.target.value)
    }

    function saveNewMapConfig() {
        updateDeptConfig({
            ...mapIconConfig,
            name: mapIcon,
            color: mapIconColor,
            size: mapIconSize,
        })

        closeDetailConfig()
    }

    function defaultConfig() {
        defaultDeptConfig()
        closeDetailConfig()
    }

    return(
        <div className={detailOpen() ? classes.depotsRoot + ' ' + classes.detailSideBarOpen : classes.depotsRoot}>
            <Container className={classes.mapContainer}>
                {points ?
                    <div className={classes.mapWrapper}>
                        <DepotsMapComponent points={points} selectedPoint={pointDetail} setSelectedPoint={getDetail} iconConfig={mapIconConfig} />
                        <MapControlComponent>
                            <Fab size="small" color="inherit" onClick={openDetailConfig}>
                                <SvgIconComponent path={mdiCogOutline} />
                            </Fab>
                        </MapControlComponent>
                    </div>
                : ''}
            </Container>

            <ModalComponent open={configOpen} onClose={closeDetailConfig}>
                <form>
                    <Typography className={classes.detailConfigTitle} variant="h5" align="center">Configuración del Mapa</Typography>
                    <Divider />
                    <FormControl className={classes.configFormControl}>
                        <InputLabel>Tipo de Icono</InputLabel>
                        <Select value={mapIcon} onChange={handleSetMapIcon} className={classes.select}>
                            {mapPointerIconList.map((option, key) =>
                                <MenuItem key={key} className={classes.configSelectItem} value={option}>
                                    <SvgIconComponent className={classes.configSelectIcon} path={mdi[option]} />{option.replace(/^mdi/, '')}
                                </MenuItem>
                            )}
                        </Select>
                    </FormControl>

                    <FormControl className={`${classes.configFormControl} ${classes.leftInput}`}>
                        <InputLabel>Color del Icono</InputLabel>
                        <Input
                            type="text"
                            value={mapIconColor}
                            onChange={handlerSetMapIconColor}
                            startAdornment={
                                <InputAdornment style={{color: mapIconColor}}>
                                    <SvgIconComponent className={classes.configSelectIcon} path={mdi[mapIcon]} />
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                    <FormControl className={`${classes.configFormControl} ${classes.rightInput}`}>
                        <InputLabel>Tamaño del Icono</InputLabel>
                        <Select value={mapIconSize} onChange={handleSetMapIconSize} className={classes.select}>
                            <MenuItem className={classes.configSelectItem} value={32}>
                                Pequeño
                            </MenuItem>
                            <MenuItem className={classes.configSelectItem} value={39}>
                                Default
                            </MenuItem>
                            <MenuItem className={classes.configSelectItem} value={48}>
                                Grande
                            </MenuItem>
                        </Select>
                    </FormControl>
                    <div className={classes.configButtonsContainer}>
                        <Button className={classes.configButtons} variant="contained" color="primary" onClick={saveNewMapConfig}>Guardar</Button>
                        <Button className={classes.configButtons} variant="contained" color="default" onClick={defaultConfig}>Reestablecer</Button>
                    </div>
                </form>
            </ModalComponent>

            <DetailPanelComponent classes={{ paper: classes.detailSideBar }} open={detailOpen()} onClose={removeDetail}>
                <List>
                    <ListItem>
                        <SvgIconComponent className={classes.detailSideBarIcon} path={mdiOfficeBuilding} />
                        <Typography variant="h6">Centro de Distribución</Typography>
                        <IconButton className={classes.closeDetailSideBarButton} onClick={removeDetail}>
                            <SvgIconComponent path={mdiClose} />
                        </IconButton>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <p className={`${classes.p} ${classes.firstItem}`}>ID<span>{detailProp('id')}</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Nombre<span>{detailProp('name')}</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Cenntro de Costo<span>{detailProp('')}Sin Información</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Code<span>{detailProp('nid')}</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Telefono<span>{detailProp('')}Sin Información</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Ultima Modificación<span>{new Date(detailProp('updated_at')).toLocaleString()}</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Estacionamiento<span>{detailProp('')}Sin Información</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={`${classes.p} ${classes.lastItem}`}>Geoservice Speed weight<span>{detailProp('')}Sin Información</span></p>
                    </ListItem>
                    <Divider />
                    <ListItem>
                        <p className={`${classes.p} ${classes.firstItem}`}>Confirmación<span>{detailProp('')}Sin Información</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Latitud/Longitud<span>{detailProp('latitude')}, {detailProp('longitude')}</span></p>
                    </ListItem>
                    <ListItem>
                        <p className={classes.p}>Dirección Completa<span>{detailProp('addr_street')} {detailProp('addr_number')}, {detailProp('addr_city')}, {detailProp('addr_country')}</span></p>
                    </ListItem>
                </List>
                <div className={classes.backButtonContainer}>
                    <Button color="primary" variant="contained" onClick={removeDetail}>Ver todos</Button>
                </div>
            </DetailPanelComponent>
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(DepotsPage)
