import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    formContainer: {
        '& > div': {
            margin: '16px 0',
        },
    },
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        position: 'absolute',
        bottom: 35,
        left: 0,
    },

    modalWrapper: {
        height: 240,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    }
}))
