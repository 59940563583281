import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    formContainer: {
        '& > div': {
            margin: '16px 0',
            width: 'calc(50% - 5px)',

            '&:nth-child(odd)': {
                marginLeft: 5,
            },
            '&:nth-child(even)': {
                marginRight: 5,
            }
        },
    },

    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100% !important',
        marginRight: '0 !important',
        marginLeft: '0 !important',
        position: 'absolute',
        bottom: 35,
        left: 0,
    },

    modalWrapper: {
        height: 240,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    paramsControlRoot: {
        margin: '8px 0 26px 10px'
    },

    table: {
        '& th, & td': {
            textAlign: 'center'
        }
    },
    addBtn: {
        margin: '8px 0',
        float: 'right',
    }
}))
