import { get } from '../../services/fetchService'
import { addAlertMessage } from '../ui-states/actions'

// Action Types

export const GET_ALL_START = 'LAYERS_GET_ALL_START'
export const GET_ALL_SUCCESS = 'LAYERS_GET_ALL_SUCCESS'
export const GET_ALL_ERROR = 'LAYERS_GET_ALL_ERROR'

// Action Creators

export const fetchDataPromise = async (token, querystring) => get('layers/', querystring || '', {Authorization: token})

export const fetchData = (token, querystring) => async dispatch => {
    dispatch({ type: GET_ALL_START })

    try {
        const payload = await fetchDataPromise(token, querystring)

        dispatch({
            type: GET_ALL_SUCCESS,
            payload,
        })

    } catch (error) {
        console.error(error)
        dispatch({ type: GET_ALL_ERROR })
        dispatch(addAlertMessage(error))
    }
}
