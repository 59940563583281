import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    wrapper: {
        padding: 20,
        maxHeight: 'calc(100vh - 160px)',
        overflowY: 'auto',
        minWidth: 320,
        '& h5': {
            textAlign: 'center',
        }
    },
    itemStyle: {
        margin: '4px 0',
        padding: 0,
    },
    itemWrapper: {
        display: 'block',
        cursor: 'pointer',
        padding: '8px 16px',
        width: '100%',

        '& p:last-child': {
            marginLeft: '2rem',
        }
    },

    formRow: {
        border: '1px solid ' + theme.palette.grey[500],
        padding: '5px 15px',
        borderRadius: 4,
        marginTop: 5,
    },

    // Notifications Colors
    default: {
        backgroundColor: theme.palette.background.default
    }
}))
