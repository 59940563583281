import * as R from 'ramda'
import { DATA_REQUEST, DATA_SUCCESS, DATA_ERROR, DETAIL_SUCCESS, DETAIL_CLEAR } from './actions'

const initialState = {
    requestStatus: 'none',
    active: true,
    response: null,
    detail: null,
}

function depotsReducer (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case DATA_REQUEST:
            return Object.assign({}, state, { requestStatus: 'pending' })
        case DATA_SUCCESS:
            return Object.assign({}, state, {
                response: {
                    data: payload
                },
                requestStatus: 'done'
            })
        case DATA_ERROR:
            return Object.assign({}, state, { requestStatus: 'error' })
        case DETAIL_SUCCESS:
            const detail = R.find(R.propEq('id', parseInt(payload)), R.prop('data', state))
            return Object.assign({}, state, { detail })
        case DETAIL_CLEAR:
            return Object.assign({}, state, { detail: null })
        default:
            return state
    }
}

export default depotsReducer
