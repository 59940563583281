import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { path, isEmpty, isNil } from 'ramda'
import { TextField, Button, Typography, Table, TableHead, TableRow, TableCell, FormControlLabel, TableBody,
    Divider, IconButton, Checkbox, Select, MenuItem, FormControl, InputLabel } from '@material-ui/core'
import { mdiPlusCircle, mdiMinusCircle } from '@mdi/js'

import { SvgIconComponent } from '../..'
import { fetchPermissionActions as _fetchPermissionActions,addPermissionActions as _addPermissionActions,
    updatePermissionActions as _updatePermissionActions } from '../../../redux/permissions/actions'
import { i18n as i18nService } from '../../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    action: path(['permissions', 'action'], state)
})

const mapDispatchToProps = dispatch => ({
    fetchPermissionActions: (permissionId, token, actionId) => dispatch(_fetchPermissionActions(permissionId, token, actionId)),
    addPermissionActions: (permissionId, token, body, callback) => dispatch(_addPermissionActions(permissionId, token, body, callback)),
    updatePermissionActions: (permissionId, token, actionId, body, callback) => dispatch(_updatePermissionActions(permissionId, token, actionId, body, callback)),
})

function EditActionsRoleComponent({
    token,
    action,

    fetchPermissionActions,
    addPermissionActions,
    updatePermissionActions,
}) {
    const classes = styles()
    const i18n = prop => i18nService(['admin', 'role_actions', prop])
    const { permissionId, actionId } = useParams()

    const initialValue = {
        id: { value: '', error: null },
        name: { value: '', error: null },
        controller_action: { value: '', error: null },
        http_method: { value: '', error: null },
        controller_path: { value: '', error: null },
        uses_parameter: { value: false, error: null },
    }

    const tableTitles = [
        i18n('input_placeholder_name'),
        i18n('input_placeholder_controller_action'),
        i18n('input_placeholder_http_method'),
        i18n('input_placeholder_controller_url')
    ]

    const [formData, setFormData] = useState(initialValue)
    const [relatedData, setRelatedData] = useState([])
    const controllerMethods = ['get', 'post', 'put', 'delete', 'patch']

    useEffect(() => {
        if (!!actionId) {
            fetchPermissionActions(permissionId, token, actionId)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (!isNil(action)) {
            setFormData(parseData(action))
            setRelatedData(action.related.map(item => parseData(item)) || [])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [action])

    function handleChange(input) {
        return function(event) {
            setFormData({
                ...formData,
                [input]: {
                    value: event.target.value,
                    error: null,
                }
            })
        }
    }

    function checkHandleChange(event) {
        setFormData({
            ...formData,
            'uses_parameter': {
                value: event.target.checked,
                error: null,
            }
        })
    }

    function handleRelatedChange(input, k) {
        return function(event) {
            const resp = relatedData.map((item, key) => {
                if (k === key) {
                    return {
                        ...item,
                        [input]: {
                            value: event.target.value,
                            error: null,
                        }
                    }
                }
                return item
            })

            setRelatedData(resp)
        }
    }

    function checkRelatedHandleChange(k) {
        return function(event) {
            const resp = relatedData.map((item, key) => {
                if (k === key) {
                    return {
                        ...item,
                        'uses_parameter': {
                            value: event.target.checked,
                            error: null,
                        }
                    }
                }
                return item
            })

            setRelatedData(resp)
        }
    }

    function handleSubmit() {
        const keys = Object.keys(formData)
        let hasError = false
        let body = {}
        let related = []

        keys.forEach(key => {
            if (!['id', 'controller_path'].includes(key) && isEmpty(formData[key].value)) {
                hasError = true
                setFormData({
                    ...formData,
                    [key]: {
                        ...formData[key],
                        error: 'El campo no puede estar vacio'
                    }
                })
            } else {
                let value = formData[key].value
                if (typeof value === 'boolean') {
                    value = value ? '1' : '0'
                }
                body = {
                    ...body,
                    [key]: value
                }
            }
        })

        relatedData.forEach((action, position) => {
            let relatedBody = {}
            keys.forEach(key => {
                if (isEmpty(action[key].value)) {
                    const resp = relatedData.map((item, itemKey) => {
                        if (itemKey === key) {
                            let value = action[key].value
                            if (typeof value === 'boolean') {
                                value = value ? '1' : '0'
                            }

                            return {
                                ...item,
                                [action[key]]: {
                                    value,
                                    error: 'El campo no puede estar vacio',
                                }
                            }
                        }
                        return item
                    })
                    setRelatedData(resp)
                } else {
                    relatedBody = {
                        ...relatedBody,
                        [key]: action[key].value,
                        uses_parameter: '0',
                        _destroy: false,
                    }
                }
            })
            related.push(relatedBody)
        })

        if (hasError) {
            console.error('hubo un error')
        } else {
            const related_actions_attributes = {}
            related.forEach((item, key) => {
                related_actions_attributes[key] = item
            })

            const resp = {
                ...body,
                related: false,
                action_id: null,
                related_actions_attributes,
            }

            if (!actionId) {
                addPermissionActions(
                    permissionId,
                    token,
                    { actionx: resp }
                )
            } else {
                updatePermissionActions(
                    permissionId,
                    token,
                    actionId,
                    { actionx: resp }
                )
            }

        }
    }

    function clear() {
        setFormData(initialValue)
    }

    function parseData(data) {
        const params = Object.keys(initialValue)
        let results = {}

        params.forEach(item => {
            results = {
                ...results,
                [item]: {
                    value: data[item],
                    error: null
                }
            }
        })
        return results
    }

    function addHandler() {
        setRelatedData([...relatedData, initialValue])
    }

    function removeItem(key) {
        return function() {
            setRelatedData(relatedData.filter((i,k) => k !== key))
        }
    }

    return (
        <React.Fragment>
            <form className={classes.formContainer}>
                <Typography variant="h5" align="center">{!actionId ? i18n('title_new') : i18n('title_edit')}</Typography>
                <TextField
                    id="name"
                    error={!!formData.name.error}
                    label={i18n('input_placeholder_name')}
                    required
                    variant="outlined"
                    value={formData.name.value}
                    onChange={handleChange('name')}
                />
                <TextField
                    id="controller_action"
                    error={!!formData.controller_action.error}
                    label={i18n('input_placeholder_controller_action')}
                    required
                    multiline
                    row={6}
                    variant="outlined"
                    value={formData.controller_action.value}
                    onChange={handleChange('controller_action')}
                />
                <FormControl variant="outlined">
                    <InputLabel required id="http-method-label">Método HTTP</InputLabel>
                    <Select
                        id="http_method"
                        labelId="http-method-label"
                        label={i18n('input_placeholder_http_method') + '--'}
                        error={!!formData.http_method.error}
                        value={formData.http_method.value}
                        onChange={handleChange('http_method')}
                    >
                        <MenuItem value={''}>None</MenuItem>
                        {controllerMethods.map((item, key) => <MenuItem key={key} value={item}>{item.toUpperCase()}</MenuItem>)}
                    </Select>
                </FormControl>
                <TextField
                    id="controller_path"
                    label={i18n('input_placeholder_controller_url')}
                    row={6}
                    variant="outlined"
                    value={formData.controller_path.value}
                    onChange={handleChange('controller_path')}
                />
                <FormControlLabel
                    classes={{ root: classes.paramsControlRoot }}
                    control={
                        <Checkbox
                            checked={formData.uses_parameter.value}
                            onChange={checkHandleChange}
                            name="uses_parameter"
                            color="primary" />
                    }
                    label={i18n('input_placeholder_use_parameters')}
                />
                <Divider />
                <Button
                    className={classes.addBtn}
                    variant="contained"
                    onClick={addHandler}
                    endIcon={<SvgIconComponent path={mdiPlusCircle}/>}
                >
                    {i18n('button_add')}
                </Button>
                <Table
                    size="small"
                    className={classes.table}>
                    <TableHead>
                        <TableRow>
                            {tableTitles.map((item, key) => <TableCell key={key}>{item}</TableCell>)}
                            <TableCell>{i18n('input_placeholder_use_parameters')}</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {relatedData.map((item, key) => <TableRow key={key}>
                            <TableCell>
                                <TextField
                                    id="name"
                                    fullWidth
                                    error={!!item.name.error}
                                    required
                                    variant="outlined"
                                    value={item.name.value}
                                    onChange={handleRelatedChange('name', key)}
                                />
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="controller_action"
                                    fullWidth
                                    error={!!item.controller_action.error}
                                    required
                                    multiline
                                    row={6}
                                    variant="outlined"
                                    value={item.controller_action.value}
                                    onChange={handleRelatedChange('controller_action', key)}
                                />
                            </TableCell>
                            <TableCell>
                                <FormControl variant="outlined" fullWidth>
                                    <Select
                                        id="http_method"
                                        labelId="http-method-label"
                                        error={!!item.http_method.error}
                                        value={item.http_method.value}
                                        onChange={handleRelatedChange('http_method', key)}
                                    >
                                        <MenuItem value={''}>None</MenuItem>
                                        {controllerMethods.map((item, key) => <MenuItem key={key} value={item}>{item.toUpperCase()}</MenuItem>)}
                                    </Select>
                                </FormControl>
                            </TableCell>
                            <TableCell>
                                <TextField
                                    id="controller_path"
                                    fullWidth
                                    error={!!item.controller_path.error}
                                    required
                                    multiline
                                    row={6}
                                    variant="outlined"
                                    value={item.controller_path.value}
                                    onChange={handleRelatedChange('controller_path', key)}
                                />
                            </TableCell>
                            <TableCell>
                                <Checkbox
                                    checked={item.uses_parameter.value}
                                    onChange={checkRelatedHandleChange(key)}
                                    name="uses_parameter"
                                    color="primary" />
                            </TableCell>
                            <TableCell>
                                <IconButton onClick={removeItem(key)}>
                                    <SvgIconComponent path={mdiMinusCircle} />
                                </IconButton>
                            </TableCell>
                        </TableRow>)}
                    </TableBody>
                </Table>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>{!actionId ? i18n('button_new') : i18n('button_edit')}</Button>
                    <Button className={classes.button} variant="contained" onClick={clear}>{i18n('button_clear')}</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditActionsRoleComponent)
