import React from 'react'
import { Modal, Paper, makeStyles } from '@material-ui/core'

const styles = makeStyles(theme => ({
    modal: {
        display: 'flex'
    },
    paper: {
        margin: 'auto',
        minWidth: '30%',
        minHeight: '30%',
        padding: 20,
    }
}))


function ModalComponent({ children, ...rest }) {
    const classes = styles()
    return(
        <Modal {...rest} className={classes.modal}>
            <Paper className={classes.paper}>
                {children}
            </Paper>
        </Modal>
    )
}

export default ModalComponent
