import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    paperRoot: {
        overflow: 'auto',
        height: 'calc(100vh - 11rem)',
        marginBottom: '1rem',

        '& td': {
            whiteSpace: 'nowrap'
        },
    },
    progress: {
        minWidth: 150,
    },
    backgroundWrapperCell: {
        background: theme.palette.background.highlight + ' !important',
    },
    wrapperCell: {
        padding: 10,
        border: 'none',
    },
    nestedHead: {
        background: theme.palette.background.paper + ' !important',
    },
    nestedTh: {
        color: theme.palette.text.primary,
    }
}))
