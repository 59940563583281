import { /*requestPending,*/ requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'

// Action Types
import { get, put } from "../../services/fetchService"

export const GET_START = 'ROUTE_GET_START'
export const GET_SUCCESS = 'ROUTE_GET_SUCCESS'
export const GET_ERROR = 'ROUTE_GET_ERROR'

export const GET_NOTIFICATIONS_START = 'ROUTE_GET_NOTIFICATIONS_START'
export const GET_NOTIFICATIONS_SUCCESS = 'ROUTE_GET_NOTIFICATIONS_SUCCESS'
export const GET_NOTIFICATIONS_ERROR = 'ROUTE_GET_NOTIFICATIONS_ERROR'

export const GET_FILTERS_START = 'ROUTE_GET_FILTERS_DATA_START'
export const GET_FILTERS_SUCCESS = 'ROUTE_GET_FILTERS_DATA_SUCCESS'
export const GET_FILTERS_ERROR = 'ROUTE_GET_FILTERS_DATA_ERROR'

export const SEND_REVIEW_START = 'ROUTE_SEND_REVIEW_DATA_START'
export const SEND_REVIEW_SUCCESS = 'ROUTE_SEND_REVIEW_DATA_SUCCESS'
export const SEND_REVIEW_ERROR = 'ROUTE_SEND_REVIEW_DATA_ERROR'

// Promises
export const fetchRoutePromise = async (token, id = '', querystring) => get(`routes/${id}`, querystring || '', {Authorization: token})
export const fetchRouteNotificationsPromise = async (token, querystring) => get(`notifications`, querystring || '', {Authorization: token})
export const sendNotificationsReviewPromise = async (token, id, body) => put(`notifications/${id}/review`, body, {Authorization: token})
export const sendNotificationsNotifyPromise = async (token, id, body) => put(`notifications/${id}/notify`, body, {Authorization: token})

// Async action creators
export const fetchRoute = (token, id, querystring, callback) => async dispatch => {
    dispatch({ type: GET_START })
    try {
        const response = await fetchRoutePromise(token, id, querystring)

        dispatch({
            type: GET_SUCCESS,
            payload: response
        })

        dispatch(requestSuccess())

        if (callback) callback()

    } catch (error) {
        dispatch({ type: GET_ERROR })
        dispatch(requestError())
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }

        dispatch(addAlertMessage(error))
    }
}

export const fetchRouteNotifications = (token, querystring, callback) => async dispatch => {
    dispatch({ type: GET_NOTIFICATIONS_START })
    try {
        const response = await fetchRouteNotificationsPromise(token, querystring)

        dispatch({
            type: GET_NOTIFICATIONS_SUCCESS,
            payload: response
        })

        dispatch(requestSuccess())

        if (callback) callback()

    } catch (error) {
        dispatch({ type: GET_NOTIFICATIONS_ERROR })
        dispatch(requestError())
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }

        dispatch(addAlertMessage(error))
    }
}

export const sendNotificationsReview = (token, id, body, cb) => async dispatch => {
    dispatch({ type: SEND_REVIEW_START })
    try {
        const response = await sendNotificationsReviewPromise(token, id, body)

        dispatch({
            type: SEND_REVIEW_SUCCESS,
            payload: response
        })

        dispatch(requestSuccess())

        if (cb) cb(null, response)

    } catch (error) {
        if (cb) cb(error, null)
        dispatch({ type: SEND_REVIEW_ERROR })
        dispatch(requestError())
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }

        dispatch(addAlertMessage(error))
    }
}

export const sendNotificationsNotify = (token, id, body, cb) => async dispatch => {
    dispatch({ type: SEND_REVIEW_START })
    try {
        const response = await sendNotificationsNotifyPromise(token, id, body)

        dispatch({
            type: SEND_REVIEW_SUCCESS,
            payload: response
        })

        dispatch(requestSuccess())

        if (cb) cb(null, response)

    } catch (error) {
        if (cb) cb(error, null)
        dispatch({ type: SEND_REVIEW_ERROR })
        dispatch(requestError())
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }

        dispatch(addAlertMessage(error))
    }
}
