import { makeStyles } from '@material-ui/core/styles'
import { blueGrey } from '@material-ui/core/colors'

export const appStyles = makeStyles(theme => ({
    '@global': {
        'html, body': {
            backgroundColor: theme.palette.background.default,
            margin: '0',
            padding: '0',
        },
        'table': {
            '& > tbody': {
                '& > tr:nth-child(even)': {
                    background: theme.palette.background.emphasis
                },
                '& > tr:nth-child(odd)': {
                    background: theme.palette.background.paper
                },
            }
        },
        '.leaflet-popup-content *': {
            margin: '2px 0 !important'
        }
    },
    root: {
        backgroundColor: theme.palette.background.default,
        paddingTop: '65px',
        minHeight: 'calc(100% - 65px)',
        display: 'flex',
        flexDirection: 'column'
    },
}))

export const cmDefaultTheme = {
    palette: {
        primary: {
            main: '#1565c0',
            light: '#5e92f3',
            dark: '#003c8f',
        },
        secondary: {
            main: '#d32f2f',
            light: '#ff6659',
            dark: '#9a0007',
        },
        background: {
            highlight: '#ebebeb',
            emphasis: '#e9e9e9',
        }
    },

    detailDrawer: {
        width: '400px'
    },

    menuConfig: {
        height: 65,
    },

    overrides: {
        MuiTableHead: {
            root: {
                backgroundColor: blueGrey[600],
            }
        },
        MuiTableCell: {
            head: {
                color: blueGrey[50],
                fontWeight: 700,
            }
        }
    }
}

export default cmDefaultTheme
