import { get, post, put, del } from '../../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../../models/htmlMessage'

// Actions types
export const GET_CATEGORIES_START = 'GET_CATEGORIES_START'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORY_SUCCESS = 'GET_CATEGORY_SUCCESS'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'
export const ADD_CATEGORY_START = 'ADD_CATEGORY_START'
export const ADD_CATEGORY_SUCCESS = 'ADD_CATEGORY_SUCCESS'
export const ADD_CATEGORY_ERROR = 'ADD_CATEGORY_ERROR'
export const UPDATE_CATEGORY_START = 'UPDATE_CATEGORY_START'
export const UPDATE_CATEGORY_SUCCESS = 'UPDATE_CATEGORY_SUCCESS'
export const UPDATE_CATEGORY_ERROR = 'UPDATE_CATEGORY_ERROR'
export const DELETE_CATEGORY_START = 'DELETE_CATEGORY_START'
export const DELETE_CATEGORY_SUCCESS = 'DELETE_CATEGORY_SUCCESS'
export const DELETE_CATEGORY_ERROR = 'DELETE_CATEGORY_ERROR'
export const CLEAR_CATEGORY = 'CLEAR_CATEGORY'

export const GET_USERS_START = 'GET_USERS_START'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_ERROR = 'GET_USERS_ERROR'

export const GET_COLORS_START = 'GET_COLORS_START'
export const GET_COLORS_SUCCESS = 'GET_COLORS_SUCCESS'
export const GET_COLORS_ERROR = 'GET_COLORS_ERROR'

// Actions creators
export const clearCategory = () => ({
    type: CLEAR_CATEGORY
})

// Promisses
export const fetchCategoriesPromise = async (token, id = '') => get(`amm/categories/${id}`, '', {Authorization: token})
export const addCategoryPromise = async (token,body) => post('amm/categories', body, {Authorization: token})
export const updateCategoryPromise = async (token,body,id) => put(`amm/categories/${id}`, body, {Authorization: token})
export const deleteCategoryPromise = async (token,id) => del(`amm/categories/${id}`, {Authorization: token})
export const fetchOrgUsersPromise = async (token) => get(`users`, '', {Authorization: token})
export const fetchColorsPromise = async (token) => get(`amm/colors`, '', {Authorization: token})

// Async actions creators
export const fetchCategories = (token, id) => async dispatch => {
    dispatch({ type: GET_CATEGORIES_START })
    dispatch(requestPending())
    try {
        const response = await fetchCategoriesPromise(token, id)

        dispatch(requestSuccess())

        if (!!id) {
            dispatch({ type: GET_CATEGORY_SUCCESS, payload: response.data })
        } else {
            dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response })
        }
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_CATEGORIES_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const addCategory = (token, body) => async dispatch => {
    dispatch({ type: ADD_CATEGORY_START })
    dispatch(requestPending())

    try {
        const payload = await addCategoryPromise(token,{ category: body })

        dispatch(requestSuccess())
        dispatch({ type: ADD_CATEGORY_SUCCESS })
        const response = await fetchCategoriesPromise(token)
        dispatch(requestSuccess())
        dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_CATEGORY_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateCategory = (token, body, id) => async dispatch => {
    dispatch({ type: UPDATE_CATEGORY_START })
    dispatch(requestPending())

    try {
        const payload = await updateCategoryPromise(token, { category: body }, id)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_CATEGORY_SUCCESS })

        const response = await fetchCategoriesPromise(token)
        dispatch(requestSuccess())
        dispatch({ type: GET_CATEGORIES_SUCCESS, payload: response })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_CATEGORY_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deleteCategory = (token,id, callback) => async dispatch => {
    dispatch({ type: DELETE_CATEGORY_START })
    dispatch(requestPending())

    try {
        const payload = await deleteCategoryPromise(token,id)

        dispatch(requestSuccess())
        dispatch({ type: DELETE_CATEGORY_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: DELETE_CATEGORY_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const fetchOrgUsers = (token) => async dispatch => {
    dispatch({ type: GET_USERS_START })
    dispatch(requestPending())

    try {
        const result = await fetchOrgUsersPromise(token)
        dispatch({ type: GET_USERS_SUCCESS, payload: result })
        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: GET_USERS_SUCCESS })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const fetchColors = (token) => async dispatch => {
    dispatch({ type: GET_COLORS_START })
    dispatch(requestPending())
    try {
        const result = await fetchColorsPromise(token)
        dispatch({ type: GET_COLORS_SUCCESS, payload: result })
        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: GET_COLORS_SUCCESS })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}