// import * as R from 'ramda'
import { DATA_REQUEST, DATA_SUCCESS, DATA_ERROR } from './actions'

const initialState = {
    requestStatus: 'none',
    active: true,
    response: null,
    detail: null,
}

function byClusterReducer (state = initialState, action) {
    const { type, payload } = action

    switch (type) {
        case DATA_REQUEST:
            return Object.assign({}, state, { requestStatus: 'pending' })
        case DATA_SUCCESS:
            return Object.assign({}, state, {
                response: {
                    data: payload
                },
                requestStatus: 'done'
            })
        case DATA_ERROR:
            return Object.assign({}, state, { requestStatus: 'error' })
        default:
            return state
    }
}

export default byClusterReducer
