import React, { useState } from 'react'
import classnames from 'classnames'
import { Typography, List, ListItem, Checkbox, Dialog, TextField } from '@material-ui/core'
import { format } from 'date-fns'

import { AlertModalComponent } from '../..'
import styles from './styles'
import { i18n } from '../../../services/util'
import { Autocomplete } from '@material-ui/lab'
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;
const notificationTypes = [
    { name: 'Entrada a Planta', type: 'depot_in', group: 'Planta' },
    { name: 'Salida de Planta', type: 'depot_out', group: 'Planta' },
    { name: 'Ruta iniciada', type: 'START', group: 'Paradas' },
    { name: 'Llegada a Parada', type: 'ARRIVAL', group: 'Paradas' },
    { name: 'Entrega exitosa', type: 'SUCCESS', group: 'Paradas' },
    { name: 'Entrega fallida', type: 'FAILURE', group: 'Paradas' },
    { name: 'Ruta finalizada', type: 'FINISH', group: 'Paradas' },
    { name: 'Entrada a Parada', type: 'geofence_in', group: 'Paradas' },
    { name: 'Salida de Parada', type: 'geofence_out', group: 'Paradas' },
    { name: 'Entrada a Zona', type: 'layer_geofence_in', group: 'Zonas' },
    { name: 'Salida de Zona', type: 'layer_geofence_out', group: 'Zonas' },
    { name: 'Ruta sin nuevos trackpoints', type: 'route_without_new_trackpoints', group: 'Trackpoints' },
    { name: 'Ruta activa por mucho tiempo', type: 'route_active_for_too_long', group: 'Trackpoints' }
];

function AlertsMapDetailsComponent(props) {
    const classes = styles()
    const {alerts, onChange} = props
    const [open, setOpen] = useState(false)
    const [selectedItem, setSelectedItem] = useState([])
    const handleClose = () => {
        setOpen(false);
    };

    function date(date) {
        return format(new Date(date), 'dd/MM/yyyy HH:mm')
    }

    function showAlertModal(item) {
        return function () {
            setSelectedItem(item)
            setOpen(true)
        }
    }

    return (
        <div className={classes.wrapper}>
            <Typography variant="h5">{i18n(['routes','routes_detail', 'notifications'])}</Typography>
            <Autocomplete
                multiple
                id="notification-types"
                groupBy={(option) => option.group}
                options={notificationTypes}
                disableCloseOnSelect
                onChange={(e, opt) => {
                    const filters = []
                    setSelectedItem(opt);
                    opt.forEach(item => {
                        filters.push(item.type)
                    })
                    onChange(filters)
                }}
                getOptionLabel={option => option.name}
                renderOption={(option, state) => {
                    const selectOptIndex = selectedItem.findIndex(
                        opt => opt.name.toLowerCase() === "all"
                    );
                    if (selectOptIndex > -1) {
                        state.selected = true;
                    }
                    return (
                    <React.Fragment>
                        <Checkbox
                        icon={icon}
                        checkedIcon={checkedIcon}
                        style={{ marginRight: 8 }}
                        checked={state.selected}
                        />
                        {option.name}
                    </React.Fragment>
                    );
                }}
                style={{ width: `100%`, margin: '5px 0 0 0' }}
                renderInput={params => (
                    <TextField
                    {...params}
                    variant="outlined"
                    label={i18n(['routes','routes_detail', 'notifications_types'])}
                    placeholder="Seleccionadas"
                    />
                )}
            />
            
            <List>
                {alerts.map((item, key) => {
                    return (
                        <ListItem key={key} className={classes.itemStyle}>
                            <div className={classnames(classes.itemWrapper, classes.default)} role="button" onClick={showAlertModal(item)}>
                                <Typography>{date(item.attributes.notification_date)}</Typography>
                                <Typography>{item.attributes.description}</Typography>
                            </div>
                        </ListItem>
                    )
                })}
            </List>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth
                maxWidth="md"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <AlertModalComponent item={selectedItem} handleClose={() => setOpen(!open)}/>
            </Dialog>
        </div>
    )
}

export default AlertsMapDetailsComponent
