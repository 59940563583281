import React from 'react'
import { connect } from 'react-redux'
import { AccountCircle } from '@material-ui/icons'
import { path } from 'ramda'

import { UserFormComponent, LoaderComponent } from '../../components'
import { userLogin } from '../../redux/user/actions'
import { Typography, makeStyles } from '@material-ui/core'
import { addAlertMessage as _addAlertMessage } from '../../redux/ui-states/actions'

const useStyles = makeStyles({
    title: {
        margin: '20px auto -20px',
        textAlign: 'center',
    },
    icon: {
        fontSize: '200px',
        opacity: '.1',
    },
})

const mapStateToProps = state => ({
    loading: path(['request', 'loading'], state),
    loginErrorMessage: path(['user', 'errorMessage'], state),
})

const mapDispatchToProps = dispatch => ({
    userLogin: (payload, callback) => dispatch(userLogin(payload, callback)),
    addAlertMessage: (payload, callback) => dispatch(_addAlertMessage(payload, callback))
})

function LoginPage({ loading, loginErrorMessage, userLogin, addAlertMessage }) {
    const classes = useStyles()
    function loginHandler(payload) {
        userLogin(payload, (message) => {
            addAlertMessage({message})
        })
    }

    return (
        <div>
            <Typography className={classes.title}><AccountCircle className={classes.icon} /></Typography>
            <LoaderComponent visible={loading} />
            <UserFormComponent submit={loginHandler} type="login" error={loginErrorMessage} />
        </div>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(LoginPage)
