/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useRouteMatch, Link, useHistory } from 'react-router-dom'
import { path, isEmpty } from 'ramda'

import { Grid, TextField, Button, Typography, MenuItem, SvgIcon } from '@material-ui/core'
import { mdiFolderOpen, mdiPlus, mdiPencil } from '@mdi/js'

import Autocomplete from '@material-ui/lab/Autocomplete';

import {
  fetchOrgUsers as _fetchOrgUsers,
  addCategory as _addCategory,
  updateCategory as _updateCategory,
  clearCategory as _clearCategory,
  fetchCategories as _fetchCategories,
  fetchColors as _fetchColors,
} from '../../../redux/amm/categories/actions'

import { i18n } from '../../../services/util'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  category: path(['categories', 'category'], state),
  users: path(['categories', 'users'], state),
  categories: path(['categories', 'categories'], state),
  colors: path(['categories', 'colors'], state),
})

const mapDispatchToProps = dispatch => ({
  fetchCategory: (token, id) => dispatch(_fetchCategories(token,id)),
  addCategory: (token,payload) => dispatch(_addCategory(token,payload)),
  updateCategory: (token,payload,id) => dispatch(_updateCategory(token,payload,id)),
  clearCategory: () => dispatch(_clearCategory()),
  fetchOrgUsers: (token) => dispatch(_fetchOrgUsers(token)),
  fetchCategories: token => dispatch(_fetchCategories(token)),
  fetchColors: token => dispatch(_fetchColors(token)),
})

function CategoryFormComponent({token,category,users,categories,colors,fetchCategory,addCategory,updateCategory,clearCategory,fetchOrgUsers,fetchCategories,fetchColors}) {
  const { categoryId } = useParams()
  const { url } = useRouteMatch()
  const history = useHistory()

  const initialValue = { 
    name: { value: '', error: null },
    priority: { value: '', error: null },
    description: { value: '', error: null },
    users: { value: [], error: null },
    color: { value: '', error: null }
  }

  const [formData, setFormData] = useState(initialValue)
  
  useEffect(() => {
    fetchOrgUsers(token)
    fetchColors(token)
  }, [])

  useEffect(() => {
    if (url !== '/amm/categories/new' && categoryId) {
      fetchCategory(token, categoryId)
    }

    if (url === '/amm/categories/new') {
      clearCategory()
    }
  }, [url])

  useEffect(() => {
    if (category !== undefined && !isEmpty(category)) {
      setFormData({
        name: {
          value: category.name || '',
          error: null,
        },
        priority: {
          value: category.priority || '',
          error: null,
        },
        description: {
          value: category.description || '',
          error: null,
        },
        users: {
          value: category.users ? category.users.map((u)=>{return u.id}) : [],
          error: null,
        },
        color: {
          value: category.color || '',
          error: null,
        },
      })
    } else {
      setFormData(initialValue)
    }
  }, [category])

    function isEditUrl() {
      return url !== '/amm/categories/new' && categoryId
    }

    function handleChange(input) {
      return function(event) {
        setFormData({
          ...formData,
          [input]: {
            value: event.target.value,
            error: null,
          }
        })
      }
    }

  function handleSubmit() {
    const keys = Object.keys(formData)
    let hasError = false
    let payload = {}

    keys.forEach(key => {
      if (!['users', 'description'].includes(key) &&  isEmpty(formData[key].value)) {
        hasError = true
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            error: 'El campo no puede estar vacio'
          }
        })
      } else {
        payload = {
          ...payload,
          [key]: formData[key].value
        }
      }
    })

    if (!hasError) {
      if (isEditUrl()) {
        updateCategory(token,payload,categoryId)
      } else {
        addCategory(token,payload)
      }
      clearCategory(token)
      history.push(`/amm/categories`)
    }
  }

  const onUsersChange = (event, values) => {
    let userIds = values.map((user) => ( user.id ))
    setFormData({
      ...formData,
      'users': {
        value: userIds,
        error: null,
      }
    })
  }

  return (
    <Grid container spacing={0} direction="row" justify="center">
      <Grid item xs={6}>
        <Typography variant="h6">
          <SvgIcon className='ruc-title-first-icon'>
            <path d={isEditUrl() ? mdiPencil : mdiPlus}/>
          </SvgIcon>
          <SvgIcon><path d={mdiFolderOpen}/></SvgIcon>
          {isEditUrl() ? "Editar" : "Nueva"} Categoría
        </Typography>
        <form className="ruc-form">
          <TextField
            id="name"
            fullWidth
            error={!!formData.name.error}
            label={i18n(['amm', 'category', 'name'])}
            required
            variant="outlined"
            value={formData.name.value}
            autoComplete="off"
            onChange={handleChange('name')}
          />
          <Grid container spacing={3} direction="row" justify="center">
            <Grid item xs={6}>
              <TextField
                id="priority"
                fullWidth
                error={!!formData.priority.error}
                label={i18n(['amm', 'category', 'priority'])}
                required
                multiline
                row={6}
                variant="outlined"
                autoComplete="off"
                value={formData.priority.value}
                onChange={handleChange('priority')}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                id="color"
                fullWidth
                error={!!formData.color.error}
                label={i18n(['amm', 'category', 'color'])}
                required
                multiline
                row={6}
                variant="outlined"
                select
                value={formData.color.value}
                onChange={handleChange('color')}
                defaultValue=""
              >
                {colors.map((color) => (
                  <MenuItem key={color.id} value={color.hexadecimal}>
                    <span style={{display: 'block', width: '20px',height: '20px', float: 'left', borderRadius: '10px', marginRight: '10px', backgroundColor: color.hexadecimal}}></span>
                    <span style={{display: 'block', height: '20px', lineHeight: '22px', float: 'left' }}>{color.hexadecimal}</span>
                  </MenuItem>)
                )}
              </TextField>
            </Grid>
          </Grid>

          <Autocomplete
            multiple
            id="users"
            options={users}
            getOptionLabel={(option) => option.name}
            filterSelectedOptions
            value={users.filter(function (user) { return formData.users.value.includes(user.id) })}
            onChange={onUsersChange}
            renderInput={(params) => (
              <TextField
                error={!!formData.users.error}
                {...params}
                variant="outlined"
                label={i18n(['amm', 'category', 'users'])}
                placeholder="Todos"
              />
            )}
          />

          <TextField
            id="description"
            fullWidth
            error={!!formData.description.error}
            label={i18n(['amm', 'category', 'description'])}
            multiline
            row={6}
            rows={4}
            variant="outlined"
            value={formData.description.value}
            onChange={handleChange('description')}
            className="last-child"
          />
          <div className="ruc-form-button-wrapper">
            <Button row={6}variant="contained" color="primary" onClick={handleSubmit}>{isEditUrl() ? i18n(['amm', 'actions', 'update']) : i18n(['amm', 'actions', 'save'])}</Button>
            <Link to="/amm/categories">
              <Button row={6}variant="contained" color="default" >Volver</Button>
            </Link>
          </div>
        </form>
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryFormComponent)