import { get, post, put, del } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../models/htmlMessage'

// Actions types

export const GET_PERMISSIONS_START = 'GET_PERMISSIONS_START'
export const GET_PERMISSIONS_SUCCESS = 'GET_PERMISSIONS_SUCCESS'
export const GET_PERMISSION_BY_ID_SUCCESS = 'GET_PERMISSION_BY_ID_SUCCESS'
export const GET_PERMISSIONS_ERROR = 'GET_PERMISSIONS_ERROR'
export const ADD_PERMISSIONS_START = 'ADD_PERMISSIONS_START'
export const ADD_PERMISSIONS_SUCCESS = 'ADD_PERMISSIONS_SUCCESS'
export const ADD_PERMISSIONS_ERROR = 'ADD_PERMISSIONS_ERROR'
export const UPDATE_PERMISSIONS_START = 'UPDATE_PERMISSIONS_START'
export const UPDATE_PERMISSIONS_SUCCESS = 'UPDATE_PERMISSIONS_SUCCESS'
export const UPDATE_PERMISSIONS_ERROR = 'UPDATE_PERMISSIONS_ERROR'
export const DELETE_PERMISSIONS_START = 'DELETE_PERMISSIONS_START'
export const DELETE_PERMISSIONS_SUCCESS = 'DELETE_PERMISSIONS_SUCCESS'
export const DELETE_PERMISSIONS_ERROR = 'DELETE_PERMISSIONS_ERROR'

export const UPDATE_PERMISSIONS_ROLE_START = 'UPDATE_PERMISSIONS_ROLE_START'
export const UPDATE_PERMISSIONS_ROLE_SUCCESS = 'UPDATE_PERMISSIONS_ROLE_SUCCESS'
export const UPDATE_PERMISSIONS_ROLE_ERROR = 'UPDATE_PERMISSIONS_ROLE_ERROR'

export const SET_PERMISSION_ACTIONS_SHOW = 'SET_PERMISSION_ACTIONS_SHOW'

export const GET_ACTIONS_START = 'GET_PERMISSION_ACTIONS_START'
export const GET_ACTIONS_SUCCESS = 'GET_PERMISSION_ACTIONS_SUCCESS'
export const GET_ACTIONS_ERROR = 'GET_PERMISSION_ACTIONS_ERROR'
export const GET_ACTION_BY_ID_SUCCESS = 'GET_PERMISSION_ACTION_BY_ID_SUCCESS'

export const ADD_PERMISSIONS_ACTIONS_ROLE_START = 'ADD_PERMISSIONS_ACTIONS_ROLE_START'
export const ADD_PERMISSIONS_ACTIONS_ROLE_SUCCESS = 'ADD_PERMISSIONS_ACTIONS_ROLE_SUCCESS'
export const ADD_PERMISSIONS_ACTIONS_ROLE_ERROR = 'ADD_PERMISSIONS_ACTIONS_ROLE_ERROR'

export const UPDATE_PERMISSIONS_ACTIONS_ROLE_START = 'UPDATE_PERMISSIONS_ACTIONS_ROLE_START'
export const UPDATE_PERMISSIONS_ACTIONS_ROLE_SUCCESS = 'UPDATE_PERMISSIONS_ACTIONS_ROLE_SUCCESS'
export const UPDATE_PERMISSIONS_ACTIONS_ROLE_ERROR = 'UPDATE_PERMISSIONS_ACTIONS_ROLE_ERROR'

export const DELETE_PERMISSIONS_ACTIONS_ROLE_START = 'DELETE_PERMISSIONS_ACTIONS_ROLE_START'
export const DELETE_PERMISSIONS_ACTIONS_ROLE_SUCCESS = 'DELETE_PERMISSIONS_ACTIONS_ROLE_SUCCESS'
export const DELETE_PERMISSIONS_ACTIONS_ROLE_ERROR = 'DELETE_PERMISSIONS_ACTIONS_ROLE_ERROR'

// Promisses

export const fetchPermissionsPromise = async (token, permissionId = '') => get(`admin/permissions/${permissionId}`, '', {Authorization: token})
export const addPermissionsPromise = async (body, token) => post('admin/permissions', body, {Authorization: token})
export const updatePermissionsPromise = async (id, body, token) => put(`admin/permissions/${id}`, body, {Authorization: token})
export const deletePermissionsPromise = async (id, token) => del(`admin/permissions/${id}`, {Authorization: token})
export const assignPermissionsRolesPromise = async (body, token) => put('admin/permissions/roles', body, {Authorization: token})

export const fetchPermissionActionsPromise = async (permissionId, token, actionId = '') => get(`admin/permissions/${permissionId}/actions/${actionId}`, '', {Authorization: token})
export const addPermissionActionsPromise = async (permissionId, token, body) => post(`admin/permissions/${permissionId}/actions`, body, {Authorization: token})
export const updatePermissionActionsPromise = async (permissionId, token, actionId = '', body) => put(`admin/permissions/${permissionId}/actions/${actionId}`, body, {Authorization: token})
export const deletePermissionActionsPromise = async (permissionId, token, actionId) => del(`admin/permissions/${permissionId}/actions/${actionId}`, {Authorization: token})

// Async creators

export const fetchPermissions = (token, permissionId) => async dispatch => {
    dispatch({ type: GET_PERMISSIONS_START })
    dispatch(requestPending())

    try {
        const response = await fetchPermissionsPromise(token, permissionId)

        dispatch(requestSuccess())

        if (!!permissionId) {
            dispatch({ type: GET_PERMISSION_BY_ID_SUCCESS, payload: response })
        } else {
            dispatch({ type: GET_PERMISSIONS_SUCCESS, payload: response })
        }
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_PERMISSIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
    }
}

export const addPermission = (body, token, callback) => async dispatch => {
    dispatch({ type: ADD_PERMISSIONS_START })
    dispatch(requestPending())

    try {
        const payload = await addPermissionsPromise({ permission: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_PERMISSIONS_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_PERMISSIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updatePermission = (id, body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_PERMISSIONS_START })
    dispatch(requestPending())

    try {
        const payload = await updatePermissionsPromise(id, { permission: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_PERMISSIONS_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_PERMISSIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deletePermission = (id, token, callback) => async dispatch => {
    dispatch({ type: DELETE_PERMISSIONS_START })
    dispatch(requestPending())

    try {
        const payload = await deletePermissionsPromise(id, token)

        dispatch(requestSuccess())
        dispatch({ type: DELETE_PERMISSIONS_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: DELETE_PERMISSIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const assignPermissionsRoles = (body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_PERMISSIONS_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await assignPermissionsRolesPromise(body, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_PERMISSIONS_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_PERMISSIONS_SUCCESS })

        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const fetchPermissionActions = (permissionId, token, actionId) => async dispatch => {
    dispatch({ type: GET_ACTIONS_START })
    dispatch(requestPending())

    try {
        const payload = await fetchPermissionActionsPromise(permissionId, token, actionId)

        dispatch(requestSuccess())
        if (!actionId) {
            dispatch({ type: GET_ACTIONS_SUCCESS, payload })
        } else {
            dispatch({ type: GET_ACTION_BY_ID_SUCCESS, payload })
        }
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_ACTIONS_ERROR })

        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const addPermissionActions = (permissionId, token, body, callback) => async dispatch => {
    dispatch({ type: ADD_PERMISSIONS_ACTIONS_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await addPermissionActionsPromise(permissionId, token, body)

        dispatch(requestSuccess())
        dispatch({ type: ADD_PERMISSIONS_ACTIONS_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_PERMISSIONS_ACTIONS_ROLE_ERROR })

        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updatePermissionActions = (permissionId, token, actionId, body, callback) => async dispatch => {
    dispatch({ type: UPDATE_PERMISSIONS_ACTIONS_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await updatePermissionActionsPromise(permissionId, token, actionId, body)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_PERMISSIONS_ACTIONS_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_PERMISSIONS_ACTIONS_ROLE_ERROR })

        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deletePermissionActions = (permissionId, token, actionId, callback) => async dispatch => {
    dispatch({ type: DELETE_PERMISSIONS_ACTIONS_ROLE_START })
    dispatch(requestPending())

    try {
        const payload = await deletePermissionActionsPromise(permissionId, token, actionId)

        dispatch(requestSuccess())
        dispatch({ type: DELETE_PERMISSIONS_ACTIONS_ROLE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: DELETE_PERMISSIONS_ACTIONS_ROLE_ERROR })

        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}
