import React, { useState } from 'react'
import { Fab } from '@material-ui/core'
import SvgIconComponent from '../SvgIcon/SvgIconComponent'
import styles from './styles'

function FabHoverButtonCompoent({ text, icon, onClick, className }) {
    const [fabHover, setFabHover] = useState(false)
    const classes = styles()

    return (
        <Fab
            variant={fabHover ? 'extended' : 'round'}
            size={fabHover ? 'large' : 'medium'}
            color="primary"
            aria-label="add"
            className={className || classes.saveButton}
            onMouseEnter={() => setFabHover(true)}
            onMouseLeave={() => setFabHover(false)}
            onClick={onClick}
        >
            <SvgIconComponent path={icon} className={fabHover ? classes.icon : ''} />
            {fabHover ? text : ''}
        </Fab>
    )
}

export default FabHoverButtonCompoent
