import { mergeRight } from 'ramda'

import {
    GET_ALERT_TEMPLATES_SUCCESS,
    GET_ALERT_TEMPLATE_SUCCESS,
    CLEAR_ALERT_TEMPLATE
} from './actions'

const initialState = {
    alertTemplates: [],
    alertTemplate: {},
}

export default function alertTemplatesReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)

    switch (type) {
        case GET_ALERT_TEMPLATES_SUCCESS:
            return merge({ alertTemplates: payload })
        case GET_ALERT_TEMPLATE_SUCCESS:
            return merge({ alertTemplate: payload })
        case CLEAR_ALERT_TEMPLATE:
            return merge({ alertTemplate: {} })
        default:
            return state
    }
}