import { get } from '../../services/fetchService'
import { /*requestPending, requestSuccess, */requestError } from '../request-state/actions'
import { addAlertMessage } from '../ui-states/actions'

// Action Types

export const GET_ALL_START = 'MOBILE_GET_ALL_START'
export const GET_ALL_SUCCESS = 'MOBILE_GET_ALL_SUCCESS'
export const GET_ALL_ERROR = 'MOBILE_GET_ALL_ERROR'

// Action Creators

export const fetchDataPromise = async (token, querystring) => get('mobile_devices/', querystring || '', {Authorization: token})

export const fetchData = (token, querystring, callback) => async dispatch => {
    dispatch({ type: GET_ALL_START })

    try {
        const payload = await fetchDataPromise(token, querystring)

        dispatch({
            type: GET_ALL_SUCCESS,
            payload,
        })

        if (callback) callback()

    } catch (error) {
        console.error(error)
        dispatch({ type: GET_ALL_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}
