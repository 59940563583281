import { mergeRight } from 'ramda'

import {
    GET_ROLES_SUCCESS,
    GET_ROLE_SUCCESS,
    GET_ROLE_ACTIONS_SUCCESS,
    CLEAR_ROLE,
} from './actions'

const initialState = {
    roles: [],
    role: {},
    rolesPermissions: {},
}

export default function rolesReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)

    switch (type) {
        case GET_ROLES_SUCCESS:
            return merge({ roles: payload })
        case GET_ROLE_SUCCESS:
            return merge({ role: payload })
        case GET_ROLE_ACTIONS_SUCCESS:
            const { roleId, permissions } = payload
            return merge({ rolesPermissions: { ...state.rolesPermissions, [roleId]: permissions }})
        case CLEAR_ROLE:
            return merge({ role: {} })
        default:
            return state
    }
}
