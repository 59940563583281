import { v4 as uuid_v4 } from 'uuid'

export class HtmlMessage {
    constructor(status, statusText, message, severity = 'success') {
        this.id = uuid_v4()
        this.htmlStatus = status
        this.htmlStatusText = statusText
        this.name = 'HtmlMessage'
        this.message = message
        this.severity = severity
    }
}

export function ResponseMessageParse(response) {
    const { responseStatus } = response
    let message, severity

    if (response.success) {
        message = response.success
        severity = 'success'
    } else if (response.warning) {
        message = response.warning
        severity = 'warning'
    } else {
        message = 'Actualización satisfactoria'
        severity = 'success'
    }

    return {
        status: responseStatus.htmlStatus,
        statusText: responseStatus.htmlStatusText,
        message,
        severity,
    }
}
