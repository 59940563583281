/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { connect } from 'react-redux'
import { path, isNil, pick, mergeRight, last } from 'ramda'
import { ThemeProvider, createMuiTheme, IconButton } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import { useSnackbar } from 'notistack'

import { MenuComponent, ExpiredSessionComponent } from './components'
import * as Components from './pages'
import { loadSesion, getUserInfo as _getUserInfo } from './redux/user/actions';
import { closeExpiredSessionDialog as _closeExpiredSessionDialog, removeAlertMessage as _removeAlertMessage } from './redux/ui-states/actions'
import { fetchTranslations as _fetchTranslations } from './redux/i18n/actions'
import { fetchConfigurations as _fetchConfigurations } from './redux/ui-config/actions'
import { getSesionData } from './services/sesionService';
import { channelSubscription } from './services/pusherService';
import { fetchPendingAlerts as _fetchPendingAlerts } from './redux/amm/alerts/actions'
import { cmDefaultTheme, appStyles } from './theme'
import { ROUTES } from './routes'

function AuthRoute({ children, auth, ...rest }) {
    if (!auth) return <Route {...rest} />
    return <Redirect to={rest.location.state.from} />
}

function PrivateRoute({ auth, redirect, ...rest }) {
    if (auth && redirect) return <Redirect to={{
        pathname: redirect,
        from: rest.location
    }}/>

    if (auth) return <Route {...rest} />

    return (
        <Redirect
            to={{
                pathname: ROUTES.LOGIN.path,
                state: { from: rest.location }
            }}
        />
    )
}

function App(props) {
    const {
        auth, alerts, i18n, theme, userInfo, pendingAlerts, fetchPendingAlerts,
        fetchTranslations, fetchConfigurations, expiredSessionDialog, closeExpiredSessionDialog, loadHandler, getUserInfo,
    } = props

    const { enqueueSnackbar, closeSnackbar } = useSnackbar()
    const classes = appStyles()
    const isTokenEmpty = isNil
    const routes = Object.keys(ROUTES)
    const [localTheme, setLocalTheme] = useState(createMuiTheme(cmDefaultTheme))
    const [alertCount, setAlertCount] = useState(0)
    const [_pendingAlerts, setPendingAlerts] = useState(0)

    const action = key => (
        <React.Fragment>
            <IconButton onClick={() => { closeSnackbar(key) }}>
                <CloseIcon color="inherit" />
            </IconButton>
        </React.Fragment>
    );

    useEffect(() => {
        const sesionData = getSesionData()
        if (sesionData) {
            loadHandler(sesionData)
        }
    }, [])

    useEffect(() => {
        if (alerts.length > alertCount) {
            const alert = last(alerts)
            enqueueSnackbar(alert.message, { variant: alert.severity, action })
        }
        setAlertCount(alerts.length)
    }, [alerts])

    useEffect(() => {
        if (auth) {
            fetchTranslations(auth)
            fetchConfigurations(auth)
            fetchPendingAlerts(auth)
            if (!userInfo) {
                getUserInfo(auth)
            }
        }
    }, [auth])

    useEffect(() => {
        setPendingAlerts(pendingAlerts);
    },[pendingAlerts])

    useEffect(() => {
        const newTheme = pick(['palette', 'overrides'], theme)
        const mergedTheme = mergeRight(cmDefaultTheme, newTheme)
        setLocalTheme(createMuiTheme(mergedTheme))
    }, [theme])

    useEffect(() => {
        window.i18n = i18n
    }, [i18n])

    useEffect(() => {
        const organization_id = userInfo?.data?.attributes?.organization_id
        // TODO:
        // Modificar getPendingAlerts y reemplazarlo con un endpoint genérico que haga lo siguiente:
        // ->   Canales y eventos registrados en el backend, ej: amm-channel/set-pending-alerts
        // ->   Devolver cada elemento que luego se puede actualizar mediante pusher
        if (organization_id) {
            const ammChannel = channelSubscription('amm-channel',organization_id);
            ammChannel.bind('set-pendings-alerts', function(data) {
                setPendingAlerts(data.pendings);
            });
        }
    },[userInfo])

    return (
        <ThemeProvider theme={localTheme}>
            <MenuComponent logged={!isTokenEmpty(auth)} pendingAlerts={_pendingAlerts}></MenuComponent>
            {expiredSessionDialog && <ExpiredSessionComponent open={true} closeSession={closeExpiredSessionDialog}/>}
            <div className={classes.root}>
                <Switch>
                    {routes.map((route, i) => {
                        if (ROUTES[route].type === 'public') {
                            return (
                                <AuthRoute
                                    key={i}
                                    exact={ROUTES[route].exact}
                                    auth={!isTokenEmpty(auth)}
                                    path={ROUTES[route].path}
                                    component={Components[ROUTES[route].component]}
                                />
                            )
                        }
                        return (
                            <PrivateRoute
                                key={i}
                                exact={ROUTES[route].exact}
                                auth={!isTokenEmpty(auth)}
                                path={ROUTES[route].path}
                                component={Components[ROUTES[route].component]}
                                redirect={ROUTES[route].redirect}
                            />
                        )
                    })}

                    <Route path="*">
                        <Components.NotFoundPage />
                    </Route>
                </Switch>
            </div>
        </ThemeProvider>
    );
}

const mapStateToProps = state => ({
    auth: path(['user', 'auth_token'], state),
    userInfo: path(['user', 'info'], state),
    expiredSessionDialog: path(['uiStates', 'expiredSessionDialog', 'show'], state),
    alerts: path(['uiStates', 'alerts'], state),
    i18n: path(['i18n', 'data', 'attributes', 'i18n'], state),
    theme: path(['uiConfig', 'data', 'attributes', 'app_data'], state),
    pendingAlerts: path(['alerts', 'pendings'], state),
})

const mapDispatchToProps = dispatch => ({
    loadHandler: (payload) => dispatch(loadSesion(payload)),
    getUserInfo: (token) => dispatch(_getUserInfo(token)),
    fetchTranslations: token => dispatch(_fetchTranslations(token)),
    fetchConfigurations: token => dispatch(_fetchConfigurations(token)),
    closeExpiredSessionDialog: () => dispatch(_closeExpiredSessionDialog()),
    removeAlertMessage: (id) => dispatch(_removeAlertMessage(id)),
    fetchPendingAlerts: (token) => dispatch(_fetchPendingAlerts(token)),
})

export default connect(mapStateToProps, mapDispatchToProps)(App)