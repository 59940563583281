import { get, put } from '../../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../../models/htmlMessage'

// Actions types
export const GET_OPTIONS_START = 'GET_OPTIONS_START'
export const GET_OPTIONS_SUCCESS = 'GET_OPTIONS_SUCCESS'
export const GET_OPTIONS_ERROR = 'GET_OPTIONS_ERROR'

export const UPDATE_OPTIONS_START = 'UPDATE_OPTIONS_START'
export const UPDATE_OPTIONS_SUCCESS = 'UPDATE_OPTIONS_SUCCESS'
export const UPDATE_OPTIONS_ERROR = 'UPDATE_OPTIONS_ERROR'


export const GET_PENDING_ALERTS_SUCCESS = 'GET_PENDING_ALERTS_SUCCESS'

// Promisses
export const fetchOptionsPromise = async (token) => get(`amm/options`, '', {Authorization: token})
export const updateOptionsPromise = async (token, body) => put(`amm/options`, body, {Authorization: token})
export const fetchAlertsPromise = async (token) => get(`amm/alerts`, '', {Authorization: token})

// Async actions creators
export const fetchOptions = (token) => async dispatch => {
  dispatch({ type: GET_OPTIONS_START })
  dispatch(requestPending())
  try {
    const response = await fetchOptionsPromise(token)
    dispatch(requestSuccess())
    dispatch({ type: GET_OPTIONS_SUCCESS, payload: response })
  } catch (error) {
    dispatch(requestError())
    dispatch({ type: GET_OPTIONS_ERROR })
    if (error.htmlStatus === 422) {
      dispatch(openExpiredSessionDialog())
    }
    dispatch(addAlertMessage(error))
  }
}

export const updateOptions = (token, body) => async dispatch => {
  dispatch({ type: UPDATE_OPTIONS_START })
  dispatch(requestPending())

  try {
    const payload = await updateOptionsPromise(token, { options: body })
    dispatch(requestSuccess())
    dispatch({ type: UPDATE_OPTIONS_SUCCESS })

    const response = await fetchAlertsPromise(token)
    dispatch(requestSuccess())
    dispatch({ type: GET_PENDING_ALERTS_SUCCESS, payload: response })

    const { status, statusText, message, severity } = ResponseMessageParse(payload)
    dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
  } catch (error) {
    dispatch(requestError())
    dispatch({ type: UPDATE_OPTIONS_ERROR })
    if (error.htmlStatus === 422) {
      dispatch(openExpiredSessionDialog())
    }
    dispatch(addAlertMessage(error))
  }
}