/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'
import { path, pathOr, propOr, map, compose, isNil, filter, omit } from 'ramda'
import L, { DivIcon, latLngBounds } from 'leaflet'
import { Map, Marker, Popup, Polygon, GeoJSON } from 'react-leaflet'
import MarkerClusterGroup from 'react-leaflet-markercluster'
import { GoogleLayer } from 'react-leaflet-google-v2'
import * as svgIcon from '@mdi/js'

import { getBounds, getLatLng } from '../../../services/mapServices'
import { coloredSvgIcon } from '../../../services/iconService'
import styles from './styles'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { parseDateTime } from '../../../services/util'

const road = 'ROADMAP'

const defaultPoint = [-33.437967, -70.6504]
const getDepots = propOr([], 'depots')
const iconDefauls = {
    iconAnchor: [22, 40],
    popupAnchor: [0, -35],
    iconSize: [43, 43],
}

function RoutesMapComponent({ layers, mapLayer, config, clusterConfig, activeSource, resizeListener, controls }) {
    const classes = styles()
    const [data, setData] = useState(layers)
    const [bounds, setBounds] = useState(new latLngBounds([]))
    const mapEl = useRef(null)
    let mapParams = {}

    useEffect(() => {
        if (mapEl.current) {
            resizeListener(mapEl.current.leafletElement)
        }
    }, [mapEl])

    useEffect(() => {
        setBounds(getLayersBounds(layers, activeSource))
        setData(layers)
    }, [layers, activeSource])

    function mapIcon (iconConfig, point) {
        const {last_status, layer, route_gps_status} = point
        const {iconAnchor, popupAnchor, iconSize} = iconDefauls
        const configByCluster = path([point.cluster_name, 'mapLayers', layer, 'icon'], clusterConfig)
        let useConfig = null
        
        if(configByCluster) {
            useConfig = configByCluster
        } else {
            useConfig = iconConfig ? iconConfig : config.default.icon
        }
        
        const icon = svgIcon[useConfig.name]
        let color = useConfig.color || (useConfig.colors && useConfig.colors[(typeof last_status === 'string' ? last_status : '').toLowerCase()])

        if (/vehicle/ig.test(layer) && (!route_gps_status || route_gps_status === 'inactive')) {
            color = pathOr('grey', ['inactive'], useConfig)
        }

        const html = coloredSvgIcon(icon, color)

        return new DivIcon({
            html,
            iconAnchor,
            popupAnchor,
            iconSize,
            className: classes.mapIcon,
        })
    }

    function getLayersBounds(layersArray, source) {
        const sw = {
            depots: ['depots', 'data'],
            routes: ['routes', 'included'],
            vehicles: ['vehicles', 'data'],
            mobiles: ['mobiles', 'data'],
        }

        const routesLatitude = {
            item: pathOr(null, ['attributes', 'profile_latitude']),
            vehicle: pathOr(null, ['meta', 'coords', 'latitude']),
            mobile_device: pathOr(null, ['meta', 'coords', 'latitude']),
        }

        const routesLongitude = {
            item: pathOr(null, ['attributes', 'profile_longitude']),
            vehicle: pathOr(null, ['meta', 'coords', 'longitude']),
            mobile_device: pathOr(null, ['meta', 'coords', 'longitude']),
        }

        const swLatitude = {
            routes: pathOr(null, ['attributes', 'profile_latitude']),
            depots: propOr(null, 'latitude'),
            vehicles: pathOr(null, ['meta', 'coords', 'latitude']),
            mobiles: pathOr(null, ['meta', 'coords', 'latitude']),
        }

        const swLongitude = {
            depots: propOr(null, 'longitude'),
            routes: pathOr(null, ['attributes', 'profile_longitude']),
            vehicles: pathOr(null, ['meta', 'coords', 'longitude']),
            mobiles: pathOr(null, ['meta', 'coords', 'longitude']),
        }

        const dataPath = pathOr([], sw[source])

        const dataParse = map(item => {
                if (source === 'routes' && item.type !== 'history') {
                    return {
                        latitude: routesLatitude[item.type](item),
                        longitude: routesLongitude[item.type](item)
                    }
                }

                return {
                    latitude: swLatitude[source](item),
                    longitude: swLongitude[source](item)
                }
            })

        const dataSanitize = filter(item => !(isNil(item.latitude) || isNil(item.longitude)))

        const bounds = compose(getBounds, dataSanitize, dataParse, dataPath)

        return bounds(layersArray)
    }


    if (bounds.isValid()) {
        mapParams = {
            ...bounds,
            zoom: mapEl.current.leafletElement.getZoom()
        }
    } else {
        mapParams = {
            center: defaultPoint,
            zoom: 13
        }
    }

    return (
        <div className={classes.routesMapRoot}>
            <Map
                {...mapParams} 
                ref={mapEl}
                center={defaultPoint}
                zoom={13}
                zoomControl={false}
                attributionControl={true}
            >
               <GoogleLayer googlekey={process.env.REACT_APP_GOOGLE_MAPS_API}  maptype={road} />
               <RenderPoints
                    {...{ [activeSource]: data[activeSource] }}
                    mapLayer={mapLayer}
                    config={config}
                    classes={classes}
                    mapIcon={mapIcon}
                />
            </Map>
            { controls }
        </div>
    )
}

function RenderPoints({routes, vehicles, mobiles, mapLayer, classes, mapIcon, config}) {
    function layerParser(routes, vehicles, mobiles, mapLayer) {
        const parsedDepots = getDepots(mapLayer).map(item => ({...item, layer: 'depots'}))
        const parsedRoutes = propOr([], 'included', routes)
            .filter(item => {
                if (/vehicle/gi.test(item.type)) {
                    return item.meta.coords.latitude
                }
                return item.attributes.profile_latitude
            })
            .map(item => {
                return {
                    id: item.id,
                    uid: path(['attributes', 'route_uid'], item),
                    vehicle_nid: path(['attributes', 'vehicle_nid'], item),
                    eta: path(['attributes', 'arrives_at'], item),
                    routeId: item.attributes.route_id || null,
                    layer: `${pathOr(item.type, ['attributes', 'item_type'], item)}`.toLowerCase(),
                    organization_id: item.attributes.organization_id,
                    nid: path(['attributes','profile_nid'], item) || path(['attributes','nid'], item) || item.nid,
                    name: item.attributes.profile_name,
                    latitude: item.attributes.profile_latitude || item.meta.coords.latitude,
                    longitude: item.attributes.profile_longitude || item.meta.coords.longitude,
                    addr_street: item.attributes.profile_addr_street,
                    addr_number: item.attributes.profile_addr_number,
                    addr_line2: item.attributes.profile_addr_line2,
                    addr_city: item.attributes.profile_addr_city,
                    country: item.attributes.profile_country,
                    geofence: item.attributes.profile_geofence,
                    license_plate: path(['attributes', 'license_plate'], item),
                    driver_name: path(['attributes', 'driver_name'], item),
                    last_status: pathOr('unassigned', ['attributes', 'last_status'], item),
                    last_status_reason: path(['attributes', 'last_status_reason'], item),
                    itinerary_events_url: path(['attributes', 'itinerary_events_url'], item),
                    route_gps_status: path(['meta', 'table', 'route_gps_status'], item),
                    load: item.attributes.load,
                    effective_load: item.attributes.effective_load || null,
                    profile_url: item.attributes.profile_url,
                    cluster_name: path(['meta', 'table', 'cluster_name'], item),
                }
            })

        const parsedVehicles = propOr([], 'data', vehicles)
            .filter(item => !isNil(item.meta.coords.latitude))
            .map(item => ({
                id: item.id,
                layer: item.type,
                organization_id: item.attributes.organization_id,
                nid: item.attributes.nid || null,
                license_plate: item.attributes.license_plate,
                latitude: item.meta.coords.latitude,
                longitude: item.meta.coords.longitude,
                driver_name: item.attributes.driver_name,
                route_gps_status: path(['meta', 'table', 'route_gps_status'], item),
                cluster_name: path(['meta', 'table', 'cluster_name'], item),
                routeId: path(['meta', 'table', 'route_id'], item),
            }))

        const parsedMobiles = propOr([], 'data', mobiles)
            .filter(item => !isNil(item.meta.coords.latitude))
            .map(item => ({
                id: item.id,
                layer: item.type,
                organization_id: item.attributes.organization_id,
                description: item.attributes.description,
                nid: item.attributes.uid,
                license_plate: item.meta.table.vehicle_license_plate,
                routeId: item.meta.table.route_id,
                latitude: item.meta.coords.latitude,
                longitude: item.meta.coords.longitude,
                driver_name: item.attributes.driver_name,
            }))

        const response = [...parsedDepots, ...parsedRoutes, ...parsedVehicles, ...parsedMobiles]

        return response.reduce((acum, item) => {
            if (acum[item.layer]) {
                return {
                    ...acum,
                    [item.layer]: [...acum[item.layer], item]
                }
            }
            return {
                ...acum,
                [item.layer]: [item]
            }
        }, {})
    }

    const getPoligon = pathOr([], ['geofence', 'coords'])
    const objPoints = layerParser(routes, vehicles, mobiles, mapLayer)
    const getCustomLayers = omit(['depots'])

    return (
        <React.Fragment>
            {getDepots(mapLayer).map((point, key) => {
                if (getPoligon(point).length) {
                    return <Polygon key={key} color="purple" positions={getPoligon(point)} />
                }
                return ''
            })}
            {Object.keys(getCustomLayers(mapLayer)).map((item, key) => {
                return <GeoJSON
                        key={key}
                        data={mapLayer[item].feature_collection}
                        style={function (feature) {
                            return {
                                stroke: !!feature.properties.stroke,
                                color: feature.properties.stroke,
                                weight: feature.properties.stroke_width,
                                fill: !!feature.properties.fill,
                                fillColor: feature.properties.fill,
                            };
                        }}
                        pointToLayer={function(geoJsonPoint, latlng) {
                            const {iconAnchor, popupAnchor, iconSize} = iconDefauls
                            return L.marker(latlng, {
                                icon: L.divIcon({
                                    html: coloredSvgIcon(svgIcon[config.default.icon.name], geoJsonPoint.properties.fill),
                                    iconAnchor,
                                    popupAnchor,
                                    iconSize,
                                    className: classes.mapIcon,
                                })
                            });
                        }}
                        onEachFeature={function (feature, layer) {
                            const popupContent = `<h3>${feature.properties.name}</h3><p>${feature.properties.description}<p>`
                            layer.bindPopup(popupContent)
                        }}
                    />
            })}
            {
                Object.keys(objPoints).map((layer, key) => {
                    return (<MarkerClusterGroup key={key}>
                            {objPoints[layer].map((point, key) => {
                                return (
                                    <Marker
                                        key={key+JSON.stringify(point)}
                                        position={getLatLng(point)}
                                        icon={mapIcon(path([point.layer, 'icon'], config), point)}
                                    >
                                        <Popup>
                                            <Tooltip classes={classes} point={point} />
                                        </Popup>
                                    </Marker>
                                )
                            })}
                        </MarkerClusterGroup>
                    )
                })
            }
        </React.Fragment>
    )
}

function Tooltip(props) {
    const { classes, point } = props
    const route_map_url = <a href={'/routes/map/' + point.routeId} target="_blank" rel="noopener noreferrer">{point.uid}</a>;
    switch (point.layer) {
        case 'vehicle':
        case 'mobile_device':
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>
                                {point.nid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Ruta</TableCell>
                            <TableCell>{route_map_url}</TableCell>
                        </TableRow>
                        {point.last_status_reason && (
                            <TableRow>
                                <TableCell>Motivo</TableCell>
                                <TableCell>
                                    <a href={point.itinerary_events_url} target="_blank" rel="noopener noreferrer">
                                        {point.last_status_reason}
                                    </a>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )
        case 'stop':
            let item_nid;
            if (!point.profile_url) {
                item_nid = point.nid;
            } else {
                item_nid = <a href={point.profile_url} target="_blank" rel="noopener noreferrer">{point.nid}</a>;
            }
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>
                                {item_nid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>
                                {point.name}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Ruta</TableCell>
                            <TableCell>{route_map_url}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Camión</TableCell>
                            <TableCell>{point.vehicle_nid}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Carga</TableCell>
                            <TableCell>{point.load}</TableCell>
                        </TableRow>
                        {point.effective_load && (
                            <TableRow>
                                <TableCell>Carga efectiva</TableCell>
                                <TableCell>{point.effective_load}</TableCell>
                            </TableRow>
                        )}
                        <TableRow>
                            <TableCell>ETA</TableCell>
                            <TableCell>{parseDateTime(point.eta)}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dirección</TableCell>
                            <TableCell>{`${!point.addr_street ? '' : point.addr_street} ${point.addr_number} ${(point.addr_street && point.addr_city) ? '-' : ''} ${!point.addr_city ? '' : point.addr_city}`}</TableCell>
                        </TableRow>
                        {point.last_status_reason && (
                            <TableRow>
                                <TableCell>Motivo</TableCell>
                                <TableCell>
                                    <a href={point.itinerary_events_url} target="_blank" rel="noopener noreferrer">
                                        {point.last_status_reason}
                                    </a>
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
            )
        default:
            return (
                <Table size="small" className={classes.popupTable}>
                    <TableBody>
                        <TableRow>
                            <TableCell>Id</TableCell>
                            <TableCell>
                                {point.nid}
                            </TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell>Dirección</TableCell>
                            <TableCell>{`${!point.addr_street ? '' : point.addr_street} ${point.addr_number} ${(point.addr_street && point.addr_city) ? '-' : ''} ${!point.addr_city ? '' : point.addr_city}`}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            )  
    }
}

RoutesMapComponent.propTypes = {
    config: PropTypes.object,
    layers: PropTypes.object,
}

export default RoutesMapComponent
