import {
    GET_ALL_START,
    GET_ALL_SUCCESS,
    GET_ALL_ERROR,
} from './actions'

const initialState = {
    requestStatus: 'none',
    active: true,
    data: null,
    detail: null,
}

export default function vehiclesReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_ALL_SUCCESS:
            return {...state, response: payload}
        case GET_ALL_START:
        case GET_ALL_ERROR:
        default:
            return state
    }
}
