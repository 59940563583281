import { path } from 'ramda'
import { get } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'
import { fetchDataPromise as depotsFetchDataPromise, DATA_SUCCESS as DEPOTS_DATA_SUCCESS } from '../depots/actions'
import { fetchDataPromise as vehicleFetchDataPromise, GET_ALL_SUCCESS as VEHICLES_GET_ALL_SUCCESS } from '../vehicles/actions'
import { renameKeys } from '../../services/util'

// Action Types

export const GET_ALL_START = 'ROUTES_GET_ALL_START'
export const GET_ALL_SUCCESS = 'ROUTES_GET_ALL_SUCCESS'
export const GET_ALL_ERROR = 'ROUTES_GET_ALL_ERROR'

export const GET_ALL_ITEMS_START = 'ROUTES_GET_ALL_ITEMS_START'
export const GET_ALL_ITEMS_SUCCESS = 'ROUTES_GET_ALL_ITEMS_SUCCESS'
export const GET_ALL_ITEMS_ERROR = 'ROUTES_GET_ALL_ITEMS_ERROR'

export const GET_FILTERS_START = 'ROUTES_GET_FILTERS_DATA_START'
export const GET_FILTERS_SUCCESS = 'ROUTES_GET_FILTERS_DATA_SUCCESS'
export const GET_FILTERS_ERROR = 'ROUTES_GET_FILTERS_DATA_ERROR'

// Action Creators

export const getFiltersStart = () => ({type: GET_FILTERS_START})
export const getFiltersSuccess = payload => ({type: GET_FILTERS_SUCCESS, payload})
export const getFiltersError = () => ({type: GET_FILTERS_ERROR})

// Promises

export const fetchDataPromise = async (token, querystring) => get('routes/', querystring || '', {Authorization: token})
export const fetchItemsDataPromise = async (token, querystring) => get('items/', querystring || '', {Authorization: token})
export const getStatusDataPromise = async token => get('routes/route_states', '', {Authorization: token})
export const getFilterDepotsDataPromise = async token => get('depots/as_select', '', {Authorization: token})
export const getFilterRoutesDataPromise = async token => get('items/as_select', '', {Authorization: token})
export const getFilterVehiclesDataPromise = async (token, querystring) => get('vehicles/as_select', querystring || '', {Authorization: token})
export const getFilterMobileDevicesDataPromise = async token => get('mobile_devices/as_select', '', {Authorization: token})

// Async action creators

export const fetchData = (token, querystring, callback) => async dispatch => {
    dispatch({ type: GET_ALL_START })
    try {
        const response = await fetchDataPromise(token, querystring)

        dispatch({
            type: GET_ALL_SUCCESS,
            payload: response
        })

        if (callback) callback()

    } catch (error) {
        dispatch({ type: GET_ALL_ERROR })
        dispatch(requestError())
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }

        dispatch(addAlertMessage(error))
    }
}

export const fetchItemsData = (token, querystring, callback) => async dispatch => {
    dispatch({ type: GET_ALL_ITEMS_START })

    try {
        const response = await fetchItemsDataPromise(token, querystring)

        dispatch({
            type: GET_ALL_ITEMS_SUCCESS,
            payload: response
        })

        if (callback) callback()

    } catch (error) {
        dispatch({ type: GET_ALL_ITEMS_ERROR })
        dispatch(requestError())
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }

        dispatch(addAlertMessage(error))
    }
}

export const fetchLayers = token => async dispatch => {
    dispatch({ type: GET_ALL_START })
    dispatch(requestPending())

    try {
        const response = await Promise.all([
            fetchDataPromise(token),
            depotsFetchDataPromise(token),
            vehicleFetchDataPromise(token),
        ])

        dispatch(requestSuccess())

        dispatch({
            type: GET_ALL_SUCCESS,
            payload: path([0], response)
        })

        dispatch({
            type: DEPOTS_DATA_SUCCESS,
            payload: path([1], response)
        })

        dispatch({
            type: VEHICLES_GET_ALL_SUCCESS,
            payload: path([2], response),
        })

    } catch (error) {
        console.error(error)
        dispatch({ type: GET_ALL_ERROR })
        dispatch(requestError())

        dispatch(addAlertMessage(error))
    }
}

export const fetchFiltersData = (token, querystring) => async dispatch => {
    dispatch(getFiltersStart())
    dispatch(requestPending())

    try {
        const response = await Promise.all([
            getFilterDepotsDataPromise(token),
            getFilterRoutesDataPromise(token),
            getFilterVehiclesDataPromise(token, querystring),
            getFilterMobileDevicesDataPromise(token),
            getStatusDataPromise(token),
        ])

        dispatch(getFiltersSuccess(
            renameKeys({ '0': 'depots', '1': 'routes', '2': 'vehicles', '3': 'mobiles', '4': 'stats' }, response)
        ))

        dispatch(requestSuccess())
    } catch (error) {
        console.error(error)
        dispatch(getFiltersError())
        dispatch(requestError())

        dispatch(addAlertMessage(error))
    }
}
