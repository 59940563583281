import React from 'react'
import { DivIcon, latLng, latLngBounds } from 'leaflet'
import { Map, TileLayer, Marker, Popup, Polygon } from 'react-leaflet'
import * as svgIcon from '@mdi/js'
import { pathOr, isEmpty } from 'ramda'

import { coloredSvgIcon } from '../../services/iconService'
import styles from './styles'

function DepotsMapComponent({points, iconConfig, selectedPoint, setSelectedPoint}) {
    const classes = styles()

    const depotsMapIcon = new DivIcon({
        html: coloredSvgIcon(svgIcon[iconConfig.name], iconConfig.color),
        iconAnchor: [iconConfig.left, 40],
        popupAnchor: [0, -35],
        iconSize: [iconConfig.size, iconConfig.size],
        className: classes.mapIcon,
    })

    function getLatLng(point) {
        return [parseFloat(point.latitude), parseFloat(point.longitude)]
    }

    function getCenter() {
        if (!selectedPoint) {
            return false
        }

        return getLatLng(selectedPoint)
    }

    function getBounds() {
        const parsedPoints = points.map(point => {
            return new latLng(getLatLng(point))
        })

        if (!selectedPoint) {
            return new latLngBounds(parsedPoints)
        }

        if (!isEmpty(getPoligon(selectedPoint))) {
            return new latLngBounds(getPoligon(selectedPoint))
        }

        return false
    }

    function showPointDetail(e) {
        e.preventDefault()
        setSelectedPoint(e.target.name)
    }

    const getPoligon = pathOr([], ['geofence', 'coords'])

    return(
        <Map bounds={getBounds()} center={getCenter()}>
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution="&copy; <a href=&quot;http://osm.org/copyright&quot;>OpenStreetMap</a> contributors"
            />
            {selectedPoint && <Polygon color="purple" positions={getPoligon(selectedPoint)} />}
            {points.map((point, key) => {
                return (
                    <Marker position={getLatLng(point)} key={key+JSON.stringify(point)} icon={depotsMapIcon}>
                        <Popup>
                            <div>
                                <button className={classes.button} name={point.id} onClick={showPointDetail}>{point.name}</button>
                                <p className={classes.p}>{point.addr_city} - {point.addr_street}</p>
                            </div>
                        </Popup>
                    </Marker>
                )}
            )}
        </Map>
    )
}

export default DepotsMapComponent
