import { isEmpty, pathOr } from 'ramda'
import { latLng, latLngBounds } from 'leaflet'

export const getPoligon = pathOr([], ['geofence', 'coords'])

export function getLatLng(point) {
    return [parseFloat(point.latitude), parseFloat(point.longitude)]
}

export function getCenter(selectedPoint) {
    if (!selectedPoint) {
        return false
    }

    return getLatLng(selectedPoint)
}

export function getBounds(points, selectedPoint) {
    const parsedPoints = points.map(point => {
        return new latLng(getLatLng(point))
    })

    if (!selectedPoint) {
        return new latLngBounds(parsedPoints)
    }

    if (!isEmpty(getPoligon(selectedPoint))) {
        return new latLngBounds(getPoligon(selectedPoint))
    }

    return false
}
