import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    headerContainer: {
        display: 'flex',

        '& p:first-child': {
            marginRight: '2rem',
        }
    },
    formRow: {
        display: 'flex',
        justifyContent: 'space-evenly',
        padding: 10,
        marginLeft: 10,

        '& button': {
            width: '8rem',
            height: '2rem',
            margin: '0 0 auto auto',
        }
    },

    dActions: {
        display: 'block',
    },

    messageInput: {
        width: 'calc(100% - 10rem)',
    },

    notifyInput: {
        width: 'calc(100% - 10rem)',
        display: 'flex',
    },

    notifyText: {
        marginRight: '1rem',
        whiteSpace: 'nowrap',
    }
}))
