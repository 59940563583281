import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        position: 'absolute',
        bottom: 35,
        left: 0,
    },

    tableRoot: {
        marginTop: 15,
    },

    modalWrapper: {
        height: 240,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    saveButton: {
        position: 'absolute',
        top: 26,
        right: 30,
    },

    alignCenter: {
        textAlign: 'center'
    },

    updateButton: {
        display: 'block',
        margin: '40px auto 0'
    },

    title: {
        position: 'relative',
    },
}))
