import { cleanSesion } from '../user/actions'
import { HtmlMessage } from '../../models/htmlMessage'

// Actions type

export const OPEN_SIDE_MENU = 'UI_OPEN_SIDE_MENU'
export const CLOSE_SIDE_MENU = 'UI_CLOSE_SIDE_MENU'
export const OPEN_DETAIL_CONFIG = 'UI_OPEN_DETAIL_CONFIG'
export const CLOSE_DETAIL_CONFIG = 'UI_CLOSE_DETAIL_CONFIG'
export const OPEN_CONTROL_PANEL = 'UI_OPEN_CONTROL_PANEL'
export const CLOSE_CONTROL_PANEL = 'UI_CLOSE_CONTROL_PANEL'
export const TOGGLE_LEFT_PANEL = 'UI_TOGGLE_LEFT_PANEL'
export const TOGGLE_RIGHT_PANEL = 'UI_TOGGLE_RIGHT_PANEL'
export const OPEN_EXPIRED_SESSION_DIALOG = 'UI_OPEN_EXPIRED_SESSION_DIALOG'
export const CLOSE_EXPIRED_SESSION_DIALOG = 'UI_CLOSE_EXPIRED_SESSION_DIALOG'
export const ADD_ALERT_MESSAGE = 'UI_ADD_ALERT_MESSAGE'
export const REMOVE_ALERT_MESSAGE = 'UI_REMOVE_ALERT_MESSAGE'

// Actions creators

export const openSideMenu = () => ({
    type: OPEN_SIDE_MENU
})

export const closeSideMenu = () => ({
    type: CLOSE_SIDE_MENU
})

export const openDetailConfig = () => ({
    type: OPEN_DETAIL_CONFIG
})

export const closeDetailConfig = () => ({
    type: CLOSE_DETAIL_CONFIG
})

export const openControlPanel = () => ({
    type: OPEN_CONTROL_PANEL,
})

export const closeControlPanel = () => ({
    type: CLOSE_CONTROL_PANEL,
})

export const toggleLeftPanel = () => ({
    type: TOGGLE_LEFT_PANEL,
})

export const toggleRightPanel = () => ({
    type: TOGGLE_RIGHT_PANEL,
})

export const openExpiredSessionDialog = () => ({
    type: OPEN_EXPIRED_SESSION_DIALOG,
})

export const closeExpiredSessionDialog = () => dispatch => {
    dispatch({ type: CLOSE_EXPIRED_SESSION_DIALOG })
    dispatch(cleanSesion())
}

export const removeAlertMessage = payload => ({
    type: REMOVE_ALERT_MESSAGE,
    payload
})

export const addAlertMessage = payload => dispatch => {
    let resp = payload
    if (payload.name !== 'HtmlMessage') resp = new HtmlMessage(payload.status, payload.statusText, payload.message, 'error')

    setTimeout(() => {
        dispatch(removeAlertMessage(resp.id))
    }, 10000)

    dispatch({
        type: ADD_ALERT_MESSAGE,
        payload: resp
    })
}