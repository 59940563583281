import { get } from '../../services/fetchService'
import { requestError } from '../request-state/actions'

export const DATA_REQUEST = 'DEPOT_DATA_REQUEST'
export const DATA_SUCCESS = 'DEPOT_DATA_SUCCESS'
export const DATA_ERROR = 'DEPOT_DATA_ERROR'
export const DETAIL_SUCCESS = 'DEPOT_DETAIL_SUCCESS'
export const DETAIL_CLEAR = 'DEPOT_DETAIL_CLEAR'

export const fetchDataPromise = async (token, querystring) => get('depots/', querystring || '', {Authorization: token})

export const fetchData = (token, querystring, callback) => async dispatch => {
    dispatch({ type: DATA_REQUEST })

    try {
        const response = await fetchDataPromise(token, querystring)

        dispatch({
            type: DATA_SUCCESS,
            payload: response
        })

        if (callback) callback()

    } catch(e) {
        console.error('error cacheado', e)
        dispatch(requestError())
    }
}

export const getDetail = id => dispatch => {
    dispatch({
        type: DETAIL_SUCCESS,
        payload: id,
    })
}

export const removeDetail = () => dispatch => {
    dispatch({
        type: DETAIL_CLEAR,
    })
}
