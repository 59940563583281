import React, { useState } from 'react'
import { connect } from 'react-redux'
import { DialogTitle, /*DialogContent, DialogContentText, */DialogActions, Button, TextField, Divider } from '@material-ui/core'
import { format } from 'date-fns'

import styles from './styles'
import { path, isEmpty } from 'ramda'
import { sendNotificationsReview as _sendNotificationsReview, sendNotificationsNotify as _sendNotificationsNotify} from '../../../redux/route-detail/actions'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
})

const mapDispatchToProps = dispatch => ({
    sendNotificationsReview: (token, id, qs, cb) => dispatch(_sendNotificationsReview(token, id, qs, cb)),
    sendNotificationsNotify: (token, id, qs, cb) => dispatch(_sendNotificationsNotify(token, id, qs, cb)),
})

function AlertModalComponent(props) {
    const { item, token, handleClose, sendNotificationsReview/*, sendNotificationsNotify*/ } = props
    const classes = styles()
    const [review, setReview] = useState('')
    /*const [notify, setNotify] = useState('')*/

    function sendReview() {
        if (!isEmpty(review)) {
            sendNotificationsReview(token, item.id, { seen_message: review }, (error, response) => {
                handleClose()
            })
        }
    }

    /*function sendNotify() {
        if (!isEmpty(notify)) {
            sendNotificationsReview(token, item.id, { notified_to: notify }, (error, response) => {
                handleClose()
            })
        }
    }*/

    function handleChange(event) {
        if (event.target.name === 'review') {
            setReview(event.target.value)
        }/* else {
            setNotify(event.target.value)
        }*/
    }

    return (
        <React.Fragment>
            <DialogTitle>
                <div className={classes.headerContainer}>
                    <p>{format(new Date(item.attributes.notification_date), 'dd/MM/yyyy HH:mm')}</p>
                    <p>{item.attributes.description}</p>
                </div>
                <Divider />
            </DialogTitle>
            {/* <DialogContent>
                <DialogContentText id="alert-dialog-description">
                    Table Placeholder
                </DialogContentText>
                <Divider />
            </DialogContent> */}
            <DialogActions classes={{ root: classes.dActions }}>
                <div className={classes.formRow}>
                    <TextField id="outlined-basic" label="Mensaje" multiline rows={4} variant="outlined" classes={{ root: classes.messageInput }} value={review} onChange={handleChange} name="review"/>
                    <Button variant="contained" color={item.attributes.reviewed ? 'primary' : 'default'} onClick={sendReview}>Gestionada</Button>
                </div>
                {/* <div className={classes.formRow}>
                    <div className={classes.notifyInput}>
                        <p className={classes.notifyText}>Notificar a </p>
                        <TextField id="outlined-basic" label="Email" variant="outlined" fullWidth value={notify} onChange={handleChange} name="notify" />
                    </div>
                    <Button variant="contained" color={item.attributes.notified ? 'primary' : 'default'} onClick={sendNotify}>Notificada</Button>
                </div> */}

            </DialogActions>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertModalComponent)
