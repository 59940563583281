import React from 'react'
import { Snackbar } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function AlertComponent(props) {
    const { message, severity } = props

    return (
        <Snackbar
            open
            autoHideDuration={80000}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
            <Alert severity={severity} variant="filled">
                {message || 'No text'}
            </Alert>
        </Snackbar>
    )
}

export default AlertComponent
