import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    container: {
        position: 'absolute',
        zIndex: 99,
        minWidth: '100%',
    },
    paperRoot: {
        padding: 20,
        overflow: 'auto',
        maxHeight: '58vh',
    },

    selectAll: {
        display: 'flex',
        padding: '0 16px',

        '& h6': {
            paddingTop: 6,
            paddingLeft: 24,
        }
    },

    col: {
        display: 'flex'
    },
    label: {
        padding: '6px 0 4px',
    }
}))
