import React from 'react'

import { Grid, Button, Typography, SvgIcon } from '@material-ui/core'
import { ModalComponent } from '../'

function ConfirmationBuilder(props) {
  return (
    <ModalComponent open={props.open} onClose={props.onClose}>
      <Typography className="ruc-icon-modal-confirmation" variant="h3" align="center">
        <SvgIcon><path d={props.confirmIcon}></path></SvgIcon>
      </Typography>
      <Typography className="ruc-title-modal-confirmation" variant="h6" align="center">
        <span className="ruc-confirmation-question">
          ¿{props.confirmMessage}
        </span>
        <span className="ruc-confirmation-item">
          {props.children}
          <span className="ruc-confirmation-question">?</span>
        </span>
      </Typography>
      <Grid container spacing={0} direction="row" justify="center">
        <Grid item xs={4}>
          <Button color="default" variant="contained" onClick={() => props.cancelAction()}>{props.cancelText||'Cancelar'}</Button>
        </Grid>
        <Grid item xs={4}>
          <Button color="secondary" variant="contained" onClick={() => props.confirmAction()}>{props.confirmText||'Confirmar'}</Button>
        </Grid>
      </Grid>
    </ModalComponent>
  )
}

export default ConfirmationBuilder