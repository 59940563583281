/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react'
import { connect } from 'react-redux'
import { useParams, useRouteMatch, Link, useHistory } from 'react-router-dom'
import { path, isEmpty } from 'ramda'

import { Grid, TextField, FormControlLabel, Checkbox, MenuItem, Button, Typography, SvgIcon } from '@material-ui/core'
import { mdiBell, mdiPlus, mdiPencil } from '@mdi/js'

import {
  fetchAlertTemplates as _fetchAlertTemplates,
  fetchCategories as _fetchCategories,
  addAlertConfiguration as _addAlertConfiguration,
  updateAlertConfiguration as _updateAlertConfiguration,
  fetchAlertConfigurations as _fetchAlertConfigurations,
  clearAlertConfiguration as _clearAlertConfiguration
} from '../../../redux/amm/alert-configurations/actions'

import { i18n } from '../../../services/util'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  alertConfiguration: path(['alertConfigurations', 'alertConfiguration'], state),
  alertTemplates: path(['alertConfigurations', 'alertTemplates'], state),
  categories: path(['alertConfigurations', 'categories'], state),
})

const mapDispatchToProps = dispatch => ({
  fetchAlertConfiguration: (token, alertConfigurationId) => dispatch(_fetchAlertConfigurations(token, alertConfigurationId)),
  addAlertConfiguration: (payload, token, callback) => dispatch(_addAlertConfiguration(payload, token, callback)),
  updateAlertConfiguration: (id, payload, token, callback) => dispatch(_updateAlertConfiguration(id, payload, token, callback)),
  clearAlertConfiguration: () => dispatch(_clearAlertConfiguration()),
  fetchAlertTemplates: (token) => dispatch(_fetchAlertTemplates(token)),
  fetchCategories: (token) => dispatch(_fetchCategories(token)),
  fetchAlertConfigurations: (token) => dispatch(_fetchAlertConfigurations(token)),
})

function AlertConfigurationFormComponent({
  token,alertConfiguration,alertTemplates,categories,
  fetchAlertConfiguration,addAlertConfiguration,updateAlertConfiguration,clearAlertConfiguration,
  fetchAlertTemplates,fetchCategories,fetchAlertConfigurations}) {

  const { alertConfigurationId } = useParams()
  const { url } = useRouteMatch()
  const history = useHistory()

  const requiredFields = [  ]
  const alertTemplateInfo = Object.assign({}, ...(alertTemplates.map(at => ({ [at.id]: { name: at.name, description: at.alert_description } }) )) );
  const prioritiesByCategory = Object.assign({}, ...(categories.map(c => ({ [c.id]: c.priority }) )) );

  const initialValue = {
    alert_template_id: { value: '', error: null },
    description: { value: '', error: null },
    parameter: { value: '', error: null },
    category_id: { value: '', error: null },
    priority: { value: '', error: null },
    disabled: { value: false, error: null }
  }

  const [formData, setFormData] = useState(initialValue)
  
  useEffect(() => {
    fetchCategories(token)
  }, [])

  useEffect(() => {
    fetchAlertTemplates(token)
  }, [])

  useEffect(() => {
    if (url !== '/amm/alert-configurations/new' && alertConfigurationId) {
      fetchAlertConfiguration(token, alertConfigurationId)
    }

    if (url === '/amm/alert-configurations/new') {
      clearAlertConfiguration()
    }
  }, [url])

  const inheritPriority = useRef(null);
  const alertTemplateDescription = useRef(null);

  useEffect(() => {
    if (alertConfiguration !== undefined && !isEmpty(alertConfiguration)) {
      setFormData({
        alert_template_id: {
          value: alertConfiguration.alert_template_id || '',
          error: null,
        },
        description: {
          value: alertConfiguration.description || '',
          error: null,
        },
        parameter: {
          value: alertConfiguration.parameter || '',
          error: null,
        },
        category_id: {
          value: alertConfiguration.category_id || '',
          error: null,
        },
        priority: {
          value: alertConfiguration.priority || '',
          error: null,
        },
        disabled: {
          value: alertConfiguration.disabled || false,
          error: null,
        },
      })
    } else {
      setFormData(initialValue)
    }
  }, [alertConfiguration])

  function isEditUrl() {
    return url !== '/amm/alert-configurations/new' && alertConfigurationId
  }

  function handleChange(input) {
    return function(event) {

      if ( input === 'category_id') {
        const priorityKey = 'priority'
        const priorityValue = prioritiesByCategory[event.target.value]
        formData.priority.value = priorityValue
        setFormData({...formData, [priorityKey]: { value: priorityValue, error: null } })
      }

      if ( input === 'alert_template_id') {
        const alertTemplateField = alertTemplateInfo[event.target.value]
        for ( let alertTemplateKey of [ 'description' ] ) {
          formData[alertTemplateKey].value = alertTemplateField[alertTemplateKey]
          setFormData({...formData, [alertTemplateKey]: { value: alertTemplateField[alertTemplateKey], error: null } })
        }
      }

      setFormData({
        ...formData,
        [input]: {
          value: event.target.value,
          error: null,
        }
      })
    }
  }

  function checkHandleChange(event) {
    setFormData({...formData, [event.target.name]: {
      value: event.target.checked,
      error: null,
    }})
  }

  function handleSubmit() {
    const keys = Object.keys(formData)
    let hasError = false
    let payload = {}

    keys.forEach(key => {
      if (requiredFields.includes(key) && isEmpty(formData[key].value)) {
        hasError = true
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            error: 'El campo no puede estar vacio'
          }
        })
      } else {
        payload = {
          ...payload,
          [key]: formData[key].value
        }
      }
    })

    if (!hasError) {
      if (isEditUrl()) {
        updateAlertConfiguration(alertConfigurationId, payload, token)
      } else {
        addAlertConfiguration(payload, token)
      }
      fetchAlertConfigurations(token);
      history.push(`/amm/alert-configurations`)
    }
  }

  function AlertTemplateInput(props) {
    return (
      <TextField
        id="alert_template_name"
        label={i18n(['amm', 'alert-configuration', 'alert_template_id'])}
        value={alertConfiguration.alert_template_name}
        row={6}
        InputProps={{
          readOnly: true,
        }}
      />
    )
  }

  function AlertTemplateSelect(props) {
    return <TextField
      id="alert_template_id"
      fullWidth
      error={!!formData.alert_template_id.error}
      label={i18n(['amm', 'alert-configuration', 'alert_template_id'])}
      required
      multiline
      row={6}
      variant="outlined"
      select
      value={formData.alert_template_id.value}
      onChange={handleChange('alert_template_id')}
    >
      {alertTemplates.map((alertTemplate) => (
        <MenuItem key={alertTemplate.id} value={alertTemplate.id}>
          {alertTemplate.name}
        </MenuItem>)
      )}
    </TextField>
  }

  return (
    <Grid container spacing={0} direction="row" justify="center">
      <Grid item xs={6}>
        <Typography variant="h6">
          <SvgIcon className='ruc-title-first-icon'>
            <path d={isEditUrl() ? mdiPencil : mdiPlus}/>
          </SvgIcon>
          <SvgIcon><path d={mdiBell}/></SvgIcon>
          {isEditUrl() ? "Editar" : "Nueva"} Alerta
        </Typography>
        <form className="ruc-form">
          {isEditUrl() ? <AlertTemplateInput/> : <AlertTemplateSelect/>}
          <TextField
            id="description"
            fullWidth
            error={!!formData.description.error}
            label={i18n(['amm', 'alert-configuration', 'description'])}
            multiline
            row={6}
            rows={4}
            variant="outlined"
            value={formData.description.value}
            ref={alertTemplateDescription}
            disabled={true}
          />
          <Grid container spacing={3} direction="row" justify="center">
            <Grid item xs={5}>
              <TextField
                id="category_id"
                fullWidth
                error={!!formData.category_id.error}
                label={i18n(['amm', 'alert-configuration', 'category_id'])}
                required
                multiline
                row={6}
                variant="outlined"
                select
                value={formData.category_id.value}
                onChange={handleChange('category_id')}
              >
                {categories.map((category) => (
                  <MenuItem key={category.id} value={category.id}>
                    {category.name}
                  </MenuItem>)
                )}
              </TextField>
            </Grid>
            <Grid item xs={3}>
              <TextField
                id="priority"
                fullWidth
                error={!!formData.priority.error}
                label={i18n(['amm', 'alert-configuration', 'priority'])}
                multiline
                row={6}
                variant="outlined"
                autoComplete="off"
                value={formData.priority.value}
                ref={inheritPriority}
                disabled={true}
                className="last-child"
              />
            </Grid>
            <Grid item xs={4}>
              <TextField
                id="parameter"
                fullWidth
                error={!!formData.parameter.error}
                label={i18n(['amm', 'alert-template', alertConfiguration.alert_template_unit])}
                multiline
                row={6}
                variant="outlined"
                autoComplete="off"
                value={formData.parameter.value}
                onChange={handleChange('parameter')}
              />
            </Grid>
          </Grid>
          <div className="ruc-form-button-wrapper">
            <div className="ruc-form-checkbox">
              { alertConfigurationId &&
                <FormControlLabel control={<Checkbox checked={formData.disabled.value} onChange={checkHandleChange} name="disabled" color="primary" />} label="Deshabilitar"  labelPlacement="start"/>
              }
              </div>
            <div className="ruc-form-buttons">
              <Button row={6}variant="contained" color="primary" onClick={handleSubmit}>{isEditUrl() ? i18n(['amm', 'actions', 'update']) : i18n(['amm', 'actions', 'save'])}</Button>
              <Link to="/amm/alert-configurations">
                <Button row={6}variant="contained" color="default" >Volver</Button>
              </Link>
            </div>
          </div>
        </form>
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertConfigurationFormComponent)