import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useHistory, Link, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, SvgIcon } from '@material-ui/core'
import { /*mdiFormatListBulleted, */mdiBellRemove, mdiPencilCircle, mdiBell, mdiPlusBox } from '@mdi/js'

import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';

import { fetchAlertConfigurations as _fetchAlertConfigurations, deleteAlertConfiguration as _deleteAlertConfiguration } from '../../../redux/amm/alert-configurations/actions'
import { i18n as i18nService } from '../../../services/util'
import { NotFoundPage } from '../../../pages'
import { AlertConfigurationFormComponent } from '../../../components'
import { ConfirmationBuilder as RucConfirmationBuilder } from '../../../components/Ruc/'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  alertConfigurations: path(['alertConfigurations', 'alertConfigurations'], state)
})

const mapDispatchToProps = dispatch => ({
  fetchAlertConfigurations: token => dispatch(_fetchAlertConfigurations(token)),
  deleteAlertConfiguration: (alertId, token, callback) => dispatch(_deleteAlertConfiguration(alertId, token, callback))
})

function AlertConfigurationTableComponent({token,alertConfigurations,fetchAlertConfigurations,deleteAlertConfiguration}) {
  let { path, url } = useRouteMatch();
  const history = useHistory()
  const i18n = prop => i18nService(['amm',"alert-configuration",prop])
  const selectedKeys = [
    { value: 'id', label: i18n('id') },
    { value: 'name', label: i18n('name') },
    { value: 'description', label: i18n('description') },
    { value: 'category', label: i18n('category') },
    { value: 'priority', label: i18n('priority') },
    { value: 'parameter', label: i18n('parameter') },
    { value: 'alert_template_unit', label: i18n('parameter_unity') }
  ]
  const [selectedToDelete, setSelectedToDelete] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)

  useEffect(() => { fetchAlertConfigurations(token) }, [fetchAlertConfigurations,token])

  function editAlertConfigurationHandler({ id }) {
    return function () {
      history.push(`/amm/alert-configurations/${id}/edit`)
    }
  }

  function removeHanlder(alertConfiguration) {
    return function() {
      setSelectedToDelete(alertConfiguration)
      setDeleteModalShow(true)
    }
  }

  function closeRemoveModal() {
    setDeleteModalShow(false)
    setSelectedToDelete(null)
  }

  function confirmRemove() {
    deleteAlertConfiguration(selectedToDelete.id, token, () => {
      setDeleteModalShow(false)
      fetchAlertConfigurations(token)
    })
  }

  function renderRow(item, alertConfiguration) {
    let row;
    if(item.value === 'color') {
      row = <div style={{borderRadius: "15px", backgroundColor: alertConfiguration[item.value], width: '20px',height: '20px'}}/>
    } else {
      let rowValue = alertConfiguration[item.value]
      row = item.value === "alert_template_unit" ? i18nService(['amm', 'alert-template', rowValue]) : rowValue
    }
    return row
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={url}>
          <Typography variant="h6">
            {/* <SvgIcon className="ruc-title-first-icon"><path d={mdiFormatListBulleted}/></SvgIcon> */}
            <SvgIcon><path d={mdiBell}/></SvgIcon>
            Configuración de Alertas
            <Link to={`${url}/new`}>
              <IconButton className="new-class">
                <SvgIcon><path d={mdiPlusBox}/></SvgIcon>
              </IconButton>
            </Link>
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                {selectedKeys.map((column, k) => (
                  <TableCell key={k} className={`ruc-${column.value}`}>{column.label}</TableCell>
                ))}
                <TableCell className="actions two-icons">{i18n('actions')}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {alertConfigurations.map((alertConfiguration, k) => (
                <TableRow key={k} className={alertConfiguration.disabled ? 'ruc-table-row-disabled' : null }>
                  {selectedKeys.map((item, kx) => (
                    <TableCell key={kx} className={`ruc-${item.value}`}>
                      {renderRow(item, alertConfiguration)}
                    </TableCell>
                  ))}
                  <TableCell style={{ whiteSpace: 'nowrap' }} className={`actions two-icons ${alertConfiguration.disabled ? 'one-disabled' : '' }`}>
                    <Tooltip title={i18nService(['amm','actions','edit'])}>
                      <IconButton onClick={editAlertConfigurationHandler(alertConfiguration)}>
                        <SvgIcon><path d={mdiPencilCircle}></path></SvgIcon>
                      </IconButton>
                    </Tooltip>
                    {alertConfiguration.disabled ? null :
                    <Tooltip title={i18nService(['amm','tooltips',alertConfiguration.used ? 'used-item' : 'delete'])}>
                      <IconButton onClick={alertConfiguration.used ? (()=>{}) : removeHanlder(alertConfiguration)}>
                        {alertConfiguration.used ? <BlockIcon /> : <DeleteIcon />}
                      </IconButton>
                    </Tooltip>}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Route>
        <Route path={`${path}/new`}>
          <AlertConfigurationFormComponent />
        </Route>
        <Route exact path={`${path}/:alertConfigurationId/edit`}>
          <AlertConfigurationFormComponent />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
      <RucConfirmationBuilder 
        open={deleteModalShow}
        onClose={closeRemoveModal}
        confirmIcon={mdiBellRemove}
        confirmMessage="Seguro que desea eliminar"
        cancelAction={closeRemoveModal}
        confirmAction={confirmRemove}
      >{selectedToDelete && selectedToDelete.name}</RucConfirmationBuilder>
    </React.Fragment>
  )
}

AlertConfigurationTableComponent.propTypes = {
  alertConfigurations: PropTypes.array.isRequired
}

AlertConfigurationTableComponent.defaultProps = {
  alertConfigurations: []
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertConfigurationTableComponent)
