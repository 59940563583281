import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'
import { path, isEmpty } from 'ramda'
import { TextField, Button, Typography, Checkbox, FormControlLabel } from '@material-ui/core'

import { addPermission as _addPermission, updatePermission as _updatePermission,
    fetchPermissions as _fetchPermissions } from '../../../redux/permissions/actions'
import styles from './styles'
import { i18n } from '../../../services/util'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    permission: path(['permissions', 'permission'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchPermission: (token, permissionId) => dispatch(_fetchPermissions(token, permissionId)),
    addPermission: (payload, token, callback) => dispatch(_addPermission(payload, token, callback)),
    updatePermission: (id, payload, token, callback) => dispatch(_updatePermission(id, payload, token, callback)),
})

function AddPermissionsComponent({
    token,
    permission,

    fetchPermission,
    addPermission,
    updatePermission,
}) {
    const classes = styles()
    const { permissionId } = useParams()
    const { url } = useRouteMatch()

    const initialValue = {
        name: { value: '', error: null },
        controller_path: { value: '', error: null },
        description: { value: '', error: null },
        has_crud: { value: false, error: null }
    }

    const [formData, setFormData] = useState(initialValue)

    useEffect(() => {
        if (url !== '/admin/permissions/new' && permissionId) {
            fetchPermission(token, permissionId)
        }

        if (url === '/admin/permissions/new') {
            setFormData(initialValue)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [url])

    useEffect(() => {
        if (!isEmpty(permission)) {
            setFormData({
                name: {
                    value: permission.name,
                    error: null,
                },
                controller_path: {
                    value: permission.controller_path,
                    error: null,
                },
                description: {
                    value: permission.description,
                    error: null,
                },
                has_crud: {
                    value: permission.has_crud,
                    error: null,
                }
            })
        }
    }, [permission])

    function isEditUrl() {
        return url !== '/admin/permissions/new' && permissionId
    }

    function handleChange(input) {
        return function(event) {
            setFormData({
                ...formData,
                [input]: {
                    value: event.target.value,
                    error: null,
                }
            })
        }
    }

    function checkHandleChange(event) {
        setFormData({...formData, [event.target.name]: {
            value: event.target.checked,
            error: null,
        }})
    }

    function handleSubmit() {
        const keys = Object.keys(formData)
        let hasError = false
        let payload = {}

        keys.forEach(key => {
            if (isEmpty(formData[key].value)) {
                hasError = true
                setFormData({
                    ...formData,
                    [key]: {
                        ...formData[key],
                        error: 'El campo no puede estar vacio'
                    }
                })
            } else {
                payload = {
                    ...payload,
                    [key]: formData[key].value
                }
            }
        })

        if (!hasError) {
            if (isEditUrl()) {
                updatePermission(permissionId, payload, token)
            } else {
                addPermission(payload, token)
            }
        }
    }

    function clear() {
        setFormData(initialValue)
    }

    return (
        <React.Fragment>
            <form className={classes.formContainer}>
                <Typography variant="h5" align="center">{isEditUrl() ? i18n(['admin', 'permissions_form', 'title_edit']) : i18n(['admin', 'permissions_form', 'title_new'])}</Typography>
                <TextField
                    id="name"
                    fullWidth
                    error={!!formData.name.error}
                    label={i18n(['admin', 'permissions_form', 'input_placeholder_name'])}
                    required
                    variant="outlined"
                    value={formData.name.value}
                    onChange={handleChange('name')}
                />

                <TextField
                    id="controller_path"
                    fullWidth
                    error={!!formData.controller_path.error}
                    label={i18n(['admin', 'permissions_form', 'input_placeholder_controller_url'])}
                    required
                    variant="outlined"
                    value={formData.controller_path.value}
                    onChange={handleChange('controller_path')}
                />

                <TextField
                    id="description"
                    fullWidth
                    error={!!formData.description.error}
                    label={i18n(['admin', 'permissions_form', 'input_placeholder_description'])}
                    required
                    multiline
                    row={6}
                    variant="outlined"
                    value={formData.description.value}
                    onChange={handleChange('description')}
                />

                <FormControlLabel
                    control={<Checkbox checked={formData.has_crud.value} onChange={checkHandleChange} name="has_crud" color="primary" />}
                    label={i18n(['admin', 'permissions_form', 'input_check_label_has_crud'])}
                />

                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={handleSubmit}>{isEditUrl() ? i18n(['admin', 'permissions_form', 'button_edit']) : i18n(['admin', 'permissions_form', 'button_new'])}</Button>
                    <Button className={classes.button} variant="contained" onClick={clear}>{i18n(['admin', 'permissions_form', 'button_clear'])}</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddPermissionsComponent)
