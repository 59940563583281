import React, { useState, useEffect } from 'react'
import { Table, TableBody, TableRow, TableCell } from '@material-ui/core'
import { filterDataParser, getCurrentDate } from '../../services/util'
import styles from './styles'

function ResumeTableComponent({ 
    stats, 
    // Filters Slect Data
    filtersByView,
    // Views
    activeSource,
    // Set Filters
    qs, setqs,
    // Events
    setActiveSource, submit, toggleFilter, history
}) {
    const classes = styles()
    const [clicked, setClicked] = useState(false)

    useEffect(() => {
        if(clicked) {
            setActiveSource('items')
            history.push('/routes/table')
            submit(filterDataParser(qs, filtersByView, activeSource), qs)
            setClicked(false)
            toggleFilter()
        }
        
    },[clicked, setActiveSource, history, submit, qs, filtersByView, activeSource, toggleFilter])

    function selectHandleChange(stats_type, stats_label) {
        setClicked(true)
        return (
            setqs({...qs, 
                'by_item_status': {name: stats_label, value: stats_type},
                'with_routes': false,
                'by_starts_at[from]': getCurrentDate('-',false, "00:00:00"),
                'by_starts_at[to]': getCurrentDate('-',false, "23:59:59")
            })
        )
    }

    return (
        <Table className={classes.resumeTable}>
            <TableBody>
                {stats.map((item, k) => (
                    <TableRow key={k}>
                        <TableCell className={classes.cellTitle} style={{background:  item.color, cursor: 'pointer'}} onClick={() => selectHandleChange(item.stats_type, item.label)}>{item.label}</TableCell>
                        <TableCell>{item.value.count}</TableCell>
                        <TableCell>{parseFloat(item.value.percentage).toFixed(2)}%</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

export default ResumeTableComponent
