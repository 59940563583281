/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { or, isEmpty, isNil, pathOr, omit } from 'ramda'
import { Paper, Table, TableHead, TableBody, TableRow, TableCell } from '@material-ui/core'

import { TableCellComponent, PaginatorComponent } from '../..'
import { i18n } from '../../../services/util'
import styles from './styles'

const schemas = {
    table: {
        routes: [
            {label: 'cluster_name', type: 'string', path: ['meta', 'table', 'cluster_name']},
            {label: 'depot_nid', type: 'string', path: ['attributes', 'depot_nid']},
            {label: 'uid', type: 'string', path: ['attributes', 'uid']},
            {label: 'starts_at', type: 'date', path: ['attributes', 'starts_at']},
            {label: 'vehicle_nid', type: 'string', path: ['attributes', 'vehicle_nid']},
            {label: 'vehicle_driver_name', type: 'string', path: ['attributes', 'vehicle_driver_name']},
            {label: 'delivered', type: 'string-nested', path: [['meta', 'table', 'items', 'delivered'], ['meta', 'table', 'items', 'total']], divider: ' / '},
            {label: 'starts_at_diff', type: 'time-nested', path: [['attributes', 'starts_at'], ['meta', 'table', 'starts_at_diff']], divider: ' ', tooltip: ['attributes', 'started_at'], align: 'center'},
            {label: 'last_point_punctuality', type: 'time-nested', path: [['meta', 'table', 'last_point_arrives_at'], ['meta', 'table', 'last_point_punctuality']], divider: ' ', tooltip: ['meta', 'table', 'last_point_arrived_at'], align: 'center'},
            {label: 'time_in_route', type: 'string', path: ['meta', 'table', 'time_in_route']},
            {label: 'kms_in_route', type: 'string', path: ['meta', 'table', 'kms_in_route']},
            {label: 'gps_status', type: 'string', path: ['attributes', 'gps_status']},
            {label: 'route_status', type: 'string', path: ['attributes', 'route_status']},
            {label: 'progress', type: 'progress-table'},
            {label: 'observations', type: 'observations'},
            {label: 'details', type: 'details', mapLink: true, showItems: { path: ['attributes', 'uid'], filter: 'by_route_nid', redirect: 'items'}},
        ],
        items: [
            {label: 'cluster_name', type: 'string', path: ['meta', 'table', 'cluster_name']},
            {label: 'depot_nid', type: 'string', isRelation: true, relationType: 'route', path: ['attributes', 'depot_nid'] },
            {label: 'uid', type: 'string', isRelation: true, relationType: 'route', path: ['attributes', 'uid'] },
            {label: 'profile_nid', type: 'string', path: ['attributes', 'profile_nid']},
            {label: 'profile_name', type: 'string', path: ['attributes', 'profile_name']},
            {label: 'document_id', type: 'string', path: ['attributes', 'document_id']},
            {label: 'starts_at', type: 'date', isRelation: true, relationType: 'route', path: ['attributes', 'starts_at'] },
            {label: 'vehicle_license_plate', type: 'string', isRelation: true, relationType: 'route', path: ['attributes', 'vehicle_license_plate'] },
            {label: 'vehicle_driver_name', type: 'string', isRelation: true, relationType: 'route', path: ['attributes', 'vehicle_driver_name'] },
            {label: 'item_order', type: 'string', path: ['attributes', 'item_order']},
            {label: 'arrives_at', type: 'time-string', path: ['attributes', 'arrives_at']},
            {label: 'arrived_at', type: 'time-string', path: ['attributes', 'arrived_at']},
            {label: 'arrived_at_diff', type: 'time-string', path: ['attributes', 'arrived_at_diff']},
            {label: 'last_event_distance_diff', type: 'string', path: ['attributes', 'last_event_distance_diff']},
            {label: 'last_status', type: 'string', path: ['attributes', 'last_status']},
            {label: 'itinerary_events_url', type: 'comments', path: ['attributes', 'last_status_comments'], image: ['attributes', 'itinerary_events_url']},
            {label: 'observations', type: 'observations'},
            {label: 'details', type: 'details', mapLink: true},
        ],
        vehicles: [
            { label: 'license_plate', type: 'string', path: ['attributes', 'license_plate']},
            { label: 'route_id', type: 'string', path: ['meta', 'table', 'route_id']},
            { label: 'capacity0', type: 'string', path: ['attributes', 'capacity0']},
            { label: 'route_avg_load', type: 'string', path: ['meta', 'table', 'route_avg_load']},
            { label: 'delivered_load', type: 'string', path: ['meta', 'table', 'delivered_load']},
            { label: 'route_status', type: 'string', path: ['meta', 'table', 'route_status']},
            { label: 'planned_start_date', type: 'time-nested', path: [['meta', 'table', 'planned_start_date'], ['meta', 'table', 'start_date_difference']], divider: ' ', tooltip: ['meta', 'table', 'real_start_date']},
            { label: 'route_ends_at', type: 'time-string', path: ['meta', 'table', 'route_ends_at']},
        ],
        mobiles: [
            { label: 'description', type: 'string', path: ['attributes', 'description']},
            { label: 'vehicle_license_plate', type: 'string', path: ['meta', 'table', 'vehicle_license_plate']},
            { label: 'route_id', type: 'string', path: ['meta', 'table', 'route_id']},
            { label: 'route_status', type: 'string', path: ['meta', 'table', 'route_status']},
            { label: 'planned_start_date', type: 'time-nested', path: [['meta', 'table', 'planned_start_date'], ['meta', 'table', 'start_date_difference']], divider: ' ', tooltip: ['meta', 'table', 'real_start_date']},
            { label: 'route_ends_at', type: 'time-string', path: ['meta', 'table', 'route_ends_at']},
        ]
    },

    detail: {
        routes: [
            {label: 'load', type: 'string', path: ['attributes', 'data', 'load']},
            {label: 'depot_nid', type: 'string', path: ['attributes', 'data', 'depot_nid']},
            {label: 'driver_nid', type: 'string', path: ['attributes', 'data', 'driver_nid']},
            {label: 'driver_helper_nid', type: 'string', path: ['attributes', 'data', 'driver_helper_nid']},
            {label: 'vehicle_nid', type: 'string', path: ['attributes', 'data', 'vehicle_nid']},
            {label: 'doc_created_at', type: 'string', path: ['attributes', 'data', 'doc_created_at']},
        ],
        items: [
            {label: 'code', type: 'string', path: ['attributes', 'code']},
            {label: 'description', type: 'string', path: ['attributes', 'description']},
            {label: 'depot_nid', type: 'string', path: ['attributes', 'depot_nid']},
            {label: 'load', type: 'string', path: ['attributes', 'load']},
            {label: 'load1', type: 'string', path: ['attributes', 'load1']},
            {label: 'load2', type: 'string', path: ['attributes', 'load2']},
        ]
    }
}

function RoutesTableComponent({ layers, activeSource, colors, paginatorChange, onFilter }) {
    const classes = styles()
    const [tableSchema, setTableSchema] = useState([])
    const [detailSchema, setDetailSchema] = useState([])
    const [data, setData] = useState({})
    const [source, setSource] = useState('')
    const [details, setDetails] = useState({})
    const [comments, setComments] = useState({})

    useEffect(() => {
        setData(layers[activeSource])
        setSource(activeSource)
        setTableSchema(schemas.table[activeSource])
        setDetailSchema(schemas.detail[activeSource])
    }, [layers])

    function detailsHandler (route) {
        return () => {
            const getDetails = pathOr([], ['relationships', route.type === 'route' ? 'histories' : 'item_details', 'data'])
            if (!details[route.id]) {
                setDetails({
                    ...details,
                    [route.id]: getDetails(route)
                })
            } else {
                setDetails(omit([route.id], details))
            }
        }
    }

    function itemRelations (item, data, type) {
        return data.included
            .find(i => item.relationships[type].data.id === i.id && i.type === type)
    }

    function commentsHandler(id, text) {
        return () => {
            if (!comments[id]) {
                setComments({
                    ...comments,
                    [id]: text
                })
            } else {
                setComments(omit([id], comments))
            }

        }
    }

    return (
        <React.Fragment>
            <Paper classes={{ root: classes.paperRoot }}>
                <Table>
                    <TableHead>
                        <TableRow>
                            {tableSchema.map((item, key) => <TableCell key={key}>{i18n(['routes', source + '_table', item.label])}</TableCell>)}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {!or(isEmpty(data), isNil(data)) && data.data.map((route, k) => {
                            return (
                                <React.Fragment key={k}>
                                    <TableRow className={k % 2 ? classes.evenRow : classes.oddRow}>
                                        {tableSchema.map((schemaItem, key) => {
                                            let item = route
                                            if (schemaItem.isRelation) {
                                                item = itemRelations(route, data, schemaItem.relationType)
                                            }
                                            return <TableCellComponent
                                                        key={key}
                                                        colors={colors}
                                                        data={item}
                                                        {...schemaItem}
                                                        showDetails={detailsHandler}
                                                        onFlter={onFilter}
                                                        showComments={commentsHandler}
                                                        />
                                        })}
                                    </TableRow>

                                    {comments[route.id] && (
                                        <TableRow className={classes.backgroundWrapperCell}>
                                            <TableCell colSpan={tableSchema.length} className={classes.wrapperCell}>
                                                <Table size="small">
                                                    <TableBody>
                                                        <TableRow className={classes.detailsWrapperCell}>
                                                            <TableCell colSpan={route.length}>{comments[route.id]}</TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    )}

                                    {details[route.id] && details[route.id].length > 0 && (
                                        <TableRow className={classes.backgroundWrapperCell}>
                                            <TableCell colSpan={tableSchema.length} className={classes.wrapperCell}>
                                                <Table size="small">
                                                    <TableHead>
                                                        <TableRow className={classes.nestedHead}>
                                                            {detailSchema.map((item, key) => <TableCell key={key} className={classes.nestedTh}>{i18n(['routes', activeSource + '_detail', item.label])}</TableCell>)}
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {details[route.id].map((routeDetail) => {
                                                            return pathOr([], ['included'], data).filter(item => item.type === routeDetail.type && item.id === routeDetail.id).map((detail, key) => {
                                                                return (
                                                                    <TableRow key={key} className={classes.detailsWrapperCell}>
                                                                        {detailSchema.map((detailItem, key) => <TableCellComponent key={key} colors={colors} data={detail} {...detailItem} />)}
                                                                    </TableRow>
                                                                )
                                                            })
                                                        })}
                                                    </TableBody>
                                                </Table>
                                            </TableCell>
                                        </TableRow>
                                    )}
                                </React.Fragment>
                            )
                        })}
                    </TableBody>
                </Table>
            </Paper>
            <PaginatorComponent onChange={paginatorChange} {...pathOr({}, ['pagination'], data)}/>
        </React.Fragment>
    )
}

export default RoutesTableComponent
