import { mergeRight } from 'ramda'

import {
    GET_ALL_START,
    GET_ALL_SUCCESS,
    GET_ALL_ERROR,
    GET_ALL_ITEMS_SUCCESS,
    GET_FILTERS_START,
    GET_FILTERS_SUCCESS,
    GET_FILTERS_ERROR,
} from './actions'

const initialState = {
    requestStatus: 'none',
    errorMessage: null,
    active: true,
    response: null,
    filtersData: null,
}

export default function routesViewReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)
    switch (type) {
        case GET_ALL_START:
        case GET_FILTERS_START:
            return merge({ requestStatus: 'start' })
        case GET_ALL_SUCCESS:
            return merge({ requestStatus: 'success', response: payload })
        case GET_ALL_ITEMS_SUCCESS:
            return merge({ requestStatus: 'success', itemsResponse: payload })
        case GET_FILTERS_SUCCESS:
            return merge({ filtersData: payload })
        case GET_ALL_ERROR:
        case GET_FILTERS_ERROR:
            return merge({ requestStatus: 'error', errorMessage: 'error' })
        default:
            return state
    }
}
