import { mergeRight } from 'ramda'
import { GET_CSV_ALERTS_SUCCESS } from '../actions'

const initialState = { response: [] }

export default function ammAlertsCsvReducer(state = initialState, action) {
  const { type, payload } = action
  const merge = mergeRight(state)
  switch (type) {
    case GET_CSV_ALERTS_SUCCESS:
      return merge({ response: payload })
    default:
      return state
  }
}