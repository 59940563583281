import { makeStyles } from '@material-ui/core'

export const title = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}))

export const autocomplete = makeStyles(theme => ({
    root: {
        width: '100%'
    }
}))

export const buttonContainer = makeStyles(theme => ({
    root: {
        justifyContent: 'space-evenly',
        paddingTop: 15,
    }
}))

export const list = makeStyles(theme => ({
    root: {
        '& li': {
            paddingLeft: 20
        }
    }
}))

export const sourceGroup = makeStyles(theme => ({
    root: {
        margin: 'auto',
        minWidth: '22rem',
    },
    grouped: {
        whiteSpace: 'nowrap'
    }
}))

export const sourceControlWrapper = makeStyles(theme => ({
    root: {
        paddingTop: 15,
        paddingBottom: 11,
    },
}))