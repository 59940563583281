import React, { useState } from 'react'
import { connect } from 'react-redux'
import { path, pathOr } from 'ramda'
import { format } from 'date-fns'
import classnames from 'classnames'
import { Paper, TableBody, TableCell, Table, TableRow, IconButton, Button, TableHead, Tooltip } from '@material-ui/core'
import * as mdi from '@mdi/js'
import { mdiAccount, mdiGauge, mdiFileDocument, mdiTimerOutline, mdiAlert, mdiMapMarkerPath, mdiMapMarkerCheck,
    mdiPackageVariantClosed, mdiClockOutline, mdiMapMarker, mdiCamera, mdiChevronDown, mdiChevronUp, mdiMapMarkerAlert } from '@mdi/js'

import { SvgIconComponent, ProgressComponent } from '../..'
import { i18n } from '../../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    colors: path(['uiConfig', 'data', 'attributes', 'app_data', 'graphics'], state),
    layers: path(['uiConfig', 'data', 'attributes', 'app_data', 'mapLayers'], state),
    header: path(['routeDetail', 'response', 'data', 'meta', 'left_panel', 'header'], state),
    items: path(['routeDetail', 'response', 'data', 'meta', 'left_panel', 'items'], state),
    gpsStatus: path(['routeDetail', 'response', 'data', 'attributes', 'gps_status'], state),
})

function StatsFloatPanelComponent(props) {
    const {colors, header, items, layers, gpsStatus, selectedItem} = props
    const classes = styles()
    const [details, setDetails] = useState([])
    const [showTable, setShowTable] = useState(true)

    function toggleDetail(item) {
        const { item_order } = item
        return function() {
            const idx = details.indexOf(item_order)
            if (idx === -1) {
                selectedItem(item)
                setDetails([...details, item_order])
            } else {
                selectedItem({})
                setDetails(details.filter(item => item !== item_order))
            }
        }
    }

    function parseTime(item) {
        return format(new Date(item), 'HH:mm')
    }

    function toggleTable() {
        setShowTable(!showTable)
    }

    function getPercent(value) {
        return (parseFloat(pathOr('0', ['events', value], header)) / parseFloat(pathOr('0', ['events', 'total'], header)) * 100).toFixed(2)
    }

    return (
        <div className={classes.container}>
            <Paper>
                <Table>
                    <TableBody>
                        <TableRow className={classes.tr}>
                            <TableCell>
                                <SvgIconComponent path={mdiAccount} />
                                <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'client'])}>
                                    <p>{pathOr(0, ['items', 'delivered'], header)}/{pathOr(0, ['items', 'total'], header)}</p>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <SvgIconComponent path={mdiGauge} />
                                <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'distanceUnit'])}>
                                    <p>{pathOr(0, ['kilometers', 'traveled'], header)}/{pathOr(0, ['kilometers', 'total'], header)}</p>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tr}>
                            <TableCell>
                                <SvgIconComponent path={mdiFileDocument} />
                                <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'document'])}>
                                    <p>{pathOr(0, ['documents', 'with_documents'], header)}/{pathOr(0, ['documents', 'total'], header)}</p>
                                </Tooltip>
                            </TableCell>
                            <TableCell>
                                <SvgIconComponent path={mdiTimerOutline} />
                                <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'timeUnit'])}>
                                    <p>{pathOr(0, ['time', 'past'], header)}/{pathOr(0, ['time', 'total'], header)}</p>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tr}>
                            <TableCell colSpan={2}>
                                <ProgressComponent sections={[
                                    { color: colors.pending, percentage: getPercent('pending') },
                                    { color: colors.failure, percentage: getPercent('failure') },
                                    { color: colors.success, percentage: getPercent('success') },
                                    { color: colors.partial, percentage: getPercent('partial') },
                                ]} />
                            </TableCell>
                        </TableRow>
                        <TableRow className={classes.tr}>
                        <TableCell colSpan={2}>
                                <div className={classes.spaceBetween}>
                                    <div>
                                        <SvgIconComponent path={mdiMapMarkerPath} />
                                        <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'route_status'])}>
                                            <p>{i18n(['routes', 'table_content', pathOr('', ['route_status'], header)])}</p>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <SvgIconComponent path={mdiAlert} />
                                        <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'alerts'])}>
                                            <p>{pathOr(0, ['alerts'], header)}</p>
                                        </Tooltip>
                                    </div>
                                    <div>
                                        <SvgIconComponent
                                            path={["action","error","inherit","primary","secondary"].includes(gpsStatus) ? mdiMapMarkerCheck : mdiMapMarkerAlert }
                                            color={["action","error","inherit","primary","secondary"].includes(gpsStatus) ? gpsStatus : 'disabled'}
                                        />
                                        <Tooltip placement="top" title={i18n(['routes', 'routes_detail', 'gps_status'])}>
                                            <p>{i18n(['routes', 'table_content', `${gpsStatus}`.toLowerCase()], gpsStatus)}</p>
                                        </Tooltip>
                                    </div>
                                </div>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
            <IconButton className={classes.button} color="primary" onClick={toggleTable}>
                <SvgIconComponent path={showTable ? mdiChevronUp : mdiChevronDown} />
            </IconButton>
            {showTable && (
                <Paper className={classes.itemsContainer}>
                    <Table className={classes.tableItems}>
                        <TableBody>
                            {items && items.map((item, key) => {
                                const buttonColor = (item.status && colors[item.status.toLowerCase()]) ? colors[item.status.toLowerCase()] : colors['pending']
                                return (
                                    <React.Fragment key={key}>
                                        <TableRow className={key % 2 ? classes.even : classes.odd}>
                                            <TableCell className={classes.itemLeft}>
                                                <Button
                                                    variant="contained"
                                                    style={{backgroundColor: buttonColor, color: '#fff'}}
                                                    className={classes.numberButton}
                                                    onClick={toggleDetail(item)}
                                                >
                                                    {['DEPOT', 'RETURN_TO_DEPOT'].includes(item.item_type) ? (
                                                        <SvgIconComponent path={mdi[layers.depot.icon.name] || mdi[layers.default.icon.name]} />
                                                    ) : item.item_order + 1}
                                                </Button>
                                                <p style={ item.arrived_at ? {} : { marginLeft: '.8rem'}}>
                                                    {item.arrived_at ? format(new Date(item.arrived_at), 'dd/MM HH:mm') : '--'}
                                                </p>
                                            </TableCell>
                                            <TableCell>
                                                <div className={classes.itemsTop}>
                                                    <div><p>{item.item_nid}</p></div>
                                                    <div><SvgIconComponent path={mdiPackageVariantClosed} /><p>{item.load}</p></div>
                                                </div>
                                                <div className={classes.itemBottom}>
                                                    <div><SvgIconComponent path={mdiClockOutline} /><p>{item.arrived_at_diff}</p></div>
                                                    <div><SvgIconComponent path={mdiMapMarker} /><p>{item.distance_diff}</p></div>
                                                    <a
                                                        href={item.itinerary_events_url}
                                                        className={classnames({ [classes.linkDisabled]: !item.event_has_images })}
                                                        rel="noopener noreferrer"
                                                        target="_blank"
                                                    ><SvgIconComponent path={mdiCamera}  /></a>
                                                </div>
                                            </TableCell>
                                        </TableRow>
                                        {details.includes(item.item_order) && (
                                            <TableRow className={classes.detailSection}>
                                                <TableCell colSpan={2}>
                                                    <Table className={classes.nestedTable}>
                                                        <TableHead className={classes.nestedHead}>
                                                            <TableRow>
                                                                <TableCell></TableCell>
                                                                <TableCell>Llegue</TableCell>
                                                                <TableCell>Visito</TableCell>
                                                                <TableCell>TP</TableCell>
                                                            </TableRow>
                                                        </TableHead>
                                                        <TableBody>
                                                            <TableRow>
                                                                <TableCell>Plan</TableCell>
                                                                <TableCell>
                                                                    <p>{item.arrives_at ? parseTime(item.arrives_at) : '--'}</p>
                                                                    {<a
                                                                        href={item.profile_google_maps_url}
                                                                        className={classnames({ [classes.linkDisabled]: !item.profile_google_maps_url })}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">
                                                                        <SvgIconComponent path={mdiMapMarker} />
                                                                    </a>}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <p>{item.departs_at ? parseTime(item.departs_at) : '--'}</p>
                                                                    {<a
                                                                        href={item.profile_google_maps_url}
                                                                        className={classnames({ [classes.linkDisabled]: !item.profile_google_maps_url })}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">
                                                                        <SvgIconComponent path={mdiMapMarker} />
                                                                    </a>}
                                                                </TableCell>
                                                                <TableCell>{item.planned_stop_time || '--'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Real</TableCell>
                                                                <TableCell>
                                                                    <p>{item.arrived_at ? parseTime(item.arrived_at) : '--'}</p>
                                                                    {<a
                                                                        href={item.arrival_google_maps_url}
                                                                        className={classnames({ [classes.linkDisabled]: !item.arrival_google_maps_url })}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">
                                                                        <SvgIconComponent path={mdiMapMarker} />
                                                                    </a>}
                                                                </TableCell>
                                                                <TableCell>
                                                                    <p>{item.visited_at ? parseTime(item.visited_at) : '--'}</p>
                                                                    {<a
                                                                        href={item.event_google_maps_url}
                                                                        className={classnames({ [classes.linkDisabled]: !item.event_google_maps_url })}
                                                                        rel="noopener noreferrer"
                                                                        target="_blank">
                                                                            <SvgIconComponent path={mdiMapMarker} />
                                                                    </a>}
                                                                </TableCell>
                                                                <TableCell>{item.real_stop_time || '--'}</TableCell>
                                                            </TableRow>
                                                            <TableRow>
                                                                <TableCell>Delta</TableCell>
                                                                <TableCell>
                                                                    <p>{item.arrived_at_diff || '--'}</p>
                                                                    <p>{item.arrival_distance_diff}</p>
                                                                </TableCell>
                                                                <TableCell>
                                                                    <p>{item.visited_at_diff || '--'}</p>
                                                                    <p>{item.distance_diff}</p>
                                                                </TableCell>
                                                                <TableCell>{item.stop_time_diff || '--'}</TableCell>
                                                            </TableRow>
                                                        </TableBody>
                                                    </Table>
                                                </TableCell>
                                            </TableRow>
                                        )}
                                    </React.Fragment>
                                )
                            })}
                        </TableBody>
                    </Table>
                </Paper>
            )}
        </div>
    )
}

export default connect(mapStateToProps)(StatsFloatPanelComponent)
