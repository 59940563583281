import { get, post, put, del } from '../../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../../models/htmlMessage'

// Actions types
export const GET_ALERT_TEMPLATES_START = 'GET_ALERT_TEMPLATES_START'
export const GET_ALERT_TEMPLATES_SUCCESS = 'GET_ALERT_TEMPLATES_SUCCESS'
export const GET_ALERT_TEMPLATE_SUCCESS = 'GET_ALERT_TEMPLATE_SUCCESS'
export const GET_ALERT_TEMPLATES_ERROR = 'GET_ALERT_TEMPLATES_ERROR'

export const ADD_ALERT_TEMPLATE_START = 'ADD_ALERT_TEMPLATE_START'
export const ADD_ALERT_TEMPLATE_SUCCESS = 'ADD_ALERT_TEMPLATE_SUCCESS'
export const ADD_ALERT_TEMPLATE_ERROR = 'ADD_ALERT_TEMPLATE_ERROR'

export const UPDATE_ALERT_TEMPLATE_START = 'UPDATE_ALERT_TEMPLATE_START'
export const UPDATE_ALERT_TEMPLATE_SUCCESS = 'UPDATE_ALERT_TEMPLATE_SUCCESS'
export const UPDATE_ALERT_TEMPLATE_ERROR = 'UPDATE_ALERT_TEMPLATE_ERROR'

export const DELETE_ALERT_TEMPLATE_START = 'DELETE_ALERT_TEMPLATE_START'
export const DELETE_ALERT_TEMPLATE_SUCCESS = 'DELETE_ALERT_TEMPLATE_SUCCESS'
export const DELETE_ALERT_TEMPLATE_ERROR = 'DELETE_ALERT_TEMPLATE_ERROR'

export const CLEAR_ALERT_TEMPLATE = 'CLEAR_ALERT_TEMPLATE'

// Actions creators
export const clearAlertTemplate = () => ({
    type: CLEAR_ALERT_TEMPLATE
})

// Promisses
export const fetchAlertTemplatesPromise = async (token, alertTemplateId = '') => get(`amm/alert-templates/${alertTemplateId}`, '', {Authorization: token})
export const addAlertTemplatePromise = async (body, token) => post('amm/alert-templates', body, {Authorization: token})
export const updateAlertTemplatePromise = async (id, body, token) => put(`amm/alert-templates/${id}`, body, {Authorization: token})
export const deleteAlertTemplatePromise = async (id, token) => del(`amm/alert-templates/${id}`, {Authorization: token})

// Async actions creators
export const fetchAlertTemplates = (token, alertTemplateId) => async dispatch => {
    dispatch({ type: GET_ALERT_TEMPLATES_START })
    dispatch(requestPending())
    try {
        const response = await fetchAlertTemplatesPromise(token, alertTemplateId)

        dispatch(requestSuccess())

        if (!!alertTemplateId) {
            dispatch({ type: GET_ALERT_TEMPLATE_SUCCESS, payload: response.data })
        } else {
            dispatch({ type: GET_ALERT_TEMPLATES_SUCCESS, payload: response })
        }
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_ALERT_TEMPLATES_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const addAlertTemplate = (body, token) => async dispatch => {
    dispatch({ type: ADD_ALERT_TEMPLATE_START })
    dispatch(requestPending())

    try {
        const payload = await addAlertTemplatePromise({ alert_template: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_ALERT_TEMPLATE_SUCCESS })
        const response = await fetchAlertTemplatesPromise(token)
        dispatch(requestSuccess())
        dispatch({ type: GET_ALERT_TEMPLATES_SUCCESS, payload: response })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_ALERT_TEMPLATE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateAlertTemplate = (id, body, token) => async dispatch => {
    dispatch({ type: UPDATE_ALERT_TEMPLATE_START })
    dispatch(requestPending())

    try { 
        const payload = await updateAlertTemplatePromise(id, { alert_template: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_ALERT_TEMPLATE_SUCCESS })

        const response = await fetchAlertTemplatesPromise(token)
        dispatch(requestSuccess())
        dispatch({ type: GET_ALERT_TEMPLATES_SUCCESS, payload: response })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ALERT_TEMPLATE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deleteAlertTemplate = (id, token, callback) => async dispatch => {
    dispatch({ type: DELETE_ALERT_TEMPLATE_START })
    dispatch(requestPending())

    try {
        const payload = await deleteAlertTemplatePromise(id, token)

        dispatch(requestSuccess())
        dispatch({ type: DELETE_ALERT_TEMPLATE_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: DELETE_ALERT_TEMPLATE_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}