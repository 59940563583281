import { get, post, put } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../models/htmlMessage'

// Action Types

export const GET_START = 'UI_CONFIG_GET_START'
export const GET_SUCCESS = 'UI_CONFIG_GET_SUCCESS'
export const GET_ERROR = 'UI_CONFIG_GET_ERROR'
export const ADD_START = 'UI_CONFIG_ADD_START'
export const ADD_SUCCESS = 'UI_CONFIG_ADD_SUCCESS'
export const ADD_ERROR = 'UI_CONFIG_ADD_ERROR'
export const UPDATE_START = 'UI_CONFIG_UPDATE_START'
export const UPDATE_SUCCESS = 'UI_CONFIG_UPDATE_SUCCESS'
export const UPDATE_ERROR = 'UI_CONFIG_UPDATE_ERROR'

// Actions type old
export const UPDATE = 'UI_CONFIG_UPDATE'
export const UPDATE_DEPOT_CONFIG = 'UI_UPDATE_DEPOT_CONFIG'
export const DEFAULT_DEPOT_CONFIG = 'UI_DEFAULT_DEPOT_CONFIG'

// Actions creators

export const updateConfig = payload => dispatch => {
    dispatch({
        type: UPDATE,
        payload
    })
}

export const updateDeptConfig = payload => ({
    type: UPDATE_DEPOT_CONFIG,
    payload
})

export const defaultDeptConfig = () => ({
    type: DEFAULT_DEPOT_CONFIG
})

const fetchConfigurationsPromise = async (token, querystring) => get('app_configurations/', querystring || '', {Authorization: token})
const addConfigurationsPromise = async (body, token) => post('app_configurations', body, {Authorization: token})
const updateConfigurationsPromise = async (id, body, token) => put(`app_configurations/${id}`, body, {Authorization: token})

export const fetchConfigurations = (token, querystring) => async dispatch => {
    dispatch({ type: GET_START })
    dispatch(requestPending())

    try {
        const payload = await fetchConfigurationsPromise(token, querystring)

        dispatch({
            type: GET_SUCCESS,
            payload,
        })

        dispatch(requestSuccess())

    } catch (error) {
        console.error(error)
        dispatch({ type: GET_ERROR })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const addConfigurations = (body, token, callback) => async dispatch => {
    dispatch({ type: ADD_START })
    dispatch(requestPending())

    try {
        const payload = await addConfigurationsPromise({ app_configuration: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateConfigurations = (id, body, token, callback) => async dispatch => {
    dispatch({ type: UPDATE_START })
    dispatch(requestPending())

    try {
        const payload = await updateConfigurationsPromise(id, { app_configuration: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_SUCCESS, payload })
        if (!!callback) {
            callback({ success: 'Actualizado' })
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ERROR })
        if (error.htmlStatus === 422) {
            if (!!callback) {
                console.error(error)
                callback(error)
            }
            // dispatch(openExpiredSessionDialog())
            dispatch(addAlertMessage(error))
        }
    }
}
