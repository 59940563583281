import React from 'react'
import classnames from 'classnames'
import { Paper, Button } from '@material-ui/core'
import { mdiFilterMenu } from '@mdi/js'

import { SvgIconComponent } from '../../'
import { i18n } from '../../../services/util'
import styles from './styles'

function MapFiltersComponent({ open, change, label, icon, children }) {
    const classes = styles()
    return (
        <div className={classes.rootPaper }>
            <Button onClick={() => { change() }}>{label || i18n(['routes', 'filters', 'main_button'])} <SvgIconComponent path={icon || mdiFilterMenu} /> </Button>
            <div className={classnames(classes.wrapper, { [classes.open]: open, [classes.close]: !open })}>
                <Paper square classes={{ root: classes.filterPaper }}>
                    {children}
                </Paper>
            </div>
        </div>
    )
}

export default MapFiltersComponent
