import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    paper: {
        zIndex: theme.zIndex.appBar - 100,
        flexShrink: 1,
        paddingTop: 80,
        height: 'calc(100% - 80px)',
        whiteSpace: 'nowrap',
        overflow: 'unset',
    },
    open: {
        width: 240,
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        }),
    },
    close: {
        transition: theme.transitions.create('width', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        width: 0,
    },
    wrapper: {
        height: 'calc(100% - 24px)',
        overflow: 'hidden',
    },

    openButtomLeft: {
        position: 'absolute',
        bottom: `calc(51% - ${theme.menuConfig.height}px)`,
        right: -19,
        width: 35,
        height: 35,
    },

    openButtomRight: {
        position: 'absolute',
        bottom: `calc(51% - ${theme.menuConfig.height}px)`,
        left: -19,
        width: 35,
        height: 35,
    }
}))
