import { get } from '../../services/fetchService'
import { requestError } from '../request-state/actions'

export const DATA_REQUEST = 'BY_CLUSTER_DATA_REQUEST'
export const DATA_SUCCESS = 'BY_CLUSTER_DATA_SUCCESS'
export const DATA_ERROR = 'BY_CLUSTER_DATA_ERROR'

export const fetchDataPromise = async (token, querystring) => get('/stats/indicators', querystring || '', {Authorization: token})

export const fetchData = (token, querystring, cb) => async dispatch => {
    dispatch({ type: DATA_REQUEST })

    try {
        const response = await fetchDataPromise(token, querystring)

        dispatch({
            type: DATA_SUCCESS,
            payload: response
        })

        if (cb) cb(null, response)

    } catch(e) {
        if (cb) cb(e)
        dispatch(requestError())
    }
}
