/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { path, isEmpty } from 'ramda'
import { Typography, TextField } from '@material-ui/core'
import { mdiContentSave } from '@mdi/js'
import JSONInput from 'react-json-editor-ajrm'
import es from 'react-json-editor-ajrm/locale/es'
import en from 'react-json-editor-ajrm/locale/en'

import { FabHoverButtonComponent } from '../..'
import { i18n as i18nService } from '../../../services/util'
import styles from './styles'
import { fetchTranslations as _fetchTranslations, addTranslations as _addTranslations, updateTranslations as _updateTranslations } from '../../../redux/i18n/actions'
import { addAlertMessage as _addAlertMessage } from '../../../redux/ui-states/actions'

const validLocales = {
    es,
    en,
}

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    id: path(['i18n', 'data', 'id'], state),
    locale: path(['i18n', 'data', 'attributes', 'locale'], state),
    json: path(['i18n', 'data', 'attributes', 'i18n'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchTranslations: token => dispatch(_fetchTranslations(token)),
    addTranslations: (body, token, callback) => dispatch(_addTranslations(body, token, callback)),
    updateTranslations: (id, body, token, callback) => dispatch(_updateTranslations(id, body, token, callback)),
    addAlertMessage: payload => dispatch(_addAlertMessage(payload))
})

function TranslateComponent (props) {
    const { token, id, locale, json, /*fetchTranslations, addTranslations, */updateTranslations, addAlertMessage } = props
    const classes = styles()
    const i18n = prop => i18nService(['admin', 'i18n_config', prop])
    const initialValue = { locale: { value: '', error: null }, json: { value: '', error: null } }
    const [formData, setFormData] = useState(initialValue)
    const [jsonData, setJsonData] = useState({})
    const [hasError, setHasError] = useState(false)

    // useEffect(() => {
    //     fetchTranslations(token)
    // }, [])

    useEffect(() => {
        setJsonData(json)
        setFormData({
            ...formData,
            locale: {
                ...formData.locale,
                value: locale,
            }
        })
    }, [json, locale])

    function handleChange(input) {
        return function(event) {
            setFormData({
                ...formData,
                [input]: {
                    value: event.target.value,
                    error: null,
                }
            })
        }
    }

    function changeJsonHandler(event) {
        if (!event.error && !event.jsObject !== undefined) {
            setJsonData(event.jsObject)
            setHasError(false)
        } else {
            setHasError(true)
        }
    }

    function updatingRolesHandler() {
        if (!hasError && !isEmpty(formData.locale.value)) {
            updateTranslations(id, {
                locale: formData.locale.value,
                i18n: jsonData
            }, token)
        } else {
            let message
            if (hasError) {
                message = 'Hay un Error en el JSON'
            } else {
                message = 'La localización no puede estar vacia'
                setFormData({
                    ...formData,
                    locale: {
                        ...formData.locale,
                        error: 'La localización no puede estar vacia'
                    }
                })
            }
            addAlertMessage({ message, severity: 'error' })
        }
    }

    return (
        <form>
            <FabHoverButtonComponent text={i18n('button_save')} icon={mdiContentSave} onClick={updatingRolesHandler} className={classes.saveButton} />
            <Typography variant="h5" align="center">{i18n('title')}</Typography>
            <TextField
                id="locale"
                error={!!formData.locale.error}
                label={i18n('locale')}
                required
                variant="outlined"
                value={formData.locale.value}
                onChange={handleChange('locale')}
                classes={{ root: classes.inputText }}
            />
            <JSONInput
                id="json-input"
                placeholder={json || {}}
                theme="light_mitsuketa_tribute"
                onKeyPressUpdate={true}
                locale={validLocales[locale]}
                height="100%"
                width="100%"
                style={{ body: { fontSize : '1.2rem'}}}
                onChange={changeJsonHandler}
                waitAfterKeyPress={2000}
            />
        </form>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(TranslateComponent)
