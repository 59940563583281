import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    button: {
        border: 'none',
        background: 'none',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        textDecoration: 'underline',

        '&:hover': {
            color: theme.palette.primary.dark,
        },

        '&:focus': {
            outline: 'none'
        }
    },

    p: {
        margin: '0 !important'
    },

    mapIcon: {
        color: theme.palette.primary.light
    },
}))
