import React, { useState, useEffect } from 'react'
import { ModalComponent } from '..'
import { Button, Typography, makeStyles } from '@material-ui/core'

const styles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexDirection: 'column',
        height: 240,
        justifyContent: 'center',
        textAlign: 'center'
    },
    button: {
        margin: 20,
    }
}))

function ExpiredSessionComponent({ closeSession, ...rest }) {

    const classes = styles()
    const [timer, setTimer] = useState(15)
    const [counter, setCounter] = useState(null)

    useEffect(() => {
        setCounter(() => setInterval(() => {
            setTimer(timer => timer - 1)
        }, 1000))
    }, [])

    useEffect(() => {
        if (timer === 0) {
            closeSession()
            clearInterval(counter)
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [timer])

    function buttonHandler(event) {
        event.preventDefault()
        clearInterval(counter)
        closeSession()
    }

    return (
        <ModalComponent {...rest}>
            <div className={classes.root}>
                <Typography variant="h5">
                    Su sesión ha caducado.<br /> Se cerrará en {timer} segundos.
                </Typography>
                <Button className={classes.button} variant="contained" color="primary" onClick={buttonHandler}>Cerrar Sesión.</Button>
            </div>
        </ModalComponent>
    )
}

export default ExpiredSessionComponent
