import React from 'react'
import styles from './styles'


function MapControlComponent({ children }) {
    const classes = styles()
    return (
        <div className={classes.root}>
            {children}
        </div>
    )
}

export default MapControlComponent
