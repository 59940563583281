import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    saveButton: {
        position: 'fixed',
        right: 30,
        top: 86,
    },
    paperTitle: {
        padding: theme.spacing(2),
        marginBottom: theme.spacing(3),
    },
    selectAll: {
        position: 'fixed',
        top: 156,
        right: 30,
    },
    reload: {
        position: 'fixed',
        top: 222,
        right: 30,
    }
}))
