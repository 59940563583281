/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Typography } from '@material-ui/core'
import { mdiContentSave } from '@mdi/js'
import JSONInput from 'react-json-editor-ajrm'
import es from 'react-json-editor-ajrm/locale/es'
import en from 'react-json-editor-ajrm/locale/en'

import { FabHoverButtonComponent } from '../..'
import { i18n as i18nService } from '../../../services/util'
/*import styles from './styles'*/
import { addConfigurations as _addConfigurations, updateConfigurations as _updateConfigurations } from '../../../redux/ui-config/actions'
import { addAlertMessage as _addAlertMessage } from '../../../redux/ui-states/actions'

const validLocales = {
    es,
    en,
}

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    locale: path(['i18n', 'data', 'attributes', 'locale'], state),
    id: path(['uiConfig', 'data', 'id'], state),
    json: path(['uiConfig', 'data', 'attributes', 'app_data'], state),
})

const mapDispatchToProps = dispatch => ({
    addConfigurations: (body, token, callback) => dispatch(_addConfigurations(body, token, callback)),
    updateConfigurations: (id, body, token, callback) => dispatch(_updateConfigurations(id, body, token, callback)),
    addAlertMessage: payload => dispatch(_addAlertMessage(payload))
})

function CustomizeComponent (props) {
    const { token, id, locale, json, addAlertMessage, /*addConfigurations, */updateConfigurations } = props
    /*const classes = styles()*/
    const i18n = prop => i18nService(['admin', 'app_config', prop])
    const [jsonData, setJsonData] = useState({})
    const [hasError, setHasError] = useState(false)

    useEffect(() => {
        setJsonData(json)
    }, [json, locale])

    function changeJsonHandler(event) {
        if (!event.error) {
            setJsonData(event.jsObject)
            setHasError(false)
        } else {
            setHasError(true)
        }
    }

    function updatingRolesHandler() {
        if (!hasError) {
            updateConfigurations(id, {
                app_data: jsonData
            }, token)
        } else {
            addAlertMessage({ message: 'Hay un Error en el JSON', severity: 'error' })
        }
    }

    return (
        <form>
            <FabHoverButtonComponent text={i18n('button_save')} icon={mdiContentSave} onClick={updatingRolesHandler}/>
            <Typography variant="h5" align="center">{i18n('title')}</Typography>
            <JSONInput
                id="json-input"
                placeholder={json || {}}
                theme="light_mitsuketa_tribute"
                onKeyPressUpdate={true}
                locale={validLocales[locale]}
                height="100%"
                width="100%"
                style={{ body: { fontSize : '1.2rem'}}}
                onChange={changeJsonHandler}
                waitAfterKeyPress={2000}
            />
        </form>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(CustomizeComponent)
