/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, Checkbox } from '@material-ui/core'
import { mdiPlusCircle, mdiPencilCircle, mdiAccountMultipleRemove, mdiContentSave } from '@mdi/js'

import { ModalComponent, SvgIconComponent, FabHoverButtonComponent } from '../../'
import { fetchPermissions as _fetchPermissions, deletePermission as _deletePermission,
    assignPermissionsRoles as _assignPermissionsRoles } from '../../../redux/permissions/actions'
import { fetchRoles as _fetchRoles } from '../../../redux/roles/actions'
import styles from './styles'
import { i18n } from '../../../services/util'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    permissions: path(['permissions', 'permissions'], state),
    roles: path(['roles', 'roles'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchPermissions: token => dispatch(_fetchPermissions(token)),
    fetchRoles: token => dispatch(_fetchRoles(token)),
    deletePermission: (permissionId, token, callback) => dispatch(_deletePermission(permissionId, token, callback)),
    assignPermissionsRoles: (body, token, callback) => dispatch(_assignPermissionsRoles(body, token, callback)),
})

function ListPermissionsComponent({
    token,
    permissions,
    roles,
    fetchPermissions,
    fetchRoles,
    deletePermission,
    assignPermissionsRoles,
}) {
    const classes = styles()
    const history = useHistory()
    const selectedKeys = [
        { value: 'name', label: i18n(['admin', 'permissions_list', 'table_name']) },
        { value: 'description', label: i18n(['admin', 'permissions_list', 'table_description']) },
    ]
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const [deleteModalShow, setDeleteModalShow] = useState(false)
    const [mapKeys, setMapKeys] = useState(selectedKeys)
    const [localPermissions, setLocalPermissions] = useState([])
    const [prevLocalPermissions, setPrevLocalPermissions] = useState([])

    useEffect(() => {
        preload()
    }, [])

    useEffect(() => {
        setMapKeys([
            ...selectedKeys,
            ...roles.map(item => ({ value: item.id, label: item.name }))
        ])

        loadData()

    }, [roles, permissions])

    function preload() {
        fetchPermissions(token)
        fetchRoles(token)
    }

    function loadData() {
        setLocalPermissions(permissions.map(({ id, name, description, roles }) => {
            return { id, name, description, roles }
        }))

        setPrevLocalPermissions(permissions.map(({ id, name, description, roles }) => {
            return { id, name, description, roles }
        }))
    }

    function accionsHandler({ id }) {
        return function() {
            history.push(`/admin/permissions/${id}/actions`)
        }
    }

    function editPermissionHandler({ id }) {
        return function () {
            history.push(`/admin/permissions/${id}/edit`)
        }
    }

    function removeHanlder(permission) {
        return function() {
            setSelectedToDelete(permission)
            setDeleteModalShow(true)
        }
    }

    function closeRemoveModal() {
        setDeleteModalShow(false)
        setSelectedToDelete(null)
    }

    function confirmRemove() {
        deletePermission(selectedToDelete.id, token, () => {
            setDeleteModalShow(false)
        })
    }

    function handleChange(permissionId, roleId) {
        return function (e) {
            setLocalPermissions(localPermissions.map(({ roles, ...rest}) => {
                if (rest.id === permissionId) {
                    const roleData = {
                        ...roles[rest.id],
                        [roleId]: !roles[rest.id][roleId]
                    }

                    return {
                        ...rest,
                        roles: {
                            [rest.id]: roleData
                        }
                    }
                }

                return {
                    ...rest,
                    roles,
                }
            }))
        }
    }

    function updatingRolesHandler() {
        const updated = getRoles(localPermissions)
        const current = getRoles(prevLocalPermissions)
        const body = { 'role_permission': {updated, current} }

        assignPermissionsRoles(body, token, (message) => {
            preload()
        })
    }

    function getRoles(perm) {
        let response = {}
        perm.forEach(({ id, roles }) => {
            let objRoles = {}
            const keyRoles = Object.keys(roles[id])

            keyRoles.forEach(item => {
                objRoles = {...objRoles, [item]: roles[id][item] ? '1' : '0'}
            })
            response = {
                ...response,
                [id]: objRoles
            }
        })
        return response
    }

    return (
        <React.Fragment>
            <FabHoverButtonComponent text={i18n(['admin', 'permissions_list', 'button_save'])} icon={mdiContentSave} onClick={updatingRolesHandler} className={classes.saveButton} />
            <Typography variant="h5" align="center">{i18n(['admin', 'permissions_list', 'title'])}</Typography>
            <Table size="small" classes={{ root: classes.tableRoot }}>
                <TableHead>
                    <TableRow>
                        {mapKeys.map((column, k) => {
                            if (roles.find(item => item.id === column.value)) {
                                return <TableCell key={k} className={classes.alignCenter}>{column.label}</TableCell>
                            }

                            return <TableCell key={k}>{column.label}</TableCell>
                        })}
                        <TableCell className={classes.alignCenter}>{i18n(['admin', 'permissions_list', 'table_actions'])}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {localPermissions.map((permission, k) => (
                        <TableRow key={k}>
                            {mapKeys.map((item, k) => {
                                if (permission[item.value] === undefined) {
                                    return (
                                        <TableCell key={k} className={classes.alignCenter}>
                                            <Checkbox
                                                checked={permission.roles[permission.id][item.value]}
                                                onChange={handleChange(permission.id, item.value)}
                                                name="checkedB"
                                                color="primary"
                                            />
                                        </TableCell>
                                    )
                                }
                                return <TableCell key={k}>{permission[item.value]}</TableCell>
                            })}
                            <TableCell style={{ whiteSpace: 'nowrap' }} className={classes.alignCenter}>
                                <Tooltip title={i18n(['admin', 'permissions_list', 'tooltip_add'])}>
                                    <IconButton onClick={accionsHandler(permission)}>
                                        <SvgIconComponent path={mdiPlusCircle} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n(['admin', 'permissions_list', 'tooltip_edit'])}>
                                    <IconButton onClick={editPermissionHandler(permission)}>
                                        <SvgIconComponent path={mdiPencilCircle} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n(['admin', 'permissions_list', 'tooltip_delete'])}>
                                    <IconButton onClick={removeHanlder(permission)}>
                                        <SvgIconComponent path={mdiAccountMultipleRemove} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <ModalComponent open={deleteModalShow} onClose={closeRemoveModal}>
                <div className={classes.modalWrapper}>
                    <Typography variant="h5" align="center">{i18n(['admin', 'permissions_list', 'modal_title'])}<br />{selectedToDelete && selectedToDelete.name}</Typography>
                    <div className={classes.modalButtonContainer}>
                        <Button color="default" variant="contained" onClick={() => closeRemoveModal()}>{i18n(['admin', 'permissions_list', 'modal_button_cancel'])}</Button>
                        <Button color="secondary" variant="contained" onClick={() => confirmRemove()}>{i18n(['admin', 'permissions_list', 'modal_button_confirm'])}</Button>
                    </div>
                </div>
            </ModalComponent>
        </React.Fragment>
    )
}

ListPermissionsComponent.propTypes = {
    permissions: PropTypes.array.isRequired
}

ListPermissionsComponent.defaultProps = {
    permissions: []
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPermissionsComponent)
