/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { useHistory, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { prop, path, pathOr, isNil, omit, /*propOr, */isEmpty } from 'ramda'
import classnames from 'classnames'
import { v4 as uuid_v4 } from 'uuid'
import { Container, Grid, /*Switch, */Typography, /*withStyles, */List, ListItem, Fab,
    MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList, /*IconButton, */Tooltip } from '@material-ui/core'
import { /*mdiTableEye, mdiMapClockOutline, mdiLayersTriple, */mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline, mdiMapMarkerPlus, mdiLayers, mdiMapOutline, mdiTruck, mdiFileDocument, mdiArrowLeftCircle, mdiAlert, mdiApplicationExport } from '@mdi/js'

import { RoutesTableComponent, SvgIconComponent, DetailMapComponent, ControlPanelComponent, LoaderComponent,
    MapControlComponent, MapFiltersComponent, StatsFloatPanelComponent, AlertsMapDetailsComponent, PaginatorComponent } from '../../components'
import { fetchRoute, fetchRouteNotifications } from '../../redux/route-detail/actions'
import { toggleLeftPanel as _toggleLeftPanel, toggleRightPanel as _toggleRightPanel } from '../../redux/ui-states/actions'
import { requestPending as _requestPending, requestSuccess as _requestSuccess } from '../../redux/request-state/actions'
import { fetchData as _getLayersData } from '../../redux/layers/actions'
import { i18n } from '../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    colors: path(['uiConfig', 'data', 'attributes', 'app_data', 'graphics'], state),
    loading: path(['request', 'loading'], state),
    leftPanelShow: path(['uiStates', 'leftPanel', 'show'], state),
    rightPanelShow: path(['uiStates', 'rightPanel', 'show'], state),
    layerConfig: path(['uiConfig', 'data', 'attributes', 'app_data', 'mapLayers'], state),
    clusterConfig: path(['uiConfig', 'data', 'attributes', 'app_data', 'clusters'], state),
    customLayers: prop('layers', state),
    route: path(['routeDetail', 'response'], state),
    notifications: path(['routeDetail', 'notifications', 'data'], state),
    links: pathOr([], ['user', 'info', 'data', 'meta', 'links'], state),
    notifyPagination: path(['routeDetail', 'notifications', 'pagination'], state),
})

const mapDispatchToProps = dispatch => ({
    getRouteData: (token, id, qs, cb) => dispatch(fetchRoute(token, id, qs, cb)),
    fetchRouteNotificationsData: (token, qs, cb) => dispatch(fetchRouteNotifications(token, qs, cb)),
    toggleLeftPanel: () => dispatch(_toggleLeftPanel()),
    toggleRightPanel: () => dispatch(_toggleRightPanel()),
    requestPending: () => dispatch(_requestPending()),
    requestSuccess: () => dispatch(_requestSuccess()),
    getLayers: (token, qs, callback) => dispatch(_getLayersData(token, qs, callback)),
})

function RoutesDetailsPage(props) {
    const {
        // State Props
        token, route, loading, leftPanelShow, rightPanelShow, layerConfig, customLayers, colors, links, notifications, notifyPagination, clusterConfig,

        // Actions Props
        getRouteData, toggleLeftPanel, toggleRightPanel, requestPending, /*requestSuccess, */getLayers, fetchRouteNotificationsData,
    } = props
    const history = useHistory()
    const match = useRouteMatch()
    const classes = styles()
    const notificationInitialValues = {
        sort: '-id',
        by_route_id: match.params.id,
        not_reviewed: '',
        page: 1,
    }
    /*const [activeSource, setActiveSource] = useState('map_detail')*/
    const activeSource = 'map_detail'
    const [layers, setLayers] = useState([])
    const [mapLayer, setMapLayer] = useState({})
    const [mapOptions, setMapOptions] = useState({})
    const [mapLayerSelected, setMapLayerSelected] = useState([])
    const [resizeEventListener, setResizeEventListener] = useState(null)
    const [anchorEl, setAnchorEl] = useState({})
    const [updater, setUpdater] = useState('')
    const [notificationsQs, setNotificationsQs] = useState(notificationInitialValues)
    const [selectedItem, setSelectedItem] = useState()

    let mapReload

    useEffect(() => {
        mapReload = setInterval(() => {
            setUpdater(uuid_v4())
        }, 10000)

        setMapOptions({
            geofence: true,
            trackpoints: false,
            trackpath: false,
        })

        return () => {
            clearInterval(mapReload)
        }
    }, [])

    useEffect(() => {
        setLayers(pathOr([], ['data', 'meta', 'left_panel', 'items'], route))
    }, [route])

    useEffect(() => {
        mapLoad()
    }, [updater, notificationsQs])

    useEffect(() => {
        if (!isNil(resizeEventListener) && match.path === '/routes/map/:id') {
            setTimeout(() => {
                resizeEventListener.invalidateSize()
            }, 400)
        }
    }, [leftPanelShow])

    useEffect(() => {
        const customs = pathOr([], ['response', 'data'], customLayers)
        const response = {}

        for (const item of customs) {
            if (item.attributes.is_active) {
                response[item.attributes.name] = item.attributes
            }
        }

        setMapLayer(response)
    }, [customLayers])

    function mapLoad() {
        getLayers(token)
        getRouteData(token, match.params.id, '')
        fetchRouteNotificationsData(token, notificationsQs)
    }

    function handlerMapControlOpen(elementName) {
        return function (e) {
            setAnchorEl({...anchorEl, [elementName]: e.target})
        }
    }

    function handleMapControlClose(elementName) {
        return function () {
            setAnchorEl(omit([elementName], anchorEl))
        }
    }

    function handleListKeyDown(elementName) {
        return function (event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setAnchorEl(omit([elementName], anchorEl))
              }
        }
    }

    function handleToggleMapLayer(item) {
        return function () {
            if (mapLayerSelected.includes(item)) {
                setMapLayerSelected(mapLayerSelected.filter(layer => item !== layer))
            } else {
                setMapLayerSelected([...mapLayerSelected, item])
            }
        }
    }

    function handleToggleMapOptions(item) {
        return function () {
            setMapOptions({...mapOptions, [item]: !mapOptions[item]})
        }
    }

    function sendSelectedMapLayer() {
        return mapLayerSelected.reduce((a, b) => ({...a, [b]: mapLayer[b] }), {})
    }

    function handleNotifyPaginatorChange(event, page) {
        requestPending()
        const newQs = {
            ...notificationsQs,
            page,
        }

        setNotificationsQs(newQs)
    }

    function paginationChange(event, page) {
        // useSource[activeSource](token, {...filtersQs, page})
    }

    function parseVehicle(route) {
        let resp

        if (!isEmpty(route)) {
            const path = path => pathOr(null, ['included', 0, ...path])
            resp = {
                item_type: "VEHICLE",
                item_nid: path(['attributes', 'nid'])(route),
                capacity0: path(['attributes', 'capacity0'])(route),
                capacity1: path(['attributes', 'capacity1'])(route),
                capacity2: path(['attributes', 'capacity2'])(route),
                cluster_id: path(['attributes', 'cluster_id'])(route),
                driver_name: path(['attributes', 'driver_name'])(route),
                license_plate: path(['attributes', 'license_plate'])(route),
                ll_id: path(['attributes', 'll_id'])(route),
                load_type: path(['attributes', 'load_type'])(route),
                make: path(['attributes', 'make'])(route),
                model: path(['attributes', 'model'])(route),
                name: path(['attributes', 'name'])(route),
                organization_id: path(['attributes', 'organization_id'])(route),
                item_latitude: path(['meta', 'coords', 'latitude'])(route),
                item_longitude: path(['meta', 'coords', 'longitude'])(route),
                delivered_load: path(['meta', 'table', 'delivered_load'])(route),
                planned_start_date: path(['meta', 'table', 'planned_start_date'])(route),
                real_start_date: path(['meta', 'table', 'real_start_date'])(route),
                route_avg_load: path(['meta', 'table', 'route_avg_load'])(route),
                route_ends_at: path(['meta', 'table', 'route_ends_at'])(route),
                route_id: path(['meta', 'table', 'route_id'])(route),
                route_status: path(['meta', 'table', 'route_status'])(route),
                start_date_difference: path(['meta', 'table', 'start_date_difference'])(route),
                route_gps_status: path(['meta', 'table', 'route_gps_status'])(route),
                cluster_name: path(['meta', 'table', 'cluster_name'])(route),
            }
        }

        return resp
    }

    function handleFilterUpdate(newFilters = []) {
        requestPending()
        const newQs = {
            sort: notificationsQs.sort,
            by_route_id: match.params.id,
            not_reviewed: notificationsQs.not_reviewed,
            page: 1,
        }

        if (!isEmpty(newFilters)) {
            newQs.by_type = newFilters
        }

        setNotificationsQs(newQs)
    }

    return (
        <React.Fragment>
            <div className={classnames(
                    classes.root,
                    {
                        [classes.openLeftControl]: leftPanelShow,
                        // [classes.openRightControl]: rightPanelShow,
                        })}>
                <Container maxWidth={false} className={classes.routesViewRoot}>
                    <ControlPanelComponent open={leftPanelShow} change={toggleLeftPanel} size={500}>
                        <List>
                            <ListItem>
                                <StatsFloatPanelComponent selectedItem={setSelectedItem} />
                            </ListItem>
                        </List>
                    </ControlPanelComponent>

                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                        <Grid item className={classes.submenuContainer}>
                            <Typography variant="h6">
                                {match.path === '/routes/map/:id' ? i18n(['routes', 'title', 'map_detail']) : i18n(['routes', 'title', activeSource])}
                            </Typography>
                            {route && (
                                <React.Fragment>
                                    <div className={classes.linkContainer}>
                                        <Tooltip title={'Atrás'}>
                                            <button onClick={() => history.goBack()}>
                                                <SvgIconComponent path={mdiArrowLeftCircle} />
                                            </button>
                                        </Tooltip>
                                        {links.includes('itinerary_edit_url') && (
                                            <Tooltip title={i18n(['routes', 'routes_detail', 'itinerary_edit_url'])}>
                                                <a href={pathOr('', ['data', 'attributes', 'itinerary_edit_url'], route)} target="_blank" rel="noopener noreferrer">
                                                    <SvgIconComponent path={mdiMapOutline} />
                                                </a>
                                            </Tooltip>
                                        )}
                                        {links.includes('scheduling_url') && (
                                            <Tooltip title={i18n(['routes', 'routes_detail', 'scheduling_url'])}>
                                                <a href={pathOr('',['data', 'attributes', 'scheduling_url'], route)} target="_blank" rel="noopener noreferrer">
                                                    <SvgIconComponent path={mdiTruck} />
                                                </a>
                                            </Tooltip>
                                        )}
                                        {links.includes('itinerary_table_url') && (
                                            <Tooltip title={i18n(['routes', 'routes_detail', 'itinerary_table_url'])}>
                                                <a href={pathOr('',['data', 'attributes', 'itinerary_table_url'], route)} target="_blank" rel="noopener noreferrer">
                                                    <SvgIconComponent path={mdiFileDocument} />
                                                </a>
                                            </Tooltip>
                                        )}
                                        <Tooltip title={'Acceso a Módulo de ejecución'}>
                                            <a href={pathOr('',['data', 'attributes', 'running_routes_url'], route)} target="_blank" rel="noopener noreferrer">
                                                <SvgIconComponent path={mdiApplicationExport} />
                                            </a>
                                        </Tooltip>
                                    </div>
                                    <div className={classes.linkContainer}>
                                        <Typography>
                                            Intinerario {links.includes('itinerary_url') ? (
                                                <a href={pathOr('',['data', 'attributes', 'itinerary_url'], route)} target="_blank" rel="noopener noreferrer">
                                                    {pathOr('',['data', 'attributes', 'itinerary_id'], route)}
                                                </a>
                                            ) : (
                                                <span>{pathOr('',['data', 'attributes', 'itinerary_id'], route)}</span>
                                            )}
                                            <b> Ruta</b> {pathOr('',['data', 'attributes', 'uid'], route)}
                                            <b> {pathOr('',['data', 'attributes', 'vehicle_license_plate'], route)} {pathOr('',['data', 'attributes', 'vehicle_driver_name'], route)}</b>
                                        </Typography>
                                    </div>
                                </React.Fragment>
                            )}
                        </Grid>
                        <Grid item>
                            <Grid container>
                                {notifications && (
                                    <Grid item>
                                        <MapFiltersComponent label={i18n(['routes','routes_detail', 'notifications'])} icon={mdiAlert} open={rightPanelShow} change={toggleRightPanel}>
                                            <AlertsMapDetailsComponent alerts={notifications} onChange={handleFilterUpdate} />
                                            {notifyPagination && (
                                                <div className={classes.paginatorContainer}>
                                                    <PaginatorComponent
                                                        page={notifyPagination.page}
                                                        total={notifyPagination.total}
                                                        perPage={notifyPagination.perPage}
                                                        onChange={handleNotifyPaginatorChange}
                                                        siblingCount={1}
                                                        boundaryCount={1}
                                                        size="small"
                                                    />
                                                </div>
                                            )}
                                        </MapFiltersComponent>
                                    </Grid>
                                )}
                            </Grid>
                        </Grid>
                    </Grid>

                    {/map/gi.test(match.path) &&
                        <DetailMapComponent
                            config={layerConfig}
                            clusterConfig={clusterConfig}
                            activeSource={activeSource}
                            points={route ? [...layers, parseVehicle(route)] : layers}
                            trackpoints={path(['data', 'meta', 'trackpoints'], route)}
                            mapLayer={sendSelectedMapLayer()}
                            resizeListener={setResizeEventListener}
                            geofence={mapOptions.geofence}
                            showTrackpath={mapOptions.trackpath}
                            showTrackpoints={mapOptions.trackpoints}
                            showTooltip={selectedItem}
                            controls={
                                <MapControlComponent>
                                    <Tooltip title={'Capas'} placement="left">
                                        <Fab aria-label="add" onClick={handlerMapControlOpen('optionsControl')}>
                                            <SvgIconComponent path={mdiMapMarkerPlus} color="primary"/>
                                        </Fab>
                                    </Tooltip>

                                    <Popper
                                        open={Boolean(anchorEl['optionsControl'])}
                                        anchorEl={anchorEl['optionsControl']}
                                        role={undefined}
                                        transition
                                        placement="bottom-end"
                                        disablePortal>
                                        {({ TransitionProps }) => (
                                            <Grow
                                            {...TransitionProps}
                                            >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleMapControlClose('optionsControl')}>
                                                <MenuList
                                                    autoFocusItem={Boolean(anchorEl['optionsControl'])}
                                                    onKeyDown={handleListKeyDown('optionsControl')}>
                                                    {Object.keys(mapOptions).map((map, key) =>
                                                        <MenuItem key={key} onClick={handleToggleMapOptions(map)}>
                                                            <SvgIconComponent
                                                                path={mapOptions[map] ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline }
                                                                color="primary"
                                                            />
                                                                {i18n(['routes', 'routes_detail', map]) === '--' ? map.toUpperCase() : i18n(['routes', 'routes_detail', map]).toUpperCase()}
                                                        </MenuItem>)
                                                    }
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                            </Grow>
                                    )}
                                    </Popper>

                                    <Tooltip title={'Zonas'} placement="left">
                                        <Fab aria-label="add" onClick={handlerMapControlOpen('layersControl')}>
                                            <SvgIconComponent path={mdiLayers} color="primary"/>
                                        </Fab>
                                    </Tooltip>

                                    <Popper
                                        open={Boolean(anchorEl['layersControl'])}
                                        anchorEl={anchorEl['layersControl']}
                                        role={undefined}
                                        transition
                                        placement="bottom-end"
                                        disablePortal>
                                        {({ TransitionProps }) => (
                                            <Grow
                                            {...TransitionProps}
                                            >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleMapControlClose('layersControl')}>
                                                <MenuList
                                                    autoFocusItem={Boolean(anchorEl['layersControl'])}
                                                    onKeyDown={handleListKeyDown('layersControl')}>
                                                    {Object.keys(mapLayer).map((map, key) =>
                                                        <MenuItem key={key} onClick={handleToggleMapLayer(map)}>
                                                            <SvgIconComponent
                                                                path={mapLayerSelected.includes(map) ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline }
                                                                color="primary"
                                                            />
                                                                {map.toUpperCase()}
                                                        </MenuItem>)
                                                    }
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                            </Grow>
                                    )}
                                    </Popper>
                                </MapControlComponent>
                            }
                        />
                    }

                    {match.path === '/routes/table' &&
                        <RoutesTableComponent
                            layers={layers}
                            activeSource={activeSource}
                            colors={colors}
                            paginatorChange={paginationChange}
                        />
                    }
                </Container>
            </div>
            <LoaderComponent visible={loading} />
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesDetailsPage)
