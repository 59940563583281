import { get, put, post } from '../../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../../models/htmlMessage'


export const GET_ALERTS_START   = 'GET_ALERTS_START'
export const GET_ALERTS_SUCCESS = 'GET_ALERTS_SUCCESS'
export const GET_ALERT_SUCCESS  = 'GET_ALERT_SUCCESS'
export const GET_ALERTS_ERROR   = 'GET_ALERTS_ERROR'

export const GET_CSV_ALERTS_START   = 'GET_CSV_ALERTS_START'
export const GET_CSV_ALERTS_SUCCESS = 'GET_CSV_ALERTS_SUCCESS'
export const GET_CSV_ALERT_SUCCESS  = 'GET_CSV_ALERT_SUCCESS'
export const GET_CSV_ALERTS_ERROR   = 'GET_CSV_ALERTS_ERROR'

export const GET_PENDING_ALERTS_START   = 'GET_PENDING_ALERTS_START'
export const GET_PENDING_ALERTS_SUCCESS = 'GET_PENDING_ALERTS_SUCCESS'
export const GET_PENDING_ALERTS_ERROR   = 'GET_PENDING_ALERTS_ERROR'

export const SET_USER_ACTION_START = 'SET_USER_ACTION_START'
export const SET_USER_ACTION_SUCCESS = 'SET_USER_ACTION_SUCCESS'
export const SET_USER_ACTION_ERROR = 'SET_USER_ACTION_ERROR'

export const UPDATE_EVENT = 'UPDATE_EVENT'
export const UPDATE_EVENT_SUCCESS = 'UPDATE_EVENT_SUCCESS'
export const UPDATE_EVENT_ERROR = 'UPDATE_EVENT_ERROR'

export const fetchAlertsPromise = async (token, alertActionOrId = '', querystring) => get(`amm/alerts/${alertActionOrId}`, querystring || '', {Authorization: token})
export const fetchAlertsCsvPromise = async (token, querystring) => get(`amm/alerts/csv`, querystring || '', {Authorization: token})
export const markAsManagedPromise = async (id, body, token) => put(`/amm/alerts/${id}/managed`, body, {Authorization: token})
export const updateEventPromise = async (id, body, token) => post(`/amm/alerts/${id}/update_event/`, body, {Authorization: token})

export const fetchAlerts = (token,alertActionOrId,querystring) => async dispatch => {
  dispatch({ type: GET_ALERTS_START })
  dispatch(requestPending())
  try {
    const response = await fetchAlertsPromise(token, alertActionOrId, querystring)
    dispatch(requestSuccess())
    if (typeof alertActionOrId === 'number') {
      dispatch({ type: GET_ALERT_SUCCESS, payload: response.data })
    } else {
      dispatch({ type: GET_ALERTS_SUCCESS, payload: response })
    }
  } catch (error) {
    dispatch(requestError())
    dispatch({ type: GET_ALERTS_ERROR })
    if (error.htmlStatus === 422) {
      dispatch(openExpiredSessionDialog())
    }
    dispatch(addAlertMessage(error))
  }
}

export const fetchAlertsCsv = (token,querystring) => async dispatch => {
  dispatch({ type: GET_CSV_ALERTS_START })
  dispatch(requestPending())
  try {
    const response = await fetchAlertsCsvPromise(token, querystring)
    dispatch(requestSuccess())
    dispatch({ type: GET_CSV_ALERTS_SUCCESS, payload: response })
  } catch (error) {
    dispatch(requestError())
    dispatch({ type: GET_CSV_ALERTS_ERROR })
    if (error.htmlStatus === 422) {
      dispatch(openExpiredSessionDialog())
    }
    dispatch(addAlertMessage(error))
  }
}

export const fetchPendingAlerts = (token) => async dispatch => {
  dispatch({ type: GET_PENDING_ALERTS_START })
  dispatch(requestPending())
  try {
    const response = await fetchAlertsPromise(token,'of-the-day')
    dispatch(requestSuccess())
    dispatch({ type: GET_PENDING_ALERTS_SUCCESS, payload: response })
  } catch (error) {
    dispatch(requestError())
    dispatch({ type: GET_PENDING_ALERTS_ERROR })
    if (error.htmlStatus === 422) {
      dispatch(openExpiredSessionDialog())
    }
    dispatch(addAlertMessage(error))
  }
}

export const markAsReadAlert = (token, id, action) => async dispatch => {
    dispatch({ type: SET_USER_ACTION_START })
    dispatch(requestPending())

    try {
      const markAlert = await fetchAlertsPromise(token,id)
      dispatch(requestSuccess())
      dispatch({ type: SET_USER_ACTION_SUCCESS })
      const { status, statusText, message, severity } = ResponseMessageParse(markAlert)
      dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
      dispatch(requestError())
      dispatch({ type: SET_USER_ACTION_ERROR })
      if (error.htmlStatus === 422) {
          dispatch(openExpiredSessionDialog())
      }
      dispatch(addAlertMessage(error))
    }
}

export const markAsManagedAlert = (token, id, body, action) => async dispatch => {
    dispatch({ type: SET_USER_ACTION_START })
    dispatch(requestPending())

    try {
      const markAlert = await markAsManagedPromise(id, { alert: body }, token)
      const refreshAlerts = await fetchAlertsPromise(token,'of-the-day')
      Promise.all([markAlert, refreshAlerts]).then(data => {
        dispatch(requestSuccess())
        dispatch({ type: SET_USER_ACTION_SUCCESS })
        const { status, statusText, message, severity } = ResponseMessageParse(data[0])
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
        dispatch({ type: GET_ALERTS_SUCCESS, payload: data[1] })
      })
    } catch (error) {
      dispatch(requestError())
      dispatch({ type: SET_USER_ACTION_ERROR })
      if (error.htmlStatus === 422) {
        dispatch(openExpiredSessionDialog())
      }
      dispatch(addAlertMessage(error))
    }
}

export const changeEvent = (token, id, payload) => async dispatch => {
  dispatch({ type: UPDATE_EVENT })
  dispatch(requestPending())
  try {
    const updateEvent = await updateEventPromise(id, payload, token)
    dispatch(requestSuccess())
    dispatch({ type: UPDATE_EVENT_SUCCESS })
    const { status, statusText, message, severity } = ResponseMessageParse(updateEvent)
    dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
  } catch (error) {
    dispatch(requestError())
    dispatch({ type: UPDATE_EVENT_ERROR })
    if (error.htmlStatus === 422) {
      dispatch(openExpiredSessionDialog())
    }
    dispatch(addAlertMessage(error))
  }
}