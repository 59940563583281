/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useRouteMatch } from 'react-router-dom'
import { path, propOr, lensPath, set, isEmpty } from 'ramda'
import { TextField, Button, Typography, Grid, Paper, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core'

import { fetchUsers as _fetchUsers, updateUserActions as _updateUserActions, updateUserRole as _updateUserRole,
    fetchUserActions as _fetchUserActions } from '../../../redux/users/actions'
import { fetchRoles as _fetchRoles, fetchRoleActions as _fetchRoleActions } from '../../../redux/roles/actions'
import styles from './styles'
import ActionListCheckerComponent from '../../ActionListChecker/ActionListCheckerComponent'
import { i18n as i18nService } from '../../../services/util'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    user: path(['users', 'user'], state),
    userActions: path(['users', 'userActions'], state),
    roles: path(['roles', 'roles'], state),
    rolesPermissions: path(['roles', 'rolesPermissions'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchUsers: (token, userId) => dispatch(_fetchUsers(token, userId)),
    fetchRoles: token => dispatch(_fetchRoles(token)),
    fetchUserActions: (token, userId) => dispatch(_fetchUserActions(token, userId)),
    fetchRoleActions: (roleId, token) => dispatch(_fetchRoleActions(roleId, token)),
    updateUserRole: (userId, payload, token, callback) => dispatch(_updateUserRole(userId, payload, token, callback)),
    updateUserActions: (userId, payload, token, callback) => dispatch(_updateUserActions(userId, payload, token, callback)),
})

function AddUserComponent({
    token,
    user,
    roles,
    rolesPermissions,
    userActions,

    fetchUsers,
    fetchRoles,
    fetchUserActions,
    fetchRoleActions,
    updateUserRole,
    updateUserActions,
}) {
    const classes = styles()
    const { userId } = useParams()
    const { url } = useRouteMatch()
    const i18n = prop => i18nService(['admin', 'edit_roles_permissions', prop])
    const [selectedRole, setSelectedRole] = useState('')
    const [rolesPermissionsLocal, setRolesPermissionsLocal] = useState(null)

    useEffect(() => {
        fetchUsers(token, userId)
        fetchRoles(token)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (selectedRole === '') {
            setRolesPermissionsLocal(null)
        } else {
            reloadActions()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedRole])

    useEffect(() => {
        if (!!userActions && selectedRole === user.main_role_id) {
            setRolesPermissionsLocal(userActions)
        } else {
            setRolesPermissionsLocal(rolesPermissions[selectedRole])
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userActions, rolesPermissions, selectedRole])

    useEffect(() => {
        if (!!user) {
            setSelectedRole(user.main_role_id)
        }
    }, [user])

    function handleSetRol(e) {
        setSelectedRole(e.target.value)
    }

    function updateRolePermission(permission, actionKey, value) {
        return function () {
            const stateLens = lensPath([permission, actionKey, 'active'])
            const newState = set(stateLens, value, rolesPermissionsLocal)
            setRolesPermissionsLocal(newState)
        }
    }

    function saveHandler() {
        let payload = { current: {}, updated: {} }

        for (const actionRole in userActions) {
            const current = userActions[actionRole]
                .map(item => ({[item.id]: item.active ? '1' : '0'}))
                .reduce((a,b,{}) => ({...b, ...a}))

            payload = {
                current: {
                    ...payload.current,
                    ...current
                },
            }
        }

        for (const actionRole in rolesPermissionsLocal) {
            const updated = rolesPermissionsLocal[actionRole]
                .map(item => ({[item.id]: item.active ? '1' : '0'}))
                .reduce((a,b,{}) => ({...b, ...a}))

            payload = {
                updated: {
                    ...payload.updated,
                    ...updated
                },
            }
        }

        if (!isEmpty(selectedRole) && selectedRole !== user.main_role_id) {
            updateUserRole(userId, selectedRole, token)
        }

        updateUserActions(userId, payload, token, (message) => {
            reloadActions()
        })
    }

    function reloadActions() {
        if (selectedRole === user.main_role_id) {
            fetchUserActions(token, userId)
        } else {
            fetchRoleActions(selectedRole, token)
        }
    }

    return (
        <React.Fragment>
            <form className={classes.formContainer}>
                <Typography variant="h5" align="center">{i18n('title')} {user && user.name}</Typography>
                <Grid container spacing={3}>
                    <Grid item lg={3} md={4}>
                        <FormControl className={classes.roleControl} variant="outlined" fullWidth>
                            <InputLabel id="role-label">{i18n('input_placeholder_role')}</InputLabel>
                            <Select
                                value={selectedRole}
                                labelId="role-label"
                                label="Rol-"
                                onChange={handleSetRol}>
                                <MenuItem value={''}>None</MenuItem>
                                {roles.map((role, key) => <MenuItem key={key} value={role.id}>{role.name}</MenuItem>)}
                            </Select>
                        </FormControl>
                        <TextField
                            id="email"
                            fullWidth
                            label={i18n('input_placeholder_email')}
                            variant="outlined"
                            value={propOr('', 'email' , user)}
                            disabled
                        />
                    </Grid>
                    <Grid item lg={9} md={8}>
                        <Paper elevation={0} variant="outlined" classes={{ root: classes.actionsWrapper }}>
                            {rolesPermissionsLocal ?
                                <Grid container spacing={4}>
                                    {Object.keys(rolesPermissionsLocal)
                                        .map((title, k) => <Grid item xl={4} lg={6} md={6} sm={12} key={k}>
                                                <ActionListCheckerComponent
                                                    title={title}
                                                    items={rolesPermissionsLocal[title]}
                                                    onChange={updateRolePermission}/>
                                            </Grid>)
                                    }
                                </Grid> :
                                <Typography align="center">{i18n('empty_placeholder')}</Typography>
                            }
                        </Paper>
                    </Grid>
                </Grid>
                <div className={classes.buttonContainer}>
                    <Button className={classes.button} variant="contained" color="primary" onClick={saveHandler}>{url === '/admin/roles/add' ? i18n('button_new') : i18n('button_edit')}</Button>
                    <Button className={classes.button} variant="contained" onClick={() => {}}>{i18n('button_reload')}</Button>
                </div>
            </form>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(AddUserComponent)
