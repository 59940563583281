export class HtmlError extends Error {
    constructor(status, statusText, message) {
        super()
        this.message = message
        this.htmlStatus = status
        this.htmlStatusText = statusText
        this.name = 'HtmlError'
        this.severity = 'error'
    }
}
