import { post, get } from '../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../request-state/actions'
import { addAlertMessage } from '../ui-states/actions'

// Actions types

export const LOGIN_START = 'USER_LOGIN_START'
export const LOGIN_SUCCESS = 'USER_LOGIN_SUCCESS'
export const LOGIN_ERROR = 'USER_LOGIN_ERROR'
export const LOAD_SESION = 'USER_LOAD_SESION'

export const GET_USER_INFO_START = 'GET_USER_INFO_START'
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS'
export const GET_USER_INFO_ERROR = 'GET_USER_INFO_ERROR'

export const LOGOUT = 'USER_LOGOUT'

// Action creators

export const loadSesion = payload => ({
    type: LOAD_SESION,
    payload
})

export const loginError = error => ({
    type: LOGIN_ERROR,
    payload: error
})

export const cleanSesion = () => ({
    type: LOGOUT
})


// Async Actions creators

export const userLogin = ({username, password, exp}, callback) => async dispatch => {
    dispatch({ type: LOGIN_START })
    dispatch(requestPending())

    try {
        const result = await post('auth/login', {
            authentication: {
                organization_id: null,
                cluster_nid: null,
                username,
                password,
                exp: exp || 8760,
            }
        })

        dispatch({
            type: LOGIN_SUCCESS,
            payload: {
                username,
                auth_token: result.auth_token
            },
        })

        dispatch(requestSuccess())

    } catch (error) {
        //TODO catch errors
        dispatch(loginError(error.message))
        dispatch(requestError())

        dispatch(addAlertMessage(error))
    }
}

// TODO: Check if is need a callback to redirect route on logout
export const userLogout = () => async dispatch => {
    dispatch({ type: LOGOUT })
}


export const getUserInfo = (token) => async dispatch => {
    dispatch({ type: GET_USER_INFO_START })
    dispatch(requestPending())

    try {
        const payload = await get('users/user_info', '', {Authorization: token})

        dispatch({
            type: GET_USER_INFO_SUCCESS,
            payload,
        })

        dispatch(requestSuccess())

    } catch (error) {
        //TODO catch errors
        dispatch({ type: GET_USER_INFO_ERROR })
        dispatch(requestError())

        dispatch(addAlertMessage(error))
    }
}
