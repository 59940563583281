import React from 'react'
import { Grid, Box, LinearProgress } from '@material-ui/core'


function AlertIndicatorCardComponent(props) {
  const { item, callback, icon, title, labelValue, totals, name, value } = props
  const progress = labelValue/totals*100
  return (
    <>
      <div className={`ruc-indicators-button-${item}`} onClick={() => callback({name: name, value: value})}>
        <Grid container spacing={0} direction="row">
          <Grid item xs={12}>
            <Grid container spacing={0} direction="row">
              <Grid item xs={4}>
                <Box display="flex" alignItems="center" justifyContent="center" css={{ height: '100%'}}>
                  {icon}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Grid container spacing={0} direction="row" className="ruc-card-info">
                  <Grid item xs={12}>
                    <label>{title}</label>
                    <span>{labelValue}</span>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={0} direction="row">
              <Grid item xs={4} className="ruc-card-progress-numeric">
                <Box display="flex" justifyContent="center" css={{ height: '100%'}}>
                  { isNaN(progress) ? null : `${Math.round(progress)}%` }
                </Box>
              </Grid>
              <Grid item xs={8}>
                { isNaN(progress) ? null :
                  <LinearProgress variant="determinate" value={progress} className="ruc-card-progress-component"/>
                }
              </Grid>
            </Grid> 
          </Grid>
        </Grid>
      </div>
    </>
  )
}


export default AlertIndicatorCardComponent