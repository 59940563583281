import React from 'react'

// import { makeStyles } from '@material-ui/core/styles';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';


// const useStyles = makeStyles((theme) => ({
//   root: {
//     width: '100%',
//     maxWidth: 360,
//     backgroundColor: theme.palette.background.paper,
//   },
// }));

function BasicList(props) {
  // const classes = useStyles();

  return (
    <React.Fragment>
      <List component="nav" aria-label="secondary mailbox folders">
        {props.array.map((item, key) => (
          <ListItem button key={`ruc-basic-item-${key}-${item[props.arrayKey]}`}>
            <ListItemText primary={item[props.arrayKey]}/>
          </ListItem>
        ))}
      </List>
    </React.Fragment>
  )
}

export default BasicList