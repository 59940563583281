/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-empty-pattern */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams } from 'react-router-dom'
import { path, set, lensPath } from 'ramda'
import { Grid, Paper, Typography } from '@material-ui/core'
import { mdiContentSave, mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline, mdiRestore } from '@mdi/js'

import { ActionListCheckerComponent, FabHoverButtonComponent } from '../..'
import { fetchRoleActions as _fetchRoleActions, updateRoleActions as _updateRoleActions, fetchRoles as _fetchRoles } from '../../../redux/roles/actions'
import styles from './styles'
import { i18n as i18nService } from '../../../services/util'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    rolesPermissions: path(['roles', 'rolesPermissions'], state),
    role: path(['roles', 'role'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchRoles: (token, roleId) => dispatch(_fetchRoles(token, roleId)),
    fetchRoleActions: (roleId, token) => dispatch(_fetchRoleActions(roleId, token)),
    updateRoleActions: (roleId, payload, token, callback) => dispatch(_updateRoleActions(roleId, payload, token, callback)),
})

function EditRoleActionsComponent({
    // States
    token,
    role,
    rolesPermissions,

    // Actions
    fetchRoles,
    fetchRoleActions,
    updateRoleActions,
}) {
    const classes = styles()
    const { roleId } = useParams()
    const i18n = prop => i18nService(['admin', 'edit_actions_roles', prop])
    const [rolesPermissionsLocal, setRolesPermissionsLocal] = useState(null)
    const [selectedAll, setSelectedAll] = useState(false)

    useEffect(() => {
        fetchRoleActions(roleId, token)
        fetchRoles(token, roleId)
    }, [])

    useEffect(() => {
        if (rolesPermissions[roleId]) {
            setRolesPermissionsLocal(rolesPermissions[roleId])
        }
    }, [rolesPermissions])

    useEffect(() => {
        const rolesPermissionsLocalNew = {}
        for (const rpl in rolesPermissionsLocal) {
            rolesPermissionsLocalNew[rpl] = rolesPermissionsLocal[rpl].map(item => ({...item, active: selectedAll}))
        }
        setRolesPermissionsLocal(rolesPermissionsLocalNew)
    }, [selectedAll])

    function updateRolePermission(permission, actionKey, value) {
        return function () {
            const stateLens = lensPath([permission, actionKey, 'active'])
            const newState = set(stateLens, value, rolesPermissionsLocal)
            setRolesPermissionsLocal(newState)
        }
    }

    function updateHandler() {
        let payload = { current: {}, updated: {} }

        for (const actionRole in rolesPermissionsLocal) {
            const current = rolesPermissions[roleId][actionRole]
                .map(item => ({[item.id]: item.active ? '1' : '0'}))
                .reduce((a,b,{}) => ({...b, ...a}))

            const updated = rolesPermissionsLocal[actionRole]
                .map(item => ({[item.id]: item.active ? '1' : '0'}))
                .reduce((a,b,{}) => ({...b, ...a}))

            payload = {
                current: {
                    [roleId]: {
                        ...payload.current[roleId],
                        ...current
                    }
                },
                updated: {
                    [roleId]: {
                        ...payload.updated[roleId],
                        ...updated
                    }
                },
            }
        }

        updateRoleActions(roleId, payload, token, (message) => {
            fetchRoleActions(roleId, token)
        })
    }

    return (
        <React.Fragment>
            {rolesPermissionsLocal && <React.Fragment>
            <FabHoverButtonComponent text={i18n('button_save')} icon={mdiContentSave} onClick={updateHandler} />
            <FabHoverButtonComponent
                text={i18n('button_select_all')}
                icon={selectedAll ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline}
                onClick={() => { setSelectedAll(!selectedAll)}} className={classes.selectAll}
            />
            <FabHoverButtonComponent
                text={i18n('button_revert')}
                icon={mdiRestore}
                onClick={() => { setRolesPermissionsLocal(rolesPermissions[roleId]) }} className={classes.reload}
            />
            </React.Fragment>}
            {rolesPermissionsLocal && role.name &&
                <Paper classes={{ root: classes.paperTitle}}>
                    <Typography variant="h5" align="center">{i18n('title')} {role.name || roleId}</Typography>
                </Paper>
            }
            <Grid container spacing={4}>
                {rolesPermissionsLocal ?
                    Object.keys(rolesPermissionsLocal)
                        .map((title, k) => <Grid item xl={3} lg={4} md={6} sm={12} key={k}>
                                <ActionListCheckerComponent
                                    title={title}
                                    items={rolesPermissionsLocal[title]}
                                    onChange={updateRolePermission}/>
                            </Grid>)
                    : ''
                }
            </Grid>
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(EditRoleActionsComponent)
