/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { path, isEmpty } from 'ramda'
import { Grid, Typography, TextField, Button } from '@material-ui/core'

import {
  fetchOptions as _fetchOptions,
  updateOptions as _updateOptions
} from '../../../redux/amm/options/actions'

import { i18n } from '../../../services/util'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  fields: path(['ammOptions','fields'], state),
  options: path(['ammOptions','options'], state),
})

const mapDispatchToProps = dispatch => ({
  fetchOptions: (token) => dispatch(_fetchOptions(token)),
  updateOptions: (token, payload) => dispatch(_updateOptions(token, payload)),
})

function OptionFormComponent({token,fields,options,fetchOptions,updateOptions, icon}) {

  const [formData, setFormData] = useState({})

  useEffect(() => {
    fetchOptions(token)
  },[fetchOptions,token])

  useEffect(() => {
    let initialValue = {}
    fields.forEach(key => {
      initialValue[key] = { value: options[key], error: null }
    })
    setFormData(initialValue)
  },[fields,options])

  function handleChange(input) {
    return function(event) {
      setFormData({
        ...formData,
        [input]: {
          value: event.target.value,
          error: null,
        }
      })
    }
  }

  function handleSubmit() {
    const keys = Object.keys(formData)
    let hasError = false
    let payload = {}

    keys.forEach(key => {
      if (isEmpty(formData[key].value)) {
        hasError = true
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            error: 'El campo no puede estar vacio'
          }
        })
      } else {
        payload = {
          ...payload,
          [key]: formData[key].value
        }
      }
    })

    if (!hasError) {
      updateOptions(token,payload)
    }
  }

  return (
    <Grid container spacing={0} direction="row" justify="center">
      <Grid item xs={6}>
        <Typography variant="h6">
          {icon}
          Opciones
        </Typography>
        <form className="ruc-form">
          <Grid container spacing={3} direction="row" justify="center">
            { Object.keys(formData).filter(word => word !== "application_id").map((key)=>(
              <Grid item xs={12} key={key}>
                <TextField
                  id={key}
                  fullWidth
                  error={!!formData[key].error}
                  label={i18n(['amm', 'options', key])}
                  variant="outlined"
                  value={formData[key].value}
                  autoComplete="off"
                  onChange={handleChange(key)}
                  helperText={i18n(['amm', 'options', `${key}_helper_text`])}
                />
              </Grid>
            ))}
            <Grid item xs={12}>
              <Button variant="contained" color="primary" onClick={handleSubmit} className="ruc-float-right">
                Guardar
              </Button>
            </Grid>
          </Grid>
        </form>
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(OptionFormComponent)