import { mergeRight } from 'ramda'

import { /*GET_PERMISSIONS_START,*/ GET_PERMISSIONS_SUCCESS, /*GET_PERMISSIONS_ERROR, ADD_PERMISSIONS_START, ADD_PERMISSIONS_SUCCESS,
    ADD_PERMISSIONS_ERROR,*/ GET_PERMISSION_BY_ID_SUCCESS, GET_ACTIONS_SUCCESS, GET_ACTION_BY_ID_SUCCESS, SET_PERMISSION_ACTIONS_SHOW } from './actions'

const initialState = {
    permissions: [],
    permission: {},
    actions: [],
    selected: {}
}

export default function rolesReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)

    switch (type) {
        case GET_PERMISSIONS_SUCCESS:
            return merge({ permissions: payload })
        case GET_PERMISSION_BY_ID_SUCCESS:
            return merge({ permission: payload })
        case GET_ACTIONS_SUCCESS:
            return merge({ actions: payload })
        case GET_ACTION_BY_ID_SUCCESS:
            return merge({ action: payload })
        case SET_PERMISSION_ACTIONS_SHOW:
            return merge({ selected: payload })
        default:
            return state
    }
}
