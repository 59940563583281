import React from 'react'
import { useLocation, useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { AppBar, BottomNavigation, BottomNavigationAction, Toolbar, Typography, Button, withStyles, Badge } from '@material-ui/core'
import { mdiTruckDelivery, mdiLogout, mdiCog, mdiChartAreasplineVariant/*, mdiBell*/ } from '@mdi/js'
import AmmAlertsIcon from '@material-ui/icons/Notifications';
import { path, isNil, find, equals, or, head, pathOr, isEmpty } from 'ramda'

import { SvgIconComponent } from '..'
import { openSideMenu } from '../../redux/ui-states/actions'
import { userLogout } from '../../redux/user/actions'
import { i18n } from '../../services/util'
import logoPlaceholder from '../../assets/logoPlaceholder.png'
import ROUTES from '../../routes'
import styles from './styles'

const mapStateToProps = state => ({
    isRightPanelOpen: !isNil(path(['depotsView', 'pointDetail'], state)),
    links: pathOr([], ['user', 'info', 'data', 'meta', 'links'], state),
    logo: pathOr('', ['uiConfig', 'data', 'attributes', 'app_data', 'logo_url'], state),
})

const mapDispatchToProps = dispatch => ({
    openSideMenuHandler: () => dispatch(openSideMenu()),
    logoutHandler: () => dispatch(userLogout()),
})

const StyledBadge = withStyles((theme) => ({
  badge: {
    right: -3,
    top: 13,
    border: `2px solid ${theme.palette.background.paper}`,
    padding: '0 4px',
  },
}))(Badge);


function MenuComponent({ isRightPanelOpen, links, logged, pendingAlerts,logo, logoutHandler }) {
    const classes = styles()
    const location = useLocation()
    const history = useHistory()
    const findLocationOnArray = array => or(find(equals(location.pathname), array), head(array))
    const findLocationStartLike = startPath => new RegExp(`^${startPath}`, 'gi').test(location.pathname) ? location.pathname : startPath
    const menuMainTitle = React.useRef()

    const handleChange = (event, newValue) => {
        history.push(newValue)
    }

    const logout = (event) => {
        history.push('/')
        logoutHandler()
    }

    return (
        <div className={isRightPanelOpen ? `${classes.root} ${classes.isRightPanelOpen}` : classes.root}>
            <AppBar position="fixed">
                <Toolbar>
                    {!['none', '', null, undefined].includes(logo) && (
                        <div className={classes.logoContainer}>
                            <img className={classes.imgLogo} src={isEmpty(logo) ? logoPlaceholder : logo} alt="logo" />
                        </div>
                    )}
                    <Typography variant="h6" className={logged ? classes.title : classes.titleFull} ref={menuMainTitle}>
                        {i18n(['main', 'title'])}
                    </Typography>
                    {logged && (
                        <BottomNavigation value={location.pathname} onChange={handleChange} className={classes.navigator} showLabels>

                            {links.includes('amm_url') && (
                                <BottomNavigationAction
                                    label={ROUTES.AMM.label()}
                                    value={ROUTES.AMM.path}
                                    icon={<StyledBadge badgeContent={pendingAlerts} color="secondary"><AmmAlertsIcon/></StyledBadge>}
                                    classes={{
                                        root: classes.navigatorChild,
                                        selected: classes.navigatorChildSelected,
                                    }}
                                />
                            )}

                            <BottomNavigationAction
                                label={ROUTES.ROUTES.label()}
                                value={findLocationOnArray(ROUTES.ROUTES.path)}
                                icon={<SvgIconComponent path={mdiTruckDelivery} />}
                                classes={{
                                    root: classes.navigatorChild,
                                    selected: classes.navigatorChildSelected,
                                }}
                            />

                            {links.includes('indicators_url') && (
                                <BottomNavigationAction
                                    label={ROUTES.STATS.label()}
                                    value={ROUTES.STATS.path}
                                    icon={<SvgIconComponent path={mdiChartAreasplineVariant} />}
                                    classes={{
                                        root: classes.navigatorChild,
                                        selected: classes.navigatorChildSelected,
                                    }}
                                />
                            )}

                            {links.includes('administration_url') && (
                                <BottomNavigationAction
                                    label={ROUTES.ADMIN.label()}
                                    value={findLocationStartLike(ROUTES.ADMIN.path)}
                                    icon={<SvgIconComponent path={mdiCog} />}
                                    classes={{
                                        root: classes.navigatorChild,
                                        selected: classes.navigatorChildSelected,
                                    }}
                                />
                            )}

                        </BottomNavigation>
                    )}

                    {logged &&
                        <Button
                            color="inherit"
                            onClick={logout}
                            endIcon={<SvgIconComponent path={mdiLogout} />}
                            classes={{ root: classes.logoutButton }}
                        >
                            {i18n(['main', 'logout'])}
                        </Button>
                    }
                </Toolbar>
            </AppBar>
        </div>
    );
}

export default connect(mapStateToProps, mapDispatchToProps)(MenuComponent)
