import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory, useParams } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip } from '@material-ui/core'
import { mdiPencilCircle, mdiAccountMultipleRemove } from '@mdi/js'

import { ModalComponent, SvgIconComponent } from '../..'
import { fetchPermissions as _fetchPermissions, deletePermissionActions as _deletePermissionActions,
    assignPermissionsRoles as _assignPermissionsRoles, fetchPermissionActions as _fetchPermissionActions } from '../../../redux/permissions/actions'
import { fetchRoles as _fetchRoles } from '../../../redux/roles/actions'
import { i18n as i18nService } from '../../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    permissions: path(['permissions', 'permissions'], state),
    actions: path(['permissions', 'actions'], state),
    roles: path(['roles', 'roles'], state),
})

const mapDispatchToProps = dispatch => ({
    fetchPermissions: token => dispatch(_fetchPermissions(token)),
    fetchPermissionActions: (permissionId, token) => dispatch(_fetchPermissionActions(permissionId, token)),
    fetchRoles: token => dispatch(_fetchRoles(token)),
    deletePermissionActions: (permissionId, token, actionId, callback) => dispatch(_deletePermissionActions(permissionId, token, actionId, callback)),
    assignPermissionsRoles: (body, token, callback) => dispatch(_assignPermissionsRoles(body, token, callback)),
})

function ListPermissionsActionsComponent({
    token,
    actions,

    fetchPermissions,
    fetchPermissionActions,
    fetchRoles,
    deletePermissionActions,
}) {
    const classes = styles()
    const history = useHistory()
    const { permissionId } = useParams()
    const i18n = prop => i18nService(['admin', 'permissions_actions_list', prop])
    const selectedKeys = [
        { value: 'name', label: i18n('table_name') },
        { value: 'controller_action', label: i18n('table_description') },
        { value: 'related', label: i18n('table_related_actions') },
    ]
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const [deleteModalShow, setDeleteModalShow] = useState(false)

    useEffect(() => {
        fetchPermissions(token)
        fetchRoles(token)
        fetchPermissionActions(permissionId, token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function editPermissionHandler({ id }) {
        return function () {
            history.push(`/admin/permissions/${permissionId}/actions/${id}/edit`)
        }
    }

    function removeHanlder(permission) {
        return function() {
            setSelectedToDelete(permission)
            setDeleteModalShow(true)
        }
    }

    function closeRemoveModal() {
        setDeleteModalShow(false)
        setSelectedToDelete(null)
    }

    function confirmRemove() {
        deletePermissionActions(permissionId, token, selectedToDelete.id)
        setDeleteModalShow(false)
    }

    function addActionHandler() {
        history.push(`/admin/permissions/${permissionId}/actions/new`)
    }

    // TODO: Agregar a el titulo el nombre del permiso con la accion SET_PERMISSION_ACTIONS_SHOW

    return (
        <React.Fragment>
            <Typography variant="h5" align="center">
                {i18n('title')}
                <Button color="primary" variant="contained" onClick={addActionHandler} className={classes.addActionButton}>
                    {i18n('button_add')}
                </Button>
            </Typography>
            <Table size="small" classes={{ root: classes.tableRoot }}>
                <TableHead>
                    <TableRow>
                        {selectedKeys.map((column, k) => (
                            <TableCell key={k}>{column.label}</TableCell>
                        ))}
                        <TableCell>{i18n('table_actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {actions.map((action, k) => (
                        <TableRow key={k}>
                            {selectedKeys.map((item, k) => {
                                if (item.value === 'related') {
                                    return <TableCell key={k} className={classes.actionsRelated}>{`${action[item.value].length}`}</TableCell>
                                }
                                return <TableCell key={k}>{action[item.value]}</TableCell>
                            })}
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                <Tooltip title={i18n('tooltip_edit')}>
                                    <IconButton onClick={editPermissionHandler(action)}>
                                        <SvgIconComponent path={mdiPencilCircle} />
                                    </IconButton>
                                </Tooltip>
                                <Tooltip title={i18n('tooltip_delete')}>
                                    <IconButton onClick={removeHanlder(action)}>
                                        <SvgIconComponent path={mdiAccountMultipleRemove} />
                                    </IconButton>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <ModalComponent open={deleteModalShow} onClose={closeRemoveModal}>
                <div className={classes.modalWrapper}>
                    <Typography variant="h5" align="center">{i18n('modal_title')}<br />{selectedToDelete && selectedToDelete.name}</Typography>
                    <div className={classes.modalButtonContainer}>
                        <Button color="default" variant="contained" onClick={closeRemoveModal}>{i18n('modal_button_cancel')}</Button>
                        <Button color="secondary" variant="contained" onClick={confirmRemove}>{i18n('modal_button_confirm')}</Button>
                    </div>
                </div>
            </ModalComponent>
        </React.Fragment>
    )
}

ListPermissionsActionsComponent.propTypes = {
    permissions: PropTypes.array.isRequired
}

ListPermissionsActionsComponent.defaultProps = {
    permissions: []
}

export default connect(mapStateToProps, mapDispatchToProps)(ListPermissionsActionsComponent)
