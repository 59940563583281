import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    resumeTable: {
        '& td': {
            padding: 6,
            textAlign: 'center',
        },
        '& td:first-child': {
            textAlign: 'left',
        },
        '& tr:last-child td:first-child': {
            color: theme.palette.text.primary,
        }
    },
    cellTitle: {
        color: '#ffffff',
    },
}))
