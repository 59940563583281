import {
    GET_START,
    GET_SUCCESS,
    GET_ERROR,
} from './actions'

const initialState = {
    requestStatus: 'none',
    active: true,
    data: {
        attributes: {
            indicators: {
                total: {
                    count: 0,
                    percentage: 10
                },
                pending: {
                    count: 0,
                    percentage: 0
                },
                failure: {
                    count: 0,
                    percentage: 0
                },
                success: {
                    count: 0,
                    percentage: 0
                },
                partial: {
                    count: 0,
                    percentage: 0
                },
                unassigned: {
                    count: 0,
                    percentage: 0
                }
            },
            route_states: {
                planned: {},
                unassigned: 0,
                not_started: 0,
                running: 0,
                unordered: 0,
                delayed: 0,
            },
            vehicles_with_routes: 0
        }
    },
    detail: null,
}

export default function statsReducer(state = initialState, action) {
    const { type, payload } = action
    switch (type) {
        case GET_SUCCESS:
            return payload.data ? {...state, data: payload.data} : state
        case GET_START:
        case GET_ERROR:
        default:
            return state
    }
}
