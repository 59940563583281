import { mergeRight } from 'ramda'

import {
    GET_START,
    GET_SUCCESS,
    GET_ERROR,
    GET_NOTIFICATIONS_START,
    GET_NOTIFICATIONS_SUCCESS,
    GET_NOTIFICATIONS_ERROR,
} from './actions'

const initialState = {
    requestStatus: 'none',
    errorMessage: null,
    active: true,
    response: null,
    notifications: null,
    filtersData: null,
}

export default function routeDetailReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)
    switch (type) {
        case GET_START:
        case GET_NOTIFICATIONS_START:
            return merge({ requestStatus: 'start' })
        case GET_SUCCESS:
            return merge({ requestStatus: 'success', response: payload })
        case GET_NOTIFICATIONS_SUCCESS:
            return merge({ requestStatus: 'success', notifications: payload })
        case GET_ERROR:
        case GET_NOTIFICATIONS_ERROR:
            return merge({ requestStatus: 'error', errorMessage: 'error' })
        default:
            return state
    }
}
