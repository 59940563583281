import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import Grid from '@material-ui/core/Grid';
import MenuItem from '@material-ui/core/MenuItem';
import { i18n } from '../../../services/util'
import { path } from 'ramda'
import { connect } from 'react-redux'

import {
  changeEvent as _changeEvent
} from '../../../redux/amm/alerts/actions'
const mapStateToProps = (state,ownProps) => ({
  props: ownProps,
  token: path(['user', 'auth_token'], state)
})
const mapDispatchToProps = dispatch => ({
  changeEvent: (token, alertId, body) => dispatch(_changeEvent(token, alertId, body))
})

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  formControlTextField: {
    margin: theme.spacing(1),
    minWidth: '93%',
  },
  inputClick: {
    cursor: 'pointer'
  }
}));

function AlertEventComponent(props) {
  const { alert, changeEvent, token } = props
  const initialValues = {
    code: "SUCCESS"
  }
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [inputs, setInputs] = useState(initialValues);
  const [eventCode, setEventCode] = useState(alert.event.code)
  const FAILURE_STOP_ALERT = 6

  const handleChange = (event) => {
    setInputs(inputs => ({
      ...inputs, 
      [event.target.name]: event.target.value
    }));

    event.persist();
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  const handleSubmit = (event) => {
    changeEvent(token, alert.id, inputs)
    setOpen(false);
    setEventCode(inputs.code)
    if (event) event.preventDefault();
  }

  return (
      <>
        <li>
          <label>Evento</label>
          {alert.alert_template_id === FAILURE_STOP_ALERT && alert.event.code === "FAILURE" && !alert.managed ? 
            <span 
              onClick={handleClickOpen} 
              className={classes.inputClick}>: {eventCode || '--'}
            </span>
          : `: ${alert.event.code || '--'}`
          }
        </li>

        <Dialog disableBackdropClick disableEscapeKeyDown open={open} onClose={handleClose} maxWidth='xs'>
          <DialogTitle>Evento</DialogTitle>
          <DialogContent>
            <form className={classes.container}>
              <Grid container spacing={0}>
                <Grid item xs={12}>
                  <FormControl className={classes.formControl}>
                    <InputLabel id="code-label">Código</InputLabel>
                    <Select
                      labelId="code-label"
                      id="code"
                      name='code'
                      value={inputs.code}
                      onChange={handleChange}
                    >
                      <MenuItem value={'SUCCESS'}>{i18n(['routes', 'stats', 'success'])}</MenuItem>
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </form>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose} color="primary">
              Cerrar
            </Button>
            <Button onClick={handleSubmit} color="primary">
              Cambiar
            </Button>
          </DialogActions>
        </Dialog>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertEventComponent)