import { get, post, put, del } from '../../../services/fetchService'
import { requestPending, requestSuccess, requestError } from '../../request-state/actions'
import { openExpiredSessionDialog, addAlertMessage } from '../../ui-states/actions'
import { ResponseMessageParse, HtmlMessage } from '../../../models/htmlMessage'

// Actions types
export const GET_ALERT_CONFIGURATIONS_START = 'GET_ALERT_CONFIGURATIONS_START'
export const GET_ALERT_CONFIGURATIONS_SUCCESS = 'GET_ALERT_CONFIGURATIONS_SUCCESS'
export const GET_ALERT_CONFIGURATION_SUCCESS = 'GET_ALERT_CONFIGURATION_SUCCESS'
export const GET_ALERT_CONFIGURATIONS_ERROR = 'GET_ALERT_CONFIGURATIONS_ERROR'
export const ADD_ALERT_CONFIGURATION_START = 'ADD_ALERT_CONFIGURATION_START'
export const ADD_ALERT_CONFIGURATION_SUCCESS = 'ADD_ALERT_CONFIGURATION_SUCCESS'
export const ADD_ALERT_CONFIGURATION_ERROR = 'ADD_ALERT_CONFIGURATION_ERROR'
export const UPDATE_ALERT_CONFIGURATION_START = 'UPDATE_ALERT_CONFIGURATION_START'
export const UPDATE_ALERT_CONFIGURATION_SUCCESS = 'UPDATE_ALERT_CONFIGURATION_SUCCESS'
export const UPDATE_ALERT_CONFIGURATION_ERROR = 'UPDATE_ALERT_CONFIGURATION_ERROR'
export const DELETE_ALERT_CONFIGURATION_START = 'DELETE_ALERT_CONFIGURATION_START'
export const DELETE_ALERT_CONFIGURATION_SUCCESS = 'DELETE_ALERT_CONFIGURATION_SUCCESS'
export const DELETE_ALERT_CONFIGURATION_ERROR = 'DELETE_ALERT_CONFIGURATION_ERROR'
export const CLEAR_ALERT_CONFIGURATION = 'CLEAR_ALERT_CONFIGURATION'


export const GET_ALERT_TEMPLATES_START = 'GET_ALERT_TEMPLATES_START'
export const GET_ALERT_TEMPLATES_SUCCESS = 'GET_ALERT_TEMPLATES_SUCCESS'
export const GET_ALERT_TEMPLATES_ERROR = 'GET_ALERT_TEMPLATES_ERROR'

export const GET_CATEGORIES_START = 'GET_CATEGORIES_START'
export const GET_CATEGORIES_SUCCESS = 'GET_CATEGORIES_SUCCESS'
export const GET_CATEGORIES_ERROR = 'GET_CATEGORIES_ERROR'

// Actions creators
export const clearAlertConfiguration = () => ({
    type: CLEAR_ALERT_CONFIGURATION
})

// Promisses
export const fetchAlertConfigurationsPromise = async (token, alertConfigurationId = '') => get(`amm/alert-configurations/${alertConfigurationId}`, '', {Authorization: token})
export const addAlertConfigurationPromise = async (body, token) => post('amm/alert-configurations', body, {Authorization: token})
export const updateAlertConfigurationPromise = async (id, body, token) => put(`amm/alert-configurations/${id}`, body, {Authorization: token})
export const deleteAlertConfigurationPromise = async (id, token) => del(`amm/alert-configurations/${id}`, {Authorization: token})
export const fetchAlertTemplatesPromise = async (token) => get(`amm/alert-templates`, { unattached: true }, {Authorization: token})
export const fetchCategoriesPromise = async (token) => get(`amm/categories`, '', {Authorization: token})

// Async actions creators
export const fetchAlertConfigurations = (token, alertConfigurationId) => async dispatch => {
    dispatch({ type: GET_ALERT_CONFIGURATIONS_START })
    dispatch(requestPending())
    try {
        const response = await fetchAlertConfigurationsPromise(token, alertConfigurationId)

        dispatch(requestSuccess())

        if (!!alertConfigurationId) {
            dispatch({ type: GET_ALERT_CONFIGURATION_SUCCESS, payload: response.data })
        } else {
            dispatch({ type: GET_ALERT_CONFIGURATIONS_SUCCESS, payload: response })
        }
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: GET_ALERT_CONFIGURATIONS_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const addAlertConfiguration = (body, token) => async dispatch => {
    dispatch({ type: ADD_ALERT_CONFIGURATION_START })
    dispatch(requestPending())

    try {
        const payload = await addAlertConfigurationPromise({ alert_configuration: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: ADD_ALERT_CONFIGURATION_SUCCESS })

        const response = await fetchAlertConfigurationsPromise(token)
        dispatch(requestSuccess())
        dispatch({ type: GET_ALERT_CONFIGURATIONS_SUCCESS, payload: response })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: ADD_ALERT_CONFIGURATION_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const updateAlertConfiguration = (id, body, token) => async dispatch => {
    dispatch({ type: UPDATE_ALERT_CONFIGURATION_START })
    dispatch(requestPending())

    try {
        const payload = await updateAlertConfigurationPromise(id, { alert_configuration: body }, token)

        dispatch(requestSuccess())
        dispatch({ type: UPDATE_ALERT_CONFIGURATION_SUCCESS })
        const response = await fetchAlertConfigurationsPromise(token)
        dispatch(requestSuccess())
        dispatch({ type: GET_ALERT_CONFIGURATIONS_SUCCESS, payload: response })

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: UPDATE_ALERT_CONFIGURATION_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const deleteAlertConfiguration = (id, token, callback) => async dispatch => {
    dispatch({ type: DELETE_ALERT_CONFIGURATION_START })
    dispatch(requestPending())

    try {
        const payload = await deleteAlertConfigurationPromise(id, token)

        dispatch(requestSuccess())
        dispatch({ type: DELETE_ALERT_CONFIGURATION_SUCCESS })
        if (!!callback) {
            callback(payload)
        }

        const { status, statusText, message, severity } = ResponseMessageParse(payload)
        dispatch(addAlertMessage(new HtmlMessage(status, statusText, message, severity)))
    } catch (error) {
        dispatch(requestError())
        dispatch({ type: DELETE_ALERT_CONFIGURATION_ERROR })
        if (error.htmlStatus === 422) {
            dispatch(openExpiredSessionDialog())
        }
        dispatch(addAlertMessage(error))
    }
}

export const fetchAlertTemplates = (token) => async dispatch => {
    dispatch({ type: GET_ALERT_TEMPLATES_START })
    dispatch(requestPending())

    try {
        const result = await fetchAlertTemplatesPromise(token)
        dispatch({ type: GET_ALERT_TEMPLATES_SUCCESS, payload: result })
        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: GET_ALERT_TEMPLATES_SUCCESS })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}

export const fetchCategories = (token) => async dispatch => {
    dispatch({ type: GET_CATEGORIES_START })
    dispatch(requestPending())

    try {
        const result = await fetchCategoriesPromise(token)
        dispatch({ type: GET_CATEGORIES_SUCCESS, payload: result })
        dispatch(requestSuccess())
    } catch (error) {
        //TODO catch errors
        dispatch({ type: GET_CATEGORIES_SUCCESS })
        dispatch(requestError())
        dispatch(addAlertMessage(error))
    }
}