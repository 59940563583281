import React from 'react'
import { Tooltip, makeStyles } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'

const styles = makeStyles(theme => ({
    progressRoot: {
        width: '100%',
        display: 'flex',
        background: grey[200],
        height: 35,
        borderRadius: 4,
        overflow: 'hidden',
        textAlign: 'center'
    },
}))

function ProgressComponent({sections}) {
    const classes = styles()

    return (
        <div className={classes.progressRoot}>
            {sections.map((item, key) => (
                <Tooltip key={`progress-item ${key}`} title={`${item.percentage}%`} placement="top" arrow>
                    <div style={{ background: item.color, width: `${item.percentage}%` }}></div>
                </Tooltip>
            ))}
        </div>
    )
}

export default ProgressComponent
