import React from 'react'
import classnames from 'classnames'
import { Drawer, Fab } from '@material-ui/core'
import { mdiChevronRight, mdiChevronLeft } from '@mdi/js'

import SvgIconComponent from '../SvgIcon/SvgIconComponent'
import styles from './styles'

function ControlPanelComponent({ anchor, size, children, open, change, ...rest }) {

    const classes = styles()

    function changeHanlder(e) {
        e.preventDefault()
        change()
    }

    function selectIcon(open, anchor) {
        if (anchor === 'right') {
            return open ? mdiChevronRight : mdiChevronLeft
        }
        return open ? mdiChevronLeft : mdiChevronRight
    }

    return (
        <Drawer
            classes={{ paper: classes.paper }}
            variant="permanent"
            anchor={anchor || 'left'}
            {...rest} >
            <div
                style={{...size && open ? {width: size} : {} , overflowY: 'auto'}}
                className={classnames(classes.wrapper, { [classes.open]: open, [classes.close]: !open })}>
                {children}
                <Fab color="primary" className={anchor === 'right' ? classes.openButtomRight : classes.openButtomLeft} onClick={changeHanlder}>
                    <SvgIconComponent path={selectIcon(open, anchor)} />
                </Fab>
            </div>
        </Drawer>
    )
}

export default ControlPanelComponent
