import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useHistory, Link, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { path, pathOr } from 'ramda'
import { Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip, SvgIcon } from '@material-ui/core'
import { /*mdiFormatListBulleted, */mdiBellRemove, mdiPencilCircle, mdiTrashCan, mdiBellOutline, mdiPlusBox } from '@mdi/js'

import {
  fetchAlertTemplates as _fetchAlertTemplates,
  deleteAlertTemplate as _deleteAlertTemplate
} from '../../../redux/amm/alert-templates/actions'

import { i18n as i18nService } from '../../../services/util'
import { NotFoundPage } from '../../../pages'
import { AlertTemplateFormComponent } from '../../../components'
import { ConfirmationBuilder as RucConfirmationBuilder } from '../../../components/Ruc/'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  links: pathOr([], ['user', 'info', 'data', 'meta', 'links'], state),
  alertTemplates: path(['alertTemplates', 'alertTemplates'], state)
})

const mapDispatchToProps = dispatch => ({
  fetchAlertTemplates: token => dispatch(_fetchAlertTemplates(token)),
  deleteAlertTemplate: (alertTemplateId, token, callback) => dispatch(_deleteAlertTemplate(alertTemplateId, token, callback))
})

function AlertTemplateTableComponent({token,links,alertTemplates,fetchAlertTemplates,deleteAlertTemplate}) {
  let { path, url } = useRouteMatch();
  const history = useHistory()
  const i18n = prop => i18nService(['amm',"alert-template",prop])
  const selectedKeys = [
    { value: 'id', label: i18n('id') },
    { value: 'name', label: i18n('name') },
    { value: 'alert_description', label: i18n('alert_description') },
    { value: 'callback_description', label: i18n('callback_description') },
  ]
  const [selectedToDelete, setSelectedToDelete] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)

  useEffect(() => { fetchAlertTemplates(token) }, [fetchAlertTemplates,token])

  function editAlertTemplateHandler({ id }) {
    return function () {
      history.push(`/amm/alert-templates/${id}/edit`)
    }
  }

  function removeHanlder(alertTemplate) {
    return function() {
      setSelectedToDelete(alertTemplate)
      setDeleteModalShow(true)
    }
  }

  function closeRemoveModal() {
    setDeleteModalShow(false)
    setSelectedToDelete(null)
  }

  function confirmRemove() {
    deleteAlertTemplate(selectedToDelete.id, token, () => {
      setDeleteModalShow(false)
      fetchAlertTemplates(token)
    })
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={url}>
          <Typography variant="h6">
            {/* <SvgIcon className="ruc-title-first-icon"><path d={mdiFormatListBulleted}/></SvgIcon> */}
            <SvgIcon><path d={mdiBellOutline}/></SvgIcon>
            Plantilla de Alertas
            <Link to={`${url}/new`}>
              <IconButton className="new-class">
                <SvgIcon><path d={mdiPlusBox}/></SvgIcon>
              </IconButton>
            </Link>
          </Typography>
          <Table size="small">
            <TableHead>
              <TableRow>
                {selectedKeys.map((column, k) => (
                  <TableCell key={k} className={`ruc-${column.value}`}>{column.label}</TableCell>
                ))}
                {links.includes('only_root_actions') && <TableCell className="actions two-icons">{i18n('actions')}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {alertTemplates.map((alertTemplate, k) => (
                <TableRow key={k}>
                  {selectedKeys.map((item, kx) => (
                    <TableCell key={kx} className={`ruc-${item.value}`}>
                      { item.value === 'color' ? 
                        <div style={{borderRadius: "15px", backgroundColor: alertTemplate[item.value], width: '20px',height: '20px'}}/>
                      : alertTemplate[item.value] }
                    </TableCell>
                  ))}
                  {links.includes('only_root_actions') &&
                  <TableCell style={{ whiteSpace: 'nowrap' }} className="actions two-icons">
                    <Tooltip title={i18nService(['amm','actions','edit'])}>
                      <IconButton onClick={editAlertTemplateHandler(alertTemplate)}>
                        <SvgIcon><path d={mdiPencilCircle}></path></SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={i18nService(['amm','actions','delete'])}>
                      <IconButton onClick={removeHanlder(alertTemplate)}>
                        <SvgIcon><path d={mdiTrashCan}></path></SvgIcon>
                      </IconButton>
                    </Tooltip>
                  </TableCell>}
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Route>
        <Route path={`${path}/new`}>
          <AlertTemplateFormComponent />
        </Route>
        <Route exact path={`${path}/:alertTemplateId/edit`}>
          <AlertTemplateFormComponent />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
      <RucConfirmationBuilder 
        open={deleteModalShow}
        onClose={closeRemoveModal}
        confirmIcon={mdiBellRemove}
        confirmMessage="Seguro que desea eliminar"
        cancelAction={closeRemoveModal}
        confirmAction={confirmRemove}
      >{selectedToDelete && selectedToDelete.name}</RucConfirmationBuilder>
    </React.Fragment>
  )
}

AlertTemplateTableComponent.propTypes = {
  alert_templates: PropTypes.array.isRequired
}

AlertTemplateTableComponent.defaultProps = {
  alert_templates: []
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertTemplateTableComponent)
