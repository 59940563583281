import { mergeRight } from 'ramda'

import {
    GET_CATEGORIES_SUCCESS,
    GET_CATEGORY_SUCCESS,
    CLEAR_CATEGORY,
    GET_USERS_SUCCESS,
    GET_COLORS_SUCCESS
} from './actions'

const initialState = {
    categories: [],
    category: {},
    users: [],
    colors: []
}

export default function categoriesReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)

    switch (type) {
        case GET_CATEGORIES_SUCCESS:
            return merge({ categories: payload })
        case GET_CATEGORY_SUCCESS:
            return merge({ category: payload })
        case CLEAR_CATEGORY:
            return merge({ category: {} })
        case GET_USERS_SUCCESS:
            return merge({ users: payload })
        case GET_COLORS_SUCCESS:
            return merge({ colors: payload })
        default:
            return state
    }
}