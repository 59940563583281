import React, { useState, useEffect } from 'react'
import { pathOr } from 'ramda'
import { ResponsiveContainer, PieChart, Pie, Cell, Legend } from 'recharts'
import { grey } from '@material-ui/core/colors'
import { legend } from './styles'
import { i18n as i18nService } from '../../../services/util'

function RoutesPlaningPieComponent({ data, colors }) {
    const [parsedData, setParsedData] = useState([])
    const i18n = prop => i18nService(['routes', 'stats', prop])
    const names = [{ value: 'finished', label: i18n('pie_legend_inner_text') }, { value: 'not_started', label: i18n('pie_legend_not_started') }]
    const classes = legend()

    useEffect(() => {
        setParsedData(dataHanlder(data))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    function dataHanlder(d) {
        let finished = pathOr(0, ['finished'], d)
        let planned = pathOr(0, ['planned', 'count'], d)
        return [
            {
                name: i18n('pie_legend_inner_text'), 
                value: finished, 
                custom_label: finished
            },
            {
                name: i18n('pie_legend_not_started'), 
                value: planned - finished, 
                custom_label: planned
            }
        ]
    }

    const renderLegend = (props) => {
        const { payload } = props

        return (
            <div className={classes.legendWrapper}>
                <div>
                    {payload.map(item => pathOr('0',['payload', 'payload', 'custom_label'], item)).join(' / ')}
                </div>
                <div>
                    {i18n('pie_legend_inner_text')}
                </div>
            </div>
        )
    }

    return (
        <React.Fragment>
            <ResponsiveContainer>
                <PieChart fontFamily={'"Roboto", "Helvetica", "Arial", sans-serif'}>
                    <Legend
                        verticalAlign="middle"
                        content={renderLegend}
                        wrapperStyle={{
                            position: 'absolute',
                            top: 0,
                            left: 0,
                            width: '100%',
                            height: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    />
                    <Pie
                        data={parsedData}
                        fill={grey[800]}
                        outerRadius={90}
                        innerRadius={0}
                        dataKey="value"
                        labelLine={true}
                    >
                        {names.map((name, k) => <Cell key={k} fill={colors[name.value]}/>)}
                    </Pie>
                </PieChart>
            </ResponsiveContainer>
        </React.Fragment>
    )
}

export default RoutesPlaningPieComponent
