import { get } from '../../services/fetchService'
import { addAlertMessage } from '../ui-states/actions'

// Actions types

export const GET_START = 'STATS_GET_START'
export const GET_SUCCESS = 'STATS_GET_SUCCESS'
export const GET_ERROR = 'STATS_GET_ERROR'

// Actions creators

export const getStart = () => ({
    type: GET_START,
})
export const getSuccess = payload => ({
    type: GET_SUCCESS,
    payload
})
export const getError = () => ({
    type: GET_ERROR,
})

// Async action creators

export const fetchDataPromise = async token => get('stats', {sort: '-id'}, {Authorization: token})

export const fetchData = token => async dispatch => {
    dispatch(getStart())

    try {
        const payload = await fetchDataPromise(token)

        dispatch(getSuccess(payload))
    } catch (error) {
        console.error(error)
        dispatch(getError())
        dispatch(addAlertMessage(error))
    }
}
