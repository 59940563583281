/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { path, pathOr } from 'ramda'
import classnames from 'classnames'
import { Grid, Typography, Button, Paper, ButtonGroup, TextField } from '@material-ui/core'
import { grey } from '@material-ui/core/colors'
import * as mdi from '@mdi/js'
import { mdiSend, mdiCalendar, mdiGroup } from '@mdi/js'
import { ResponsiveContainer, PieChart, Pie, Cell, CartesianGrid, XAxis, YAxis, Bar, BarChart, Tooltip } from 'recharts'
import format from 'date-fns/format'

import { StatsProgressComponent, LoaderComponent, PopCheckMenuComponent, SvgIconComponent, DatePickerComponent } from '../../components'
import { fetchData } from '../../redux/indicators/actions'
import { fetchFiltersData as _getFiltersData } from '../../redux/routes-view/actions'
import { requestPending as _requestPending, requestSuccess as _requestSuccess } from '../../redux/request-state/actions'
import { i18n } from '../../services/util'
import styles from './styles'
import { Autocomplete } from '@material-ui/lab'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    colors: path(['uiConfig', 'data', 'attributes', 'app_data', 'graphics'], state),
    truckIcon: path(['uiConfig', 'data', 'attributes', 'app_data','mapLayers', 'vehicle', 'icon'], state),
    indicators: path(['indicators', 'response', 'indicators'], state),
    loading: path(['request', 'loading'], state),
    depots: path(['routesLayer', 'filtersData', 'depots'], state),
    vehicles: path(['routesLayer', 'filtersData', 'vehicles'], state),
    clusters: pathOr([], ['user', 'info', 'data', 'meta', 'clusters'], state),
})

const mapDispatchToProps = dispatch => ({
    getIndicators: (token, qs, cb) => dispatch(fetchData(token, qs, cb)),
    getFiltersData: (token, qs) => dispatch(_getFiltersData(token, qs)),
    requestPending: () => dispatch(_requestPending()),
    requestSuccess: () => dispatch(_requestSuccess()),
})

function StatsPage(props) {
    const {
        token, colors, indicators, loading, depots, vehicles, truckIcon,
        getIndicators, requestPending, requestSuccess, getFiltersData, clusters
    } = props
    const classes = styles()
    const today = new Date()
    const initialQs = {
        from: today,
        to: today,
    }
    const [showDepotsList, setShowDepotsList] = useState(false)
    const [showClustersList, setShowClustersList] = useState(false)
    const [showCalendarList, setShowCalendarList] = useState(false)
    const [filterQs, setFilterQs] = useState(initialQs)
    const [selectedVehicle, setSelectedVehicle] = useState(null)
    const [page_title, setPageTitle] = useState(i18n(['indicators', 'title']))
    const [selected, setSelected] = useState([])
    const [selectedCluster, setSelectedCluster] = useState([])
    const [popCheckTitle, setpopCheckTitle] = useState()

    useEffect(() => {
        if (token) {
            getIndicators(token, parseDate(), (error, response) => {
                requestSuccess()
            })
            getFiltersData(token, SetQueryStrings())
        } else {
            requestPending()
        }
    }, [])

    function SetQueryStrings() {
        return {
            ...filterQs,
            with_routes: true
        }
    }

    function send() {
        setShowDepotsList(false)
        setShowCalendarList(false)
        requestPending()
        getIndicators(token, parseDate(), (error, response) => {
            requestSuccess()
        })
    }

    function parseDate() {
        return {
            ...filterQs,
            from: format(filterQs.from, 'yyyy-MM-dd'),
            to: format(filterQs.to, 'yyyy-MM-dd'),
        }
    }

    function getChartData(chart) {
        return Object.keys(chart).map(key => ({
            name: i18n(['indicators', 'pieChartTooltip', key]),
            key: key,
            value: chart[key]
        }))
    }

    function getBarChartItemsData(items) {
        const {items_ahead, items_on_time, items_late} = items

        return [
            {name: i18n(['indicators', 'items', 'items_ahead']), value: items_ahead},
            {name: i18n(['indicators', 'items', 'items_on_time']), value: items_on_time},
            {name: i18n(['indicators', 'items', 'items_late']), value: items_late},
        ]
    }

    function toggleDepotsList() {
        setShowDepotsList(!showDepotsList)
        setShowCalendarList(false)
        setShowClustersList(false)
        setpopCheckTitle('Depots')
    }

    function toggleCalendarList() {
        setShowCalendarList(!showCalendarList)
        setShowDepotsList(false)
        setShowClustersList(false)
    }

    function toggleClusterList() {
        setShowClustersList(!showClustersList)
        setShowCalendarList(false)
        setShowDepotsList(false)
        setpopCheckTitle('Zonas')
    }

    function setFrom(value) {
        setFilterQs({
            ...filterQs,
            from: value,
            to: value
        })
    }

    function addItems(items) {
        setFilterQs({
            ...filterQs,
            by_depot_nid: items,
        })
    }

    function addClustersToFilters(itemsClusters) {
        setFilterQs({
            ...filterQs,
            cluster_id: itemsClusters,
        })
    }

    function vehiclesHandler(e, value) {
        setSelectedVehicle(value)
    }

    function renderCustomizedLabel(props) {
        const { percent, fill, x, y } = props

        return (
            <text x={x - 16} y={y} fill={fill} dominantBaseline="central">
                {`${(percent * 100).toFixed(0)}%`}
            </text>
        )
    }

    function renderCustomizedHorizontalLabel(props) {
        const { value, fill, x, y, width, height } = props
        return (
            <text x={x + width + 5} y={y + (height / 2)} fill={fill} dominantBaseline="central">
                {`${value}`}
            </text>
        )
    }

    function renderCustomizedBarLabel(props) {
        const { value, fill, x, y, width } = props
        const percent = value / indicators.items.panels.planned_items

        return (
            <text x={x + (width/2) - (`${percent || ''}`.length + 1)} y={y - 10} fill={fill}>
                {`${percent ? (percent * 100).toFixed(0) : 0}%`}
            </text>
        )
    }

    return(
        <div className={classes.container}>
            {indicators && depots && vehicles && (
                <Grid container spacing={3}>
                    <Grid item xs={4} lg={3} md={3} xl={2}>
                        <Paper variant="outlined" classes={{ root: classes.paperRoot }}>
                            <div className={classnames(classes.selectButtonPieContainer, classes.relative)}>
                                <ButtonGroup fullWidth>
                                    <Button variant="outlined" classes={{ root: classes.toggleButton }} onClick={toggleDepotsList}>
                                        {page_title}
                                    </Button>
                                    <Button classes={{ root: classnames(classes.toggleButton, classes.toggleIcon) }} onClick={toggleCalendarList}> <SvgIconComponent path={mdiCalendar} /></Button>
                                    <Button classes={{ root: classnames(classes.toggleButton, classes.toggleIcon) }} onClick={toggleClusterList}> <SvgIconComponent path={mdiGroup} /></Button>
                                    <Button classes={{ root: classnames(classes.toggleButton, classes.toggleIcon) }} onClick={send}> <SvgIconComponent path={mdiSend} /></Button>
                                </ButtonGroup>
                                {showCalendarList && <DatePickerComponent onChange={setFrom} date={filterQs.from} />}
                                {showDepotsList && <PopCheckMenuComponent itemsGroup={[{ items: depots }]} itemsByColumn={8} onChange={addItems} setPageTitle={setPageTitle} selected={selected} setSelected={setSelected} popCheckTitle={popCheckTitle} />}
                                {showClustersList && <PopCheckMenuComponent itemsGroup={[{ items: clusters }]} itemsByColumn={8} onChange={addClustersToFilters} selected={selectedCluster} setSelected={setSelectedCluster} popCheckTitle={popCheckTitle} />}
                            </div>

                            <div className={classes.pieContainer}>
                                <div className={classes.pieItem}>
                                    <Typography align="center" variant="h5">{i18n(['indicators', 'routes', 'chart_title'])}</Typography>
                                    <ResponsiveContainer>
                                        <PieChart fontFamily={'"Roboto", "Helvetica", "Arial", sans-serif'}>
                                            <Pie
                                                data={getChartData(indicators.routes.chart)}
                                                fill={grey[800]}
                                                outerRadius={90}
                                                innerRadius={70}
                                                dataKey="value"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                isAnimationActive={false}
                                                cy={120}
                                            >
                                                {getChartData(indicators.routes.chart).map((item, k) => <Cell key={k} fill={colors[item.key]}/>)}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className={classes.pieItem}>
                                    <Typography align="center" variant="h5">{i18n(['indicators', 'items', 'chart_title'])}</Typography>
                                    <ResponsiveContainer>
                                        <PieChart fontFamily={'"Roboto", "Helvetica", "Arial", sans-serif'}>
                                            <Pie
                                                data={getChartData(indicators.items.chart)}
                                                fill={grey[800]}
                                                outerRadius={90}
                                                innerRadius={70}
                                                dataKey="value"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                isAnimationActive={false}
                                                cy={120}
                                            >
                                                {getChartData(indicators.loads.chart).map((item, k) => <Cell key={k} fill={colors[item.key]}/>)}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                                <div className={classes.pieItem}>
                                    <Typography align="center" variant="h5">{i18n(['indicators', 'loads', 'chart_title'])}</Typography>
                                    <ResponsiveContainer>
                                        <PieChart fontFamily={'"Roboto", "Helvetica", "Arial", sans-serif'}>
                                            <Pie
                                                data={getChartData(indicators.loads.chart)}
                                                fill={grey[800]}
                                                outerRadius={90}
                                                innerRadius={70}
                                                dataKey="value"
                                                labelLine={false}
                                                label={renderCustomizedLabel}
                                                isAnimationActive={false}
                                                cy={120}
                                            >
                                                {getChartData(indicators.loads.chart).map((item, k) => <Cell key={k} fill={colors[item.key]}/>)}
                                            </Pie>
                                            <Tooltip />
                                        </PieChart>
                                    </ResponsiveContainer>
                                </div>
                            </div>
                        </Paper>
                    </Grid>

                    <Grid item xs={8} lg={9} md={9} xl={10}>
                        <Paper variant="outlined" classes={{ root: classes.paperRoot }}>
                            <Grid container>
                                <Grid item xs={3}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'planned_routes'])} <span>{indicators.routes.panels.planned_routes_label}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'started_routes'])} <span>{indicators.routes.panels.started_routes_label}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'delayed_routes'])} <span>{indicators.routes.panels.delayed_routes_label}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'ordered_routes'])} <span>{indicators.routes.panels.ordered_routes_label}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'finished_routes'])} <span>{indicators.routes.panels.finished_routes_label}</span>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'planned_travel_distance'])} <span>{indicators.routes.panels.planned_travel_distance}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'traveled_distance'])} <span>{indicators.routes.panels.traveled_distance}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'routes', 'traveled_distance_percentage'])} <span
                                                style={{ color: parseInt(indicators.routes.panels.traveled_distance_percentage.replace('%', '')) <= 100 ? colors.success : colors.failure}}
                                            >{indicators.routes.panels.traveled_distance_percentage}</span>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'planned_items'])} <span>{indicators.items.panels.planned_items_label}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'items', 'pending_items'])}
                                            max={indicators.items.panels.planned_items}
                                            value={indicators.items.panels.pending_items}
                                            color={colors.pending}
                                            value_label={indicators.items.panels.pending_items_label}
                                        />
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'items', 'success_items'])}
                                            max={indicators.items.panels.planned_items}
                                            value={indicators.items.panels.success_items}
                                            color={colors.success}
                                            value_label={indicators.items.panels.success_items_label}
                                        />
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'items', 'partial_items'])}
                                            max={indicators.items.panels.planned_items}
                                            value={indicators.items.panels.partial_items}
                                            color={colors.success}
                                            value_label={indicators.items.panels.partial_items_label}
                                        />
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'items', 'failure_items'])}
                                            max={indicators.items.panels.planned_items}
                                            value={indicators.items.panels.failure_items}
                                            color={colors.failure}
                                            value_label={indicators.items.panels.failure_items_label}
                                        />
                                    </div>
                                </Grid>
                                <Grid item xs={3}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'loads', 'planned_delivery'])} <span>{indicators.loads.panels.planned_delivery_label} {i18n(['indicators', 'loads', 'load_type'])}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'loads', 'pending_delivery'])}
                                            max={indicators.loads.panels.planned_delivery}
                                            value={indicators.loads.panels.pending_delivery}
                                            color={colors.pending}
                                            value_label={indicators.loads.panels.pending_delivery_label}
                                            sufix={i18n(['indicators', 'loads', 'load_type'])}
                                        />
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'loads', 'success_delivery'])}
                                            max={indicators.loads.panels.planned_delivery}
                                            value={indicators.loads.panels.success_delivery}
                                            color={colors.success}
                                            value_label={indicators.loads.panels.success_delivery_label}
                                            sufix={i18n(['indicators', 'loads', 'load_type'])}
                                        />
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <StatsProgressComponent
                                            text={i18n(['indicators', 'loads', 'failure_delivery'])}
                                            max={indicators.loads.panels.planned_delivery}
                                            value={indicators.loads.panels.failure_delivery}
                                            color={colors.failure}
                                            value_label={indicators.loads.panels.failure_delivery_label}
                                            sufix={i18n(['indicators', 'loads', 'load_type'])}
                                        />
                                    </div>
                                </Grid>
                            </Grid>
                            <div className={classes.divider}></div>
                            <Grid container>
                                <Grid item xs={4}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'items_quality'])}
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'planned_items'])} <span>{indicators.items.panels.planned_items}</span>
                                            <span>
                                                <StatsProgressComponent
                                                    max={100}
                                                    value={100}
                                                    sufix="%"
                                                    color={colors.pending}
                                                />
                                            </span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'items_assigned'])} <span>{indicators.items.panels.items_assigned}</span>
                                            <span>
                                                <StatsProgressComponent
                                                    max={100}
                                                    value={parseInt(indicators.items.panels.items_assigned / indicators.items.panels.planned_items * 100)}
                                                    sufix="%"
                                                    color={colors.pending}
                                                />
                                            </span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'items_reported'])} <span>{indicators.items.panels.items_reported}</span>
                                            <span>
                                                <StatsProgressComponent
                                                    max={100}
                                                    value={parseInt(indicators.items.panels.items_reported / indicators.items.panels.planned_items * 100)}
                                                    sufix="%"
                                                    color={colors.pending}
                                                />
                                            </span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'items_on_site'])} <span>{indicators.items.panels.items_on_site}</span>
                                            <span>
                                                <StatsProgressComponent
                                                    max={100}
                                                    value={parseInt(indicators.items.panels.items_on_site / indicators.items.panels.planned_items * 100)}
                                                    sufix="%"
                                                    color={colors.pending}
                                                />
                                            </span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'items_on_time'])} <span>{indicators.items.panels.items_on_time}</span>
                                            <span>
                                                <StatsProgressComponent
                                                    max={100}
                                                    value={parseInt(indicators.items.panels.items_on_site / indicators.items.panels.planned_items * 100)}
                                                    sufix="%"
                                                    color={colors.pending}
                                                />
                                            </span>
                                        </Typography>
                                    </div>
                                </Grid>
                                <Grid item xs={4}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'vehicles', 'total'])} <span style={{float: 'right'}}><SvgIconComponent viewBox="0 -3 24 24" path={mdi[truckIcon.name]} /></span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'vehicles', 'with_routes'])} <span>{indicators.vehicles.panels.with_routes}</span>
                                        </Typography>
                                    </div>
                                    {/* <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'vehicles', 'available'])} <span>{indicators.vehicles.panels.available}</span>
                                        </Typography>
                                    </div> */}
                                </Grid>
                                <Grid item xs={4} classes={{ root: classes.relative }}>
                                    <Autocomplete
                                        id="vehicles"
                                        options={vehicles}
                                        getOptionLabel={(option) => option.name}
                                        onChange={vehiclesHandler}
                                        classes={{input: classes.autocompleteInput}}
                                        renderInput={(params) => <TextField {...params} fullWidth label={`${i18n(['indicators', 'vehicles', 'with_routes'])} ${indicators.vehicles.panels.with_routes}`} variant="outlined" />}
                                    />
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'vehicles', 'success_delivery'])} <span>{selectedVehicle ? indicators.data_by_vehicle[selectedVehicle.value].success : indicators.vehicles.panels.success_delivery}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'vehicles', 'pending_delivery'])} <span>{selectedVehicle ? indicators.data_by_vehicle[selectedVehicle.value].pending : indicators.vehicles.panels.pending_delivery}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.itemContainer}>
                                        <Typography>
                                            {i18n(['indicators', 'vehicles', 'available_delivery'])} <span>{selectedVehicle ? indicators.data_by_vehicle[selectedVehicle.value].available : indicators.vehicles.panels.available_delivery}</span>
                                        </Typography>
                                    </div>
                                </Grid>
                            </Grid>
                            <div className={classes.divider}></div>
                            <Grid container className={classes.lastRow }>
                                <Grid item xs={6} classes={{ root: classes.fullWidth }}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'items', 'items_punctuality'])} <span>{indicators.items.panels.items_punctuality}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.barChartItemsData}>
                                        <ResponsiveContainer>
                                            <BarChart data={getBarChartItemsData(indicators.items.panels)} margin={{ top: 10, right: 0, left: 0, bottom: 0 }}>
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis dataKey="name" />
                                                <YAxis />
                                                <Tooltip content={<RenderCustomizedBarTooltip />}/>
                                                <Bar dataKey="value" fill="#8884d8" label={renderCustomizedBarLabel} isAnimationActive={false}>
                                                    <Cell fill={colors.delayed} />
                                                    <Cell fill={colors.success} />
                                                    <Cell fill={colors.failure} />
                                                </Bar>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                                <Grid item xs={6} classes={{ root: classes.fullWidth }}>
                                    <div className={classes.withBorderSubTitile}>
                                        <Typography>
                                            {i18n(['indicators', 'failure_deliveries', 'failure_items'])} <span>{indicators.failure_deliveries.failure_items}</span>
                                        </Typography>
                                    </div>
                                    <div className={classes.barChartItemsData}>
                                        <ResponsiveContainer>
                                            <BarChart
                                                data={indicators.failure_deliveries.chart.sort((a,b) => b.value - a.value)}
                                                layout="vertical"
                                                margin={{ top: 0, right: 10, left: 0, bottom: 0 }}
                                            >
                                                <CartesianGrid strokeDasharray="3 3" />
                                                <XAxis type="number" />
                                                <YAxis
                                                    dataKey="name"
                                                    width={150}
                                                    type="category"
                                                    tick={{width: 120}}
                                                />
                                                <Tooltip />
                                                <Bar dataKey="value" fill={colors.pending} label={renderCustomizedHorizontalLabel} isAnimationActive={false}/>
                                            </BarChart>
                                        </ResponsiveContainer>
                                    </div>
                                </Grid>
                            </Grid>
                        </Paper>
                    </Grid>
                </Grid>
            )}
            <LoaderComponent visible={loading} />
        </div>
    )
}


function RenderCustomizedBarTooltip(props) {
    const { active, payload, label } = props
    const classes = styles()

    if (active) {
      return (
        <div className={classes.customTooltip}>
          <p>{`${label} : ${payload[0].value}`}</p>
        </div>
      );
    }
    return null
}

export default connect(mapStateToProps, mapDispatchToProps)(StatsPage)
