import { mergeDeepRight } from 'ramda'
import { blue, teal, blueGrey, red, green, orange, grey } from '@material-ui/core/colors'
import { GET_SUCCESS, UPDATE_SUCCESS } from './actions'

// const depotsIconLens = lensPath(['depotsPage', 'icon'])

const initialState = {
    data: {
        id: '1',
        type: 'app_configuration',
        attributes: {
            organization_id: 3,
            cluster_id: null,
            app_data: {
                logo_url: '',
                palette: {
                    primary: {
                        main: '#1565c0',
                        light: '#5e92f3',
                        dark: '#003c8f',
                    },
                    secondary: {
                        main: '#d32f2f',
                        light: '#ff6659',
                        dark: '#9a0007',
                    },
                    background: {
                        default: '#ffffff',
                        paper: '#ffffff',
                        highlight: '#ebebeb',
                        emphasis: '#e9e9e9',
                    },
                },
                graphics: {
                    success: green[700],
                    pending: blue[800],
                    failure: red[800],
                    partial: orange[800],
                    unassigned: grey[200],
                    delayed: orange[800],
                    started: green[700],
                    failure_delivery: red[800],
                    pending_delivery: blue[800],
                    success_delivery: green[700],
                },
                overrides: {
                    MuiTableHead: {
                        root: {
                            backgroundColor: blueGrey[600],
                        }
                    },
                    MuiTableCell: {
                        head: {
                            color: blueGrey[50],
                            fontWeight: 700,
                        }
                    }
                },
                mapLayers: {
                    depots: {
                        icon: {
                            name: 'mdiHomeMapMarker',
                            color: teal[800],
                        }
                    },
                    depot: {
                        icon: {
                            name: 'mdiHomeMapMarker',
                            color: teal[800],
                        }
                    },
                    stop: {
                        icon: {
                            name: 'mdiMapMarkerRadius',
                            colors: {
                                success: green[700],
                                pending: blue[800],
                                failure: red[800],
                                partial: orange[800],
                                unassigned: grey[500],
                            }
                        }
                    },
                    item: {
                        icon: {
                            name: 'mdiMapMarkerRadius',
                            colors: {
                                success: green[700],
                                pending: blue[800],
                                failure: red[800],
                                partial: orange[800],
                                unassigned: grey[500],
                            }
                        }
                    },
                    vehicle: {
                        icon: {
                            name: 'mdiCar',
                            color: blueGrey[800],
                            inactive: grey[500],
                        }
                    },
                    mobile_device: {
                        icon: {
                            name: 'mdiCellphoneWireless',
                            color: blueGrey[800],
                        }
                    },
                    trackpoint: {
                        icon: {
                            name: 'mdiCircle',
                            color: blueGrey[500]
                        }
                    },
                    default: {
                        icon: {
                            name: 'mdiMapMarker',
                            color: grey[800]
                        }
                    }
                },
                brand: {
                    icon: {
                        path: ''
                    }
                }
            }
        }
    }
}

export default function uiConfigReducer(state = initialState, action) {
    const { type, payload } = action
    const mergeData = mergeDeepRight(state)

    switch (type) {
        case GET_SUCCESS:
            return mergeData(payload)
        case UPDATE_SUCCESS:
            return mergeData(payload)
        default:
            return state
    }
}
