import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    container: {
        maxWidth: 'calc(100vw - 13px)',
        '& p, & label': {
            fontSize: '1.2rem !important',
        }
    },

    pieContainer: {
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between'
    },

    pieItem: {
        height: '300px',
    },

    withBorderSubTitile: {
        padding: '1rem',
        margin: '0 .5rem .5rem',
        border: '1px solid ' + theme.palette.grey[400],
        borderRadius: theme.shape.borderRadius,
        textAlign: 'center',

        '& span': {
            display: 'block',
            margin: 'auto',
            fontWeight: 'bolder',
        }
    },

    itemContainer: {
        padding: '.1rem .5rem',
        margin: '.1rem .5rem',
        minHeight: '2.4rem',
        display: 'flex',

        '& p': {
            display: 'block',
            margin: 'auto 5px',
            width: '100%',

            '& span': {
                fontWeight: 'bold',
            },

            '& span:last-child': {
                width: '23%',
                float: 'right',
                marginLeft: 5,
                marginTop: -5,
                display: 'inline-block',
            }
        }
    },

    selectButtonPieContainer: {
        padding: '0 .5rem',
        marginBottom: 20,
    },

    barChartItemsData: {
        height: 160,
        fontFamily: theme.typography.fontFamily,
        padding: '0 10px',
        flexGrow: 2,
    },

    divider: {
        display: 'block',
        height: 40,
    },

    paperRoot: {
        padding: 10,
        margin: 10,
        minHeight: 'calc(100vh - 130px)',
        display: 'flex',
        flexDirection: 'column',
    },

    lastRow: {
        flexGrow: 2,
        minHeight: '50vh',
    },

    toggleButton: {
        marginTop: 8,
        height: 41,
    },

    toggleIcon: {
        width: 50,
    },

    relative: {
        position: 'relative',
    },

    fullWidth: {
        display: 'flex',
        flexDirection: 'column',
    },

    customTooltip: {
        backgroundColor: theme.palette.background.paper,
        padding: '5px 10px',
        borderRadius: theme.shape.borderRadius,
    },
    autocompleteInput: {
        height: 26,
    }
}))
