import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    depotsRoot: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    mapContainer: {
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
        paddingBottom: 24,
        paddingTop: 24,
    },
    mapWrapper: {
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        flexGrow: 1,
    },
    p: {
        margin: 0,
        padding: '0 20px',
        fontFamily: theme.typography.fontFamily,
        fontWeight: 'bold',

        '& > span': {
            marginLeft: '5px',
            fontWeight: 'normal',
            color: theme.palette.text.secondary
        }
    },

    firstItem: {
        marginTop: '10px'
    },

    lastItem: {
        marginBottom: '10px'
    },

    detailSideBar: {
        width: theme.detailDrawer.width,
    },

    detailSideBarOpen: {
        paddingRight: theme.detailDrawer.width,
        transition: theme.transitions.create('padding-right', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
        })
    },

    detailSideBarIcon: {
        padding: '2px 2px 4px',
    },

    closeDetailSideBarButton: {
        position: 'absolute',
        right: '10px',
        top: '-3px',
    },

    detailConfigTitle: {
        marginBottom: 10,
    },

    configFormControl: {
        background: theme.palette.background.paper,
        width: '100%',
        margin: '20px 0 0',
    },

    select: {
        display: 'flex'
    },

    configSelectItem: {
        backgroundColor: theme.palette.background.paper + ' !important',
        marginTop: '-3px'
    },

    configSelectIcon: {
        paddingRight: 20,
        margin: '-6px 0',
    },

    configButtonsContainer: {
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
    },

    configButtons: {
        margin: 20,
    },

    leftInput: {
        marginRight: '10px',
        width: 'calc(50% - 10px)'
    },

    rightInput: {
        marginLeft: '10px',
        marginTop: 21,
        width: 'calc(50% - 10px)'
    },

    backButtonContainer: {
        display: 'flex',
        flex: '1 1 auto',

        '& > button': {
            margin: 'auto'
        }
    }
}))
