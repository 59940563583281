import { mergeRight } from 'ramda'

import {
    OPEN_DETAIL_CONFIG,
    CLOSE_DETAIL_CONFIG,
    TOGGLE_LEFT_PANEL,
    TOGGLE_RIGHT_PANEL,
    OPEN_EXPIRED_SESSION_DIALOG,
    CLOSE_EXPIRED_SESSION_DIALOG,
    ADD_ALERT_MESSAGE,
    REMOVE_ALERT_MESSAGE,
} from './actions'

const initialState = {
    detailConfig: {
        show: false,
    },

    leftPanel: {
        show: true,
    },

    rightPanel: {
        show: false,
    },

    expiredSessionDialog: {
        show: false,
    },

    alerts: [],
}

export default function uiStatesReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)
    switch (type) {
        case OPEN_DETAIL_CONFIG:
            return merge({
                detailConfig: {
                    ...state.detailConfig,
                    show: true,
                }
            })
        case CLOSE_DETAIL_CONFIG:
            return merge({
                detailConfig: {
                    ...state.detailConfig,
                    show: false,
                }
            })
        case TOGGLE_LEFT_PANEL:
            return merge({
                leftPanel: {
                    ...state.leftPanel,
                    show: !state.leftPanel.show
                }
            })
        case TOGGLE_RIGHT_PANEL:
            return merge({
                rightPanel: {
                    ...state.rightPanel,
                    show: !state.rightPanel.show
                }
            })
        case OPEN_EXPIRED_SESSION_DIALOG:
            return merge({
                expiredSessionDialog: {
                    ...state.expiredSessionDialog,
                    show: true,
                }
            })
        case CLOSE_EXPIRED_SESSION_DIALOG:
            return merge({
                expiredSessionDialog: {
                    ...state.expiredSessionDialog,
                    show: false,
                }
            })
        case ADD_ALERT_MESSAGE:
            if (state.alerts.find(item => item.message === payload.message) === undefined) {
                return merge({
                    alerts: [...state.alerts, payload]
                })
            }
            return state
        case REMOVE_ALERT_MESSAGE:
            return merge({ alerts: state.alerts.filter(item => item.id !== payload) })
        default:
            return state
    }
}
