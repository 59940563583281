export function coloredSvgIcon(icon, color){
    return `
        <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="${color}">
            <path d="${icon}" stroke="#fff" stroke-width="0.4">
        </svg>
    `
}

export const mapPointerIconList = Object.freeze([
    'mdiMapMarker',
    'mdiMapMarkerAlert',
    'mdiMapMarkerAlertOutline',
    'mdiMapMarkerCheck',
    'mdiMapMarkerCheckOutline',
    'mdiMapMarkerCircle',
    'mdiMapMarkerDown',
    'mdiMapMarkerLeft',
    'mdiMapMarkerLeftOutline',
    'mdiMapMarkerMinus',
    'mdiMapMarkerMinusOutline',
    'mdiMapMarkerMultiple',
    'mdiMapMarkerMultipleOutline',
    'mdiMapMarkerOff',
    'mdiMapMarkerOffOutline',
    'mdiMapMarkerOutline',
    'mdiMapMarkerPlus',
    'mdiMapMarkerPlusOutline',
    'mdiMapMarkerQuestion',
    'mdiMapMarkerQuestionOutline',
    'mdiMapMarkerRadius',
    'mdiMapMarkerRadiusOutline',
    'mdiMapMarkerRemove',
    'mdiMapMarkerRemoveOutline',
    'mdiMapMarkerRemoveVariant',
    'mdiMapMarkerRight',
    'mdiMapMarkerRightOutline',
    'mdiMapMarkerUp',
    'mdiNavigation',
    'mdiTaxi',
    'mdiTrain',
    'mdiAirplane',
    'mdiBus',
    'mdiCar',
    'mdiCompass',
    'mdiCompassOutline',
    'mdiCrosshairsGps',
    'mdiBrightness1',
    'mdiTransitTransfer',
])
