import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Switch, Route, useHistory, Link, useRouteMatch } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Typography, Table, TableRow, TableCell, TableBody, IconButton, Tooltip, SvgIcon, TextField, Grid } from '@material-ui/core'
import { mdiPencilCircle, mdiFolderOpen, mdiPlusBox, mdiFolderRemove } from '@mdi/js'
import SearchIcon from '@material-ui/icons/Search';

import BlockIcon from '@material-ui/icons/Block';
import DeleteIcon from '@material-ui/icons/Delete';

import { fetchCategories as _fetchCategories, deleteCategory as _deleteCategory } from '../../../redux/amm/categories/actions'
import { i18n as i18nService } from '../../../services/util'
import { NotFoundPage } from '../../../pages'

import { CategoryFormComponent } from '../../../components'
import { ConfirmationBuilder as RucConfirmationBuilder, TableSortHeadBuilder as RucTableSortHeadBuilder } from '../../../components/Ruc/'

import { PopoverBuilder, BasicList } from '../../../components/Ruc/'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  categories: path(['categories', 'categories'], state)
})

const mapDispatchToProps = dispatch => ({
  fetchCategories: token => dispatch(_fetchCategories(token)),
  deleteCategory: (categoryId, token, callback) => dispatch(_deleteCategory(categoryId, token, callback))
})


function CategoryTableComponent({token,categories,fetchCategories,deleteCategory}) {
  let { path, url } = useRouteMatch();
  const history = useHistory()
  const i18n = prop => i18nService(['amm',"category",prop])
  const selectedKeys = [
    { value: 'name', label: i18n('name') },
    { value: 'priority', label: i18n('priority') },
    { value: 'users_list', label: i18n('users') },
    { value: 'color', label: i18n('color') },
    { value: 'description', label: i18n('description') }
  ]
  const header = [
    {
      "id": "name",
      "numeric": false,
      "label": "name"
    },
    {
      "id": "priority",
      "numeric": true,
      "label": "priority"
    },
    {
      "id": "users",
      "numeric": false,
      "label": "users"
    },
    {
      "id": "color",
      "numeric": false,
      "label": "color"
    },
    {
      "id": "description",
      "numeric": false,
      "label": "description"
    }
  ]

  const [selectedToDelete, setSelectedToDelete] = useState(null)
  const [deleteModalShow, setDeleteModalShow] = useState(false)
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState();

  useEffect(() => { fetchCategories(token) }, [fetchCategories,token])
  useEffect(() => { setRows(categories) }, [categories])
  
  // FILTER
  const [rows, setRows] = useState(categories);
  const [searched, setSearched] = useState("");

  function handleSearch(event) {
    setSearched(event.target.value)
  }

  const requestSearch = () => {
    if(searched.length > 1){
      const filteredRows = categories.filter((row) => {
        if(
          row.name.toString().toLowerCase().includes(searched.toString().toLowerCase()) ||
          row.description.toString().toLowerCase().includes(searched.toString().toLowerCase())
        ) {
          return true
        } else {
          return false
        }
      });
      setRows(filteredRows);
    } else {
      setRows(categories);
    }
  };

  function editCategoryHandler({ id }) {
    return function () {
      history.push(`/amm/categories/${id}/edit`)
    }
  }

  function removeHanlder(category) {
    return function() {
      setSelectedToDelete(category)
      setDeleteModalShow(true)
    }
  }

  function closeRemoveModal() {
    setDeleteModalShow(false)
    setSelectedToDelete(null)
  }

  function confirmRemove() {
    deleteCategory(token,selectedToDelete.id,() => {
      setDeleteModalShow(false)
      fetchCategories(token)
    })
  }

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  function descendingComparator(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
      return -1;
    }
    if (b[orderBy] > a[orderBy]) {
      return 1;
    }
    return 0;
  }
  
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }
  
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  return (
    <React.Fragment>
      <Switch>
        <Route exact path={url}>
         

          <Grid container spacing={0} direction="row" justify="space-between">
            <Grid item xs={2}>
              <Typography variant="h6">
                <SvgIcon><path d={mdiFolderOpen}/></SvgIcon>
                Categorías
                <Link to={`${url}/new`}>
                  <IconButton className="new-class">
                    <SvgIcon><path d={mdiPlusBox}/></SvgIcon>
                  </IconButton>
                </Link>
              </Typography>
            </Grid>
            <Grid item xs={2}>
              <TextField
                value={searched}
                onChange={(searchVal) => handleSearch(searchVal)}
                label="Buscar"
                variant="outlined"
                autoComplete="off"
                style={{ width: "100%", marginTop: "17px"}}
                InputProps={{
                  endAdornment: (
                    <IconButton position="end" onClick={() => requestSearch()}>
                      <SearchIcon />
                    </IconButton>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <br/>

          <Table>
            <RucTableSortHeadBuilder
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              headCells={header}
              i18n={i18n}
            >
              <TableCell className="actions three-icons">
                {i18n('actions')}
              </TableCell>
            </RucTableSortHeadBuilder>
            <TableBody>
              {rows && stableSort(rows, getComparator(order, orderBy)).map((category, k) => (
                <TableRow key={k}>
                  {selectedKeys.map((item, kx) => (
                    <TableCell key={kx} className={`ruc-${item.value}`}>
                      { item.value === 'color' ? 
                        <div style={{margin: '0 auto', width: '20px',height: '20px', borderRadius: "15px", backgroundColor: category[item.value]}}/>
                      : item.value === 'users_list' ?
                        ( category['users'].length > 0 ?
                          <PopoverBuilder>
                            <BasicList array={category['users']} arrayKey='name' />
                          </PopoverBuilder>
                        : "Todos" )
                      : category[item.value] }
                    </TableCell>
                  ))}
                  <TableCell style={{ whiteSpace: 'nowrap' }} className="actions two-icons">
                    <Tooltip title={i18nService(['amm','actions','edit'])}>
                      <IconButton onClick={editCategoryHandler(category)}>
                        <SvgIcon><path d={mdiPencilCircle}></path></SvgIcon>
                      </IconButton>
                    </Tooltip>
                    <Tooltip title={i18nService(['amm','tooltips',category.used ? 'used-item' : 'delete'])}>
                      <IconButton onClick={category.used ? (()=>{}) : removeHanlder(category)}>
                        {category.used ? <BlockIcon /> : <DeleteIcon />}
                      </IconButton>
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </Route>
        <Route path={`${path}/new`}>
          <CategoryFormComponent />
        </Route>
        <Route exact path={`${path}/:categoryId/edit`} tableHistory={history}>
          <CategoryFormComponent />
        </Route>
        <Route path="*">
          <NotFoundPage />
        </Route>
      </Switch>
      <RucConfirmationBuilder 
        open={deleteModalShow}
        onClose={closeRemoveModal}
        confirmIcon={mdiFolderRemove}
        confirmMessage="Seguro que desea eliminar"
        cancelAction={closeRemoveModal}
        confirmAction={confirmRemove}
      >{selectedToDelete && selectedToDelete.name}</RucConfirmationBuilder>
    </React.Fragment>
  )
}

CategoryTableComponent.propTypes = {
  categories: PropTypes.array.isRequired
}

CategoryTableComponent.defaultProps = {
  categories: []
}

export default connect(mapStateToProps, mapDispatchToProps)(CategoryTableComponent)
