import React from 'react'
import { withStyles, Grid, Chip, Link, TextField, Button, SvgIcon, Tooltip} from '@material-ui/core'
import { mdiTruckDelivery } from '@mdi/js'

import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import Typography from '@material-ui/core/Typography';

import MapIcon from '@material-ui/icons/Map';
import LocalShippingIcon from '@material-ui/icons/LocalShipping';
import DescriptionIcon from '@material-ui/icons/Description';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { AlertHistoryComponent as AlertHistory } from '../../../components'
import { AlertEventComponent as ChangeEvent } from '../../../components'
// import { path/*, find, equals, or, head*/ } from 'ramda'
//import { useLocation } from 'react-router-dom'

//import ROUTES from '../../../routes'
import { i18n } from '../../../services/util'

const DialogContent = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

export default function AlertPopupComponent(props) {
  const { handleClose, managed, alert, formData, i18nService, handleChange, handleSubmit, managedIcon, readIcon, unreadIcon, closedIcon, eventUpdateIcon } = props

  //const location = useLocation()
  //const findLocationOnArray = array => or(find(equals(location.pathname), array), head(array))
  const STOP_DELIVERED_WITH_DELAY_ALERT = 1
  const STOP_TIME_DEFLECTION_ALERT = 3
  const DELAYED_ROUTE_ALERT = 5
  const FAILURE_EVENT_ALERT = 6
  const STOP_DELIVERED_WITH_ADVANCE_ALERT = 8
  const DELAYED_ROUTES_ALERT = 10
  const UNFINISHED_ROUTES_ALERT = 11
  const DEPOT_OUT_OF_PLACE_ALERT = 14
  const STOP_OUT_OF_PLACE_ALERT = 15
  const ROUTE_ENDED_WITH_DELAY_ALERT = 17
  const ROUTE_ENDED_WITH_ADVANCE_ALERT = 18

  return (
    <>
      <MuiDialogTitle disableTypography>
        <Grid container spacing={2} direction="row">

          <Grid item xs={2} className={`${alert.managed_or_closed  ? `ruc-${alert.closed ? 'un' : ''}managed-alert` : ''}`}>
            <Chip 
                size="small" 
                label={alert.id} 
                style={{backgroundColor: alert.managed_or_closed  ? "#478E22" : "#D21F3E", color: 'white', float: 'left'}} 
              /> 
          </Grid>

          <Grid item xs={4} style={{paddingLeft : 0}}>
            <Typography variant="h6">{`${alert.alert}`}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Grid container justify="center" spacing={2}>
              <Grid item>
                <Tooltip title={"Ir a editor de itinerio"}>
                  <Link target="_blank" href={alert.itinerary.edit_url}>
                    <MapIcon />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={"Ir a asignación vehículos"}>
                  <Link target="_blank" href={alert.itinerary.scheduling_url}>
                    <LocalShippingIcon />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={"Ir a tabla de itinerario"}>
                  <Link target="_blank" href={alert.itinerary.table_url}>
                    <DescriptionIcon />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={"Ir a módulo editor de rutas en curso"}>
                  <Link target="_blank" href={alert.itinerary.running_routes_url}>
                    <ExitToAppIcon />
                  </Link>
                </Tooltip>
              </Grid>
              <Grid item>
                <Tooltip title={"Ir a mapa de ruta"}>
                  <Link target="_blank" href={`routes/map/${alert.route_id}`}>
                    <SvgIcon>
                      <path d={mdiTruckDelivery}></path>
                    </SvgIcon>
                  </Link>
                </Tooltip>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={2} style={{paddingRight: 0}}>
            <Chip label={alert.category} style={{backgroundColor: alert.category_color, color: 'white', float: 'right'}} />
          </Grid>
        </Grid>
      </MuiDialogTitle>
      <DialogContent dividers>
        <Grid container spacing={0} direction="row" justify="center">
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" justify="center">
              <Grid item xs={12}>
                <ul className="ruc-popup-content-list ruc-popup-item">
                  <li>
                    <label>Ruta</label>
                    <span>: { alert.itinerary.resume_url ? <Link target="_blank" href={alert.itinerary.resume_url}>{alert.itinerary.name}</Link> : '--'}</span>
                  </li>
                  <li><label>Vehículo</label><span>: {alert.vehicle.license_plate || alert.vehicle.nid}</span></li>
                  <li><label>Hora real</label><span>: {alert.alert_template_id === UNFINISHED_ROUTES_ALERT ? alert.route_attr.finished_at : alert.item_attr.arrived_at || '--'}</span></li>
                  <li><label>Hora planificada</label><span>: {alert.alert_template_id === UNFINISHED_ROUTES_ALERT ? alert.route_attr.ends_at : alert.item_attr.arrives_at || '--'}</span></li>
                  {(alert.alert_template_id === STOP_DELIVERED_WITH_DELAY_ALERT 
                    || alert.alert_template_id === STOP_DELIVERED_WITH_ADVANCE_ALERT
                    || alert.alert_template_id === DELAYED_ROUTE_ALERT
                    || alert.alert_template_id === DELAYED_ROUTES_ALERT
                    || alert.alert_template_id === ROUTE_ENDED_WITH_DELAY_ALERT
                    || alert.alert_template_id === ROUTE_ENDED_WITH_ADVANCE_ALERT
                    ) &&
                    <li><label>{i18n(['routes', 'items_table', 'arrived_at_diff'])}</label><span>: {alert.item_attr.arrived_at_diff || '--'}</span></li>
                  }
                  <ChangeEvent alert={alert}/>
                  {alert.alert_template_id === FAILURE_EVENT_ALERT && 
                    <>
                      <li><label>Motivo</label><span>: {alert.event.data_error_reason || '--'}</span></li>
                      <li><label>Comentario</label><span>: {alert.event.data_comments || '--'}</span></li>
                    </>
                  }
                  <li><label>Localización</label><span>: {alert.event.latitude||'--'},{alert.event.longitude||'--'}</span></li>
                  {alert.alert_template_id === STOP_TIME_DEFLECTION_ALERT && 
                    <li><Tooltip title={"Tiempo parada"}><label>TP</label></Tooltip><span>: {alert.item_attr.stop_time_diff || '--'}</span></li>
                  }
                  {(alert.alert_template_id === DEPOT_OUT_OF_PLACE_ALERT || alert.alert_template_id === STOP_OUT_OF_PLACE_ALERT) &&
                    <li><label>{i18n(['routes', 'items_table', 'last_event_distance_diff'])}</label><span>: {alert.event.distance_diff || '--'}</span></li>
                  }
                  <li><label>Origen</label><span>: {alert.event.source || '--'}</span></li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} md={6}>
            <Grid container spacing={0} direction="row" justify="center">
              <Grid item xs={12}>
                <ul className="ruc-popup-content-list ruc-popup-client">
                  <li>
                    <label>Cliente</label>
                    <span>: { alert.profile.url ? <Link target="_blank" href={alert.profile.url}>{alert.profile.name}</Link> : '--'}</span>
                  </li>
                  <li><Tooltip title={"Número parada"}><label>N. Parada</label></Tooltip><span>: {alert.item_attr.item_order || '--'}</span></li>
                  <li>
                    <label>{i18n(['routes', 'items_table', 'document_id'])}</label>
                    <span>: {alert.document || '--'}</span></li>
                  <li>
                    <label>ID</label>
                    <span>: {alert.profile.nid || '--'}</span>
                  </li>
                  <li>
                    <label>Teléfono</label>
                    <span>: {alert.profile.phone || '--'}</span>
                  </li>
                  <li>
                    <label>Correo</label>
                    <span>: {alert.profile.email || '--'}</span>
                  </li>
                </ul>
              </Grid>
              <Grid item xs={12}>
                <ul className="ruc-popup-content-list ruc-popup-vehicle">
                  <li>
                    <label>Vehículo</label>
                    <span>: { alert.vehicle.url ? <Link target="_blank" href={alert.vehicle.url}>{alert.vehicle.license_plate || alert.vehicle.nid}</Link> : '--'}</span>
                  </li>
                  <li>
                    <label>Móvil</label>
                    <span>: {alert.mobile_device.description || '--'}</span>
                  </li>
                  <li>
                    <label>Conductor</label>
                    <span>: {alert.vehicle.driver_name || '--'}</span>
                  </li>
                  <li>
                    <label>Teléfono</label>
                    <span>: {alert.vehicle.driver_phone || '--'}</span>
                  </li>
                  <li>
                    <label>Correo</label>
                    <span>: {alert.vehicle.driver_email || '--'}</span>
                  </li>
                </ul>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12}>
            <form className="ruc-alert-comment">
              <TextField
                id="comments"
                fullWidth
                error={!!formData.comments.error}
                label={i18nService(['amm', 'alert', 'comments'])}
                multiline
                disabled={managed}
                rows={4}
                variant="outlined"
                value={managed ? alert.comments : formData.comments.value}
                onChange={handleChange('comments')}
              />
            </form>
          </Grid>
          <Grid item xs={12}>
            <AlertHistory 
              alert={alert}
              i18nService={i18nService}
              managedIcon={managedIcon}
              readIcon={readIcon}
              unreadIcon={unreadIcon}
              closedIcon={closedIcon}
              commentText={false}
              rucClassName="ruc-popup-alert-history-block"
              eventUpdateIcon={eventUpdateIcon}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        { managed ? null :
        <Button row={6} variant="contained" color="primary" onClick={handleSubmit}>
          {i18nService(['amm', 'actions', 'mark_as_managed'])}
        </Button> }
        <Button row={6} variant="contained" color="default" onClick={handleClose}>
          {i18nService(['amm', 'actions', 'close'])}
        </Button>
      </DialogActions>
    </>
  );
}