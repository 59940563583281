import { mergeRight } from 'ramda'
import { GET_ALERTS_SUCCESS, GET_ALERT_SUCCESS, GET_PENDING_ALERTS_SUCCESS } from './actions'

const initialState = { indicators: [], header: [], set: [], record: {}, pendings: 0, data: {} }

export default function ammAlertsReducer(state = initialState, action) {
  const { type, payload } = action
  const merge = mergeRight(state)
  switch (type) {
    case GET_ALERTS_SUCCESS:
      return merge({ set: payload.set, header: payload.header, indicators: payload.indicators.values, response: payload })
    case GET_PENDING_ALERTS_SUCCESS:
      return merge({ pendings: payload.pendings })
    case GET_ALERT_SUCCESS:
      return merge({ record: payload })
    default:
      return state
  }
}