import { makeStyles } from "@material-ui/core/styles";

export default makeStyles(theme => ({
    root: {
        position: 'absolute',
        top: 0,
        right: 0,
        zIndex: 1000,
        display: 'flex',
        flexDirection: 'column',
        height: '100%',

        '& > button': {
            backgroundColor: theme.palette.background.default,
            margin: '4px 8px',

            '&:first-child': {
                marginTop: 8,
            },
        },

        '& > div': {
            zIndex: 99
        }
    }
}))
