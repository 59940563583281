import { createStore, combineReducers, applyMiddleware, compose as reduxCompose } from 'redux';
import thunk from 'redux-thunk'

import userReducer from './user/reducer'
import uiStatesReducer from './ui-states/reducer'
import requestStateReducer from './request-state/reducer'
import depotsReducer from './depots/reducer'
import uiConfigReducer from './ui-config/reducer'
import routesViewReducer from './routes-view/reducer'
import vehiclesReducer from './vehicles/reducer'
import mobilesReducer from './mobiles/reducer'
import statsReducer from './stats/reducer'
import rolesReducer from './roles/reducer'
import permissionsReducer from './permissions/reducer'
import usersReducer from './users/reducer'
import layersReducer from './layers/reducer'
import routeDetailReducer from './route-detail/reducer'
import i18nReducer from './i18n/reducer'
import indicatorsReducer from './indicators/reducer'
import byClusterReducer from './by-cluster/reducer'
import ammAlertsReducer from './amm/alerts/reducer'
import ammAlertsCsvReducer from './amm/alerts/csv/reducer'
import ammAlertTemplatesReducer from './amm/alert-templates/reducer'
import ammCategoriesReducer from './amm/categories/reducer'
import ammAlertConfiguationsReducer from './amm/alert-configurations/reducer'
import ammOptionsReducer from './amm/options/reducer'

const reducer = combineReducers({
    uiStates: uiStatesReducer,
    uiConfig: uiConfigReducer,
    user: userReducer,
    request: requestStateReducer,
    depotsLayer: depotsReducer,
    routesLayer: routesViewReducer,
    vehiclesLayer: vehiclesReducer,
    mobilesLayer: mobilesReducer,
    stats: statsReducer,
    roles: rolesReducer,
    permissions: permissionsReducer,
    users: usersReducer,
    layers: layersReducer,
    routeDetail: routeDetailReducer,
    i18n: i18nReducer,
    indicators: indicatorsReducer,
    byCluster: byClusterReducer,
    alerts: ammAlertsReducer,
    alertsCsv: ammAlertsCsvReducer,
    alertTemplates: ammAlertTemplatesReducer,
    categories: ammCategoriesReducer,
    alertConfigurations: ammAlertConfiguationsReducer,
    ammOptions: ammOptionsReducer,
})

let compose = reduxCompose

if (process.env.NODE_ENV === 'development') {
    compose = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
}

export default createStore(
    reducer,
    compose(applyMiddleware(thunk)),
)
