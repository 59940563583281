import { makeStyles } from "@material-ui/core";

export default makeStyles(theme => ({
    buttonContainer: {
        display: 'flex',
        justifyContent: 'space-evenly',
        width: '100%',
        position: 'absolute',
        bottom: 35,
        left: 0,
    },

    tableRoot: {
        marginTop: 15,
    },

    modalWrapper: {
        height: 240,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-around',
    },

    modalButtonContainer: {
        display: 'flex',
        justifyContent: 'space-around',
    },

    addActionButton: {
        display: 'block',
        float: 'right'
    },

    actionsRelated: {
        textAlign: 'center'
    }
}))
