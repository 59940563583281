import React from 'react'
import { 
  Box, Grid,
  Table, TableHead, TableRow, TableCell, TableBody, TableFooter,
  SvgIcon
} from '@material-ui/core'


export default function AlertHistoryComponent(props) {
  const { alert, i18nService, managedIcon, readIcon, unreadIcon, closedIcon, commentText, rucClassName, eventUpdateIcon } = props
  const historyIcon = (userAction) => {
    if(userAction === 'managed') {
      return managedIcon
    } else if (userAction === 'unmanaged_close') {
      return closedIcon
    } else if (userAction === 'event_update') {
      return eventUpdateIcon
    } else {
      return readIcon
    }
  }

  return (
    <>
      { alert.concern.length > 0 ?
        <Box margin={1} className={rucClassName}>
          <Grid container spacing={2} direction="row" justify="center">
            <Grid item xs={commentText ? 7 : 12}>
              {/* <Typography variant="h6" gutterBottom component="div">Historial</Typography> */}
              <Table size="small" aria-label="manage-history">
                <TableHead>
                  <TableRow>
                    <TableCell>Acción</TableCell>
                    <TableCell>Usuario</TableCell>
                    <TableCell align="right">Fecha</TableCell>
                    <TableCell align="right">Hora</TableCell>
                  </TableRow>
                </TableHead>
                <TableFooter>
                  <TableRow>
                    <TableCell align="right" colSpan="3">Tiempo transcurrido</TableCell>
                    <TableCell align="right" style={{color: '#9E2A00'}}>
                      {alert.elapsed_time ? `${alert.elapsed_time.hours}:${alert.elapsed_time.minutes}:${alert.elapsed_time.seconds}` : '--'}
                    </TableCell>
                  </TableRow>
                </TableFooter>
                <TableBody>
                  {alert.concern.map((history) => (
                    <TableRow key={history.id}>
                      <TableCell>
                        <SvgIcon className={`ruc-history-icon ruc-icon-${history.user_action}`}>
                          {/* <path d={history.user_action === 'managed' ? managedIcon : history.user_action === 'unmanaged_close' ? closedIcon : readIcon}></path> */}
                          <path d={historyIcon(history.user_action)}></path>
                        </SvgIcon>
                        {i18nService(['amm','actions',history.user_action])}
                      </TableCell>
                      <TableCell>{history.name}</TableCell>
                      <TableCell align="right">{history.checked_at.date}</TableCell>
                      <TableCell align="right">{history.checked_at.time}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Grid>
            { commentText ? <Grid item xs={5}>
              <div className="ruc-alert-history-comment ">{alert.comments}</div>
            </Grid> : null }
          </Grid>
        </Box> :
        <Box margin={1}>
          <div className="ruc-alert-box ruc-warning-alert-box">
            <SvgIcon><path d={unreadIcon}></path></SvgIcon>
            <span>Esta alerta no ha sido leída y tampoco gestionada</span>
          </div>
        </Box>
      }
    </>
  );
}