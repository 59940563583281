/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { prop, path, propOr, pathOr, isNil, omit } from 'ramda'
import classnames from 'classnames'
import { v4 as uuid_v4 } from 'uuid'
import { Container, Grid, Switch, Typography, withStyles, List, ListItem, Divider, Chip, TableBody, Table, TableCell, TableRow, Fab,
    MenuItem, Popper, Grow, Paper, ClickAwayListener, MenuList, Tooltip } from '@material-ui/core'
import { blue, grey, red, orange, yellow, green } from '@material-ui/core/colors'
import { mdiTableEye, mdiMapClockOutline, mdiLayersTriple, mdiCheckboxMarkedOutline, mdiCheckboxBlankOutline, mdiApplicationExport } from '@mdi/js'

import { toggleLeftPanel as _toggleLeftPanel, toggleRightPanel as _toggleRightPanel } from '../../redux/ui-states/actions'
import { fetchData as _getRoutesData, fetchFiltersData as _getFiltersData, fetchItemsData as _getItemsData } from '../../redux/routes-view/actions'
import { fetchData as _getVehiclesData } from '../../redux/vehicles/actions'
import { fetchData as _getMobilesData } from '../../redux/mobiles/actions'
import { fetchData as _getLayersData } from '../../redux/layers/actions'
import { fetchData as _getIndicatorsData } from '../../redux/indicators/actions'
import {
    fetchData as _getDepotsData,
    getDetail as _getDepotsDetail,
    removeDetail as _removeDepotsDetail,
} from '../../redux/depots/actions'
import { fetchData as _byCluster } from '../../redux/by-cluster/actions'
import { RoutesTableComponent, SvgIconComponent, RoutesMapComponent, ControlPanelComponent, LoaderComponent, ProgressComponent,
    RoutesPlaningPieComponent, RoutesFiltersComponent, ResumeTableComponent, MapControlComponent, MapFiltersComponent, StatsProgressComponent } from '../../components'
import styles from './styles'
import { i18n, filterDataParser } from '../../services/util'
import { requestPending as _requestPending, requestSuccess as _requestSuccess } from '../../redux/request-state/actions'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    colors: path(['uiConfig', 'data', 'attributes', 'app_data', 'graphics'], state),
    loading: path(['request', 'loading'], state),
    leftPanelShow: path(['uiStates', 'leftPanel', 'show'], state),
    rightPanelShow: path(['uiStates', 'rightPanel', 'show'], state),
    layerConfig: path(['uiConfig', 'data', 'attributes', 'app_data', 'mapLayers'], state),
    clusterConfig: path(['uiConfig', 'data', 'attributes', 'app_data', 'clusters'], state),
    routesLayer: prop('routesLayer', state),
    depotsLayer: prop('depotsLayer', state),
    vehiclesLayer: prop('vehiclesLayer', state),
    mobilesLayer: prop('mobilesLayer', state),
    customLayers: prop('layers', state),
    planingRoutes: path(['stats', 'data', 'attributes', 'route_states'], state),
    indicatorsByCluster: path(['indicators', 'response', 'indicators', 'data_by_clusters'], state),
    globalIndicators: path(['indicators', 'response', 'indicators'], state),
    clusters: pathOr([], ['user', 'info', 'data', 'meta', 'clusters'], state),
})

const mapDispatchToProps = dispatch => ({
    toggleLeftPanel: () => dispatch(_toggleLeftPanel()),
    toggleRightPanel: () => dispatch(_toggleRightPanel()),
    getFiltersData: token => dispatch(_getFiltersData(token)),
    getRoutes: (token, qs, callback) => dispatch(_getRoutesData(token, qs, callback)),
    getItems: (token, qs, callback) => dispatch(_getItemsData(token, qs, callback)),
    getDepots: (token, qs, callback) => dispatch(_getDepotsData(token, qs, callback)),
    getMobiles: (token, qs, callback) => dispatch(_getMobilesData(token, qs, callback)),
    getLayers: (token, qs, callback) => dispatch(_getLayersData(token, qs, callback)),
    byCluster: (token, qs, callback) => dispatch(_byCluster(token, qs, callback)),
    getIndicators: (token, qs, callback) => dispatch(_getIndicatorsData(token, qs, callback)),
    getVehicles: (token, querystring, callback) => dispatch(_getVehiclesData(token, querystring, callback)),
    getDepotsDetail: id => dispatch(_getDepotsDetail(id)),
    removeDepotsDetail: () => dispatch(_removeDepotsDetail()),
    requestPending: () => dispatch(_requestPending()),
    requestSuccess: () => dispatch(_requestSuccess()),
})

const ViewSwitch = withStyles(theme => ({
    switchBase: {
        color: theme.palette.primary.main,
        '&$checked': {
            color: theme.palette.secondary.main,
        },
        '&$checked + $track': {
            backgroundColor: theme.palette.secondary.light,
        },
    },
    checked: {},
    track: {
        backgroundColor: theme.palette.primary.light,
    },
}))(Switch);

function RoutesViewPage(props) {
    const {
        // State Props
        token, loading, routesLayer, depotsLayer, vehiclesLayer, mobilesLayer, leftPanelShow, rightPanelShow, layerConfig, customLayers, colors, indicatorsByCluster, globalIndicators, clusters, clusterConfig,

        // Actions Props
        getDepots, getRoutes, getVehicles, getMobiles, toggleLeftPanel, toggleRightPanel, getFiltersData, getLayers, getItems, requestPending, requestSuccess, getIndicators, 

        // Routes props
        history, match,
    } = props
    const prevView = '/routes/map'
    const classes = styles()

    const filtersByView = {
        routes: ['running', 'by_itinerary_id', 'by_item_nid', 'by_item_document', 'by_route_nid', 'by_starts_at[from]', 'by_starts_at[to]', 'by_depot', 'by_status', 'by_vehicle', 'by_cluster_id'],
        items: ['with_routes', 'by_itinerary_id', 'by_item_document', 'by_route_nid', 'by_starts_at[from]', 'by_starts_at[to]', 'by_depot', 'by_status', 'by_vehicle', 'by_mobile_device_uid', 'by_item_status', 'by_cluster_id'],
        vehicles: ['with_routes', 'by_nid', 'by_license_plate', 'by_route_nid', 'by_depot', 'by_item_nid', 'by_mobile_device_uid'],
        mobiles: ['by_uid', 'by_description', 'with_routes', 'by_route_nid', 'by_depot', 'by_item_nid', 'by_vehicle_nid' ],
        depots: ['by_route_nid', 'by_item_document', 'by_depot', 'by_vehicle', 'by_mobile_device_uid'],
    }

    const filtersDefault = {
        'with_routes': true,
        'running': true,
        'by_route_nid': '',
        'by_item_document': '',
        'by_item_nid': '',
        'by_uid': '',
        'by_description': '',
        'by_vehicle_nid': '',
        'by_nid': '',
        'by_depot': '',
        'by_vehicle': null,
        'by_mobile_device_uid': null,
        'by_itinerary_id': '',
        'by_license_plate': '',
        'by_starts_at[from]': null,
        'by_starts_at[to]': null,
        'by_status': null,
        'by_cluster_id': null,
    }

    const useSource = {
        routes: getRoutes,
        items: getItems,
        vehicles: getVehicles,
        mobiles: getMobiles,
    }

    const [activeSource, setActiveSource] = useState('routes')
    const [layers, setLayers] = useState({})
    const [mapLayer, setMapLayer] = useState({})
    const [mapLayerSelected, setMapLayerSelected] = useState([])
    const [resizeEventListener, setResizeEventListener] = useState(null)
    const [filtersQs, setFiltersQs] = useState(filtersDefault)
    const [anchorEl, setAnchorEl] = useState({})
    const [updater, setUpdater] = useState('')
    const [indicators, setIndicators] = useState(false)
    let mapReload

    useEffect(() => {
        getFiltersData(token)
        mapReload = setInterval(() => {
            setUpdater(uuid_v4())
        }, 60000)
        return () => {
            clearInterval(mapReload)
        }
    }, [])

    useEffect(() => {
        mapLoad()
    }, [updater])

    useEffect(() => {
        if (match.path === '/routes') {
            history.push(prevView)
        }
        mapLoad()
    }, [match])

    useEffect(() => {
        const response = propOr([], 'response')
        const tempLayer = {
            routes: response(routesLayer),
            vehicles: response(vehiclesLayer),
            mobiles: response(mobilesLayer),
            items: propOr([], 'itemsResponse', routesLayer)
        }

        setLayers(tempLayer)
    }, [routesLayer, vehiclesLayer, mobilesLayer])

    useEffect(() => {
        const depots = pathOr([], ['response', 'data'], depotsLayer)
        const customs = pathOr([], ['response', 'data'], customLayers)
        const response = {
            depots
        }

        for (const item of customs) {
            if (item.attributes.is_active) {
                response[item.attributes.name] = item.attributes
            }
        }

        setMapLayer(response)
    }, [depotsLayer, customLayers])

    useEffect(() => {
        if (!isNil(resizeEventListener) && match.path === '/routes/map') {
            setTimeout(() => {
                resizeEventListener.invalidateSize()
            }, 400)
        }
    }, [leftPanelShow])

    function mapLoad() {
        getDepots(token)
        getLayers(token)
        getIndicators(token, {
            ...filterDataParser(filtersQs, filtersByView, activeSource)
        })

        if (match.path === '/routes/map') {
            useSource[activeSource] (
                token,
                {...filterDataParser(filtersQs, filtersByView, activeSource), show_all: 'true'}
            )
        } else {
            useSource[activeSource] (
                token,
                {...filterDataParser(filtersQs, filtersByView, activeSource), page: pathOr(1, [activeSource, 'pagination', 'page'], layers), show_all: 'false'}
            )
        }
    }

    function switchView(e) {
        if (e.target.checked) {
            if (activeSource === 'items') {
                setActiveSource('routes')
            }
            history.push('/routes/map')
        } else {
            history.push('/routes/table')
        }
    }

    function filtersSubmit(querystring, filter) {
        requestPending()
        setFiltersQs(filter)

        if (match.path === '/routes/map') {
            useSource[activeSource] (
                token,
                {...querystring, show_all: 'true'},
                requestSuccess
            )
        } else {
            useSource[activeSource] (
                token,
                {...querystring, page: pathOr(1, [activeSource, 'pagination', 'page'], layers), show_all: 'false'},
                requestSuccess
            )
        }
    }

    function handlerMapControlOpen(elementName) {
        return function (e) {
            setAnchorEl({...anchorEl, [elementName]: e.target})
        }
    }

    function handleMapControlClose(elementName) {
        return function () {
            setAnchorEl(omit([elementName], anchorEl))
        }
    }

    function handleListKeyDown(elementName) {
        return function (event) {
            if (event.key === 'Tab') {
                event.preventDefault();
                setAnchorEl(omit([elementName], anchorEl))
              }
        }
    }

    function handleToggleMapLayer(item) {
        return function () {
            if (mapLayerSelected.includes(item)) {
                setMapLayerSelected(mapLayerSelected.filter(layer => item !== layer))
            } else {
                setMapLayerSelected([...mapLayerSelected, item])
            }
        }
    }

    function sendSelectedMapLayer() {
        return mapLayerSelected.reduce((a, b) => ({...a, [b]: mapLayer[b] }), {})
    }

    function paginationChange(event, page) {
        useSource[activeSource](token, {...filterDataParser(filtersQs, filtersByView, activeSource), page, show_all: 'false'})
    }

    function filterByTable (filter, redirect) {
        const querystring = {...filtersQs, ...filter}
        setFiltersQs(querystring)
        useSource[redirect || activeSource](token, {...querystring, page: pathOr(1, [redirect || activeSource, 'pagination', 'page'], layers), show_all: 'false'})
        if (redirect) setActiveSource(redirect)
    }

    function switchIndicators() {
        setIndicators(!indicators)
    }

    function byClusterColor(value) {
        if (value <= 25) {
            return red[500]
        } else if (value <= 50) {
            return orange[500]
        } else if (value <= 75) {
            return yellow[500]
        }
        return green[500]
    }

    return (
        <React.Fragment>
            <div className={classnames(classes.root, {[classes.openLeftControl]: leftPanelShow})}>
                <Container maxWidth={false} className={classes.routesViewRoot}>
                    <ControlPanelComponent open={leftPanelShow} change={toggleLeftPanel} size={300}>
                        <List classes={{root: classes.listContainer}}>
                            <ListItem>
                                <Grid container style={{justifyContent: 'space-evenly'}}>
                                    <Grid item className={classes.item}>
                                        <Typography variant="body2" align="center">{i18n(['routes', 'switch', 'general'])}</Typography>
                                    </Grid>
                                    <Grid item>
                                        <ViewSwitch checked={indicators} onChange={switchIndicators}/>
                                    </Grid>
                                    <Grid item className={classes.item}>
                                        <Typography variant="body2" align="center">{i18n(['routes', 'switch', 'cluster'])}</Typography>
                                    </Grid>
                                </Grid>
                            </ListItem>
                            <Divider />

                            {!indicators ? (
                                <React.Fragment>
                                    <ListItem style={{justifyContent: 'space-evenly'}}>
                                        <Chip label={pathOr('0', ['items', 'panels', 'planned_items_label'], globalIndicators)} size="small" /><Typography style={{paddingTop: 2}}> {i18n(['routes', 'stats', 'title'])}</Typography>
                                    </ListItem>
                                    <ListItem>
                                        <ProgressComponent sections={[
                                            { color: colors.pending, percentage: parseFloat(pathOr('0', ['items', 'panels', 'pending_percentage'], globalIndicators)).toFixed(2) },
                                            { color: colors.failure, percentage: parseFloat(pathOr('0', ['items', 'panels', 'failure_percentage'], globalIndicators)).toFixed(2) },
                                            { color: colors.success, percentage: parseFloat(pathOr('0', ['items', 'panels', 'success_percentage'], globalIndicators)).toFixed(2) },
                                            { color: colors.partial, percentage: parseFloat(pathOr('0', ['items', 'panels', 'partial_percentage'], globalIndicators)).toFixed(2) },
                                            { color: colors.unasigned, percentage: parseFloat(pathOr('0', ['items', 'panels', 'unasigned_percentage'], globalIndicators)).toFixed(2) },
                                        ]} />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <ResumeTableComponent stats={[
                                            {
                                                value: { count: pathOr('0', ['items', 'panels', 'pending'], globalIndicators), percentage: pathOr('0', ['items', 'panels', 'pending_percentage'], globalIndicators) },
                                                color: colors.pending,
                                                label: i18n(['routes', 'stats', 'pending']),
                                                stats_type: 'pending'
                                            },
                                            {
                                                value: { count: pathOr('0', ['items', 'chart', 'failure'], globalIndicators), percentage: pathOr('0', ['items', 'panels', 'failure_percentage'], globalIndicators) },
                                                color: colors.failure,
                                                label: i18n(['routes', 'stats', 'failure']),
                                                stats_type: 'failure'
                                            },
                                            {
                                                value: { count: pathOr('0', ['items', 'chart', 'success'], globalIndicators), percentage: pathOr('0', ['items', 'panels', 'success_percentage'], globalIndicators) },
                                                color: colors.success,
                                                label: i18n(['routes', 'stats', 'success']),
                                                stats_type: 'success'
                                            },
                                            {
                                                value: { count: pathOr('0', ['items', 'chart', 'partial'], globalIndicators), percentage: pathOr('0', ['items', 'panels', 'partial_percentage'], globalIndicators) },
                                                color: colors.partial,
                                                label: i18n(['routes', 'stats', 'partial']),
                                                stats_type: 'partial'
                                            },
                                            {
                                                value: { count: pathOr('0', ['items', 'panels', 'unasigned'], globalIndicators), percentage: pathOr('0', ['items', 'panels', 'unasigned_percentage'], globalIndicators) },
                                                color: colors.unasigned,
                                                label: i18n(['routes', 'stats', 'unasigned']),
                                                stats_type: 'arrival'
                                            }
                                        ]}
                                        activeSource={activeSource}
                                        setActiveSource={setActiveSource}
                                        submit={filtersSubmit}
                                        qs={filtersQs}
                                        setqs={setFiltersQs}
                                        filtersByView={filtersByView}
                                        toggleFilter={toggleRightPanel}
                                        history={history}
                                    />
                                    </ListItem>
                                    <Divider />
                                    <ListItem>
                                        <Typography align="center" classes={{ root: classes.pieTitle }}>{i18n(['routes', 'stats', 'state_routes_planing_title'])}</Typography>
                                    </ListItem>

                                    {globalIndicators &&
                                        <React.Fragment>
                                            <ListItem className={classes.planingItem}>
                                                    <React.Fragment>
                                                        <div className={classes.pieContainer}>
                                                            <RoutesPlaningPieComponent
                                                                data={{
                                                                    delayed: pathOr('0', ['routes', 'chart', 'delayed'], globalIndicators),
                                                                    finished: pathOr('0', ['routes', 'panels', 'finished_routes'], globalIndicators),
                                                                    not_started: pathOr('0', ['routes', 'panels', 'not_started_routes'], globalIndicators),
                                                                    planned: { count: pathOr('0', ['routes', 'panels', 'planned_routes'], globalIndicators) },
                                                                    running: pathOr('0', ['routes', 'chart', 'started'], globalIndicators),
                                                                    unasigned: pathOr('0', ['routes', 'panels', 'unassigned_routes'], globalIndicators),
                                                                    unordered: pathOr('0', ['routes', 'panels', 'unordered_routes'], globalIndicators)
                                                                }}
                                                                colors={{
                                                                    not_started: grey[400],
                                                                    finished: blue[300],
                                                                }}
                                                            />
                                                        </div>
                                                        <Table size="small" classes={{ root: classes.planingTable }}>
                                                            <TableBody>
                                                                <TableRow>
                                                                    <TableCell className={classes.planingRowCant}>{pathOr('0', ['routes', 'panels', 'not_started_routes'], globalIndicators)}</TableCell>
                                                                    <TableCell className={classes.planingRowClear}></TableCell>
                                                                    <TableCell>{i18n(['routes', 'stats', 'pie_legend_not_started'])}</TableCell>
                                                                </TableRow>
                                                                <TableRow>
                                                                    <TableCell className={classes.planingRowCant}>{pathOr('0', ['routes', 'chart', 'started'], globalIndicators)}</TableCell>
                                                                    <TableCell className={classes.planingRowClear}></TableCell>
                                                                    <TableCell>{i18n(['routes', 'stats', 'pie_legend_running'])}</TableCell>
                                                                </TableRow>
                                                            </TableBody>
                                                        </Table>
                                                    </React.Fragment>
                                            </ListItem>
                                            <Divider />
                                            <ListItem style={{justifyContent: 'space-evenly'}}>
                                                <div className={classes.orderDesorderWrap}>
                                                    <div>{pathOr('0', ['routes', 'panels', 'unordered_routes'], globalIndicators)}</div>
                                                    <div>{i18n(['routes', 'stats', 'unordered'])}</div>
                                                </div>
                                                <div className={classes.orderDesorderWrap}>
                                                    <div>{pathOr('0', ['routes', 'chart', 'delayed'], globalIndicators)}</div>
                                                    <div>{i18n(['routes', 'stats', 'delayed'])}</div>
                                                </div>
                                                <div className={classes.orderDesorderWrap}>
                                                    <div>{pathOr('0', ['routes', 'panels', 'unassigned_routes'], globalIndicators)}</div>
                                                    <div>{i18n(['routes', 'stats', 'pie_legend_unasigned'])}</div>
                                                </div>
                                            </ListItem>
                                        </React.Fragment>
                                    }
                                </React.Fragment>
                            ) : (
                                <React.Fragment>
                                    {Object.keys(indicatorsByCluster.header).map((key, item) => {
                                        const maxValue = indicatorsByCluster.header[key].total
                                        const value = indicatorsByCluster.header[key].reported
                                        const calc_value = parseFloat(value / maxValue * 100).toFixed(0)

                                        return (
                                            <ListItem key={item}>
                                                <StatsProgressComponent
                                                    text={key}
                                                    max={100}
                                                    value={!isNaN(calc_value) ? calc_value : 0}
                                                    color={byClusterColor(value)}
                                                    sufix="%"
                                                    tooltip={value}
                                                />
                                            </ListItem>
                                        )
                                    })}
                                    <Divider />
                                    <ListItem>
                                        <List style={{width: '100%'}}>
                                            {Object.keys(indicatorsByCluster.by_clusters).map((key, item) => {
                                                const count = indicatorsByCluster.by_clusters[key].total
                                                return (
                                                    <ListItem key={item} style={{display: 'block', padding: 0, marginBottom: 40}}>
                                                        <div style={{display: 'flex'}}>
                                                            <Chip label={count} size="small" />
                                                            <Typography style={{paddingLeft: 10}}>{key}</Typography>
                                                        </div>
                                                        <div style={{ margin: '5px 0' }}>
                                                            <ProgressComponent sections={[
                                                                { color: colors.pending, percentage: (parseFloat(pathOr('0', [key, 'pending'], indicatorsByCluster.by_clusters)) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)) * 100).toFixed(2) || 0 },
                                                                { color: colors.failure, percentage: (parseFloat(pathOr('0', [key, 'failure'], indicatorsByCluster.by_clusters)) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)) * 100).toFixed(2) || 0 },
                                                                { color: colors.success, percentage: (parseFloat(pathOr('0', [key, 'success'], indicatorsByCluster.by_clusters)) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)) * 100).toFixed(2) || 0 },
                                                                { color: colors.partial, percentage: (parseFloat(pathOr('0', [key, 'partial'], indicatorsByCluster.by_clusters)) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)) * 100).toFixed(2) || 0 },
                                                                { color: colors.unasigned, percentage: (parseFloat(pathOr('0', [key, 'unassigned'], indicatorsByCluster.by_clusters)) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)) * 100).toFixed(2) || 0 },
                                                            ]} />
                                                        </div>
                                                        <div>
                                                            <ResumeTableComponent stats={[
                                                                {
                                                                    value: {
                                                                        count: parseFloat(pathOr('0', [key, 'pending'], indicatorsByCluster.by_clusters)),
                                                                        percentage: parseFloat(pathOr('0', [key, 'pending'], indicatorsByCluster.by_clusters)).toFixed(2) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)).toFixed(2) * 100 || 0
                                                                    },
                                                                    color: colors.pending,
                                                                    label: i18n(['routes', 'stats', 'pending'])
                                                                },
                                                                {
                                                                    value: {
                                                                        count: parseFloat(pathOr('0', [key, 'failure'], indicatorsByCluster.by_clusters)),
                                                                        percentage: parseFloat(pathOr('0', [key, 'failure'], indicatorsByCluster.by_clusters)).toFixed(2) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)).toFixed(2) * 100 || 0
                                                                    },
                                                                    color: colors.failure,
                                                                    label: i18n(['routes', 'stats', 'failure'])
                                                                },
                                                                {
                                                                    value: {
                                                                        count: parseFloat(pathOr('0', [key, 'success'], indicatorsByCluster.by_clusters)),
                                                                        percentage: parseFloat(pathOr('0', [key, 'success'], indicatorsByCluster.by_clusters)).toFixed(2) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)).toFixed(2) * 100 || 0
                                                                    },
                                                                    color: colors.success,
                                                                    label: i18n(['routes', 'stats', 'success'])
                                                                },
                                                                {
                                                                    value: {
                                                                        count: parseFloat(pathOr('0', [key, 'partial'], indicatorsByCluster.by_clusters)),
                                                                        percentage: parseFloat(pathOr('0', [key, 'partial'], indicatorsByCluster.by_clusters)).toFixed(2) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)).toFixed(2) * 100 || 0
                                                                    },
                                                                    color: colors.partial,
                                                                    label: i18n(['routes', 'stats', 'partial'])
                                                                },
                                                                {
                                                                    value: {
                                                                        count: parseFloat(pathOr('0', [key, 'unassigned'], indicatorsByCluster.by_clusters)),
                                                                        percentage: parseFloat(pathOr('0', [key, 'unassigned'], indicatorsByCluster.by_clusters)).toFixed(2) / parseFloat(pathOr('0', [key, 'total'], indicatorsByCluster.by_clusters)).toFixed(2) * 100 || 0
                                                                    },
                                                                    color: colors.unasigned,
                                                                    label: i18n(['routes', 'stats', 'unasigned'])
                                                                }
                                                            ]}
                                                            />
                                                        </div>
                                                    </ListItem>
                                                )
                                            })}
                                        </List>
                                    </ListItem>
                                </React.Fragment>
                            )}
                        </List>
                    </ControlPanelComponent>

                    <Grid container direction="row" justify="space-between" alignItems="center" spacing={0}>
                        <Grid item className={classes.submenuContainer}>
                            <Typography variant="h6">{match.path === '/routes/map' ? i18n(['routes', 'title', 'map']) : i18n(['routes', 'title', activeSource])}</Typography>
                            <div className={classes.linkContainer}>
                                <Tooltip title={'Acceso a Módulo de ejecución'}>
                                    <a href={`https://app.llegando.cl/running_routes`} target="_blank" rel="noopener noreferrer">
                                        <SvgIconComponent path={mdiApplicationExport} />
                                    </a>
                                </Tooltip>
                            </div>
                        </Grid>

                        <Grid item>
                            <Grid container>
                                <Grid item className={classes.item}>
                                    <SvgIconComponent path={mdiTableEye} /><Typography variant="body2" align="center">{i18n(['routes', 'switch', 'table'])}</Typography>
                                </Grid>
                                <Grid item>
                                    <ViewSwitch checked={match.path === '/routes/map'} onChange={switchView}/>
                                </Grid>
                                <Grid item className={classes.item}>
                                    <SvgIconComponent path={mdiMapClockOutline} /><Typography variant="body2" align="center">{i18n(['routes', 'switch', 'map'])}</Typography>
                                </Grid>
                                <Grid item>
                                    <MapFiltersComponent open={rightPanelShow} change={toggleRightPanel}>
                                        <RoutesFiltersComponent
                                            {...routesLayer.filtersData}
                                            view={match.path === '/routes/map' ? 'map' : 'table'}
                                            activeSource={activeSource}
                                            setActiveSource={setActiveSource}
                                            submit={filtersSubmit}
                                            qs={filtersQs}
                                            setqs={setFiltersQs}
                                            initialState={filtersDefault}
                                            filtersByView={filtersByView}
                                            toggleFilter={toggleRightPanel}
                                            clusters={clusters}
                                        />
                                    </MapFiltersComponent>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>

                    {match.path === '/routes/map' &&
                        <RoutesMapComponent
                            config={layerConfig}
                            clusterConfig={clusterConfig}
                            activeSource={activeSource}
                            layers={layers}
                            mapLayer={sendSelectedMapLayer()}
                            resizeListener={setResizeEventListener}
                            controls={
                                <MapControlComponent>
                                    <Tooltip title={'Capas'} placement="left">
                                        <Fab aria-label="add" onClick={handlerMapControlOpen('layersControl')}>
                                            <SvgIconComponent path={mdiLayersTriple} color="primary"/>
                                        </Fab>
                                    </Tooltip>

                                    <Popper
                                        open={Boolean(anchorEl['layersControl'])}
                                        anchorEl={anchorEl['layersControl']}
                                        role={undefined}
                                        transition
                                        placement="bottom-end"
                                        disablePortal>
                                        {({ TransitionProps }) => (
                                            <Grow
                                            {...TransitionProps}
                                            >
                                            <Paper>
                                                <ClickAwayListener onClickAway={handleMapControlClose('layersControl')}>
                                                <MenuList
                                                    autoFocusItem={Boolean(anchorEl['layersControl'])}
                                                    onKeyDown={handleListKeyDown('layersControl')}>
                                                    {Object.keys(mapLayer).map((map, key) =>
                                                        <MenuItem key={key} onClick={handleToggleMapLayer(map)}>
                                                            <SvgIconComponent
                                                                path={mapLayerSelected.includes(map) ? mdiCheckboxMarkedOutline : mdiCheckboxBlankOutline }
                                                                color="primary"
                                                            />
                                                                {map.toUpperCase()}
                                                        </MenuItem>)
                                                    }
                                                </MenuList>
                                                </ClickAwayListener>
                                            </Paper>
                                            </Grow>
                                    )}
                                    </Popper>
                                </MapControlComponent>
                            }
                        />
                    }

                    {match.path === '/routes/table' &&
                        <RoutesTableComponent
                            layers={layers}
                            activeSource={activeSource}
                            colors={colors}
                            paginatorChange={paginationChange}
                            onFilter={filterByTable}
                        />
                    }
                </Container>
            </div>
            <LoaderComponent visible={loading} />
        </React.Fragment>
    )
}

export default connect(mapStateToProps, mapDispatchToProps)(RoutesViewPage)
