/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { useParams, useRouteMatch, Link, useHistory } from 'react-router-dom'
import { path, isEmpty } from 'ramda'
import JSONInput from 'react-json-editor-ajrm'

import { Grid, TextField, Button, MenuItem, Typography, SvgIcon, FormControl, FormLabel } from '@material-ui/core'
import { mdiBell, mdiPlus, mdiPencil } from '@mdi/js'

import {
  addAlertTemplate as _addAlertTemplate,
  updateAlertTemplate as _updateAlertTemplate,
  fetchAlertTemplates as _fetchAlertTemplates,
  clearAlertTemplate as _clearAlertTemplate
} from '../../../redux/amm/alert-templates/actions'

import { i18n } from '../../../services/util'

const mapStateToProps = state => ({
  token: path(['user', 'auth_token'], state),
  alertTemplate: path(['alertTemplates', 'alertTemplate'], state),
  alertTemplates: path(['alertTemplates', 'alertTemplates'], state),
})

const mapDispatchToProps = dispatch => ({
  fetchAlertTemplate: (token, alertTemplateId) => dispatch(_fetchAlertTemplates(token, alertTemplateId)),
  fetchAlertTemplates: (token) => dispatch(_fetchAlertTemplates(token)),
  addAlertTemplate: (payload, token, callback) => dispatch(_addAlertTemplate(payload, token, callback)),
  updateAlertTemplate: (id, payload, token, callback) => dispatch(_updateAlertTemplate(id, payload, token, callback)),
  clearAlertTemplate: () => dispatch(_clearAlertTemplate()),
})

function AlertTemplateFormComponent({token,alertTemplate,alertTemplates,fetchAlertTemplate,fetchAlertTemplates,addAlertTemplate,updateAlertTemplate,clearAlertTemplate}) {
  const { alertTemplateId } = useParams()
  const { url } = useRouteMatch()
  const history = useHistory()

  const activities = ['running','finished', 'unstarted']
  const units = ['', 'seconds', 'minutes', 'hours', 'meters', 'kilometers', 'zone']
  const requiredFields = [ 'name', 'alert_description', 'callback_description', 'callback_trigger', 'activity' ]
  const initialValue = {
    name: { value: '', error: null },
    alert_description: { value: '', error: null },
    callback_description: { value: '', error: null },
    callback_trigger: { value: '', error: null },
    callback_options: { value: {}, error: null },
    activity: { value: 'running', error: null },
    unit: { value: '', error: null }
  }

  const [formData, setFormData] = useState(initialValue)

  useEffect(() => {
    if (url !== '/amm/alert-templates/new' && alertTemplateId) {
      fetchAlertTemplate(token, alertTemplateId)
    }

    if (url === '/amm/alert-templates/new') {
      clearAlertTemplate()
    }
  }, [url])

  useEffect(() => {
    if (alertTemplate !== undefined && !isEmpty(alertTemplate)) {
      setFormData({
        name: { value: alertTemplate.name || '', error: null },
        alert_description: { value: alertTemplate.alert_description || '', error: null },
        callback_description: { value: alertTemplate.callback_description || '', error: null },
        callback_trigger: { value: alertTemplate.callback_trigger || '', error: null },
        callback_options: { value: alertTemplate.callback_options || {}, error: null },
        activity: { value: alertTemplate.activity || 'running', error: null },
        unit: { value: alertTemplate.unit || '', error: null }
      })
    } else {
      setFormData(initialValue)
    }
  }, [alertTemplate])

  function isEditUrl() {
    return url !== '/amm/alert-templates/new' && alertTemplateId
  }

  function handleChange(input) {
    return function(event) {
      let value;
      if ( input === 'callback_options') {
        value = event.jsObject
      } else {
        value = event.target.value;
      }
      setFormData({
        ...formData,
        [input]: {
          value: value,
          error: null,
        }
      })
    }
  }

  function handleSubmit() {
    const keys = Object.keys(formData)
    let hasError = false
    let payload = {}

    keys.forEach(key => {
      if (requiredFields.includes(key) && isEmpty(formData[key].value)) {
        hasError = true
        setFormData({
          ...formData,
          [key]: {
            ...formData[key],
            error: 'El campo no puede estar vacio'
          }
        })
      } else {
        payload = {
          ...payload,
          [key]: formData[key].value
        }
      }
    })

    if (!hasError) {
      if (isEditUrl()) {
        updateAlertTemplate(alertTemplateId, payload, token)
      } else {
        addAlertTemplate(payload, token)
      }
      fetchAlertTemplates(token);
      history.push(`/amm/alert-templates`)
    }
  }

  return (
    <Grid container spacing={0} direction="row" justify="center">
      <Grid item xs={8}>
        <Typography variant="h6">
          <SvgIcon className='ruc-title-first-icon'>
            <path d={isEditUrl() ? mdiPencil : mdiPlus}/>
          </SvgIcon>
          <SvgIcon><path d={mdiBell}/></SvgIcon>
          {isEditUrl() ? "Editar" : "Nueva"} Plantilla de Alerta
        </Typography>
        <form className="ruc-form">
          <Grid container spacing={3} direction="row" justify="center">
            <Grid item xs={12}>
              <TextField
                id="name"
                fullWidth
                error={!!formData.name.error}
                label={i18n(['amm', 'alert-template', 'name'])}
                required
                variant="outlined"
                value={formData.name.value}
                autoComplete="off"
                onChange={handleChange('name')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="alert_description"
                fullWidth
                error={!!formData.alert_description.error}
                label={i18n(['amm', 'alert-template', 'alert_description'])}
                required
                multiline
                row={6}
                variant="outlined"
                value={formData.alert_description.value}
                onChange={handleChange('alert_description')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="callback_description"
                fullWidth
                error={!!formData.callback_description.error}
                label={i18n(['amm', 'alert-template', 'callback_description'])}
                required
                multiline
                row={6}
                variant="outlined"
                autoComplete="off"
                value={formData.callback_description.value}
                onChange={handleChange('callback_description')}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="callback_trigger"
                fullWidth
                error={!!formData.callback_trigger.error}
                label={i18n(['amm', 'alert-template', 'callback_trigger'])}
                multiline
                row={6}
                variant="outlined"
                autoComplete="off"
                value={formData.callback_trigger.value}
                onChange={handleChange('callback_trigger')}
              />
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset" className="ruc-callback-options">
                <FormLabel component="legend">{i18n(['amm', 'alert-template', 'callback_options'])}</FormLabel>
                <JSONInput
                  id="json-input"
                  placeholder={formData.callback_options.value}
                  theme="light_mitsuketa_tribute"
                  onKeyPressUpdate={true}
                  height="100%"
                  width="100%"
                  style={{ body: { fontSize : '1.2rem'}}}
                  onChange={handleChange('callback_options')}
                  waitAfterKeyPress={2000}
                />
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="activity"
                fullWidth
                error={!!formData.activity.error}
                label={i18n(['amm', 'alert-template', 'activity'])}
                required
                multiline
                row={6}
                variant="outlined"
                select
                value={formData.activity.value}
                onChange={handleChange('activity')}
              >
                {activities.map((activity) => (
                  <MenuItem key={activity} value={activity}>
                    {i18n(['amm', 'alert-template', activity])}
                  </MenuItem>)
                )}
              </TextField>
            </Grid>
            <Grid item xs={12}>
              <TextField
                id="unit"
                fullWidth
                error={!!formData.unit.error}
                label={i18n(['amm', 'alert-template', 'unit'])}
                multiline
                row={6}
                variant="outlined"
                select
                value={formData.unit.value}
                onChange={handleChange('unit')}
              >
                {units.map((unit) => (
                  <MenuItem key={unit} value={unit}>
                    {i18n(['amm', 'alert-template', unit])}
                  </MenuItem>)
                )}
              </TextField>
            </Grid>
          </Grid>
          <div className="ruc-form-button-wrapper">
          <Button row={6}variant="contained" color="primary" onClick={handleSubmit}>{isEditUrl() ? i18n(['amm', 'actions', 'update']) : i18n(['amm', 'actions', 'save'])}</Button>
          <Link to="/amm/alert-templates">
            <Button row={6}variant="contained" color="default" >Volver</Button>
          </Link>
          </div>
        </form>
      </Grid>
    </Grid>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(AlertTemplateFormComponent)