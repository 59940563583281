import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    routesViewRoot: {
        display: 'flex',
        flexDirection: 'column'
    },

    root: {
        flexGrow: 1,
        display: 'flex',
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        overflowX: 'hidden',
    },

    openLeftControl: {
        marginLeft: 240
    },

    openRightControl: {
        marginRight: 425
    },

    nested: {
        paddingLeft: theme.spacing(4),
    },

    listItemIcon: {
        minWidth: 30,
    },

    formPaper: {
        margin: '6vh auto auto',
        width: 600,
        minHeight: 200,
        padding: '30px 50px 100px',
        position: 'relative',
    },

    formPaperWide: {
        margin: '6vh auto auto',
        minHeight: 200,
        padding: '30px 50px 100px',
        position: 'relative',
        minWidth: '80%',
    },

    listPaper: {
        margin: '6vh auto auto',
        padding: 50,
        minWidth: '80%',
        position: 'relative',
    },

    editRoleActionContainer: {
        margin: '20px auto',
        padding: theme.spacing(3),
        minWidth: '80%',
        position: 'relative',
    },
}))
