import { mergeRight } from 'ramda'

import {
    GET_ALERT_CONFIGURATIONS_SUCCESS,
    GET_ALERT_CONFIGURATION_SUCCESS,
    CLEAR_ALERT_CONFIGURATION,
    GET_ALERT_TEMPLATES_SUCCESS,
    GET_CATEGORIES_SUCCESS
} from './actions'

const initialState = {
    alertConfigurations: [],
    alertConfiguration: {},
    alertTemplates: [],
    categories: []
}

export default function alertConfigurationsReducer(state = initialState, action) {
    const { type, payload } = action
    const merge = mergeRight(state)

    switch (type) {
        case GET_ALERT_CONFIGURATIONS_SUCCESS:
            return merge({ alertConfigurations: payload })
        case GET_ALERT_CONFIGURATION_SUCCESS:
            return merge({ alertConfiguration: payload })
        case CLEAR_ALERT_CONFIGURATION:
            return merge({ alertConfiguration: {} })
        case GET_ALERT_TEMPLATES_SUCCESS:
            return merge({ alertTemplates: payload })
        case GET_CATEGORIES_SUCCESS:
            return merge({ categories: payload })
        default:
            return state
    }
}