import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useHistory } from 'react-router-dom'
import { connect } from 'react-redux'
import { path } from 'ramda'
import { Button, Typography, Table, TableHead, TableRow, TableCell, TableBody, IconButton, Tooltip } from '@material-ui/core'
import { mdiPencilCircle } from '@mdi/js'

import { ModalComponent, SvgIconComponent } from '../..'
import { fetchUsers as _fetchUsers, deleteUser as _deleteUser } from '../../../redux/users/actions'
import { i18n as i18nService } from '../../../services/util'
import styles from './styles'

const mapStateToProps = state => ({
    token: path(['user', 'auth_token'], state),
    users: path(['users', 'data'], state)
})

const mapDispatchToProps = dispatch => ({
    fetchUsers: (token, userId) => dispatch(_fetchUsers(token, userId)),
    deleteUser: (token, userId) => dispatch(_deleteUser(token, userId)),
})

function ListUsersComponent({
    token,
    users,
    fetchUsers,
    deleteUser,
}) {
    const classes = styles()
    const history = useHistory()
    const i18n = prop => i18nService(['admin', 'users_list', prop])
    const selectedKeys = [
        { value: 'name', label: i18n('table_name') },
        { value: 'username', label: i18n('table_user_name') },
        { value: 'main_role_id', label: i18n('table_role') },
        { value: 'email', label: i18n('table_email') }
    ]
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const [deleteModalShow, setDeleteModalShow] = useState(false)

    useEffect(() => {
        fetchUsers(token)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    function editUsersHandler({ id }) {
        return function () {
            history.push(`/admin/users/${id}/edit`)
        }
    }

    // function removeHanlder(user) {
    //     return function() {
    //         setSelectedToDelete(user)
    //         setDeleteModalShow(true)
    //     }
    // }

    function closeRemoveModal() {
        setDeleteModalShow(false)
        setSelectedToDelete(null)
    }

    function confirmRemove() {
        deleteUser(selectedToDelete.id, token)
    }

    return (
        <React.Fragment>
            <Typography variant="h5" align="center">{i18n('title')}</Typography>
            <Table size="small" classes={{ root: classes.tableRoot }}>
                <TableHead>
                    <TableRow>
                        {selectedKeys.map((column, k) => (
                            <TableCell key={k}>{column.label}</TableCell>
                        ))}
                        <TableCell>{i18n('table_actions')}</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {users.map((user, k) => (
                        <TableRow key={k}>
                            {selectedKeys.map((item, k) => (
                                <TableCell key={k}>{user[item.value]}</TableCell>
                            ))}
                            <TableCell style={{ whiteSpace: 'nowrap' }}>
                                <Tooltip title={i18n('tooltip_edit')}>
                                    <IconButton onClick={editUsersHandler(user)}>
                                        <SvgIconComponent path={mdiPencilCircle} />
                                    </IconButton>
                                </Tooltip>
                                {/* <Tooltip title="Eliminar">
                                    <IconButton onClick={removeHanlder(user)}>
                                        <SvgIconComponent path={mdiAccountMultipleRemove} />
                                    </IconButton>
                                </Tooltip> */}
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>

            <ModalComponent open={deleteModalShow} onClose={closeRemoveModal}>
                <div className={classes.modalWrapper}>
                    <Typography variant="h5" align="center">Confirma eliminar el el Usuario<br />{selectedToDelete && selectedToDelete.name}</Typography>
                    <div className={classes.modalButtonContainer}>
                        <Button color="default" variant="contained" onClick={() => closeRemoveModal()}>Cancelar</Button>
                        <Button color="secondary" variant="contained" onClick={() => confirmRemove()}>Confirmar</Button>
                    </div>
                </div>
            </ModalComponent>
        </React.Fragment>
    )
}

ListUsersComponent.propTypes = {
    users: PropTypes.array.isRequired
}

ListUsersComponent.defaultProps = {
    users: []
}

export default connect(mapStateToProps, mapDispatchToProps)(ListUsersComponent)
