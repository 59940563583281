import { makeStyles } from '@material-ui/core'

export default makeStyles(theme => ({
    routesMapRoot: {
        position: 'relative',
        flexGrow: 1,
        paddingBottom: 24,
    },

    mapIcon: {
        color: theme.palette.primary.light,
    },

    button: {
        border: 'none',
        background: 'none',
        color: theme.palette.primary.light,
        cursor: 'pointer',
        textDecoration: 'underline',

        '&:hover': {
            color: theme.palette.primary.dark,
        },

        '&:focus': {
            outline: 'none'
        }
    },

    p: {
        margin: '0 !important'
    },

    popupTable: {
        whiteSpace: 'nowrap',

        '& tr:last-child': {
            '& td': {
                border: 'none'
            }
        }
    }
}))
