import React from 'react'
import { Card, CardHeader, CardContent, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core'
import { mdiCheckboxBlank, mdiCheckboxMarked } from '@mdi/js'

import { SvgIconComponent } from '..'
import styles from './styles'

function ActionListCheckerComponent({ title, items, onChange }) {
    const classes = styles()

    return (
        <React.Fragment>
            <Card>
                <CardHeader title={title} classes={{ root: classes.titleRoot, title: classes.title }} />
                <CardContent classes={{ root: classes.contentRoot }}>
                    <List>
                        {items.map((item, key) =>
                            <ListItem key={key} button onClick={onChange(title, key, !item.active)} classes={{ root: classes.itemRoot }}>
                                <ListItemIcon classes={{ root: classes.listItemIcon }}>
                                    <SvgIconComponent path={item.active ? mdiCheckboxMarked : mdiCheckboxBlank} />
                                </ListItemIcon>
                                <ListItemText primary={item.name} />
                            </ListItem>
                        )}
                    </List>
                </CardContent>
            </Card>
        </React.Fragment>
    )
}

export default ActionListCheckerComponent
