import { curry, assoc, reduce, keys, isEmpty, pathOr, /*__,*/ isNil } from 'ramda'
import format from 'date-fns/format'
import lightFormat from 'date-fns/lightFormat'

// eslint-disable-next-line
export const EMAIL_REGEX_PATTER = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

export function parsePoints(raw = []) {
    const points = []
    const max = raw.length
    let i = 0

    while (i < max) {
        points.push({
            lat: raw[i].origin_latitude,
            lng: raw[i].origin_longitude,
            status: raw[i].status
        })

        i += 1
    }

    return points
}

export function jsonToUriSearch (jsonSearch) {
    const uriString = Object.keys(jsonSearch).map(key => {
        let tempUri = ''
        const value = jsonSearch[key]
        if (value === null) {
            return ''
        }

        if (['String', 'Number', 'Boolean'].indexOf(value.constructor.name) >= 0) {
            tempUri += isEmpty(value) ? key : `${key}=${value}`
        } else if (value.constructor.name === 'Array') {
            tempUri += value.map(item => (`${key}[]=${item}`)).join('&')
        }

        return tempUri
    }).join('&')

    return uriString.replace(/&$/g, '')
}

export function getPercentage(item, total) {
    return parseInt(item * 100 / total)
}

export const renameKeys = curry((keysMap, obj) =>
    reduce((acc, key) => assoc(keysMap[key] || key, obj[key], acc), {}, keys(obj))
)

export function parseDate(date) {
    const resp = new Date(date).toLocaleDateString()
    if (resp === 'Invalid Date') {
        return date
    }
    return resp
}

export function parseTime(dateTime) {
    const date = new Date(dateTime)
    if (date.toString() === 'Invalid Date' || typeof dateTime === "boolean") {
        return dateTime
    }
    return format(date, 'HH:mm')
}

export function parseDateTime(dateTime) {
    const date = new Date(dateTime)
    if (date.toString() === 'Invalid Date' || typeof dateTime === "boolean") {
        return dateTime
    }
    return format(date, 'dd-MM-yyyy HH:mm')
}

export function i18n (path, placeholder) {
    return pathOr(placeholder || '--', ['i18n', ...path], window)
}

export function filterDataParser(data, filtersByView, activeSource) {
    const keys = filtersByView[activeSource]
    let response = {}
    keys.forEach(item => {
        if (typeof data[item] === 'string') {
            if (!isEmpty(data[item])) {
                response = {...response, [item]: data[item]}
            }
        } else if (typeof data[item] === 'boolean') {
            if (data[item]) {
                response = {...response, [item]: ''}
            }
        } else if (data[item] instanceof Date) {
            if (data[item]) {
                response = {...response, [item]: lightFormat(data[item], 'yyyy-MM-dd HH:mm')}
            }
        } else {
            if (!isNil(data[item])) {
                response = {...response, [item]: data[item].value || data[item]}
            }
        }
    })

    if (activeSource === 'items') {
        response = {
            by_item_type: 'STOP',
            sort: 'arrives_at',
            ...response,
        }
    }
    return response
}

export function getCurrentDate(separator='-', full=false, time=''){

    let newDate = new Date()
    let date = newDate.getDate();
    let month = newDate.getMonth() + 1;
    let year = newDate.getFullYear();
    let complete_day = `${year}${separator}${month<10?`0${month}`:`${month}`}${separator}${date<10?`0${date}`:`${date}`}`

    if(full) {
        let hour = newDate.getHours()
        let minutes = newDate.getMinutes()
        let seconds = newDate.getSeconds()
        complete_day = `${complete_day} ${hour}:${minutes}:${seconds}`
    }

    if(time) {
        complete_day = `${complete_day} ${time}`
    }
    
    return complete_day
}

export function stringToSnakeCase(str) {
    return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`)
}
